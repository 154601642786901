import React, { Component } from "react";
import axios from "axios";
import { message, Empty } from "antd";
import MyNotebooks from "./MyNotebooks";
import Loader from "../common/Loader";

export class Saved extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notebooks: null,
      loading: false,
      limit: 100,
      offset: 0,
      // sortBy: null,
      // sortOrder: null,
      // searchQuery: "",
    };
  }

  componentDidMount() {
    this.getSavedNotebooks();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.reloadNotebook) {
      this.setState({ offset: 0 }, () => this.getSavedNotebooks());
    }
  }

  getSavedNotebooks(obj = {}) {
    this.setState({ loading: true });
    axios
      .get(`${process.env.REACT_APP_API_HOST}/api/notebooks/saved`, {
        params: {
          limit: this.state.limit,
          offset: this.state.offset,
          sortBy: obj.sortBy,
          sortOrder: obj.sortOrder,
          searchQuery: obj.searchQuery,
        },
      })
      .then((res) => {
        // console.log(res.data);
        if (res.data.success) {
          this.setState({
            notebooks: res.data.savedNotebooks,
            loading: false,
            offset: this.state.offset + res.data.savedNotebooks.length,
          });
        }
      })
      .catch((err) =>
        message.error("Error connecting to server. Please reload this page.")
      );
  }

  render() {
    const { theme } = this.props;
    const { notebooks, loading } = this.state;
    return (
      <div>
        {" "}
        <div
          style={{
            position: "absolute",
            top: "0",
            width: "100%",
            height: "40px",
            borderBottom: "2px solid #d1d1d1",
            fontSize: "20px",
            fontWeight: "500",
            fontFamily: "Roboto",
            padding: "5px 10px",
            color: theme.color,
          }}
        >
          Saved Notebooks
        </div>
        <div>
          {loading ? (
            <Loader />
          ) : notebooks && notebooks.length === 0 ? (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{
                height: 60,
              }}
              description={
                <span style={{ color: theme.color }}>
                  Books & Notebooks that you save will appear here.
                </span>
              }
            ></Empty>
          ) : (
            <MyNotebooks
              displayHeader={false}
              notebooks={notebooks}
              getNotebooks={(obj) => this.getSavedNotebooks(obj)}
              leftPanelWidth={this.props.leftPanelWidth}
              selectNote={(note) => this.props.selectNote(note)}
              reverseNotebooks={() =>
                this.setState({ notebooks: this.state.notebooks.reverse() })
              }
              // noteUpdated={this.props.noteEdited}
              // selectedNote={this.props.selectedNote}
              // shared={true}
              selectNotebook={this.props.selectNotebook}
            />
          )}
        </div>
      </div>
    );
  }
}

export default Saved;
