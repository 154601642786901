import React, { Component } from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";

export class UnderConstruction extends Component {
  render() {
    return (
      <div style={{ textAlign: "center" }}>
        <img
          src={`${process.env.REACT_APP_CDN_ADDRESS}/file/ntbq-01/resources/landing/under-construction.jpg`}
          alt={"Under Construction"}
          style={{
            maxWidth: "500px",
            width: window.outerWidth,
            margin: "10px",
          }}
        />
        <h2>Work in progress</h2>
        <div style={{ margin: "10px 0px", fontSize: "18px" }}>
          Ssshhhh, they are working. Please come back soon.{" "}
        </div>
        <Link to={"/"}>
          <Button type="primary" size="large">
            Back Home
          </Button>
        </Link>
      </div>
    );
  }
}

export default UnderConstruction;
