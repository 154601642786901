import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";

import {
  GET_ERRORS,
  SET_CURRENT_USER,
  CHANGE_THEME,
  SET_CONNECTION_ERROR,
  SET_DEVICE,
  TOGGLE_MOBILE_SIDEBAR,
} from "./types";
import { message } from "antd";
// Register User
export const registerUser = (userData, history) => (dispatch) => {
  axios
    .post(`${process.env.REACT_APP_API_HOST}/api/users/register`, userData)
    .then((res) => {
      if (res.data.errors) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.errors,
        });
      } else {
        message.success("Successfully Registered. Login to Continue.");
        history.push("/login");
      }
    })
    .catch((err) => console.log(err));
};

// Login - Get User Token
export const loginUser =
  (userData, firstLogin = false) =>
  (dispatch) => {
    if (!firstLogin) {
      axios
        .post(`${process.env.REACT_APP_API_HOST}/api/users/login`, userData)
        .then((res) => {
          // console.log(res.data);
          if (res.data.errors) {
            dispatch({
              type: GET_ERRORS,
              payload: res.data.errors,
            });
          } else {
            //Save to localStorage
            const { token } = res.data;
            // Set token to ls
            localStorage.setItem("jwtToken", token);
            // Set token to Auth Header
            setAuthToken(token);
            // Decode token to get user data
            const decoded = jwt_decode(token);

            // Set current user
            dispatch(setCurrentUser(decoded));
          }
        })
        .catch((err) => console.log(err));
    } else {
      const data = userData;
      const { token } = data;
      // Set token to ls
      localStorage.setItem("jwtToken", token);
      // Set token to Auth Header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
      window.location.href = "/";
    }
  };

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// Refresh User Information in store after editing (e.g., changing display picture)
export const refreshUserInfo = (currentUserInfo) => (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_API_HOST}/api/users/current/`)
    .then((res) => {
      res.data.iat = currentUserInfo.iat;
      res.data.exp = currentUserInfo.exp;

      dispatch(setCurrentUser(res.data));
    })
    .catch((err) => console.log(err));
};

// Refresh User Information in store after editing (e.g., changing display picture)
export const refreshUserToken = () => (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_API_HOST}/api/users/refresh-auth-token`)
    .then((res) => {
      //Save to localStorage
      const { token } = res.data;
      // Set token to ls
      localStorage.setItem("jwtToken", token);
      // Set token to Auth Header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);

      // Set current user
      dispatch(setCurrentUser(decoded));
      return;
    });
};

// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from localStorage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to  {} which will also set isAuthenticated to false
  dispatch(setCurrentUser({}));
  window.history.pushState({}, "", "/");
};

export const changeTheme = () => (dispatch) => {
  localStorage.setItem(
    "theme",
    localStorage.theme === "dark" ? "light" : "dark"
  );
  dispatch({ type: CHANGE_THEME });
};

export const setConnectionError = (isDisconnected) => (dispatch) => {
  dispatch({ type: SET_CONNECTION_ERROR, payload: isDisconnected });
};

export const setDevice = (data) => (dispatch) => {
  dispatch({ type: SET_DEVICE, payload: data });
};

export const toggleMobileSidebar = (data) => (dispatch) => {
  dispatch({ type: TOGGLE_MOBILE_SIDEBAR, payload: data });
};
