import React, { Component } from "react";
import axios from "axios";
import { Select, Tabs, Empty, Avatar } from "antd";
import { Link } from "react-router-dom";
import notFound from "../../assets/notFound.png";
import moment from "moment";

const { TabPane } = Tabs;

export class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchResults: null,
      searchSelected: false,
      searchDropdownVisible: false,
      showMobileSearch: false,
      searchQuery: "",
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.searchQuery !== this.state.searchQuery &&
      this.state.searchQuery.length > 0
    )
      this.search();

    if (prevState.searchQuery && !this.state.searchQuery)
      this.setState({ searchResults: null });
  }
  search() {
    if (!this.state.searchQuery || this.state.searchQuery.length === 0) return;
    axios
      .get(`${process.env.REACT_APP_API_HOST}/api/search/all`, {
        params: { query: this.state.searchQuery },
      })
      .then((res) => {
        // console.log(res.data);
        if (res.data.success) {
          this.setState(
            { searchResults: res.data },
            this.props.onSearch(res.data)
          );
        }
      });
  }
  render() {
    const { searchResults, searchDropdownVisible, searchSelected } = this.state;
    return (
      <div>
        <Select
          bordered={this.props.bordered || searchSelected}
          clearIcon={<i className="fas fa-times-circle"></i>}
          showSearch
          showArrow={false}
          placeholder={this.props.placeholder || "Search"}
          allowClear={searchSelected}
          onSearch={(value) => {
            this.setState({ searchQuery: value });
          }}
          onFocus={(e) =>
            this.setState({ searchSelected: true }, this.props.onFocus())
          }
          onBlur={(e) => {
            (!searchDropdownVisible || !searchResults) &&
              this.setState({ searchSelected: false }, this.props.onBlur());
          }}
          style={{
            width: "100%",
            ...this.props.style,
          }}
          size={this.props.size || "middle"}
          optionLabelProp={null}
          // addonAfter={<i className="fas fa-search"></i>}
          notFoundContent={null}
          filterOption={false}
          menuItemSelectedIcon={null}
          onMouseUp={(e) => e.preventDefault()}
          onDropdownVisibleChange={(e) =>
            this.setState({ searchDropdownVisible: e })
          }
          dropdownRender={(menu) =>
            searchResults &&
            this.props.displayResults && (
              <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                <Tabs
                  defaultActiveKey={
                    searchResults.users
                      ? "users"
                      : searchResults.notebooks
                      ? "notebooks"
                      : "notes"
                  }
                  tabBarStyle={{
                    padding: "0px 15px",
                    fontWeight: "600",
                    margin: "0px",
                  }}
                >
                  <TabPane tab="People" key="users">
                    {searchResults.users.length > 0 ? (
                      searchResults.users.map((user) => (
                        <Link key={user.id} to={`/${user.username}`}>
                          <div
                            style={{
                              height: "60px",
                              width: "100%",
                              // border: "1px solid red",
                              padding: "0px 10px",
                              borderBottom: "1px solid #ededed",
                              borderTop: "1px solid #ededed",
                              display: "grid",
                              gridTemplateColumns: "60px auto",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                //display: "inline-block",
                                // border: "1px solid red",
                                margin: "auto",
                                justifySelf: "center",
                              }}
                            >
                              <Avatar
                                size={50}
                                src={`https://${user.display_picture.downloadUrl}`}
                              />
                            </div>
                            <div
                              style={{
                                // display: "inline-block",
                                // border: "1px solid red",
                                fontSize: "14px",
                                fontWeight: "500",
                                paddingLeft: "5px",
                              }}
                            >
                              {user.name}
                              <div style={{ fontSIze: "12px" }}>
                                @{user.username}
                              </div>
                            </div>
                          </div>
                        </Link>
                      ))
                    ) : (
                      <Empty
                        // image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                        image={notFound}
                        imageStyle={{
                          height: 120,
                        }}
                        description={
                          <span style={{ fontSize: "18px" }}>
                            No User Found
                          </span>
                        }
                      ></Empty>
                    )}
                  </TabPane>
                  <TabPane tab="Books/Notebooks" key="notebooks">
                    {searchResults.notebooks.length > 0 ? (
                      searchResults.notebooks.map((notebook) => (
                        <Link
                          key={notebook.id}
                          to={`/?notebook=${notebook.id}`}
                        >
                          <div
                            style={{
                              padding: "0px 10px",
                              borderBottom: "1px solid #ededed",
                              borderTop: "1px solid #ededed",
                            }}
                          >
                            <div style={{ fontWeight: "500" }}>
                              {notebook.name}{" "}
                              <span style={{ float: "right" }}>
                                {" "}
                                {notebook.owner.name} ({notebook.owner.username}
                                )
                              </span>
                            </div>
                            {notebook.notes.length} notes
                          </div>
                        </Link>
                      ))
                    ) : (
                      <Empty
                        // image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                        image={notFound}
                        imageStyle={{
                          height: 120,
                        }}
                        description={
                          <span style={{ fontSize: "18px" }}>
                            No Notebook Found
                          </span>
                        }
                      ></Empty>
                    )}
                  </TabPane>
                  <TabPane tab="Notes" key="notes">
                    {searchResults.notes.length > 0 ? (
                      searchResults.notes.map((note) => (
                        <Link key={note.id} to={`/?note=${note.id}`}>
                          <div
                            style={{
                              padding: "5px 10px",
                              borderBottom: "1px solid #ededed",
                              borderTop: "1px solid #ededed",
                              display: "grid",
                              gridTemplateColumns: "2fr 1fr",
                              gridGap: "10px",
                            }}
                          >
                            <div style={{ fontWeight: "500" }}>
                              {note.title ? note.title : "[Untitled]"}{" "}
                              <div
                                style={{ fontSize: "12px", fontWeight: "300" }}
                              >
                                Last edited {moment(note.updatedAt).fromNow()}
                              </div>
                            </div>
                            <div style={{ fontWeight: "500" }}>
                              {" "}
                              {note.author.name} (@{note.author.username})
                            </div>
                          </div>
                        </Link>
                      ))
                    ) : (
                      <Empty
                        // image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                        image={notFound}
                        imageStyle={{
                          height: 120,
                        }}
                        description={
                          <span style={{ fontSize: "18px" }}>
                            No Note Found
                          </span>
                        }
                      ></Empty>
                    )}
                  </TabPane>
                </Tabs>
              </div>
            )
          }
          dropdownMatchSelectWidth={
            this.props.isSmallDevice && window.innerWidth
          }
        >
          {searchResults && <div style={{ display: "none" }} />}
        </Select>
      </div>
    );
  }
}

Search.defaultProps = {
  onBlur: () => {},
  onFocus: () => {},
  onSearch: () => {},
  displayResults: true,
  showPeople: true,
  showNotes: true,
  showNotebooks: true,
};

export default Search;
