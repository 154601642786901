// import { TableCellClicked } from "./elements/TableOptionToolbar";
export const addGeneralElementEvents = (remove) => {
  // Calling table events
  addTableEvents(remove);
  // Implementing delete button for all elements
  const deleteButtonEvent = (e) => {
    e.path.forEach((element) => {
      // if (element.classList) console.log(element.classList);
      if (element.classList && element.classList[0] === "element-delete-btn") {
        e.preventDefault();
        let parent = element.parentElement;
        // console.log(element);
        let grandparent = parent.parentElement;
        if (grandparent) {
          grandparent.removeChild(parent);
          grandparent.blur();
        }
      }
    });
  };
  if (remove) document.removeEventListener("click", deleteButtonEvent);
  else document.addEventListener("click", deleteButtonEvent);

  // Key Events
  const keyElementEvents = (e) => {
    if (e.key === "Enter") {
      let selection = window.getSelection();
      // console.log(selection.anchorNode.parentElement);
      if (!selection.anchorNode) return;

      // Prevting creation of new summary element pressing enter on summary
      if (
        selection.anchorNode.parentElement.classList[0] ===
        "expand__summary__content"
      )
        return e.preventDefault();

      // Adding a node if there is none after element
      if (
        (selection.anchorNode.parentElement.classList[0] === "ed-action-item" &&
          selection.anchorNode.parentElement &&
          selection.anchorNode.parentElement.parentElement) ||
        (((selection.anchorNode.parentElement.classList.value.includes(
          "ed-columns"
        ) &&
          !selection.anchorNode.parentElement.nextSibling) ||
          (selection.anchorNode.parentElement.parentElement.classList.value.includes(
            "ed-columns"
          ) &&
            !selection.anchorNode.parentElement.parentElement.nextSibling)) &&
          !e.shiftKey) ||
        ((selection.anchorNode.parentElement.parentElement.parentElement.classList.value.includes(
          "ed-expand"
        ) ||
          selection.anchorNode.parentElement.parentElement.parentElement.parentElement.classList.value.includes(
            "ed-expand"
          )) &&
          !e.shiftKey) ||
        (selection.anchorNode.parentElement.classList[0] === "ed-panel" &&
          !e.shiftKey) ||
        ((selection.anchorNode.parentElement.parentElement.parentElement.classList.value.includes(
          "ed-message"
        ) ||
          selection.anchorNode.parentElement.parentElement.classList.value.includes(
            "ed-message"
          )) &&
          !e.shiftKey) ||
        ((selection.anchorNode.parentElement.parentElement.parentElement.classList.value.includes(
          "ed-blockquote"
        ) ||
          selection.anchorNode.parentElement.parentElement.classList.value.includes(
            "ed-blockquote"
          )) &&
          !e.shiftKey)
      ) {
        e.preventDefault();
        let edElement = null;
        let parent = selection.anchorNode.parentElement;
        while (parent.id !== "ed-editor-id") {
          if (
            !edElement &&
            parent.classList &&
            parent.classList.value.includes("ed-")
          )
            edElement = parent;
          if (parent.parentElement) parent = parent.parentElement;
        }

        // console.log(edElement);
        // let parent = document.getElementById("ed-editor-id");
        // var br = document.createElement("br");
        var textNode = document.createTextNode("\u00a0"),
          newNode = document.createElement("div");
        newNode.appendChild(textNode);

        // let index = Array.from(parent.children).indexOf(
        //   selection.anchorNode.parentElement.parentElement.parentElement
        // );
        // console.log("First ", index);
        // if (index === -1)
        //   index = Array.from(parent.children).indexOf(
        //     selection.anchorNode.parentElement.parentElement
        //   );
        // console.log(index);
        // if (index === -1 || index === parent.children.length - 1) {
        // if (edElement.classList.value.includes("ed-columns")) {

        //   return;
        // }
        // console.log(edElement.nextSibling);
        // console.log(edElement.parentElement.nextSibling);
        if (!edElement.nextSibling && !edElement.parentElement.nextSibling) {
          // parent.appendChild(br);
          parent.appendChild(newNode);
        } else {
          // parent.insertBefore(newNode, parent.children[index + 1]);
          parent.insertBefore(
            newNode,
            // parent.children[
            //   Array.from(parent.children).indexOf(edElement.parentElement) + 1
            // ]
            (edElement.classList.value.includes("ed-message") ||
              edElement.classList.value.includes("ed-columns") ||
              edElement.classList.value.includes("ed-panel") ||
              edElement.classList.value.includes("ed-expand")) &&
              edElement.parentElement.classList.value === ""
              ? edElement.parentElement.nextSibling
              : edElement.nextSibling
          );
          // parent.insertBefore(br, textNode);
        }
        // // newElement.focus();
        var range = document.createRange();
        selection.empty();

        // range.selectNode(br);
        range.selectNode(textNode);
        // range.setStart(newElement, 0);
        // range.setStart(newElement, 0);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  };

  if (remove) document.removeEventListener("keydown", keyElementEvents);
  else document.addEventListener("keydown", keyElementEvents);
};

// Storing checkbox's state in it
export const addCheckboxEvents = (remove) => {
  const checkBoxEvent = (e) => {
    e.path.forEach((element) => {
      // if (element.classList) console.log(element.classList);

      if (
        element.classList &&
        element.classList[0] === "ed-action-item-checkbox"
      ) {
        if (!remove) {
          if (element.attributes.checked) element.removeAttribute("checked");
          else element.setAttribute("checked", true);
        } else {
          e.preventDefault();
        }
      }
    });
  };

  document.addEventListener("click", checkBoxEvent);
  // if (remove) document.removeEventListener("click", checkBoxEvent);
  // else document.addEventListener("click", checkBoxEvent);
};

// Adding image events
export const addImageResizeEvents = (remove) => {
  const imageResizeEvent = (e) => {
    if (e.isDefaultPrevented) return;
    e.path.forEach((element) => {
      if (
        element.classList &&
        Array.from(element.classList).includes("ed-image-container")
      ) {
        element.style.resize = "both";
        var resizeObserver = new ResizeObserver((entries) => {
          entries.forEach((entry) => {
            // console.log(entry);
            e.target.style.height = entry.contentRect.height;
            e.target.style.width = entry.contentRect.width;
          });
        }).observe(element);

        let removeObserver = (event) => {
          event.preventDefault();
          // console.log("Removing observer");
          if (event.target !== e.target) {
            element.style.resize = "none";
            if (resizeObserver) resizeObserver.unobserve(element);
            document.execCommand("insertText", false, " ");
            document.execCommand("delete", false);
            // document.getElementById("ed-editor-id").textContent += " ";
            document.removeEventListener("click", removeObserver);
          } else return;
        };
        document.addEventListener("click", removeObserver);
        return;
      }
    });
  };
  if (remove) document.removeEventListener("click", imageResizeEvent);
  else document.addEventListener("click", imageResizeEvent);
};

// Table events
export const addTableEvents = (remove) => {
  // Prevent option toolbar by backspace
  const preventOptionsDelete = (e) => {
    let selection = window.getSelection();
    if (
      e.key === "Backspace" &&
      selection.anchorNode &&
      selection.anchorNode.classList
    ) {
      if (
        selection.anchorNode.children.length > 0 &&
        selection.anchorNode.children[0].classList.value.includes("ed-table")
      ) {
        e.preventDefault();
      }
    }
  };
  document.addEventListener("keydown", preventOptionsDelete);

  // For options actions on table
  const optionsAction = (e) => {
    let clickedElement = e.path.filter(
      (node) =>
        node && node.classList && node.classList.value.includes("table-action")
    );
    if (clickedElement.length > 0) {
      clickedElement = clickedElement[0];
      // console.log(clickedElement.classList.value);
    } else {
      return;
    }
    let tableContainer = clickedElement.parentElement.parentElement;
    let table = tableContainer.firstChild;
    // console.log(table);
    let selection = window.getSelection();
    let tableBody = Array.from(table.children).filter(
      (node) => node && node.tagName === "TBODY"
    );
    if (tableBody.length > 0) tableBody = tableBody[0];
    else return;

    // console.log(tableBody);

    let cell = selection.anchorNode.parentElement;
    if (
      (cell.tagName !== "TD" || cell.tagName !== "TH") &&
      (cell.parentElement.tagName === "TD" ||
        cell.parentElement.tagName === "TH")
    ) {
      cell = cell.parentElement;
    }

    // When  a cell is empty
    if (cell.tagName === "TR") cell = selection.anchorNode;

    // if(clickedElement.classList.value.incudes("row"))
    let row, newRow;
    if (cell.tagName === "TD") {
      //Row
      row = cell.parentElement;
      newRow = row.cloneNode(true);
    } else {
      // Row
      row = tableBody.children[1];
      newRow = row.cloneNode(true);
    }
    Array.from(newRow.children).map((node) => {
      node.innerHTML = "";
      node.setAttribute("data-placeholder", "  ");
      return node;
    });

    // Columns
    let position = -1;
    var i; // iterator
    if (cell.tagName === "TD" || cell.tagName === "TH") {
      position = Array.from(cell.parentElement.children).indexOf(cell);
    }
    // Using classList.value in switch doesn't work because of focus visible class on click
    switch (clickedElement.classList[0]) {
      case "table-action-add-row-before":
        // console.log("Adding row before");
        if (cell.tagName === "TD") {
          tableBody.insertBefore(newRow, row);
        } else {
          tableBody.insertBefore(newRow, tableBody.children[1]);
        }
        break;
      case "table-action-add-row-after":
        // console.log("Adding row after");

        if (cell.tagName === "TD") {
          if (row.nextSibling) tableBody.insertBefore(newRow, row.nextSibling);
          else tableBody.appendChild(newRow);
        } else {
          tableBody.appendChild(newRow);
        }
        break;
      case "table-action-delete-current-row":
        // console.log("Deleting current row");
        if (cell.tagName === "TD" || cell.tagName === "TH") {
          tableBody.removeChild(row);
        } else tableBody.removeChild(tableBody.lastChild);
        break;
      case "table-action-add-column-before":
        // console.log("Adding column before");
        if (position === -1) position = 0;
        for (i = 0; i < tableBody.children.length; i++) {
          let currentCell = tableBody.children[i].children[position];
          if (!currentCell) return;
          let newCell = currentCell.cloneNode(true);
          newCell.innerHTML = "";
          newCell.setAttribute("data-placeholder", "    ");

          currentCell.parentElement.insertBefore(newCell, currentCell);
        }

        break;
      case "table-action-add-column-after":
        // console.log("Adding column after");
        if (position === -1) position = tableBody.children.length - 1;
        for (i = 0; i < tableBody.children.length; i++) {
          let currentCell = tableBody.children[i].children[position];
          if (!currentCell) return;
          let newCell = currentCell.cloneNode(true);
          newCell.innerHTML = "";
          newCell.setAttribute("data-placeholder", "    ");
          if (currentCell.nextSibling)
            currentCell.parentElement.insertBefore(
              newCell,
              currentCell.nextSibling
            );
          else currentCell.parentElement.appendChild(newCell);
        }
        break;
      case "table-action-delete-current-column":
        // console.log("Delete current column");
        if (position === -1) position = tableBody.children.length - 1;
        for (i = 0; i < tableBody.children.length; i++) {
          let currentCell = tableBody.children[i].children[position];
          if (!currentCell) return;
          currentCell.parentElement.removeChild(currentCell);
        }
        break;
      case "table-action-toggle-stripes":
        if (Array.from(table.classList).includes("is-striped"))
          table.classList.remove("is-striped");
        else table.classList.add("is-striped");
        break;
      case "table-action-toggle-borders":
        if (Array.from(table.classList).includes("is-bordered")) {
          table.classList.remove("is-bordered");
        } else table.classList.add("is-bordered");
        break;
      case "table-action-delete-table":
        tableContainer.parentElement.removeChild(tableContainer);
        break;
      default:
        break;
    }
    document.execCommand("insertText", false, " ");
    document.execCommand("delete");
  };
  document.addEventListener("click", optionsAction);

  // Column Resized Events
  // Read next function first
  const createResizableColumn = function (col, resizer) {
    // Track the current position of mouse
    let x = 0;
    let w = 0;

    const mouseDownHandler = function (e) {
      // Get the current mouse position
      x = e.clientX;

      // Calculate the current width of column
      const styles = window.getComputedStyle(col);
      w = parseInt(styles.width, 10);

      // Adding resizing class for css
      resizer.classList.add("resizing");

      // Attach listeners for document's events
      document.addEventListener("mousemove", mouseMoveHandler);
      document.addEventListener("mouseup", mouseUpHandler);
    };

    const mouseMoveHandler = function (e) {
      // Determine how far the mouse has been moved
      const dx = e.clientX - x;

      // Update the width of column
      col.style.width = `${w + dx}px`;
    };

    // When user releases the mouse, remove the existing event listeners
    const mouseUpHandler = function () {
      // Removing resizing class
      resizer.classList.remove("resizing");

      document.removeEventListener("mousemove", mouseMoveHandler);
      document.removeEventListener("mouseup", mouseUpHandler);
    };

    resizer.addEventListener("mousedown", mouseDownHandler);
  };

  // Adding resizing handles to columns
  const addColumnResizingHandles = (e) => {
    let tableContainer = e.path.filter(
      (node) =>
        node && node.classList && node.classList.value.includes("ed-table")
    );
    if (!tableContainer.length > 0) return;
    tableContainer = tableContainer[0];

    let table = tableContainer.firstChild;
    if (!table) return;
    let tableBody = Array.from(table.children).filter(
      (node) => node && node.tagName === "TBODY"
    );
    if (tableBody.length > 0) tableBody = tableBody[0];
    else return;
    let tableHeader = tableBody.children[0];

    Array.from(tableHeader.children).forEach((column) => {
      // console.log(
      //   Array.from(column.children).filter((child) =>
      //     child.classList.value.includes("col-resizer-handle")
      //   )
      // );
      // If handle is already present in the column
      if (
        Array.from(column.children).filter((child) =>
          child.classList.value.includes("col-resizer-handle")
        ).length > 0
      )
        return;
      // Create a resizer element
      const colResizerHandle = document.createElement("div");
      colResizerHandle.classList.add("col-resizer-handle");

      // console.log("Adding resizer handle");
      // Set the height
      colResizerHandle.style.height = `${table.offsetHeight}px`;

      // Add a resizer element to the column
      column.appendChild(colResizerHandle);

      // Will be implemented in the next section
      createResizableColumn(column, colResizerHandle);
      document.execCommand("insertText", false, " ");
      document.execCommand("delete", false);
    });
  };
  document.addEventListener("click", addColumnResizingHandles);

  // For table cell tooltip
  // const tableClicked = (e) => {
  //   let tableContainer = e.path.filter(
  //     (node) =>
  //       node && node.classList && node.classList.value.includes("ed-table")
  //   );
  //   if (tableContainer.length > 0) TableCellClicked();
  // };
  // document.addEventListener("click", tableClicked);
};
