import React, { Component } from "react";
import { Editor } from "@tinymce/tinymce-react";
// import "./editor.scss";

export class TinyEditor extends Component {
  constructor(props) {
    super(props);
    this.editorRef = React.createRef(null);
    this.imageUploadHandler = this.imageUploadHandler.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    // let itemArray = document.getElementsByClassName(
    //   "tox-icon tox-tbtn__icon-wrap"
    // );
    // console.log(itemArray);
    // for (let i = 0; i < itemArray.length; i++) {
    //   document.getElementsByClassName("tox-icon tox-tbtn__icon-wrap")[
    //     i
    //   ].style.transform = "scale(0.5)";
    // }
  }

  imageUploadHandler(blobInfo, success, failure, progress) {
    // console.log("Uploading image");
    if (!this.props.noteId)
      failure("Please write something to save the note first.");
    var xhr,
      formData,
      fileName = Date.now();

    xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open(
      "POST",
      `${process.env.REACT_APP_API_HOST}/api/notes/upload-image`
    );
    xhr.setRequestHeader("Authorization", localStorage.jwtToken);
    xhr.setRequestHeader("noteid", this.props.noteId);
    xhr.setRequestHeader("fileid", fileName);

    xhr.upload.onprogress = function (e) {
      progress((e.loaded / e.total) * 100);
    };

    xhr.onload = function () {
      var json;

      if (xhr.status === 403) {
        failure("HTTP Error: " + xhr.status, { remove: true });
        return;
      }

      if (xhr.status < 200 || xhr.status >= 300) {
        failure("HTTP Error: " + xhr.status);
        return;
      }

      json = JSON.parse(xhr.responseText);
      // console.log(json);

      if (!json || typeof json.location != "string") {
        failure("Invalid JSON: " + xhr.responseText);
        return;
      }

      success(json.location);
    };

    xhr.onerror = function () {
      failure(
        "Image upload failed due to a XHR Transport error. Code: " + xhr.status
      );
    };

    formData = new FormData();
    formData.append("file", blobInfo.blob(), fileName);

    xhr.send(formData);
  }

  render() {
    const {
      readOnly,
      theme,
      content,
      showToolbar,
      fontSize,
      lineHeight,
      isSmallDevice,
    } = this.props;

    // Adding my custom plugins
    if (window.tinymce) {
      // Slash Command plugin
      window.tinymce.PluginManager.add("slashcommands", function (editor) {
        var insertActions = [
          // {
          //   text: "Insert Grid",
          //   icon: "G",
          //   action: function () {
          //     editor.execCommand(
          //       "mceInsertContent",
          //       false,
          //       `<div class="columns">
          //         <div class="column" style="border: 1px solid #d1d1d1; border-radius: 5px; margin:5px;">.col-md-6</div>
          //         <div class="column" style="border: 1px solid #d1d1d1; border-radius: 5px; margin:5px;">.col-md-6</div>
          //        </div><br />`
          //       // `<div class="row">
          //       //   <div class="col-md-5" style="border: 1px solid #d1d1d1; border-radius: 5px; margin:5px 10px;">.col-md-6</div>
          //       //   <div class="col-md-5" style="border: 1px solid #d1d1d1; border-radius: 5px; margin:5px 10px;">.col-md-6</div>
          //       //  </div>\n`
          //     );
          //     editor.selection.select(editor.selection.getNode());
          //   },
          // },
          // {
          //   text: "Insert Toggle",
          //   icon: "chevron-right",
          //   action: function () {
          //     editor.execCommand(
          //       "mceInsertContent",
          //       false,
          //       `<div class="ed-toggle">
          //       <input type="checkbox" id="section1" class="ed-toggle__input">
          //        <label for="section1" class="ed-toggle__label">Section #1</label>
          //        <div class="ed-toggle__content">
          //         <p>
          //           Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa, earum, magnam? Alias error, placeat! Accusamus accusantium alias eum iure laborum odit porro quaerat? Animi enim id labore officiis quam sint.
          //         </p>
          //       </div>
          //       </div><br />`
          //       // `<div class="row">
          //       //   <div class="col-md-5" style="border: 1px solid #d1d1d1; border-radius: 5px; margin:5px 10px;">.col-md-6</div>
          //       //   <div class="col-md-5" style="border: 1px solid #d1d1d1; border-radius: 5px; margin:5px 10px;">.col-md-6</div>
          //       //  </div>\n`
          //     );
          //     editor.selection.select(editor.selection.getNode());
          //   },
          // },
          {
            text: "Insert Image",
            icon: "image",
            action: function () {
              editor.execCommand("mceImage");
              editor.selection.select(editor.selection.getNode());
            },
          },
          {
            text: "Insert Table",
            icon: "table",
            action: function () {
              editor.execCommand("mceInsertTable", false, {
                rows: 3,
                columns: 2,
              });
              editor.selection.select(editor.selection.getNode());
            },
          },
          {
            type: "separator",
          },
          {
            text: "Heading 1",
            icon: "h1",
            action: function () {
              editor.execCommand(
                "mceInsertContent",
                false,
                "<h1>Heading 1</h1>"
              );
              editor.selection.select(editor.selection.getNode());
            },
          },
          {
            text: "Heading 2",
            icon: "h2",
            action: function () {
              editor.execCommand(
                "mceInsertContent",
                false,
                "<h2>Heading 2</h2>"
              );
              editor.selection.select(editor.selection.getNode());
            },
          },
          {
            text: "Heading 3",
            icon: "h3",
            action: function () {
              editor.execCommand(
                "mceInsertContent",
                false,
                "<h3>Heading 3</h3>"
              );
              editor.selection.select(editor.selection.getNode());
            },
          },
          {
            type: "separator",
          },
          {
            text: "Insert Horizontal Rule",
            icon: "horizontal-rule",
            action: function () {
              editor.execCommand("InsertHorizontalRule");
            },
          },
          {
            type: "separator",
          },
          {
            text: "Bulleted list",
            icon: "unordered-list",
            action: function () {
              editor.execCommand("InsertUnorderedList", false);
            },
          },
          {
            text: "Numbered list",
            icon: "ordered-list",
            action: function () {
              editor.execCommand("InsertOrderedList", false);
            },
          },
        ];

        // Register the slash commands autocompleter
        editor.ui.registry.addAutocompleter("slashcommands", {
          ch: "/",
          minChars: 0,
          columns: 1,
          fetch: function (pattern) {
            const matchedActions = insertActions.filter(function (action) {
              return (
                action.type === "separator" ||
                action.text.toLowerCase().indexOf(pattern.toLowerCase()) !== -1
              );
            });

            return new window.tinymce.util.Promise(function (resolve) {
              var results = matchedActions.map(function (action) {
                return {
                  meta: action,
                  text: action.text,
                  icon: action.icon,
                  value: action.text,
                  type: action.type,
                };
              });
              resolve(results);
            });
          },
          onAction: function (autocompleteApi, rng, action, meta) {
            editor.selection.setRng(rng);
            // Some actions don't delete the "slash", so we delete all the slash
            // command content before performing the action
            editor.execCommand("Delete");
            meta.action();
            autocompleteApi.hide();
          },
        });
        // // Display placeholder hint
        // editor.ui.registry.addAutocompleter("slashcommandshint", {
        //   ch: "\n",
        //   minChars: 0,
        //   columns: 1,
        //   fetch: function (pattern) {
        //     return new window.tinymce.util.Promise(function (resolve) {
        //       var results = [{ text: "Press / for commands" }];
        //       resolve(results);
        //     });
        //   },
        //   onAction: function (autocompleteApi, rng, action, meta) {
        //     return;
        //   },
        // });

        return {};
      });
    }

    let fontImportUrl = `https://fonts.googleapis.com/css2?family=Abel&family=Amiri&family=Architects+Daughter&family=Arima+Madurai&family=Bad+Script&family=Cairo&family=Caveat&family=Courgette&family=Crete+Round&family=Crimson+Text&family=Dancing+Script&family=Fauna+One&family=Fjalla+One&family=Girassol&family=Great+Vibes&family=Indie+Flower&family=Itim&family=Josefin+Sans&family=Kanit&family=Kaushan+Script&family=Lato&family=Lobster&family=Lobster+Two&family=Lora&family=Merriweather&family=Montserrat&family=Niconne&family=Noto+Sans+KR&family=Nunito&family=Nunito+Sans&family=Open+Sans&family=Open+Sans+Condensed:wght@300&family=Oswald&family=PT+Sans&family=Pacifico&family=Pangolin&family=Playfair+Display&family=Raleway&family=Roboto&family=Roboto+Condensed&family=Roboto+Mono:wght@200&family=Roboto+Slab&family=Rubik&family=Sacramento&family=Sansita&family=Source+Sans+Pro&family=Sriracha&family=Tinos&family=Ubuntu&family=Varela+Round&family=Work+Sans&display=swap`;

    let font_formats =
      "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Oswald=oswald;  Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva";

    let contextmenu =
      "image media anchor codesample blockquote  | inserttable | charmap emoticons | toc | fullscreen help |  fontselect";

    let quickbars_selection_toolbar =
      "link | bold italic underline blockquote  | formatselect | forecolor backcolor |  align | indent outdent | numlist bullist";

    let quickbars_insert_toolbar =
      "h1 h2 h3 | numlist bullist | quickimage quicktable | hr  ";

    // const editOptions = {
    //   height: 500,
    //   menubar: false,
    //   plugins:
    //     "slashcommands autoresize print preview paste importcss searchreplace autolink save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
    //   // Everything except fontselect can be done using either of context menu, selection menu or insertion menu. And the only decent font I found was Helvetica.
    //   // toolbar:
    //   //   "undo redo | formatselect fontselect fontsizeselect  | bold italic underline | blockquote codesample strikethrough | insert |alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | insertfile image media template link | anchor pagebreak toc | charmap emoticons | fullscreen | help",
    //   toolbar: false,
    //   contextmenu: contextmenu,
    //   // toolbar_sticky: true,
    //   // toolbar_mode: "wrap",
    //   quickbars_insert_toolbar: false,
    //   quickbars_selection_toolbar,
    //   // fontsize_formats:
    //   //   "8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
    //   body_class: "tm-editor",
    //   content_style: `@import url(${fontImportUrl});
    //   body {
    //     font-family: Helvetica,Arial,sans-serif;
    //     font-size:${fontSize}px;
    //     line-height: ${lineHeight};
    //     background-color: ${theme.backgroundColor};
    //     color: ${theme.color};
    //     min-height: 500px;
    //     margin:${isSmallDevice ? "5px" : "0px 30px"};
    //   }
    //   h1, h2, h3, h4, h5, h6 {
    //     font-weight: 500;
    //   }
    //   a {
    //     color : ${theme.primaryColor}
    //   }
    //    .tox.tox-tbtn {
    //       font-size: 12px;
    //     }
    //     .tox-icon.tox-tbtn__icon-wrap {
    //       transform: scale(0.75);
    //     }
    //    .tox-collection__item-icon {
    //         transform: scale(0.75);
    //     }

    //   `,
    //   skin: theme.mode === "dark" ? "oxide-dark" : "",
    //   branding: false,
    //   statusbar: false,
    //   font_formats: font_formats,
    //   images_upload_handler: this.imageUploadHandler,
    //   paste_data_images: true,
    //   help_tabs: [
    //     "shortcuts", // the default shortcuts tab
    //     "keyboardnav", // the default keyboard navigation tab
    //   ],
    //   setup: (editor) => {
    //     //
    //   },
    //   cleanup: false,
    // };
    const editOptionsWithoutToolbar = {
      selector: ".editor-without-toolbar",
      height: 500,
      menubar: false,
      plugins:
        "slashcommands autoresize print preview paste importcss searchreplace autolink save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
      toolbar: false,
      inline: true,
      contextmenu,
      quickbars_insert_toolbar,
      quickbars_selection_toolbar,
      fontsize_formats:
        "8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
      body_class: "tm-editor",
      content_style: `@import url(${fontImportUrl}); 
      div.mce-content-body {
        font-family: Helvetica,Arial,sans-serif; 
        font-size:${fontSize}px;
        line-height: ${lineHeight}; 
        background-color: ${theme.backgroundColor}; 
        color: ${theme.color}; 
        padding:${isSmallDevice ? "10px 10px" : "20px 10px"};
        min-height: 500px;
        border: ${showToolbar ? `1px solid ${theme.borderColor1}` : `none`};
        max-width: 100%;
       } 
       strong {
         color: inherit;
       }
       div.mce-content-body pre {
         display: inline-block;
         width: max-content;
         max-width: 100% !important;
         overflow: auto;
       }
        div.mce-content-body:focus {
          outline: none;
        }
       .mce-content-body h1,h2,h3,h4,h5,h6 {
          font-weight: 500;
        }
        a { 
          color : ${theme.primaryColor}
        }
        .tox.tox-tbtn {
          font-size: 12px;
        }
        .tox .tox-toolbar__group {
          padding: 0px 2px;
        } 
        .tox.tox-tbtn--select {
          padding:0px;
        }
        .tox .tox-tbtn__select-label {
          margin: 0px;
          font-size: 12px
        }
        .tox-icon.tox-tbtn__icon-wrap {
          transform: scale(0.75);
        }
       .tox-collection__item-icon {
            transform: scale(0.75);
        }
        `,
      content_css: "./editor.css",
      extended_valid_elements:
        "div[class|style], input[type|id], label[class|style|for], div[class|style]",
      skin: theme.mode === "dark" ? "oxide-dark" : "borderless",
      branding: false,
      statusbar: false,
      font_formats: font_formats,
      images_upload_handler: this.imageUploadHandler,
      paste_data_images: true,
      // autosave_prefix: `note-${this.props.noteId}`,
      cleanup: false,
      // placeholder:
      //   "     First paragraph (max. 200 chars) will be used as display text.",
    };

    const viewOptions = {
      // menubar: false,
      // toolbar: false,
      // // height: 800,
      // // plugins: "autoresize",
      // plugins:
      //   "slashcommands autoresize print preview paste importcss searchreplace autolink save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
      // selector: "textarea",
      // // readonly: true,
      // content_style: `@import url(${fontImportUrl});
      // body {
      //   font-family: Helvetica,Arial,sans-serif;
      //   font-size:${fontSize}px;
      //   line-height: ${lineHeight};
      //   background-color: ${theme.backgroundColor};
      //   color: ${theme.color};
      //   margin:${isSmallDevice ? "10px" : "0px 30px"};
      // }
      //    h1, h2, h3, h4, h5, h6 {
      //   font-weight: 500;
      // }
      //   a {
      //     color : ${theme.primaryColor}
      //   }`,
      // skin: theme.mode === "dark" ? "oxide-dark" : "borderless",
      // branding: false,
      // statusbar: false,
      // font_formats: font_formats,
      // content_css: "dark",
      ...editOptionsWithoutToolbar,
    };
    return (
      <>
        <Editor
          apiKey={process.env.REACT_APP_TINY_KEY}
          onInit={(evt, editor) => (this.editorRef.current = editor)}
          value={content || ""}
          init={readOnly ? viewOptions : editOptionsWithoutToolbar}
          disabled={readOnly}
          onEditorChange={(e) => {
            // Prevent saving from when editor content is same, removed because not working
            // if (
            //   e.replace(/\r\n|\n|\r/gm, "").normalize() ===
            //   content.replace(/\r\n|\n|\r/gm, "").normalize()
            // )
            //   return;

            this.props.handleChange(e);
          }}
        />
      </>
    );
  }
}

export default TinyEditor;
