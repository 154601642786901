import React, { useState } from "react";
import { connect } from "react-redux";
import { Input, message, Button, Dropdown, Menu, Popconfirm } from "antd";
import ProfileBoxItem from "../dashboard/Feed/ProfileBoxItem";
import moment from "moment";

export const CommentItem = ({
  auth,
  comment,
  sendLike = () => {},
  editComment = () => {},
  deleteComment = () => {},
}) => {
  const {
    theme,
    // isSmallDevice
  } = auth;
  const [editMode, setEditMode] = useState(false);
  // const [text, setText] = useState("");
  const [editText, setEditText] = useState(comment ? comment.content : "");

  const [hasLikedComment, setHasLikedComment] = useState(
    comment ? comment.temp.hasLiked : false
  );
  const [commentLikesCount, setCommentLikesCount] = useState(
    comment ? comment.temp.likesCount : null
  );

  const ellipsisMenu = () => (
    <Menu>
      <Menu.Item
        icon={<i className="fal fa-edit"></i>}
        onClick={() => {
          setEditMode(true);
        }}
      >
        &nbsp;&nbsp;Edit
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item icon={<i className="fas fa-trash"></i>} danger>
        &nbsp;&nbsp;{" "}
        <Popconfirm
          title="Are you sure to delete this reply?"
          onConfirm={() => deleteComment("comment", comment)}
          icon={<i className="fal fa-times" style={{ color: "red" }}></i>}
          onCancel={() => {}}
          okText="Yes"
          cancelText="No"
        >
          Delete
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  return (
    <div style={{ marginBottom: "15px" }}>
      <>
        {" "}
        <div>
          <ProfileBoxItem user={comment.temp.commenter} />
          <Dropdown overlay={ellipsisMenu} trigger={["click"]}>
            <Button
              size="small"
              type="text"
              style={{
                display:
                  comment.commenterId === auth.user.id
                    ? "inline-block"
                    : "none",
                padding: "0px",
                float: "right",
                width: "30px",
                color: theme.color,
              }}
              icon={<i className="fas fa-ellipsis-h"></i>}
            />
          </Dropdown>
          <div
            style={{
              display: "inline-block",
              float: "right",
              fontSize: "14px",
              marginRight:
                comment.commenterId !== auth.user.id ? "40px" : "10px",
            }}
          >
            {comment.info1 && comment.info1.isEdited ? "(edited)" : null}
            &nbsp;&nbsp;
            {moment(comment.createdAt).fromNow()}
          </div>
        </div>
        <div
          style={{
            margin: "2px",
            marginLeft: "50px",
            width: "calc(100% - 50px)",
            borderRadius: "5px",
            fontSize: "16px",
            color: theme.color,
            padding: "5px",
            backgroundColor: theme.backgroundColor2,
          }}
        >
          {editMode ? (
            <div>
              {" "}
              <Input.TextArea
                // defaultValue={comment.content}
                value={editText}
                onChange={(e) => setEditText(e.target.value)}
                onPressEnter={(e) => {
                  if (!e.ctrlKey) return;
                  if (editText.trim().length > 0)
                    return message.warn("Empty comment cannot be posted.");
                  else {
                    editComment("comment", comment, editText);
                    setEditMode(false);
                  }
                }}
              />
              <div style={{ textAlign: "right" }}>
                <Button
                  size="small"
                  icon={<i className="fal fa-times"></i>}
                  style={{ margin: "0px 5px" }}
                  onClick={() => setEditMode(false)}
                >
                  &nbsp;Cancel
                </Button>
                <Button
                  onClick={() => {
                    editComment("comment", comment, editText);
                    setEditMode(false);
                  }}
                  type="primary"
                  size="small"
                  icon={<i className="fal fa-check"></i>}
                >
                  &nbsp;Save
                </Button>
              </div>
            </div>
          ) : (
            comment.content
          )}
          <div style={{ marginTop: "10px" }}>
            <div
              style={{
                display: "inline-block",
                marginLeft: "10px",
                fontSize: "14px",
                // border: "1px solid red",
                // borderRight: `1px solid ${theme.borderColor}`,
              }}
            >
              <Button
                size="small"
                type="text"
                onClick={() => {
                  sendLike();
                  setCommentLikesCount(
                    hasLikedComment
                      ? commentLikesCount - 1
                      : commentLikesCount + 1
                  );
                  setHasLikedComment(!hasLikedComment);
                }}
                style={{
                  color: hasLikedComment ? theme.primaryColor : theme.color,
                  // fontSize: "12px",
                  width: "60px",
                  padding: "0px",
                  fontWeight: "500",
                }}
                icon={
                  hasLikedComment ? (
                    <i className="fas fa-thumbs-up"></i>
                  ) : (
                    <i className="fal fa-thumbs-up"></i>
                  )
                }
              >
                &nbsp;{hasLikedComment ? "Liked" : "Like"}
              </Button>
              <Button
                style={{
                  padding: "0px",
                  color: theme.color,
                  // fontSize: "14px",
                  marginLeft: "5px",
                  fontWeight: "500",
                }}
                type="link"
              >
                {commentLikesCount === 0 ? (
                  <span></span>
                ) : commentLikesCount === 1 ? (
                  "1 like"
                ) : (
                  `${commentLikesCount} likes`
                )}{" "}
              </Button>
            </div>
            {/* <div
                style={{
                  border: "1px solid red",
                  width: "20px",
                  display: "inline-block",
                }}
              ></div> */}
          </div>
        </div>
      </>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CommentItem);
