import ImageUploadLoader from "../../../assets/img-upload-loader.gif";
import { message } from "antd";

export const SaveSelection = () => {
  if (window.getSelection) {
    let sel = window.getSelection();
    if (sel.getRangeAt && sel.rangeCount) {
      var ranges = [];
      for (var i = 0, len = sel.rangeCount; i < len; ++i) {
        ranges.push(sel.getRangeAt(i));
      }
      return ranges;
    }
  } else if (window.selection && window.selection.createRange) {
    return window.selection.createRange();
  }
  return null;
};
export const RestoreSelection = (savedSel) => {
  if (savedSel) {
    if (window.getSelection) {
      let sel = window.getSelection();
      sel.removeAllRanges();
      for (var i = 0, len = savedSel.length; i < len; ++i) {
        sel.addRange(savedSel[i]);
      }
    } else if (window.selection && savedSel.select) {
      savedSel.select();
    }
  }
};
export const uploadHandler = (files, noteId) => {
  // console.log("Uploading image");
  // if (!this.props.noteId)
  //   failure("Please write something to save the note first.");

  // if (!Array.isArray(files)) {
  //   console.log(typeof files);
  //   console.log("Converting to array");
  //   files = [files];
  // }

  Array.from(files).forEach((file, index) => {
    // console.log(file);
    if (!["image/jpeg", "image/png", "image/gif"].includes(file.type)) {
      return message.error("Only images are allowed.");
    }
    // console.log(typeof file);
    var xhr,
      formData,
      fileName = Date.now();
    // var id = `${noteId}-${fileName}-${index}`;
    var id = `${fileName}-${index}`;

    document.execCommand("insertText", false, "  ");
    document.execCommand(
      "insertHTML",
      false,
      `<div class="ed-image-container" id="ed-ic-${id}" contenteditable="false" tabindex="-1"><img class="ed-img"  id="ed-img-${id}" alt="Image" src=${ImageUploadLoader}  /><button class="element-delete-btn"><i class="far fa-trash-alt"></i></button></div>&nbsp;`
      // `<div class="ed-image-container" id="ed-ic-${id}"> </div>`
      // `<img class="ed-img" id="ed-img-${id}" alt="Image" src=${ImageUploadLoader}  />`
      // `<div class="ed-image-container" id="ed-ic-${id}" style="background-image:${ImageUploadLoader}"> </div>`
    );
    // Trying to add image as child
    // let container = document.getElementById(`ed-ic-${id}`);
    // let image = document.createElement(`img`);
    // image.id = `ed-img-${id}`;
    // image.className = "ed-img";
    // image.src = ImageUploadLoader;
    // container.appendChild(image);
    // console.log(image);

    // Trying to add div as parent
    // let image = document.getElementById(`"ed-img-${id}`);
    // let parent = document.getElementById("ed-editor-id");
    // let container = document.createElement("div");
    // container.className = "ed-image-container";
    // container.id = `ed-ic-${id}`;
    // console.log(image);
    // parent.replaceChild(container, image);
    // container.appendChild(image);

    xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open(
      "POST",
      `${process.env.REACT_APP_API_HOST}/api/notes/upload-image`
    );
    xhr.setRequestHeader("Authorization", localStorage.jwtToken);
    // xhr.setRequestHeader("noteid", this.props.noteId);
    xhr.setRequestHeader("fileid", fileName);

    xhr.upload.onprogress = function (e) {
      // progress((e.loaded / e.total) * 100);
    };

    xhr.onload = function () {
      var json;

      if (xhr.status === 403) {
        message.error("HTTP Error: " + xhr.status);
        return;
      }

      if (xhr.status < 200 || xhr.status >= 300) {
        message.error("HTTP Error: " + xhr.status);
        return;
      }

      json = JSON.parse(xhr.responseText);

      if (!json || typeof json.location != "string") {
        message.error("Invalid JSON: " + xhr.responseText);

        return;
      }

      // Success handling
      // success(json.location);
      let imageElement = document.getElementById(`ed-img-${id}`);
      imageElement.removeAttribute("src");
      imageElement.setAttribute("src", json.location);
      imageElement.parentElement.style.height = "300px";
      // To fix a bug of contenteditable html not changing
      document.execCommand("insertText", false, " ");

      // // Trying to add div as parent
      // let parent = document.getElementById("ed-editor-id");
      // let container = document.createElement("div");
      // container.className = "ed-image-container";
      // container.id = `ed-ic-${id}`;
      // parent.replaceChild(container, imageElement);
      // container.appendChild(imageElement);

      // console.log(json);
    };

    xhr.onerror = function () {
      message.error(
        "Image upload failed due to a XHR Transport error. Code: " + xhr.status
      );
    };

    formData = new FormData();
    formData.append("file", file, fileName);

    xhr.send(formData);
  });
};
