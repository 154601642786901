import React, { Component } from "react";

export class Help extends Component {
  render() {
    let content = (
      <div>
        <div
          style={{
            fontSize: "28px",
            fontWeight: "500",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          Contact Us
        </div>
        <div
          style={{
            maxWidth: "800px",
            margin: "0 auto",
            textAlign: "center",
            fontSize: "22px",
          }}
        >
          <p>
            For any help, queries, suggestions or feedback, reach out to us at{" "}
            <strong>
              <a href="mailto: support@atlantic.com">contact@notebuq.com</a>
            </strong>
          </p>
        </div>
      </div>
    );
    return (
      <div
        style={{
          backgroundColor: "#fff",
          width: "85%",
          border: "1px solid #ddd",
          borderRadius: "15px",
          margin: "80px auto",
          padding: "25px 50px",
        }}
      >
        {content}
      </div>
    );
  }
}

export default Help;
