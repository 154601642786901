import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
// import loading from "../../assets/Loader.gif";

export default function loader(props) {
  const antIcon = (
    <LoadingOutlined style={{ fontSize: props.size || "40px" }} />
  );
  return (
    <div
      style={{
        // margin: "auto",
        textAlign: "center",
        marginTop: props.height || "150px",
      }}
    >
      {/* <img
        src={loading}
        style={{
          width: "50px",
          margin: "auto",
          marginTop: props.height || "150px",
          display: "block",
          zIndex: "2",
        }}
        draggable={false}
        onContextMenu={(e) => {
          e.preventDefault();
          //e.stopPropagation();
          // return false;
        }}
        alt="Loading..."
      /> */}

      <Spin indicator={antIcon} />
    </div>
  );
}
