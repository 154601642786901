import React, { useState } from "react";
import { Tooltip, Dropdown, Menu } from "antd";

function SectionItem({
  section,
  notes,
  listItem,
  editSection,
  deleteSection,
  sortBy,
}) {
  const [showNotes, setShowNotes] = useState(false);
  const [showSettingsIcon, setShowSettingsIcon] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  let notesOrder =
    section.info1 && section.info1.notesOrder
      ? section.info1.notesOrder.filter(
          (id) => notes.filter((n) => n.id === id).length > 0
        ) === section.info1.notesOrder
        ? section.info1.notesOrder
        : [
            // Removing notes that has been removed from section
            ...section.info1.notesOrder.filter(
              (id) => notes.filter((n) => n.id === id).length > 0
            ),
            // Adding new notes that has been created but are not in notesorder
            ...notes
              .filter((note) => !section.info1.notesOrder.includes(note.id))
              .map((note) => note.id),
          ]
      : notes.map((note) => note.id);

  let dropdownMenu = (
    <Menu>
      <Menu.Item onClick={() => editSection(section)}>
        <span style={{ margin: "0 5px", textAlign: "center" }}>
          <i className="fal fa-cog"></i>
        </span>
        Edit Section
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item onClick={() => deleteSection(section)} danger>
        {" "}
        <span style={{ margin: "0 5px", textAlign: "center" }}>
          <i className="fal fa-times"></i>
        </span>
        Delete this section
      </Menu.Item>
    </Menu>
  );
  return (
    <div>
      <div
        style={{
          borderBottom: `1px solid ${section.color}`,
          //   borderBottom: `1px solid ${"#d2d2d2"}`,
          borderLeft: section && `4px solid ${section.color}`,
        }}
      >
        <Dropdown trigger={["contextMenu"]} overlay={dropdownMenu}>
          <div
            onClick={(e) => setShowNotes(!showNotes)}
            onMouseOver={() => !dropdownVisible && setShowSettingsIcon(true)}
            onMouseOut={() => !dropdownVisible && setShowSettingsIcon(false)}
            style={{
              borderBottom: showNotes && `2px solid ${section.color}`,
              padding: "2px 10px",
              paddingLeft: "0px",
              fontSize: "12px",
              fontWeight: "500",
              cursor: "pointer",
              userSelect: "none",
              MozUserSelect: "none",
              webkitTouchCallout: "none",
              WebkitUserSelect: "none",
              boxSizing: "border-box",
              // background:
              //   "linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2))",

              // backgroundColor: theme.backgroundColor2,
              // color: section.color,
            }}
            // onSelectCapture={(e) => console.log(e)}
          >
            <span
              style={{
                margin: "0 10px",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              {showNotes ? (
                <i className="fas fa-chevron-down"></i>
              ) : (
                <i className="fas fa-chevron-right"></i>
              )}
            </span>{" "}
            {section.title}
            {showSettingsIcon ? (
              <Dropdown
                overlay={dropdownMenu}
                trigger={["click"]}
                onVisibleChange={(e) => setDropdownVisible(e)}
              >
                <span
                  onClick={(e) => e.stopPropagation()}
                  style={{
                    float: "right",
                    display: "inline-block",
                    margin: "2px 3px",
                  }}
                >
                  <i className="far fa-cog"></i>
                </span>
              </Dropdown>
            ) : (
              <Tooltip
                title={`${notes.length} ${
                  notes.length === 1 ? "note" : "notes"
                }`}
                placement="right"
              >
                <span style={{ float: "right" }}>{notes.length}</span>
              </Tooltip>
            )}
          </div>
        </Dropdown>
        {showNotes ? (
          notes.length > 0 ? (
            sortBy === "bookOrder" ? (
              notesOrder.map((id, index) =>
                listItem(
                  notes.filter((note) => note.id === id)[0],
                  index,
                  section,
                  {
                    paddingLeft: "20px",
                  }
                )
              )
            ) : (
              notes.map((note, index) =>
                listItem(note, index, section, {
                  paddingLeft: "20px",
                })
              )
            )
          ) : (
            <div style={{ fontSize: "12px", textAlign: "center" }}>
              No notes in this section
            </div>
          )
        ) : null}
      </div>
    </div>
  );
}

export default SectionItem;
