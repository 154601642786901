import React, { Component } from "react";
import { Button, Tooltip } from "antd";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import propTypes from "prop-types";
import { logoutUser, changeTheme } from "../../actions/authActions";
import { Menu, Avatar, Switch } from "antd";
import "./styles/sidebar.less";

export class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShrunk: this.props.auth.isSmallDevice
        ? false
        : this.props.isShrunk
        ? this.props.isShrunk
        : true,
      hover: null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isShrunk !== this.props.isShrunk)
      this.setState({ isShrunk: this.props.isShrunk });
    if (prevState.isShrunk !== this.state.isShrunk)
      this.props.onShrink(this.state.isShrunk);
  }
  render() {
    const { isShrunk } = this.state;
    const { theme, isSmallDevice, user, mobileSidebarVisible } =
      this.props.auth;
    const shrunkIconStyle = {
      display: "inline-block",
      width: "40px",
      textAlign: "center",
      margin: "5px",
      color: "inherit",
    };
    // console.log(selectedItem);
    return !user ? (
      <></>
    ) : !isShrunk ? (
      //Normal Sidebar
      <div
        className="sidebar-container"
        id="sidebar-container-id"
        style={{
          backgroundColor: theme.backgroundColor,
          display: !isSmallDevice ? "block" : mobileSidebarVisible || "none",
          color: theme.color,
          position: "fixed",
          left: "0",
          height: "100vh",
          width: isSmallDevice ? "min(60%, 350px)" : "200px",
          borderRight: `1px solid ${theme.borderColor1}`,
          paddingTop: "15px",
          zIndex: "6",
          ...(isSmallDevice && {
            boxShadow: "0px 0px 25px -15px rgba(0, 0, 0, 0.65)",
            WebkitBoxShadow: "0px 0px 25px -15px rgba(0, 0, 0, 0.65)",
            MozBoxShadow: "0px 0px 25px -15px rgba(0, 0, 0, 0.65)",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }),
        }}
      >
        <div style={{ width: "100%", textAlign: "center", padding: "15px 0" }}>
          <Button
            type="primary"
            size="large"
            onClick={(e) => this.props.newnotepress()}
            onMouseDown={(e) => {
              if (e.button === 1) window.open(`/`, "_blank");
            }}
            style={{ fontSize: "18px" }}
          >
            <i className="fas fa-plus"></i>&nbsp; New Note
          </Button>
        </div>
        <div
          className="sidebar-menu-item"
          onMouseEnter={() => this.setState({ hover: "feed" })}
          onMouseLeave={() => this.setState({ hover: null })}
          onClick={() => this.props.onFeedClick()}
          style={{
            backgroundColor:
              this.state.hover === "feed"
                ? theme.hoverColor1
                : theme.backgroundColor,
            color:
              localStorage.leftPanelDisplay === "feed"
                ? theme.primaryColor2
                : theme.color,
          }}
        >
          <span style={shrunkIconStyle}>
            <i
              className={`fa${
                localStorage.leftPanelDisplay === "shared" ? "d" : "l"
              } fa-hashtag`}
            ></i>
          </span>
          Feed
        </div>
        <div
          className="sidebar-menu-item"
          onClick={(e) => this.props.myNotebooks()}
          style={{
            color:
              localStorage.leftPanelDisplay === "myNotebooks"
                ? theme.primaryColor2
                : theme.color,
          }}
        >
          <span
            style={{
              display: "inline-block",
              width: "40px",
              textAlign: "center",
              margin: "5px",
            }}
          >
            <i
              className={`fa${
                localStorage.leftPanelDisplay === "myNotebooks" ? "d" : "l"
              } fa-books`}
            ></i>
          </span>
          My Notebooks
        </div>
        <div
          className="sidebar-menu-item"
          onClick={(e) => this.props.allNotes()}
          style={{
            color:
              localStorage.leftPanelDisplay === "allNotes"
                ? theme.primaryColor2
                : theme.color,
          }}
        >
          <span
            style={{
              display: "inline-block",
              width: "40px",
              textAlign: "center",
              margin: "5px",
            }}
          >
            <i
              className={`fa${
                localStorage.leftPanelDisplay === "allNotes" ? "d" : "l"
              } fa-sticky-note`}
            ></i>
          </span>
          My Notes
        </div>
        <div
          className="sidebar-menu-item"
          onClick={(e) => this.props.saved()}
          style={{
            color:
              localStorage.leftPanelDisplay === "saved"
                ? theme.primaryColor2
                : theme.color,
          }}
        >
          <span
            style={{
              display: "inline-block",
              width: "40px",
              textAlign: "center",
              margin: "5px",
            }}
          >
            <i
              className={`fa${
                localStorage.leftPanelDisplay === "saved" ? "d" : "l"
              } fa-bookmark`}
            ></i>
          </span>
          Saved Notebooks
        </div>
        <div
          className="sidebar-menu-item"
          onClick={(e) => this.props.shared()}
          style={{
            color:
              localStorage.leftPanelDisplay === "shared"
                ? theme.primaryColor2
                : theme.color,
          }}
        >
          <span
            style={{
              display: "inline-block",
              width: "40px",
              textAlign: "center",
              margin: "5px",
            }}
          >
            <i
              className={`fa${
                localStorage.leftPanelDisplay === "shared" ? "d" : "l"
              } fa-user-check`}
            ></i>
          </span>
          Shared With Me
        </div>
        <div
          className="sidebar-menu-item"
          onClick={(e) => {
            localStorage.setItem("leftPanelDIsplay", "explore");
            return (window.location.href = "/explore");
          }}
          style={{
            color: theme.color,
          }}
        >
          <span
            style={{
              display: "inline-block",
              width: "40px",
              textAlign: "center",
              margin: "5px",
              fontWeight: "500",
            }}
          >
            <i className="fal fa-globe-stand"></i>
          </span>
          Explore
        </div>
        {isSmallDevice && (
          <Menu mode="inline" theme={theme.mode}>
            <Menu.SubMenu
              title={
                <div style={{ fontSize: "16px" }}>
                  <Avatar
                    src={
                      user &&
                      user.display_picture &&
                      `https://${user.display_picture.downloadUrl}`
                    }
                    size={30}
                  />
                  &nbsp;
                  {user.name}
                </div>
              }
            >
              <Menu.Item icon={<i className="fas fa-adjust"></i>}>
                &nbsp;Appearence
                <Switch
                  style={{ float: "right", marginTop: "10px" }}
                  checked={theme.mode === "light"}
                  checkedChildren={<i className="fas fa-sun"></i>}
                  unCheckedChildren={<i className="fas fa-moon"></i>}
                  //size="small"
                  onChange={(e) => this.props.changeTheme()}
                />
              </Menu.Item>
              <Menu.Item
                icon={<i className="fas fa-user-cog"></i>}
                onClick={() => this.setState({ avatarMenuVisible: false })}
              >
                <Link to={"/account-settings"} style={{ color: theme.color }}>
                  &nbsp;Account Settings
                </Link>
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item
                icon={<i className="fas fa-sign-out-alt"></i>}
                onClick={() => {
                  this.setState({ avatarMenuVisible: false });
                  this.props.logoutUser();
                }}
              >
                &nbsp;Logout
              </Menu.Item>
            </Menu.SubMenu>
          </Menu>
        )}
        {!isSmallDevice && (
          <Tooltip title="Shrink Sidebar">
            <Button
              icon={<i className="fas fa-angle-double-left"></i>}
              style={{ position: "absolute", right: "0", bottom: "100px" }}
              onClick={() => this.setState({ isShrunk: true })}
            />
          </Tooltip>
        )}
      </div>
    ) : (
      // Shrunk Sidebar
      <div
        style={{
          backgroundColor: theme.backgroundColor,
          color: theme.color,
          position: "fixed",
          left: "0",
          height: "100vh",
          width: "70px",
          borderRight: `1px solid ${theme.borderColor1}`,
          paddingTop: "15px",
        }}
        className="sidebar-container"
      >
        <div style={{ width: "100%", textAlign: "center", padding: "15px 0" }}>
          <Tooltip title="New Note" placement="right">
            <Button
              type="primary"
              size="large"
              onClick={(e) => this.props.newnotepress()}
              onMouseDown={(e) => {
                if (e.button === 1) window.open(`/`, "_blank");
              }}
            >
              <i className="far fa-plus"></i>
            </Button>
          </Tooltip>
        </div>
        <Tooltip title="Feed" placement="right">
          <div
            className="sidebar-menu-item"
            onMouseEnter={() => this.setState({ hover: "feed" })}
            onMouseLeave={() => this.setState({ hover: null })}
            onClick={() => this.props.onFeedClick()}
            style={{
              backgroundColor:
                this.state.hover === "feed" ||
                localStorage.leftPanelDisplay === "feed"
                  ? theme.hoverColor1
                  : theme.backgroundColor,

              color:
                localStorage.leftPanelDisplay === "feed"
                  ? theme.primaryColor2
                  : theme.color,
            }}
          >
            <span style={shrunkIconStyle}>
              <i
                className={`fa${
                  localStorage.leftPanelDisplay === "shared" ? "d" : "l"
                } fa-hashtag`}
              ></i>
            </span>
          </div>
        </Tooltip>
        <Tooltip title="My Notebooks" placement="right">
          <div
            className="sidebar-menu-item"
            onMouseEnter={() => this.setState({ hover: "myNotebooks" })}
            onMouseLeave={() => this.setState({ hover: null })}
            onClick={(e) => this.props.myNotebooks()}
            style={{
              backgroundColor:
                this.state.hover === "myNotebooks" ||
                localStorage.leftPanelDisplay === "myNotebooks"
                  ? theme.hoverColor1
                  : theme.backgroundColor,
              color:
                localStorage.leftPanelDisplay === "myNotebooks"
                  ? theme.primaryColor2
                  : theme.color,
            }}
          >
            <span style={shrunkIconStyle}>
              <i
                className={`fa${
                  localStorage.leftPanelDisplay === "myNotebooks" ? "d" : "l"
                } fa-books`}
              ></i>
            </span>
          </div>
        </Tooltip>
        <Tooltip title="My Notes" placement="right">
          <div
            className="sidebar-menu-item"
            onMouseEnter={() => this.setState({ hover: "myNotes" })}
            onMouseLeave={() => this.setState({ hover: null })}
            onClick={(e) => this.props.allNotes()}
            style={{
              backgroundColor:
                this.state.hover === "myNotes" ||
                localStorage.leftPanelDisplay === "allNotes"
                  ? theme.hoverColor1
                  : theme.backgroundColor,
              color:
                localStorage.leftPanelDisplay === "allNotes"
                  ? theme.primaryColor2
                  : theme.color,
            }}
          >
            <span style={shrunkIconStyle}>
              <i
                className={`fa${
                  localStorage.leftPanelDisplay === "allNotes" ? "d" : "l"
                } fa-sticky-note`}
              ></i>
            </span>
          </div>
        </Tooltip>

        <Tooltip title="Saved Notebooks" placement="right">
          <div
            className="sidebar-menu-item"
            onMouseEnter={() => this.setState({ hover: "saved" })}
            onMouseLeave={() => this.setState({ hover: null })}
            onClick={(e) => this.props.saved()}
            style={{
              backgroundColor:
                this.state.hover === "saved" ||
                localStorage.leftPanelDisplay === "saved"
                  ? theme.hoverColor1
                  : theme.backgroundColor,
              color:
                localStorage.leftPanelDisplay === "saved"
                  ? theme.primaryColor2
                  : theme.color,
            }}
          >
            <span style={shrunkIconStyle}>
              <i
                className={`fa${
                  localStorage.leftPanelDisplay === "saved" ? "d" : "l"
                } fa-bookmark`}
              ></i>
            </span>
          </div>
        </Tooltip>
        <Tooltip title="Shared with me" placement="right">
          <div
            className="sidebar-menu-item"
            onMouseEnter={() => this.setState({ hover: "shared" })}
            onMouseLeave={() => this.setState({ hover: null })}
            onClick={(e) => this.props.shared()}
            style={{
              backgroundColor:
                this.state.hover === "shared" ||
                localStorage.leftPanelDisplay === "shared"
                  ? theme.hoverColor1
                  : theme.backgroundColor,
              color:
                localStorage.leftPanelDisplay === "shared"
                  ? theme.primaryColor2
                  : theme.color,
            }}
          >
            <span style={shrunkIconStyle}>
              <i
                className={`fa${
                  localStorage.leftPanelDisplay === "shared" ? "d" : "l"
                } fa-user-check`}
              ></i>
            </span>
          </div>
        </Tooltip>
        <Tooltip title="Explore" placement="right">
          <Link to={`/explore`}>
            <div
              className="sidebar-menu-item"
              onMouseEnter={() => this.setState({ hover: "explore" })}
              onMouseLeave={() => this.setState({ hover: null })}
              onClick={(e) => {
                localStorage.setItem("leftPanelDIsplay", "explore");
                return (window.location.href = "/explore");
              }}
              style={{
                backgroundColor:
                  this.state.hover === "explore"
                    ? theme.hoverColor1
                    : theme.backgroundColor,
                color: theme.color,
              }}
            >
              <span style={shrunkIconStyle}>
                <i className="fal fa-globe-stand"></i>
              </span>
            </div>
          </Link>
        </Tooltip>
        <Tooltip title="Expand Sidebar">
          <Button
            icon={
              this.state.isShrunk ? (
                <i className="fas fa-angle-double-right"></i>
              ) : (
                <i className="fas fa-angle-double-left"></i>
              )
            }
            style={{
              position: "absolute",
              right: "0",
              bottom: "100px",
              backgroundColor: theme.backgroundColor,
              color: theme.color,
              borderColor: theme.borderColor1,
            }}
            onClick={() => this.setState({ isShrunk: false })}
          />
        </Tooltip>
      </div>
    );
  }
}

Sidebar.propTypes = {
  auth: propTypes.object.isRequired,
  logoutUser: propTypes.func.isRequired,
  changeTheme: propTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default withRouter(
  connect(mapStateToProps, { logoutUser, changeTheme })(Sidebar)
);
