import axios from "axios";
import {
  GET_PROFILE,
  PROFILE_LOADING,
  CLEAR_PROFILE,
  GET_ERRORS,
} from "./types";
import { logoutUser } from "./authActions.js";

import { message } from "antd";

// Edit Current Profile (Can be used for creating profile as well)
export const editProfile = (profileData, history) => (dispatch) => {
  axios
    .post(
      `${process.env.REACT_APP_API_HOST}/api/users/current/edit`,
      profileData
    )
    .then((res) => {
      if (res.data && res.data.success) {
        message.success("Profile Saved Successfully.");
        history.push("/");
      } else {
        message.error(res.data.message);
      }
    })
    .catch((err) => console.log(err));
};

// Change Current User's Password
export const changePassword = (editData, history) => (dispatch) => {
  axios
    .post(
      `${process.env.REACT_APP_API_HOST}/api/users/current/edit/password`,
      editData
    )
    .then((res) => {
      if (res.data.errors) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.errors,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: {},
        });
        message.success("Password Changed Successful. Log in again.");
        dispatch(logoutUser());
        history.push("/");
      }
    })
    .catch((err) => console.log(err));
};

// Get Current Profile
export const getCurrentProfile = () => (dispatch) => {
  dispatch(setProfileLoading());
  axios
    //.get(`${process.env.REACT_APP_API_HOST}/users/current/profile`)
    .get(`/api/users/current/profile`)
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: GET_PROFILE,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_PROFILE,
        payload: {},
      })
    );
};

// Profile loading
export const setProfileLoading = () => {
  return {
    type: PROFILE_LOADING,
  };
};

// Clear Profile
export const clearCurrentProfile = () => {
  return {
    type: CLEAR_PROFILE,
  };
};

// Get Profile by Handle
export const getProfileByHandle = (handle) => (dispatch) => {
  dispatch(setProfileLoading());
  dispatch(clearCurrentProfile());
  axios
    .get(`${process.env.REACT_APP_API_HOST}/users/${handle}`)
    .then((res) => {
      if (res.data.errors) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.errors,
        });
        dispatch({
          type: GET_PROFILE,
          payload: null,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
        dispatch({
          type: GET_PROFILE,
          payload: res.data,
        });
      }
    })
    .catch((err) => console.log(err));
};
