import React, { useState } from "react";

function IconPicker({ containerStyle, itemStyle, defaultSelected, onSelect }) {
  const iconList = [
    {
      class: `fal fa-books`,
    },
    {
      class: `fal fa-car-building`,
    },
    {
      class: `fal fa-building`,
    },
    {
      class: `fal fa-briefcase`,
    },
    {
      class: `fal fa-clipboard`,
    },
    {
      class: `fal fa-analytics`,
    },
    {
      class: `fal fa-megaphone`,
    },
    {
      class: `fal fa-box-full`,
    },
    {
      class: `fal fa-bullseye-arrow`,
    },
    {
      class: `fal fa-laptop-house`,
    },
    {
      class: `fal fa-house-leave`,
    },
    {
      class: `fal fa-globe-stand`,
    },
    {
      class: `fal fa-globe-asia`,
    },
    {
      class: `fal fa-home`,
    },
    {
      class: `fal fa-home-alt`,
    },
    {
      class: `fal fa-home-heart`,
    },
    {
      class: `fal fa-user`,
    },
    {
      class: `fal fa-user-lock`,
    },
    {
      class: `fal fa-lightbulb-on`,
    },
    {
      class: `fal fa-user-shield`,
    },
    {
      class: `fal fa-chart-network`,
    },
    {
      class: `fal fa-user-hard-hat`,
    },
    {
      class: `fal fa-school`,
    },
    {
      class: `fal fa-university`,
    },
    {
      class: `fal fa-user-graduate`,
    },
    {
      class: `fal fa-chalkboard-teacher`,
    },
    {
      class: `fal fa-lamp-desk`,
    },
    {
      class: `fal fa-head-side-brain`,
    },
    {
      class: `fal fa-heartbeat`,
    },
    {
      class: `fal fa-dice`,
    },
    {
      class: `fal fa-globe-snow`,
    },
    {
      class: `fal fa-house-night`,
    },
    {
      class: `fal fa-music`,
    },
    {
      class: `fal fa-guitar`,
    },
    {
      class: `fal fa-icons`,
    },
    {
      class: `fal fa-camera-retro`,
    },
    {
      class: `fal fa-image-polaroid`,
    },
    {
      class: `fal fa-camera-movie`,
    },
    {
      class: `fal fa-route`,
    },
    {
      class: `fal fa-suitcase-rolling`,
    },
    {
      class: `fal fa-passport`,
    },
    {
      class: `fal fa-mountains`,
    },
    {
      class: `fal fa-snowboarding`,
    },
    {
      class: `fal fa-spa`,
    },
    {
      class: `fal fa-running`,
    },
    {
      class: `fal fa-tennis-ball`,
    },
    {
      class: `fal fa-racquet`,
    },
    {
      class: `fal fa-field-hockey`,
    },
    {
      class: `fal fa-futbol`,
    },
    {
      class: `fal fa-basketball-hoop`,
    },
    {
      class: `fal fa-dumbbell`,
    },
    {
      class: `fal fa-chess`,
    },
    {
      class: `fal fa-pencil-paintbrush`,
    },
    {
      class: `fal fa-paint-brush`,
    },
    {
      class: `fal fa-utensils`,
    },
  ];

  const [selected, setSelected] = useState(defaultSelected);

  const iconItem = (icon) => (
    <div
      onClick={() => {
        setSelected(icon.class);
        onSelect(icon.class);
      }}
      style={
        itemStyle || {
          display: "inline-block",
          width: "40px",
          //   border: "1px solid #9A9A9A",
          textAlign: "center",
          fontSize: "20px",
          ...(selected === icon.class && {
            backgroundColor: "lightblue",
            color: "white",
          }),
        }
      }
    >
      <i className={icon.class}></i>
    </div>
  );

  return (
    <div style={containerStyle}>{iconList.map((icon) => iconItem(icon))}</div>
  );
}

export default IconPicker;
