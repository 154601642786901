import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import propTypes from "prop-types";
import {
  Button,
  Dropdown,
  Menu,
  Input,
  message,
  Checkbox,
  Select,
  Tooltip,
  Collapse,
  Modal,
  notification,
  Avatar,
  Popover,
  Tabs,
  Empty,
} from "antd";

import {
  // SortAscendingOutlined,
  // SortDescendingOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import axios from "axios";

import AllNotes from "./AllNotes";
// import ColorPicker from "../common/ColorPicker";
import IconPicker from "../common/IconPicker";

const { Option } = Select;
const { Panel } = Collapse;
const { TabPane } = Tabs;

export class MyNotebooks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rename: null,
      newName: "",
      sortBy: localStorage.notebooksortby || "name",
      sortOrder: localStorage.notebooksortorder || "ASC",
      view: "list",
      allNotesDisplayActionHeader: true,
      openCollapsible: [],
      // refreshAlso: false,
      newNotebookModalVisible: false,
      dropdownVisible: false,
      mouseOn: null,
      filterMenuVisible: false,
      filterOptions: {
        sharing: ["private", "shared", "published"],
      },
      searchQuery: "",
      showNewSectionModal: false,
      newSection: {},
      showNotebookSetModal: false,
      newNotebookSet: {},
      searchSelected: false,
    };
    this.rename = this.rename.bind(this);
  }
  componentDidMount() {
    let itemArray = document.getElementsByClassName("ant-collapse-item");
    for (let i = 0; i < itemArray.length; i++) {
      document.getElementsByClassName("ant-collapse-item")[
        i
      ].style.backgroundColor = `${this.props.auth.theme.collapseColor}`;
      document.getElementsByClassName("ant-collapse-item")[
        i
      ].style.color = `${this.props.auth.theme.color}`;
    }
    // Changing icon color
    itemArray = document.getElementsByClassName("ant-collapse-arrow");

    for (let i = 0; i < itemArray.length; i++) {
      document.getElementsByClassName("ant-collapse-arrow")[
        i
      ].style.color = `${this.props.auth.theme.color}`;
    }
    if (!this.props.notebooks) this.getNotebooks();
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log(this.props.notebooksChanged);
    // if (this.state.refreshAlso) this.setState({ refreshAlso: false });
    if (prevProps.auth.theme.mode !== this.props.auth.theme.mode) {
      let itemArray = document.getElementsByClassName("ant-collapse-item");
      for (let i = 0; i < itemArray.length; i++) {
        document.getElementsByClassName("ant-collapse-item")[
          i
        ].style.backgroundColor = `${this.props.auth.theme.collapseColor}`;
        document.getElementsByClassName("ant-collapse-item")[
          i
        ].style.color = `${this.props.auth.theme.color}`;
      }
      // Changing icon color
      itemArray = document.getElementsByClassName("ant-collapse-arrow");

      for (let i = 0; i < itemArray.length; i++) {
        document.getElementsByClassName("ant-collapse-arrow")[
          i
        ].style.color = `${this.props.auth.theme.color}`;
      }
    }

    if (
      prevState.sortBy !== this.state.sortBy ||
      prevState.searchQuery !== this.state.searchQuery ||
      // Updating notebooks when new note is created or notebook is changed
      (this.props.noteUpdated &&
        ((!prevProps.selectedNote && this.props.selectedNote) ||
          (this.props.selectedNote.id === prevProps.selectedNote.id &&
            this.props.selectedNote.notebookId !==
              prevProps.selectedNote.notebookId)))
    )
      this.getNotebooks();
    else if (
      prevState.sortBy === this.state.sortBy &&
      prevState.sortOrder !== this.state.sortOrder
    )
      this.props.reverseNotebooks();
  }

  getNotebooks() {
    // console.log(this.state.filterOptions.sharing);
    this.props.getNotebooks({
      notes: false,
      sortBy: this.state.sortBy,
      sortOrder: this.state.sortOrder,
      searchQuery: this.state.searchQuery,
      sharing: this.state.filterOptions.sharing,
    });
  }

  async rename() {
    if (
      this.state.newName.length > 0 &&
      this.state.rename &&
      this.state.rename.notebookId &&
      this.state.rename.previousName !== this.state.newName
    ) {
      await axios
        .post(`${process.env.REACT_APP_API_HOST}/api/notebooks/edit`, {
          id: this.state.rename.notebookId,
          name: this.state.newName,
        })
        .then((res) => {
          if (res.data.success) {
            message.success(res.data.message);
            this.props.onNotebookRename(
              this.state.rename.notebookId,
              this.state.newName
            );

            // this.getNotebooks();
          } else {
            message.error(res.data.message);
          }
        });
    }
    return this.setState({ rename: null, newName: "" });
  }

  changeDefault(newDefaultNotebookId) {
    if (
      this.props.notebooks.filter((notebook) => notebook.isDefault)[0].id ===
      newDefaultNotebookId
    ) {
      message.warn("This is already your default notebook.");
      return;
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_HOST}/api/notebooks/change-default`,
          { notebookId: newDefaultNotebookId }
        )
        .then((res) => {
          if (res.data.success) {
            message.success(res.data.message);
            this.getNotebooks();
          }
        });
    }
  }

  changeDefaultSaved(newDefaultSavedNotebookId) {
    if (
      this.props.notebooks.filter((notebook) => notebook.isSaved)[0].id ===
      newDefaultSavedNotebookId
    ) {
      message.warn("This is already your default saving notebook.");
      return;
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_HOST}/api/notebooks/change-default-saved`,
          { notebookId: newDefaultSavedNotebookId }
        )
        .then((res) => {
          if (res.data.success) {
            message.success(res.data.message);
            this.getNotebooks();
          }
        });
    }
  }

  deleteNotebook(notebook) {
    axios
      .post(`${process.env.REACT_APP_API_HOST}/api/notebooks/delete`, {
        notebookId: notebook.id,
      })
      .then((res) => {
        if (res.data.success) {
          notification["success"]({
            message: "Success",
            description: res.data.message,
            placement: "bottomRight",
          });
          this.getNotebooks();
        } else {
          notification["error"]({
            message: "error",
            description: res.data.message,
            placement: "bottomRight",
          });
        }
      });
  }

  createSection() {
    // console.log(this.state.newSection);
    if (this.state.newSection.title && this.state.newSection.title.length > 1) {
      axios
        .post(
          this.state.newSection.notebookId
            ? `${process.env.REACT_APP_API_HOST}/api/notebooks/sections/edit`
            : `${process.env.REACT_APP_API_HOST}/api/notebooks/sections/create`,
          {
            sectionId: this.state.newSection.id,
            ...this.state.newSection,
            notebookId:
              this.state.newSection.notebookId ||
              this.state.newSection.notebook.id,
            // color: this.state.newSection.color || "#d2d2d2",
          }
        )
        .then((res) => {
          // console.log(res.data);
          if (res.data.success) {
            this.setState({ showNewSectionModal: false });
            this.getNotebooks();
            notification["success"]({
              message: "Success",
              description: res.data.message,
              placement: "bottomRight",
            });
          } else {
            notification["error"]({
              message: "Error",
              description: res.data.message,
              placement: "bottomRight",
            });
          }
        })
        .catch((err) =>
          notification["error"]({
            message: "Error",
            description: "Some error occured. Please try later.",
            placement: "bottomRight",
          })
        );
    }
  }

  deleteSection(section) {
    if (section && section.title) {
      const deleteSection = () =>
        axios
          .post(
            `${process.env.REACT_APP_API_HOST}/api/notebooks/sections/delete`,
            {
              sectionId: section.id,
            }
          )
          .then(async (res) => {
            // console.log(res.data);
            if (res.data.success) {
              this.setState({ showNewSectionModal: false });
              this.getNotebooks();
              notification["success"]({
                message: "Success",
                description: res.data.message,
                placement: "bottomRight",
              });
            } else {
              notification["error"]({
                message: "Error",
                description: res.data.message,
                placement: "bottomRight",
              });
            }
          })
          .catch((err) =>
            notification["error"]({
              message: "Error",
              description: "Some error occured. Please try later.",
              placement: "bottomRight",
            })
          );

      Modal.confirm({
        title: `Are you sure you want to delete section: ${section.title}`,
        icon: <ExclamationCircleOutlined />,
        content: (
          <span style={{ fontSize: "12px" }}>
            Deleting section will not delete its content.
          </span>
        ),
        okText: "Delete",
        okType: "danger",
        onOk: () => {
          deleteSection();
        },
        onCancel() {},
      });
    }
  }

  createSet() {
    // console.log(this.state.newSection);
    this.setState({ showNotebookSetModal: false });

    if (
      this.state.newNotebookSet.title &&
      this.state.newNotebookSet.title.length > 1
    ) {
      axios
        .post(
          this.state.newNotebookSet.id
            ? `${process.env.REACT_APP_API_HOST}/api/notebooks/sets/edit`
            : `${process.env.REACT_APP_API_HOST}/api/notebooks/sets/create`,
          {
            ...this.state.newNotebookSet,
          }
        )
        .then((res) => {
          // console.log(res.data);
          if (res.data.success) {
            this.getNotebooks();
            notification["success"]({
              message: "Success",
              description: res.data.message,
              placement: "bottomRight",
            });
          } else {
            notification["error"]({
              message: "Error",
              description: res.data.message,
              placement: "bottomRight",
            });
          }
        })
        .catch((err) =>
          notification["error"]({
            message: "Error",
            description: "Some error occured. Please try later.",
            placement: "bottomRight",
          })
        );
    }
  }

  addToSet({ notebook, set }) {
    if (!notebook || !set) return;
    axios
      .post(`${process.env.REACT_APP_API_HOST}/api/notebooks/sets/notebook`, {
        setId: set.id,
        notebookId: notebook.id,
      })
      .then((res) => {
        if (res.data.success) {
          this.getNotebooks();
          notification["success"]({
            message: "Success",
            description: res.data.message,
            placement: "bottomRight",
          });
        } else {
          notification["error"]({
            message: "Error",
            description: res.data.message,
            placement: "bottomRight",
          });
        }
      })
      .catch((err) =>
        notification["error"]({
          message: "Error",
          description: "Some error occured. Please try later.",
          placement: "bottomRight",
        })
      );
  }

  deleteSet(set) {
    console.log(set);
    if (set && set.title) {
      const deleteSection = () =>
        axios
          .post(`${process.env.REACT_APP_API_HOST}/api/notebooks/sets/delete`, {
            id: set.id,
          })
          .then(async (res) => {
            // console.log(res.data);
            if (res.data.success) {
              this.setState({ showNotebookSetModal: false });
              this.getNotebooks();
              notification["success"]({
                message: "Success",
                description: res.data.message,
                placement: "bottomRight",
              });
            } else {
              notification["error"]({
                message: "Error",
                description: res.data.message,
                placement: "bottomRight",
              });
            }
          })
          .catch((err) =>
            notification["error"]({
              message: "Error",
              description: "Some error occured. Please try later.",
              placement: "bottomRight",
            })
          );

      Modal.confirm({
        title: `Are you sure you want to delete section: ${set.title}`,
        icon: <ExclamationCircleOutlined />,
        content: (
          <span style={{ fontSize: "12px" }}>
            Deleting set will not delete its content.
          </span>
        ),
        okText: "Delete",
        okType: "danger",
        onOk: () => {
          deleteSection();
        },
        onCancel() {},
      });
    }
  }

  render() {
    const {
      rename,
      newName,
      filterOptions,
      newSection,
      newNotebookSet,
      searchSelected,
    } = this.state;
    const { theme, user } = this.props.auth;
    const { notebooks, sets } = this.props;

    const menuIconStyle = {
      display: "inline-block",
      width: "25px",
    };

    const newSectionModal = (
      <Modal
        visible={this.state.showNewSectionModal}
        title={
          newSection.notebook
            ? `New Section in ${this.state.newSection.notebook.name}`
            : `Edit Section ${newSection.notebookId ? newSection.title : ""}`
        }
        onOk={() => this.createSection()}
        onCancel={() =>
          this.setState({
            showNewSectionModal: false,
            newSection: { color: theme.primaryColor },
          })
        }
        destroyOnClose
      >
        {newSection.notebookId ? "Title" : "Title of the new section:"}
        <Input
          defaultValue={newSection && newSection.title}
          autoFocus
          onChange={(e) =>
            this.setState({
              newSection: { ...this.state.newSection, title: e.target.value },
            })
          }
        />
        {/* <div style={{ height: "10px" }} />
        Color:
        <ColorPicker
          defaultColor={newSection.color || theme.primaryColor}
          onColorSelect={(color) =>
            this.setState({
              newSection: { ...this.state.newSection, color: color.hex },
            })
          }
        /> */}
      </Modal>
    );
    const newNotebookSetModal = (
      <Modal
        destroyOnClose
        visible={this.state.showNotebookSetModal}
        title={"Create New Set"}
        onOk={() => this.createSet()}
        onCancel={() =>
          this.setState({ showNotebookSetModal: false, newNotebookSet: {} })
        }
      >
        Title of the set:
        <Input
          defaultValue={newNotebookSet && newNotebookSet.title}
          onChange={(e) =>
            this.setState({
              newNotebookSet: {
                ...this.state.newNotebookSet,
                title: e.target.value,
              },
            })
          }
        />
        <div style={{ height: "10px" }} />
        Icon:
        <IconPicker
          onSelect={(icon) =>
            this.setState({
              newNotebookSet: { ...newNotebookSet, icon: { class: icon } },
            })
          }
          defaultSelected={
            newNotebookSet && newNotebookSet.icon && newNotebookSet.icon.class
          }
        />
      </Modal>
    );

    const dropdownMenu = (notebook) => (
      <Menu theme={theme.mode} onClick={(e) => e.domEvent.stopPropagation()}>
        <Menu.Item
          // icon={<i className="fas fa-chevron-circle-right"></i>}
          onClick={() => this.props.selectNotebook(notebook)}
        >
          <span style={menuIconStyle}>
            <i className="far fa-chevron-circle-right"></i>
          </span>
          Open Notebook
        </Menu.Item>
        <Menu.Item
          // icon={<i className="fas fa-external-link-alt"></i>}
          onClick={() => window.open(`/?notebook=${notebook.id}`, "_blank")}
        >
          <span style={menuIconStyle}>
            <i className="far fa-external-link-alt"></i>
          </span>
          Open in new tab
        </Menu.Item>

        {notebook.ownerId === user.id && (
          <>
            <Menu.Item
              key="1"
              onClick={(e) => {
                this.props.newNotebook();
              }}
              // icon={<i className="fas fa-plus"></i>}
            >
              <span style={menuIconStyle}>
                <i className="far fa-plus"></i>
              </span>
              New Notebook
            </Menu.Item>
            <Menu.SubMenu
              title={
                <>
                  {" "}
                  <span style={menuIconStyle}>
                    <i className="fal fa-books-medical"></i>
                  </span>
                  Add to set
                </>
              }
            >
              <Menu.Item
                key="544"
                onClick={(e) => {
                  this.setState({
                    showNotebookSetModal: true,
                    newNotebookSet: {
                      notebookIds: [notebook.id],
                      title: "",
                      icon: {
                        class: `fal fa-books`,
                      },
                    },
                  });
                }}
                // icon={<i className="far fa-edit"></i>}
              >
                <span style={menuIconStyle}>
                  <i className="fal fa-plus"></i>
                </span>
                Create New Set
              </Menu.Item>
              <Menu.Divider />
              {sets &&
                sets.map((set) => (
                  <Menu.Item
                    key={set.id}
                    onClick={(e) => this.addToSet({ notebook, set })}
                    // icon={<i className="far fa-edit"></i>}
                  >
                    <span style={menuIconStyle}>
                      <i className={set.icon.class}></i>
                    </span>
                    {set.title}
                    {notebook.notebookSetId === set.id && (
                      <span style={{ float: "right" }}>
                        <i className="far fa-check"></i>
                      </span>
                    )}
                  </Menu.Item>
                ))}
            </Menu.SubMenu>
            <Menu.Item
              key="545"
              onClick={(e) => {
                this.setState({
                  showNewSectionModal: true,
                  newSection: {
                    notebook,
                    title: "",
                    color: "#d3d3d3",
                  },
                });
              }}
              // icon={<i className="far fa-edit"></i>}
            >
              <span style={menuIconStyle}>
                <i className="fal fa-folder-plus"></i>
              </span>
              Add Section
            </Menu.Item>
            <Menu.Item
              key="2"
              onClick={(e) => {
                this.setState({
                  rename: {
                    notebookId: notebook.id,
                    previousName: notebook.name,
                  },
                });
              }}
              // icon={<i className="far fa-edit"></i>}
            >
              <span style={menuIconStyle}>
                <i className="far fa-edit"></i>
              </span>
              Rename Notebook
            </Menu.Item>

            <Menu.Item
              key="3"
              // icon={<i className="fas fa-share-alt"></i>}
              onClick={() => this.props.shareNotebook(notebook)}
              // onClick={() => this.props.showSharingModal(notebook)}
            >
              <span style={menuIconStyle}>
                <i className="far fa-share-alt"></i>
              </span>
              Share
            </Menu.Item>

            {!notebook.isProtected && (
              <Menu.Item
                key="4"
                // icon={<i className="fas fa-users"></i>}
                onClick={() => this.props.publishNotebookClick(notebook)}
              >
                <span style={menuIconStyle}>
                  {notebook.sharing === "published" ? (
                    <i className="fad fa-users-slash"></i>
                  ) : (
                    <i className="fad fa-users"></i>
                  )}
                </span>
                {notebook.sharing === "published" ? "Unpublish" : "Publish"}
              </Menu.Item>
            )}
            <Menu.Item
              key="5"
              // icon={<Checkbox checked={notebook.isDefault} />}
              onClick={() => this.changeDefault(notebook.id)}
            >
              <span style={menuIconStyle}>
                <Checkbox checked={notebook.isDefault} />
              </span>
              Make this my default notebook
            </Menu.Item>
            <Menu.Item
              key="6"
              // icon={<Checkbox checked={notebook.isSaved} />}
              onClick={() => this.changeDefaultSaved(notebook.id)}
            >
              <span style={menuIconStyle}>
                <Checkbox checked={notebook.isSaved} />
              </span>
              Make this default for saving notes
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
              key="7"
              danger
              // icon={<i className="fas fa-times"></i>}
              onClick={() =>
                Modal.confirm({
                  title: `Are you ABSOLUTELY sure you want to delete notebook ${notebook.name}?`,
                  icon: <ExclamationCircleOutlined />,
                  content: (
                    <div style={{ fontSize: "12px", marginTop: "10px" }}>
                      <span style={{ fontWeight: "500" }}>WARNING:</span>{" "}
                      Deleting this notebook will{" "}
                      <span style={{ fontWeight: "500" }}>
                        permanently delete all its contents.
                      </span>
                    </div>
                  ),
                  okText: "Delete",
                  okType: "danger",
                  onOk: () => {
                    this.deleteNotebook(notebook);
                  },
                  onCancel() {},
                })
              }
            >
              <span style={menuIconStyle}>
                <i className="fas fa-times"></i>
              </span>
              Delete Notebook
            </Menu.Item>
          </>
        )}
      </Menu>
    );

    const notebookSetDropdown = (set) => (
      <Menu>
        <Menu.Item
          onClick={() =>
            this.setState(
              {
                newNotebookSet: {
                  ...set,
                },
              },
              () => this.setState({ showNotebookSetModal: true })
            )
          }
        >
          <span style={{ margin: "0 5px", textAlign: "center" }}>
            <i className="fal fa-cog"></i>
          </span>
          Edit set
        </Menu.Item>
        <Menu.Item
          onClick={() =>
            this.setState({
              showNotebookSetModal: true,
              newNotebookSet: {
                title: "",
                icon: {
                  class: `fal fa-books`,
                },
              },
            })
          }
        >
          <span style={{ margin: "0 5px", textAlign: "center" }}>
            <i className="fal fa-plus"></i>
          </span>
          Create new set
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item onClick={() => this.deleteSet(set)} danger>
          {" "}
          <span style={{ margin: "0 5px", textAlign: "center" }}>
            <i className="fal fa-times"></i>
          </span>
          Delete this set
        </Menu.Item>
      </Menu>
    );

    const collapseItem = (notebook = {}) => (
      <Panel
        className="my-notebooks-collapse-item"
        key={notebook.id}
        style={{
          padding: "0",
          //borderBottom: "2px solid #d1d1d1",
        }}
        header={
          <Dropdown
            overlay={dropdownMenu(notebook)}
            trigger={["contextMenu"]}
            onVisibleChange={(e) => this.setState({ dropdownVisible: e })}
          >
            <div
              style={{
                fontWeight: "500",
                //lineHeight: "1.8",
              }}
            >
              {rename && rename.notebookId === notebook.id ? (
                <div>
                  <Input
                    defaultValue={notebook.name}
                    onChange={(e) =>
                      this.setState({ newName: e.target.value.trim() })
                    }
                    //bordered={false}
                    style={{ width: "80%" }}
                    placeholder="Enters new name"
                  />
                  <Button
                    size="middle"
                    type="link"
                    style={{
                      display: "inline",
                      float: "right",
                      margin: "auto",
                    }}
                    icon={
                      newName &&
                      newName.length > 0 &&
                      newName !== rename.previousName ? (
                        <i className="fas fa-check"></i>
                      ) : (
                        <i className="fas fa-times"></i>
                      )
                    }
                    onClick={this.rename}
                  />
                </div>
              ) : (
                <>
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      position: "relative",
                      width: "80%",
                      display: "inline-block",
                      color: theme.color,
                      // fontFamily: "Helvetica, Arial, Sans-Serif",
                      // padding: "5px 10px",
                      // border: "1px solid red",
                    }}
                  >
                    {notebook.name}

                    {notebook.isProtected && (
                      <span
                        style={{
                          // position: "absolute",
                          // right: "0px",
                          margin: "0px 10px",
                          fontSize: "14px",
                        }}
                      >
                        <Tooltip placement="right" title="Protected">
                          <i className="far fa-lock"></i>
                        </Tooltip>
                      </span>
                    )}
                    {notebook.ownerId === user.id ? (
                      <span
                        style={{
                          // position: "absolute",
                          // right: "0px",
                          fontSize: "14px",
                          margin: "0px 10px",
                          color: theme.primaryColor,
                        }}
                      >
                        {notebook.sharing === "shared" && (
                          <span>
                            <Tooltip placement="right" title="Shared">
                              <i className="fad fa-user-friends"></i>
                            </Tooltip>
                          </span>
                        )}
                        {notebook.sharing === "published" && (
                          <span>
                            <Tooltip placement="right" title="Published">
                              <i className="fas fa-globe"></i>
                            </Tooltip>
                          </span>
                        )}
                      </span>
                    ) : (
                      notebook.temp &&
                      notebook.temp.permission === "edit" && (
                        <Tooltip title="You can edit this notebook">
                          <span
                            style={{
                              fontSize: "14px",
                              marginLeft: "10px",
                              // position: "absolute",
                              // right: "0",
                              top: "15px",
                              color: theme.primaryColor,
                            }}
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </span>
                        </Tooltip>
                      )
                    )}
                  </span>
                  <span
                    style={{
                      fontWeight: "400",
                      fontSize: "12px",
                      width: "100%",
                      color: theme.color,
                    }}
                  >
                    {notebook.temp && notebook.temp.notesCount}{" "}
                    {/* {notebook.temp &&
                      notebook.temp.savedNotesCount &&
                      notebook.temp.savedNotesCount > 0 && (
                        <span>
                          {" "}
                          &bull;{notebook.temp.savedNotesCount} saved{" "}
                          {notebook.temp.savedNotesCount === 1
                            ? "note"
                            : "notes"}
                        </span>
                      )}{" "} */}
                    {!this.state.allNotesDisplayActionHeader &&
                      this.state.openCollapsible.filter((id) => notebook.id)
                        .length > 0 && (
                        <Button
                          style={{
                            float: "right",
                            padding: "0px",
                            // marginTop: "5px",
                            height: "20px",
                          }}
                          type="link"
                          size="small"
                          icon={<i className="fas fa-chevron-down"></i>}
                          onClick={(e) => {
                            e.stopPropagation();
                            this.setState({
                              allNotesDisplayActionHeader: true,
                            });
                          }}
                        ></Button>
                      )}
                  </span>
                  <span
                    style={{
                      float: "right",
                      // display:
                      //   notebook.id !== user.id
                      //     ? "none"
                      //     : "inline-block",
                    }}
                  >
                    {notebook.ownerId !== user.id &&
                      notebook.temp &&
                      notebook.temp.owner && (
                        <Tooltip
                          title={`Owner: ${notebook.temp.owner.username}`}
                        >
                          <Avatar
                            style={{ marginBottom: "-5px" }}
                            alt={`Owner: ${notebook.temp.owner.username}`}
                            src={
                              notebook.temp &&
                              `https://${notebook.temp.owner.display_picture.downloadUrl}`
                            }
                          />
                        </Tooltip>
                      )}
                    <Dropdown
                      overlay={dropdownMenu(notebook)}
                      trigger={["click"]}
                      onVisibleChange={(e) =>
                        this.setState({ dropdownVisible: e })
                      }
                      // disabled={this.state.dropdownVisible}
                    >
                      <Button
                        size="small"
                        type="link"
                        icon={<i className="far fa-ellipsis-h"></i>}
                        onClick={(e) => e.stopPropagation()}
                        style={{
                          height: "20px",
                          marginBottom: "-8px",
                          display:
                            notebook.ownerId !== user.id
                              ? "none"
                              : "inline-block",
                        }}
                      ></Button>
                    </Dropdown>
                  </span>
                  {/* <div style={{ height: "4px" }} /> */}
                </>
              )}
            </div>
          </Dropdown>
        }
      >
        <AllNotes
          theme={theme}
          displayHeader={false}
          displayActionHeader={this.state.allNotesDisplayActionHeader}
          actionHeaderClose={() =>
            this.setState({ allNotesDisplayActionHeader: false })
          }
          notebookId={notebook.id}
          notebooks={this.props.notebooks}
          getNotebooks={() => this.getNotebooks()}
          selectNote={(note) => this.props.selectNote(note)}
          //onDeleteClick={this.props.onDeleteClick}
          leftPanelWidth={this.props.leftPanelWidth}
          isResizing={this.props.isResizing}
          view={this.state.view}
          deleteNote={(note) => this.props.deleteNote(note)}
          // refreshAlso={this.state.refreshAlso}
          // setRefreshAlso={(notebookId) =>
          //   // If refreshAlso = "all", all notebooks will be updated
          //   this.setState({ refreshAlso: notebookId })
          // }
          notebooksChanged={this.props.notebooksChanged}
          noteDeleted={this.props.noteDeleted}
          noteUpdated={this.props.noteUpdated}
          selectedNote={this.props.selectedNote}
          showSharingModal={this.props.showSharingModal}
          publish={this.props.publishNote}
          moveNote={this.props.moveNote}
          copyNote={this.props.copyNote}
          loaderHeight="10px"
          noteChanged={this.props.noteChanged}
          editSection={(section) =>
            this.setState({ newSection: section, showNewSectionModal: true })
          }
          deleteSection={(section) => this.deleteSection(section)}
        />
      </Panel>
    );

    return (
      <div>
        {/* Header */}
        <div
          style={{
            color: theme.color,
            position: "absolute",
            top: "0",
            width: "100%",
            height: "40px",
            borderBottom: "1px solid #d1d1d1",
            fontSize: "20px",
            fontWeight: "500",
            fontFamily: "Roboto",
            padding: "5px 10px",
            display: this.props.displayHeader ? "block" : "none",
          }}
        >
          {searchSelected ? (
            <>
              <Input
                style={{
                  width: "60%",
                }}
                size="small"
                placeholder="Search your notebooks"
                onChange={(e) =>
                  this.setState({ searchQuery: e.target.value, offset: 0 })
                }
              />
              <Tooltip title={`${notebooks && notebooks.length} results`}>
                <span style={{ fontSize: "12px", margin: "5px" }}>
                  {notebooks && notebooks.length}
                </span>
              </Tooltip>
            </>
          ) : (
            `My Notebooks (${notebooks && notebooks.length})`
          )}
          {this.props.leftPanelWidth > 290 && (
            <>
              <Tooltip title="New Notebook">
                <Button
                  size="small"
                  type="primary"
                  style={{
                    float: "right",
                    marginRight: "35px",
                    marginTop: "5px",
                  }}
                  onClick={() => this.props.newNotebook()}
                >
                  +
                </Button>
              </Tooltip>
              <Button
                size="small"
                type="text"
                style={{ margin: "5px 5px", float: "right" }}
                onClick={() =>
                  this.setState({ searchSelected: !searchSelected })
                }
              >
                {" "}
                <i className="fas fa-search"></i>
              </Button>
            </>
          )}
        </div>
        {newSectionModal}
        {newNotebookSetModal}
        {/* Action Header */}
        <div
          style={{
            height: "auto",
            borderBottom: "1px solid #c1c1c1",
            padding: "0px 5px",
            fontSize: "12px",
            backgroundColor: theme.backgroundColor,
            color: theme.color,
            textAlign: "left",
          }}
        >
          <span
            style={{
              width: "150px",
              //border: "1px solid blue",
              display: "inline-block",
            }}
          >
            {/* Sort by:&nbsp; */}
            <Tooltip title="Sort by:">
              <Select
                bordered={false}
                size="small"
                style={{ width: 110, fontSize: "12px", color: theme.color }}
                defaultValue={
                  localStorage.notebooksortby
                    ? localStorage.notebooksortby
                    : "name"
                }
                onChange={(e) =>
                  this.setState(
                    {
                      sortBy: e,
                      // sortOrder: e === "name" ? "ASC" : "DESC",
                    },
                    localStorage.setItem("notebooksortby", e)
                  )
                }
              >
                <Option value="name">Alphabetical</Option>
                <Option value="updatedAt">Last Edited</Option>
                <Option value="createdAt">Created on</Option>
              </Select>
            </Tooltip>
            <Tooltip title="Descending">
              <Button
                onClick={(e) =>
                  this.state.sortOrder === "ASC" &&
                  this.setState({ sortOrder: "DESC" }, () =>
                    localStorage.setItem("notebooksortorder", "DESC")
                  )
                }
                size="small"
                type="link"
                style={
                  this.state.sortOrder === "DESC"
                    ? { padding: "0" }
                    : { padding: "0", color: theme.color }
                }
              >
                {/* <SortDescendingOutlined /> */}
                {this.state.sortOrder === "DESC" ? (
                  <i className="fas fa-arrow-alt-up"></i>
                ) : (
                  <i className="fal fa-arrow-alt-up"></i>
                )}
              </Button>
            </Tooltip>
            <Tooltip title="Ascending">
              <Button
                onClick={(e) =>
                  this.state.sortOrder === "DESC" &&
                  this.setState({ sortOrder: "ASC" }, () =>
                    localStorage.setItem("notebooksortorder", "ASC")
                  )
                }
                size="small"
                type="link"
                style={
                  this.state.sortOrder === "ASC"
                    ? { padding: "0" }
                    : { padding: "0", color: theme.color }
                }
              >
                {/* <SortAscendingOutlined /> */}
                {this.state.sortOrder === "ASC" ? (
                  <i className="fas fa-arrow-alt-down"></i>
                ) : (
                  <i className="fal fa-arrow-alt-down"></i>
                )}
              </Button>
            </Tooltip>
          </span>
          <span>
            <Popover
              title={<div>Filter Options:</div>}
              content={
                <div style={{ width: "300px" }}>
                  <div style={{ marginBottom: "10px" }}>
                    <div style={{ fontWeight: "500" }}>Sharing:</div>
                    <Checkbox.Group
                      options={[
                        { label: "Private", value: "private" },
                        { label: "Shared", value: "shared" },
                        { label: "Public", value: "published" },
                      ]}
                      defaultValue={filterOptions.sharing}
                      onChange={(value) =>
                        this.setState({
                          filterOptions: {
                            ...this.state.filterOptions,
                            sharing: value,
                          },
                        })
                      }
                    />
                  </div>

                  <div style={{ textAlign: "end", paddingTop: "10px" }}>
                    <Button
                      size="small"
                      type="default"
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        this.setState({
                          filterMenuVisible: false,
                        });
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="small"
                      type="primary"
                      onClick={() => {
                        this.setState(
                          {
                            filterMenuVisible: false,
                            offset: 0,
                          },
                          () => this.getNotebooks()
                        );
                      }}
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              }
              visible={this.state.filterMenuVisible}
              placement="bottomLeft"
              arrowPointAtCenter={true}
            >
              <Button
                style={{
                  padding: "0",
                  color: theme.color,
                  fontSize: "12px",
                  float: "right",
                  margin: "0 10px",
                }}
                size="small"
                type="link"
                onClick={() =>
                  this.setState({
                    filterMenuVisible: !this.state.filterMenuVisible,
                  })
                }
              >
                <Tooltip title="Filter">
                  <i className="far fa-filter"></i>
                </Tooltip>
              </Button>
            </Popover>
          </span>
        </div>

        <Tabs
          onChange={() => {}}
          centered
          size="small"
          tabBarStyle={{
            fontSize: "14px",
            // fontWeight: "500",
            // backgroundColor: "#eeeeee",
            marginBottom: "2px",
            borderBottom: "1px solid #fff",
            color: theme.color,
            borderRadius: "10px",
          }}
        >
          <TabPane
            tab={
              <span>
                {" "}
                <Dropdown
                  trigger={["contextMenu"]}
                  overlay={
                    <Menu>
                      <Menu.Item
                        onClick={() =>
                          this.setState({
                            showNotebookSetModal: true,
                            newNotebookSet: {
                              title: "",
                              icon: {
                                class: `fal fa-books`,
                              },
                            },
                          })
                        }
                      >
                        <span style={{ margin: "0 5px", textAlign: "center" }}>
                          <i className="fal fa-plus"></i>
                        </span>
                        Create new set
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <span>
                    <span
                      style={{
                        display: "inline-block",
                        width: "30px",
                        textAlign: "center",
                      }}
                    >
                      <i className="fal fa-list-alt"></i>
                    </span>
                    All
                  </span>
                </Dropdown>
              </span>
            }
            key="1"
          >
            <Collapse
              // expandIconPosition={this.props.shared && "right"}
              onChange={(e) => this.setState({ openCollapsible: e })}
            >
              {notebooks && notebooks.map((notebook) => collapseItem(notebook))}
            </Collapse>
          </TabPane>
          {sets &&
            sets.map((set) => (
              <TabPane
                tab={
                  <Dropdown
                    overlay={notebookSetDropdown(set)}
                    trigger={["contextMenu"]}
                  >
                    <span>
                      <span
                        style={{
                          display: "inline-block",
                          width: "30px",
                          textAlign: "center",
                        }}
                      >
                        <i className={set.icon.class}></i>
                      </span>
                      {set.title}
                    </span>
                  </Dropdown>
                }
                key={set.id}
              >
                <Collapse
                  // expandIconPosition={this.props.shared && "right"}
                  onChange={(e) => this.setState({ openCollapsible: e })}
                >
                  {notebooks &&
                  notebooks.filter(
                    (notebook) => notebook.notebookSetId === set.id
                  ).length > 0 ? (
                    notebooks.map(
                      (notebook) =>
                        notebook.notebookSetId === set.id &&
                        collapseItem(notebook)
                    )
                  ) : (
                    <div style={{ textAlign: "center" }}>
                      <Empty
                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                        imageStyle={{
                          height: 60,
                          //marginTop: this.props.displayActionHeader ? "150px" : "0px",
                        }}
                        description={<span>Nothing here.</span>}
                      ></Empty>
                    </div>
                  )}
                </Collapse>
              </TabPane>
            ))}
        </Tabs>
      </div>
    );
  }
}

MyNotebooks.defaultProps = {
  displayHeader: true,
};

MyNotebooks.propTypes = {
  auth: propTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default withRouter(connect(mapStateToProps, {})(MyNotebooks));
