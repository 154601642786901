import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Avatar } from "antd";

function ProfileBoxItem({ user, auth }) {
  const { theme } = auth;
  return (
    <Link
      to={`/${user.username}`}
      style={{
        fontWeight: "500",
        color: theme.color,
        display: "inline-grid",
        gridTemplateColumns: "1fr auto",
        alignItems: "center",
        gap: "6px",
      }}
    >
      <div>
        <Avatar size={40} src={`https://${user.display_picture.downloadUrl}`} />{" "}
      </div>
      <div
        style={{
          display: "inline-block",
          padding: "0px 5px",
          color: theme.color,
        }}
      >
        <div>{user.name}</div>
        <div
          style={{
            fontSize: "14px",
            filter:
              theme.mode === "light" ? "brightness(1.4)" : "brightness(0.8)",
            fontWeight: "400",
            marginTop: "-3px",
          }}
        >
          @{user.username}
        </div>
      </div>
    </Link>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileBoxItem);
