import "dotenv/config";
import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  //Redirect,
} from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import jwt_decode from "jwt-decode";
import axios from "axios";
import ReactGA from "react-ga4";

import setAuthToken from "./utils/setAuthToken";
import { message, notification } from "antd";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import store from "./store";
import "./App.less";
import "../node_modules/react-grid-layout/css/styles.css";
import "../node_modules/react-resizable/css/styles.css";

import PrivateRoute from "./components/common/PrivateRoute";
import { light, dark } from "./themes/themes";

// Loading components
import Navbar from "./components/layout/Navbar";
import Landing from "./components/layout/Landing";
import Login from "./components/layout/Login";
import Join from "./components/layout/Join";
import Onboard from "./components/layout/Onboard";
import UpdateProfile from "./components/layout/UpdateProfile";
import VerifyEmail from "./components/layout/VerifyEmail";
import ForgotPassword from "./components/layout/ForgotPassword";
import CreatePassword from "./components/layout/CreatePassword";

// import NoteviewUnAuth from "./components/unauthorized/NoteviewUnAuth";

import Terms from "./components/layout/pages/Terms";
import PrivacyPolicy from "./components/layout/pages/PrivacyPolicy";
import Contact from "./components/layout/pages/Contact";
import About from "./components/layout/pages/About";
import Solutions from "./components/layout/pages/Solutions";
import UnderConstruction from "./components/layout/ErrorComponents/UnderConstruction";
import Editor from "./components/editor/Editor";

// Adding google analytics
if (process.env.NODE_ENV === "production") {
  ReactGA.initialize(process.env.REACT_APP_GAID);
  // ReactGA.pageview(`/`);
  ReactGA.send(`/`);
}

// Handling Resoponse errors
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Authorization error
    if (error.response.status === 401) {
      //place your reentry code
      // Logout user
      store.dispatch(logoutUser());
      // Redirect to login
      window.location.href = "/login";
      message.info("Session timed out. Please sign in again.");
      return;
    }
    return Promise.reject(error);
  }
);

// Check for token
if (localStorage.jwtToken) {
  // Set auth header token auth
  setAuthToken(localStorage.jwtToken);
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout User
    store.dispatch(logoutUser());
    // Redirect to landing
    window.location.href = "/";
  }
}

message.config({ maxCount: 1 });
notification.config({ maxCount: 2 });

export class App extends Component {
  render() {
    // DefaultContainer is used for components that display navbar
    const DefaultContainer = () => (
      <div
        style={
          localStorage.theme === "dark"
            ? { backgroundColor: dark.backgroundColor }
            : { backgroundColor: light.backgroundColor }
        }
        className="default-app-container"
      >
        <Navbar />
        <Switch>
          <Route
            exact
            path="/"
            component={(props) => (
              <Landing {...props} key={window.location.pathname} />
            )}
          />
          <Route
            exact
            path="/explore"
            component={(props) => (
              <Landing {...props} key={window.location.pathname} />
            )}
          />
          <Route
            exact
            path="/explore/:option"
            component={(props) => (
              <Landing {...props} key={window.location.pathname} />
            )}
          />

          <PrivateRoute
            exact
            path="/account-settings"
            component={UpdateProfile}
          />

          <Route
            exact
            path="/tag/:hashtag"
            component={(props) => (
              <Landing {...props} key={window.location.pathname} />
            )}
          />
          <Route exact path="/pages/terms" component={Terms} />
          <Route exact path="/pages/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/blog" component={UnderConstruction} />
          <Route exact path="/features" component={UnderConstruction} />
          <Route exact path="/about" component={UnderConstruction} />
          <Route exact path="/solutions" component={Solutions} />
          <Route exact path="/about-dev" component={About} />
          <Route exact path="/developments" component={UnderConstruction} />
          <Route exact path="/editor" component={Editor} />

          <Route
            exact
            path="/:profile"
            component={(props) => (
              <Landing {...props} key={window.location.pathname} />
            )}
          />
        </Switch>
      </div>
    );
    return (
      <Router forceRefresh={true}>
        <Provider store={store}>
          <HelmetProvider>
            <div className="App">
              <div className="app-container">
                <Switch>
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/join" component={Join} />

                  <Route exact path="/onboard/:token" component={Onboard} />
                  <Route
                    exact
                    path="/verify-email/:token"
                    component={VerifyEmail}
                  />
                  <Route
                    exact
                    path="/forgot-password/"
                    component={ForgotPassword}
                  />
                  <Route
                    exact
                    path="/create-password/:token"
                    component={CreatePassword}
                  />
                  {/* <Route component={DefaultContainer} /> */}
                  <DefaultContainer />
                </Switch>
              </div>
            </div>
          </HelmetProvider>
        </Provider>
      </Router>
    );
  }
}

export default App;
