import React, { Component } from "react";
import { connect } from "react-redux";
import propTypes from "prop-types";
import { withRouter } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import {
  Button,
  Empty,
  Dropdown,
  Menu,
  Select,
  Tooltip,
  Card,
  message,
  Modal,
  Input,
  Popover,
  Checkbox,
} from "antd";
import {
  // SortAscendingOutlined,
  // SortDescendingOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import axios from "axios";

import Loader from "../common/Loader";
import SectionItem from "./AllNotes/SectionItem";

const { Option } = Select;
const { SubMenu } = Menu;
const { confirm } = Modal;

export class AllNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notes: [],
      savedNotes: [],
      limit: 100,
      offset: 0,
      totalCount: this.props.totalCount || 0,
      showSavedNotes: false,
      loading: false,
      hoverOn: null,
      dropdownVisible: false,
      displayActionHeader: true,
      // All this to set default value of Select component
      sortBy:
        this.props.notebookId &&
        this.props.notebooks &&
        this.props.notebooks.filter((n) => n.id === this.props.notebookId)
          .length > 0 &&
        this.props.notebooks.filter((n) => n.id === this.props.notebookId)[0]
          .info1 &&
        this.props.notebooks.filter((n) => n.id === this.props.notebookId)[0]
          .info1.notesOrder
          ? "bookOrder"
          : "updatedAt",
      sortOrder: "DESC",
      view: "list",
      notebookId: null,
      search: null,
      searchQuery: "",
      minLeftPaneWidth: 350,
      filterMenuVisible: false,
      filterOptions: {
        sharing: ["private", "shared", "published"],
        notebooks: this.props.notebookId
          ? [this.props.notebookId]
          : this.props.notebooks &&
            this.props.notebooks.map((notebook) => notebook.id),
      },
      showSortOptions: false,
      sections: null,
      itemOrder: null,
    };
  }

  componentDidMount() {
    if (this.props.notes) {
      return this.setState({ notes: this.props.notes });
    } else if (this.props.notebookId) {
      let currentNotebook = this.props.notebooks.filter(
        (notebook) => notebook.id === this.props.notebookId
      )[0];
      let itemOrder = null;
      if (currentNotebook.info1 && currentNotebook.info1.notesOrder)
        itemOrder = currentNotebook.info1.notesOrder;
      this.setState({
        notebookId: this.props.notebookId,
        sections: currentNotebook && currentNotebook.sections,
        itemOrder,
        sortBy: itemOrder ? "bookOrder" : "updatedAt",
      });
    } else {
      this.setState({ loading: true });
      this.getNotes();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log(this.props.notebooksChanged);
    if (this.props.notebooks && prevProps.notebooks !== this.props.notebooks) {
      let currentNotebook = this.props.notebooks.filter(
        (notebook) => notebook.id === this.props.notebookId
      )[0];
      let itemOrder = null;
      if (
        currentNotebook &&
        currentNotebook.info1 &&
        currentNotebook.info1.notesOrder
      )
        itemOrder = currentNotebook.info1.notesOrder;

      this.setState({
        sections: currentNotebook && currentNotebook.sections,
        itemOrder,
      });
    }
    if (!prevProps.noteChanged && this.props.noteChanged) {
      if (
        this.state.notes.filter((note) => note.id === this.props.noteChanged.id)
          .length > 0
      ) {
        this.setState({
          notes: this.state.notes.map((note) =>
            note.id === this.props.noteChanged.id
              ? this.props.noteChanged
              : note
          ),
        });
      }
    }

    // Update component on note edit
    if (this.props.noteUpdated && this.props.selectedNote) {
      if (
        this.state.notes.filter(
          (note) => note.id === this.props.selectedNote.id
        ).length > 0
      ) {
        // For My Notes component
        if (!this.props.notebookId) {
          this.setState({
            notes: this.state.notes.map((note) => {
              if (note.id === this.props.selectedNote.id)
                return this.props.selectedNote;
              else return note;
            }),
          });
        }
        // For MyNotebook's AllNotes component,
        // checking if note belongs in this notebook
        if (
          this.props.notebookId &&
          this.props.notebookId !== this.props.selectedNote.notebookId
        )
          return;
        this.setState({
          notes: this.state.notes.map((note) => {
            if (note.id === this.props.selectedNote.id)
              return this.props.selectedNote;
            else return note;
          }),
        });
      } else {
        // Note not found, which means new note was created
        if (
          this.props.notebookId &&
          this.props.notebookId !== this.props.selectedNote.notebookId
        )
          return;
        this.setState({
          notes: [this.props.selectedNote, ...this.state.notes],
          itemOrder: this.state.itemOrder
            ? [...this.state.itemOrder, this.props.selectedNote.id]
            : null,
        });
      }
    }

    if (!this.props.notebookId && this.props.noteDeleted)
      this.setState({
        notes: this.state.notes.filter((n) => n.id !== this.props.noteDeleted),
      });

    if (prevProps.displayActionHeader !== this.props.displayActionHeader) {
      this.setState({ displayActionHeader: this.props.displayActionHeader });
    }

    if (
      this.props.notebooksChanged &&
      this.props.notebooksChanged.includes(this.props.notebookId)
    ) {
      this.setState({ offset: 0 }, () => this.getNotes());
    }

    if (prevState.notebookId !== this.state.notebookId) this.getNotes();

    if (prevState.searchQuery !== this.state.searchQuery) this.getNotes();

    if (prevProps.view !== this.props.view) {
      this.setState({ view: this.props.view });
    }

    if (
      prevState.sortBy !== this.state.sortBy &&
      this.state.sortBy !== "bookOrder"
    )
      // this.props.getNotes({
      this.getNotes({
        sortBy: this.state.sortBy,
        sortOrder: this.state.sortOrder,
      });
    else if (
      prevState.sortBy === this.state.sortBy &&
      prevState.sortOrder !== this.state.sortOrder
    )
      this.reverseNotes();
    // this.props.reverseNotes();
  }

  getNotes() {
    // this.setState({ loading: true });
    if (this.props.getNotes) return this.props.getNotes();
    axios
      .get(
        !this.props.notebookId
          ? `${process.env.REACT_APP_API_HOST}/api/notes`
          : `${process.env.REACT_APP_API_HOST}/api/notebooks/notes`,
        {
          params: {
            limit: this.state.limit,
            offset: this.state.offset,
            searchQuery: this.state.searchQuery,
            sortBy:
              this.state.sortBy !== "bookOrder" ? this.state.sortBy : null,
            sortOrder: this.state.sortOrder,
            notebookId: this.state.filterOptions.notebooks,
            // notebooks: this.state.filterOptions.notebooks,
            sharing: this.state.filterOptions.sharing,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        if (res.data.success) {
          this.setState({
            notes:
              this.state.offset > 0 && this.state.notes.length > 0
                ? [
                    ...this.state.notes,
                    ...res.data.notes,
                    // ...res.data.notes.filter(
                    //   // to prevent note duplication
                    //   (n) =>
                    //     this.state.notes.filter((n1) => n1.id === n.id)
                    //       .length === 0
                    // ),
                  ]
                : res.data.notes,
            savedNotes: res.data.savedNotes,
            loading: false,
            totalCount: res.data.count,
            offset: this.state.offset + res.data.notes.length,
          });
        } else message.error("Internal server error. Please try again later.");
      })
      .catch((err) =>
        message.error("Internal server error. Please try again later.")
      );
  }

  reverseNotes() {
    this.setState({ notes: this.state.notes.reverse() });
  }

  onNoteDelete = (note) => {
    confirm({
      title: "Are you absolutely sure you want to DELETE this note?",
      icon: <ExclamationCircleOutlined />,
      content: (
        <div style={{ fontSize: "12px", marginTop: "10px" }}>
          <span style={{ fontWeight: "500" }}>This deletion is PERMANENT.</span>
        </div>
      ),
      okText: "Delete",
      okType: "danger",
      cancelText: "No",
      onOk: () => {
        this.props.deleteNote(note);
      },
      onCancel() {},
    });
  };

  render() {
    const {
      notes,
      savedNotes,
      showSavedNotes,
      totalCount,
      filterOptions,
      sections,
      itemOrder,
    } = this.state;
    const { theme, notebookId, isSmallDevice } = this.props;

    const menuIconStyle = {
      display: "inline-block",
      width: "20px",
    };

    const itemDropdownMenu = (note) => (
      <Menu theme={theme.mode}>
        <Menu.Item
          key="1"
          onClick={() => window.open(`/?note=${note.id}`, "_blank")}
          // icon={<i className="fas fa-external-link-alt"></i>}
        >
          <span style={menuIconStyle}>
            <i className="fas fa-external-link-alt"></i>
          </span>{" "}
          Open Note in new tab
        </Menu.Item>
        {note.authorId === this.props.auth.user.id && (
          <>
            {" "}
            <Menu.Item
              key="2"
              // icon={<i className="far fa-share-square"></i>}
              onClick={() => {
                this.props.selectNote(note);
                this.props.showSharingModal();
              }}
            >
              <span style={menuIconStyle}>
                <i className="fas fa-share-alt"></i>
              </span>{" "}
              Share
            </Menu.Item>
            <Menu.Item
              key="3"
              disabled={note.isProtected}
              // icon={<i className="fas fa-users"></i>}
              onClick={() => {
                this.props.selectNote(note);
                this.props.publish();
                this.setState({ hoverOn: null, dropdownVisible: false });
              }}
            >
              <span style={menuIconStyle}>
                <i className="fas fa-users"></i>
              </span>{" "}
              {note.sharing === "published" ? "Unpublish" : "Publish"}
            </Menu.Item>
            {this.props.notebooks &&
              Array.isArray(this.props.notebooks) &&
              this.props.notebooks.length > 0 && (
                <>
                  <SubMenu
                    // icon={<i className="far fa-copy"></i>}
                    title={
                      <>
                        <span style={menuIconStyle}>
                          <i className="far fa-copy"></i>
                        </span>{" "}
                        Copy to
                      </>
                    }
                  >
                    {this.props.notebooks.map((notebook) => {
                      return (
                        <SubMenu
                          key={notebook.id}
                          onTitleClick={() =>
                            isSmallDevice
                              ? null
                              : this.props.copyNote({
                                  note,
                                  notebookId: notebook.id,
                                })
                          }
                          title={
                            <>
                              <span>{notebook.name}</span>
                              {notebook.id === note.notebookId ? (
                                <span style={{ float: "left", width: "20px" }}>
                                  <i className="fas fa-check"></i>
                                </span>
                              ) : (
                                <span
                                  style={{
                                    float: "left",
                                    width: "20px",
                                    height: "10px",
                                  }}
                                />
                              )}
                            </>
                          }
                        >
                          <Menu.Item
                            key="0"
                            onClick={() =>
                              this.props.copyNote({
                                note,
                                notebookId: notebook.id,
                              })
                            }
                          >
                            <div style={{ width: "150px" }}>
                              <span>None</span>
                              {!note.sectionId ? (
                                <span style={{ float: "left", width: "20px" }}>
                                  <i className="fas fa-check"></i>
                                </span>
                              ) : (
                                <span
                                  style={{
                                    float: "left",
                                    width: "20px",
                                    height: "10px",
                                  }}
                                />
                              )}
                            </div>
                          </Menu.Item>
                          {notebook.sections.map((section) => (
                            <Menu.Item
                              key={section.id}
                              onClick={(e) => {
                                e.domEvent.stopPropagation();
                                this.props.copyNote({
                                  note,
                                  notebookId: notebook.id,
                                  sectionId: section.id,
                                });
                              }}
                            >
                              <div style={{ width: "150px" }}>
                                <span>{section.title}</span>
                                {section.id === note.sectionId ? (
                                  <span
                                    style={{ float: "left", width: "20px" }}
                                  >
                                    <i className="fas fa-check"></i>
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      float: "left",
                                      width: "20px",
                                      height: "10px",
                                    }}
                                  />
                                )}
                                {/* <span
                                  style={{
                                    display: "inline-block",
                                    float: "right",
                                    height: "20px",
                                    width: "20px",
                                    borderRadius: "10px",
                                    border: `1px solid ${theme.borderColor1}`,
                                    backgroundColor: `${section.color}`,
                                  }}
                                ></span> */}
                              </div>
                            </Menu.Item>
                          ))}
                        </SubMenu>
                      );
                    })}
                  </SubMenu>
                  {this.props.auth.user.id === note.authorId && (
                    <>
                      <SubMenu
                        // icon={<i className="fas fa-cut"></i>}
                        title={
                          <>
                            {" "}
                            <span style={menuIconStyle}>
                              <i className="fas fa-cut"></i>
                            </span>{" "}
                            Move to
                          </>
                        }
                      >
                        {this.props.notebooks.map((notebook) => {
                          return (
                            <SubMenu
                              key={notebook.id}
                              onTitleClick={() =>
                                isSmallDevice
                                  ? null
                                  : this.props.moveNote({
                                      note,
                                      notebookId: notebook.id,
                                    })
                              }
                              title={
                                <>
                                  <span>{notebook.name}</span>
                                  {notebook.id === note.notebookId ? (
                                    <span
                                      style={{ float: "left", width: "20px" }}
                                    >
                                      <i className="fas fa-check"></i>
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        float: "left",
                                        width: "20px",
                                        height: "10px",
                                      }}
                                    />
                                  )}
                                </>
                              }
                            >
                              <Menu.Item
                                key="0"
                                onClick={() =>
                                  this.props.moveNote({
                                    note,
                                    notebookId: notebook.id,
                                  })
                                }
                              >
                                <div style={{ width: "150px" }}>
                                  <span>None</span>
                                  {!note.sectionId ? (
                                    <span
                                      style={{ float: "left", width: "20px" }}
                                    >
                                      <i className="fas fa-check"></i>
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        float: "left",
                                        width: "20px",
                                        height: "10px",
                                      }}
                                    />
                                  )}
                                </div>
                              </Menu.Item>
                              {notebook.sections.map((section) => (
                                <Menu.Item
                                  key={section.id}
                                  onClick={(e) => {
                                    e.domEvent.stopPropagation();
                                    this.props.moveNote({
                                      note,
                                      notebookId: notebook.id,
                                      sectionId: section.id,
                                    });
                                  }}
                                >
                                  <div style={{ width: "150px" }}>
                                    <span>{section.title}</span>
                                    {section.id === note.sectionId ? (
                                      <span
                                        style={{ float: "left", width: "20px" }}
                                      >
                                        <i className="fas fa-check"></i>
                                      </span>
                                    ) : (
                                      <span
                                        style={{
                                          float: "left",
                                          width: "20px",
                                          height: "10px",
                                        }}
                                      />
                                    )}
                                    {/* <span
                                      style={{
                                        display: "inline-block",
                                        float: "right",
                                        height: "20px",
                                        width: "20px",
                                        borderRadius: "10px",
                                        border: `1px solid ${theme.borderColor1}`,
                                        backgroundColor: `${section.color}`,
                                      }}
                                    ></span> */}
                                  </div>
                                </Menu.Item>
                              ))}
                            </SubMenu>
                          );
                        })}
                      </SubMenu>
                      <Menu.Divider />
                      <Menu.Item
                        key="4"
                        danger
                        // icon={<i className="fas fa-times"></i>}
                        onClick={() =>
                          this.onNoteDelete(note, {
                            sortBy: this.state.sortBy,
                            sortOrder: this.state.sortOrder,
                          })
                        }
                      >
                        <span style={menuIconStyle}>
                          <i className="fas fa-times"></i>
                        </span>{" "}
                        Delete
                      </Menu.Item>
                    </>
                  )}
                </>
              )}
          </>
        )}
      </Menu>
    );

    const listItem = (note, index, section, style) => (
      <Dropdown
        arrow
        overlay={itemDropdownMenu(note)}
        trigger={["contextMenu"]}
        key={note.id}
        onVisibleChange={(value) => {
          this.setState({ dropdownVisible: value });
        }}
      >
        <div
          className="allnotes-listitem"
          key={note.id}
          style={{
            padding: "8px 10px",
            paddingBottom: "0px",
            paddingRight: "35px",
            borderBottom: notebookId
              ? "1px solid #d1d1d1"
              : index === notes.length - 1
              ? `1px solid ${theme.borderColor1}`
              : "none",
            // height: "40px",
            width: "100%",
            cursor: "pointer",
            backgroundColor:
              this.props.selectedNote && this.props.selectedNote.id === note.id
                ? theme.primaryColor2
                : this.state.hoverOn === note.id
                ? theme.hoverColor1
                : !notebookId && index % 2 === 0
                ? theme.backgroundColor2
                : theme.backgroundColor,
            color:
              this.props.selectedNote && this.props.selectedNote.id === note.id
                ? "#fff"
                : theme.color,
            ...style,
          }}
          onClick={() => {
            this.props.selectNote(note);
            // window.location.href = `/?note=${note.id}`;
          }}
          onContextMenu={(e) => {
            //e.preventDefault();
            this.setState({ hoverOn: note.id });
          }}
          onMouseEnter={() => {
            if (this.state.dropdownVisible) return;
            this.setState({ hoverOn: note.id });
          }}
          onMouseLeave={() => {
            if (this.state.dropdownVisible) return;
            this.setState({ hoverOn: false });
          }}
          onMouseDown={(e) =>
            e.button === 1 && window.open(`/?note=${note.id}`, "_blank")
          }
        >
          <div
            style={{
              position: "relative",
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, Sans-Serif",
            }}
          >
            <Tooltip title={note.title && note.title.length > 0 && note.title}>
              <span
                style={{
                  display: "inline-block",
                  maxWidth:
                    this.props.leftPanelWidth > this.state.minLeftPaneWidth
                      ? "65%"
                      : "80%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {note.title && note.title.length > 0 ? (
                  <span style={{ fontWeight: "500" }}>{note.title}</span>
                ) : (
                  <>
                    {" "}
                    <span style={{ fontWeight: "500" }}>[untitled]</span>
                    {note.textContent &&
                      note.textContent.substr(
                        0,
                        Math.min(note.textContent.length, 40)
                      )}
                  </>
                )}
              </span>
            </Tooltip>

            {(isSmallDevice ||
              (note.temp && note.temp.permission) ||
              this.state.hoverOn === note.id) && (
              <Dropdown
                arrow
                overlay={itemDropdownMenu(note)}
                trigger={["click"]}
                onVisibleChange={(value) =>
                  this.setState({ dropdownVisible: value })
                }
              >
                <Tooltip
                  title={
                    note.temp && note.temp.permission
                      ? note.temp.permission === "edit"
                        ? "You can edit this note"
                        : "You can view this note"
                      : ""
                  }
                >
                  <Button
                    style={{
                      position: "absolute",
                      right: "-32px",
                      top: "50%",
                      marginTop: "-20px",

                      ...(note.temp &&
                        note.temp.permission && {
                          color:
                            this.props.selectedNote &&
                            this.props.selectedNote.id === note.id
                              ? "#fff"
                              : theme.color,
                        }),
                    }}
                    size="middle"
                    type="link"
                    //shape="circle"
                    icon={
                      <span style={{ fontSize: "14px" }}>
                        {note.temp &&
                        note.temp.permission &&
                        note.temp.permission === "edit" ? (
                          <i className="fas fa-pencil-alt"></i>
                        ) : (
                          <i className="far fa-ellipsis-h"></i>
                        )}
                      </span>
                    }
                    onClick={(e) => e.stopPropagation()}
                  />
                </Tooltip>
              </Dropdown>
            )}

            <div
              style={{
                // display: "inline-block",
                float: "right",
                fontSize: "12px",
                position: "absolute",
                right: "0px",
                top: "50%",
                marginTop: "-12px",
                //marginTop: "10px",
                color:
                  this.props.selectedNote &&
                  this.props.selectedNote.id === note.id
                    ? "#fff"
                    : "#828282",
                textAlign: "center",
              }}
            >
              {note.sharing === "published" ||
              note.notebookSharing === "published" ? (
                <Tooltip
                  title={
                    note.sharing === "published"
                      ? "Published"
                      : "In published notebook"
                  }
                >
                  <span
                    style={{
                      color:
                        this.props.selectedNote &&
                        this.props.selectedNote.id === note.id
                          ? "#fff"
                          : theme.primaryColor,
                      marginRight: "2px",
                    }}
                  >
                    <i className="fas fa-globe"></i>
                  </span>
                </Tooltip>
              ) : !(
                  note.sharing === "private" &&
                  note.notebookSharing === "private"
                ) ? (
                <Tooltip
                  title={
                    (note.sharing === "shared" && "Shared") ||
                    (note.notebookSharing === "shared" && "In shared notebook")
                  }
                >
                  <span
                    style={{
                      color:
                        this.props.selectedNote &&
                        this.props.selectedNote.id === note.id
                          ? "#fff"
                          : theme.primaryColor,
                    }}
                  >
                    <i className="fas fa-user-friends"></i>
                  </span>
                </Tooltip>
              ) : (
                <span />
              )}
              <span
                style={{
                  display:
                    this.props.leftPanelWidth > this.state.minLeftPaneWidth
                      ? "inline-block"
                      : "none",
                  marginLeft: "5px",
                  width: "80px",
                  textAlign: "right",
                }}
              >
                {/* Last Edited: */}
                {this.state.sortBy === "createdAt" ? (
                  <Tooltip title="Created on">
                    {moment(note.createdAt).format("DD MMM, YYYY")}
                  </Tooltip>
                ) : (
                  <Tooltip title="Last edited on">
                    {moment(
                      note.info1 && note.info1.editedAt
                        ? note.info1.editedAt
                        : note.updatedAt
                    ).format("DD MMM, YYYY")}
                  </Tooltip>
                )}
              </span>
            </div>
          </div>
        </div>
      </Dropdown>
    );

    const cardItem = (note) => (
      <>
        <Dropdown
          overlay={itemDropdownMenu(note)}
          trigger={["contextMenu"]}
          key={note.id}
        >
          <Card
            key={note.id}
            onClick={() => this.props.selectNote(note)}
            onMouseDown={(e) =>
              e.button === 1 && window.open(`/?note=${note.id}`, "_blank")
            }
            size="small"
            //bordered={false}
            style={{
              backgroundColor: theme.backgroundColor,
              color: theme.color,
              borderRadius: "10px",
              width: "150px",
              height: "150px",
              minHeight: "50px",
              minWidth: "50px",
              maxWidth: `${parseInt(this.props.leftPanelWidth) - 20}px`,
              display: "inline-block",
              margin: "5px",
              overflowWrap: "break-word",
              overflow: "hidden",
              fontSize: "12px",
              resize: "both",
              padding: "-10px",
              cursor: "pointer",
              position: "relative",
              border: "1px solid #A2A2A2",
            }}
            bodyStyle={{
              padding: "0px",
              textAlign: "left",
            }}
            headStyle={{
              textAlign: "center",
              padding: "auto 5px",
              height: "40px",
              borderColor: "#d2d2d2",
              color: theme.color,
            }}
            title={
              <Tooltip title={note.title}>
                <div>
                  {note.title && note.title.length > 0
                    ? note.title
                    : "[Untitled]"}
                </div>
              </Tooltip>
            }
          >
            <div style={{ padding: "10px" }}>
              {note.textContent.substr(0, 40)}
            </div>
            <Card.Meta
              //title="Card title"
              description={
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    position: "absolute",
                    bottom: "5px",
                    color: theme.color,
                  }}
                >
                  {/* <hr style={{ color: "red", height: "1px" }} /> */}
                  {/* <br />
                  Created: {moment(note.createdAt).format("Do MMMM, YYYY")}{" "} */}
                  <br />
                  {/* Edited:  */}
                  {this.state.sortBy === "createdAt"
                    ? moment(note.createdAt).format("Do MMMM, YYYY")
                    : moment(note.updatedAt).format("Do MMMM, YYYY")}{" "}
                </div>
              }
            />
          </Card>
        </Dropdown>
        &nbsp;
      </>
    );

    return (
      <div
        style={{
          backgroundColor:
            this.state.view === "cards"
              ? theme.backgroundColor2
              : theme.backgroundColor,
          color: theme.color,
          //height: "100%",
          width: "100%",
          padding: "0 auto",
          textAlign: this.state.view === "cards" ? "center" : "left",
          //textAlignLast: "left",
        }}
      >
        {this.props.displayHeader && (
          <div
            style={{
              position: "absolute",
              top: "0",
              width: "100%",
              height: "40px",
              borderBottom: `1px solid ${theme.borderColor1}`,
              fontSize: "20px",
              fontWeight: "500",
              fontFamily: "Roboto",
              padding: "5px 10px",
            }}
          >
            <div>
              {!this.state.search ? (
                `My Notes (${totalCount})`
              ) : (
                <div
                  style={{
                    display: "inline-grid",
                    width: "75%",
                    alignItems: "center",
                    gridTemplateColumns: "40px 100%",
                  }}
                >
                  <Button
                    onClick={() =>
                      this.setState({ search: false, searchQuery: "" })
                    }
                    style={{
                      color: theme.color,
                    }}
                    type="link"
                    icon={<i className="fas fa-arrow-left"></i>}
                  />
                  <Input
                    style={{
                      width: "75%",
                    }}
                    size="small"
                    placeholder="Search in your notes"
                    onChange={(e) =>
                      this.setState({ searchQuery: e.target.value, offset: 0 })
                    }
                  />
                </div>
              )}
              {!this.state.search && (
                <>
                  <Tooltip title="Search your notes">
                    <Button
                      onClick={() =>
                        !this.state.search && this.setState({ search: true })
                      }
                      type="link"
                      style={{
                        display: "inline-block",
                        float: "right",
                        marginRight: "25px",
                        //marginLeft: "25px",
                        marginTop: "0px",
                        padding: "5px",
                      }}
                    >
                      <i className="fas fa-search"></i>
                    </Button>
                  </Tooltip>
                </>
              )}
            </div>
          </div>
        )}
        {
          /* Action Header */
          notes && this.state.displayActionHeader && (
            <div
              style={{
                height: "auto",
                borderBottom: `1px solid ${theme.borderColor1}`,
                padding: "0px 5px",
                fontSize: "12px",
                backgroundColor: theme.backgroundColor,
                color: theme.color,
                textAlign: "left",
              }}
            >
              <span
                style={{
                  width: "150px",
                  //border: "1px solid blue",
                  display: "inline-block",
                }}
              >
                {/* Sort by:&nbsp; */}
                {/* <i className="fal fa-sort"></i> */}
                <Tooltip title="Sort by">
                  <Select
                    bordered={false}
                    size="small"
                    style={{ width: 110, fontSize: "12px", color: theme.color }}
                    defaultValue={this.state.sortBy}
                    onChange={(e) =>
                      this.setState({
                        sortBy: e,
                        ...(e === "updatedAt" || e === "createdAt"
                          ? { sortOrder: "DESC" }
                          : { sortOrder: "ASC" }),
                      })
                    }
                  >
                    {itemOrder && <Option value="bookOrder">Book Order</Option>}
                    <Option value="updatedAt">Last Edited</Option>
                    <Option value="createdAt">Created on</Option>
                    <Option value="title">Alphabetical</Option>
                  </Select>
                </Tooltip>

                <Tooltip title="Ascending">
                  <Button
                    onClick={(e) =>
                      this.state.sortOrder === "DESC" &&
                      this.setState({ sortOrder: "ASC" })
                    }
                    size="small"
                    type="link"
                    style={
                      this.state.sortOrder === "ASC"
                        ? { padding: "0px" }
                        : {
                            padding: "0px",
                            color: theme.color,
                          }
                    }
                  >
                    {/* <SortAscendingOutlined /> */}
                    {this.state.sortOrder === "ASC" ? (
                      <i className="fas fa-arrow-alt-up"></i>
                    ) : (
                      <i className="fal fa-arrow-alt-up"></i>
                    )}
                  </Button>
                </Tooltip>
                <Tooltip title="Descending">
                  <Button
                    onClick={(e) =>
                      this.state.sortOrder === "ASC" &&
                      this.setState({ sortOrder: "DESC" })
                    }
                    size="small"
                    type="link"
                    style={
                      this.state.sortOrder === "DESC"
                        ? { padding: "0px" }
                        : {
                            padding: "0px",
                            color: theme.color,
                          }
                    }
                  >
                    {/* <SortDescendingOutlined /> */}
                    {this.state.sortOrder === "DESC" ? (
                      <i className="fas fa-arrow-alt-down"></i>
                    ) : (
                      <i className="fal fa-arrow-alt-down"></i>
                    )}
                  </Button>
                </Tooltip>
              </span>

              <span>
                <Popover
                  title={<div>Filter Options:</div>}
                  content={
                    <div style={{ width: "300px" }}>
                      <div style={{ marginBottom: "10px" }}>
                        <div style={{ fontWeight: "500" }}>Sharing:</div>
                        <Checkbox.Group
                          options={[
                            { label: "Private", value: "private" },
                            { label: "Shared", value: "shared" },
                            { label: "Public", value: "published" },
                          ]}
                          defaultValue={filterOptions.sharing}
                          onChange={(value) =>
                            this.setState({
                              filterOptions: {
                                ...this.state.filterOptions,
                                sharing: value,
                              },
                            })
                          }
                        />
                      </div>
                      {!this.props.notebookId && (
                        <div>
                          <div style={{ fontWeight: "500" }}>In notebook:</div>
                          <Checkbox.Group
                            options={
                              this.props.notebooks &&
                              this.props.notebooks.map((notebook) => {
                                return {
                                  label: notebook.name,
                                  value: notebook.id,
                                };
                              })
                            }
                            defaultValue={filterOptions.notebooks}
                            onChange={(value) =>
                              this.setState({
                                filterOptions: {
                                  ...this.state.filterOptions,
                                  notebooks: value,
                                },
                              })
                            }
                          />
                        </div>
                      )}

                      <div style={{ textAlign: "end", paddingTop: "10px" }}>
                        <Button
                          size="small"
                          type="default"
                          style={{ marginRight: "10px" }}
                          onClick={() => {
                            this.setState({
                              filterMenuVisible: false,
                            });
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          size="small"
                          type="primary"
                          onClick={() => {
                            this.setState(
                              {
                                filterMenuVisible: false,
                                offset: 0,
                              },
                              () => this.getNotes()
                            );
                          }}
                        >
                          Apply
                        </Button>
                      </div>
                    </div>
                  }
                  visible={this.state.filterMenuVisible}
                  placement="bottomLeft"
                  arrowPointAtCenter={true}
                >
                  <Button
                    style={{
                      padding: "0",
                      color: theme.color,
                      fontSize: "12px",
                      display: this.props.notebookId && "none",
                      // float: "right",
                      margin: "0 10px",
                    }}
                    size="small"
                    type="link"
                    onClick={() =>
                      this.setState({
                        filterMenuVisible: !this.state.filterMenuVisible,
                      })
                    }
                  >
                    <Tooltip title="Filter">
                      <i className="far fa-filter"></i>
                    </Tooltip>
                  </Button>
                </Popover>
              </span>

              <span
                style={{
                  width: "70px",
                  // display:
                  //   this.props.leftPanelWidth > this.state.minLeftPaneWidth
                  //     ? "inline-block"
                  //     : "block",
                  // float:
                  //   this.props.leftPanelWidth > this.state.minLeftPaneWidth
                  //     ? "right"
                  //     : "none",
                  display: "inline-block",
                  float: "right",
                  //border: "1px solid blue"
                }}
              >
                {/* View:&nbsp; */}
                <Tooltip title="List View">
                  <Button
                    onClick={() =>
                      this.state.view === "cards" &&
                      this.setState({ view: "list" })
                    }
                    size="small"
                    type="link"
                    style={
                      this.state.view === "list"
                        ? { padding: "0" }
                        : { padding: "0", color: theme.color }
                    }
                  >
                    <i className="fal fa-list"></i>
                  </Button>
                </Tooltip>
                &nbsp;
                <Tooltip title="Card View">
                  <Button
                    onClick={() =>
                      this.state.view === "list" &&
                      this.setState({ view: "cards" })
                    }
                    size="small"
                    type="link"
                    style={
                      this.state.view === "cards"
                        ? { padding: "0" }
                        : { padding: "0", color: theme.color }
                    }
                  >
                    <i className="fal fa-th-large"></i>
                  </Button>
                </Tooltip>
                <Button
                  type="link"
                  size="small"
                  style={{ float: "right" }}
                  icon={<i className="far fa-chevron-up"></i>}
                  onClick={() => {
                    this.setState({ displayActionHeader: false });
                    this.props.actionHeaderClose &&
                      this.props.actionHeaderClose();
                  }}
                />
              </span>
            </div>
          )
        }
        {this.state.loading ? (
          <Loader height={this.props.loaderHeight} />
        ) : (notes && notes.length > 0) ||
          (sections && sections.length > 0) ||
          (savedNotes && savedNotes.length > 0) ? (
          this.state.view === "list" ? (
            <div>
              {/* Saved Notes Panel */}
              {savedNotes && savedNotes.length > 0 && (
                <div
                  style={{
                    borderBottom: `1px solid ${theme.primaryColor}`,
                    borderLeft: `4px solid ${theme.primaryColor}`,
                  }}
                >
                  <div
                    onClick={(e) =>
                      this.setState({
                        showSavedNotes: !this.state.showSavedNotes,
                      })
                    }
                    style={{
                      borderBottom:
                        this.state.showSavedNotes &&
                        `1px solid ${theme.primaryColor}`,
                      padding: "2px 10px",
                      fontSize: "12px",
                      fontWeight: "700",
                      cursor: "pointer",
                      userSelect: "none",
                      MozUserSelect: "none",
                      webkitTouchCallout: "none",
                      WebkitUserSelect: "none",
                      // backgroundColor: theme.backgroundColor2,
                      color: theme.primaryColor,
                    }}
                    // onSelectCapture={(e) => console.log(e)}
                  >
                    <span
                      style={{
                        margin: "0 10px",
                        fontSize: "12px",
                        fontWeight: "600",
                      }}
                    >
                      {showSavedNotes ? (
                        <i className="fas fa-chevron-down"></i>
                      ) : (
                        <i className="fas fa-chevron-right"></i>
                      )}
                    </span>{" "}
                    Saved Notes
                    <Tooltip
                      title={`${savedNotes.length} ${
                        savedNotes.length === 1 ? "note" : "notes"
                      }`}
                      placement="right"
                    >
                      <span style={{ float: "right" }}>
                        {savedNotes.length}
                      </span>
                    </Tooltip>
                  </div>
                  {showSavedNotes && savedNotes.map((note) => listItem(note))}
                </div>
              )}
              {this.state.sortBy === "bookOrder" ? (
                <InfiniteScroll
                  loadMore={() => this.getNotes()}
                  hasMore={notes.length < totalCount}
                  loader={<Loader />}
                  useWindow={false}
                >
                  {itemOrder.map((id, index) =>
                    notes.filter((note) => note.id === id).length > 0 ? (
                      listItem(
                        ...notes.filter(
                          (note) => note.id === id && !note.sectionId
                        ),
                        index
                      )
                    ) : sections.filter((s) => s.id === id).length > 0 ? (
                      <SectionItem
                        section={
                          sections.filter((section) => section.id === id)[0]
                        }
                        index={index}
                        notes={notes.filter((note) => note.sectionId === id)}
                        listItem={listItem}
                        editSection={this.props.editSection}
                        deleteSection={this.props.deleteSection}
                        theme={theme}
                        sortBy={this.state.sortBy}
                      />
                    ) : null
                  )}
                </InfiniteScroll>
              ) : (
                <>
                  {this.props.notebookId &&
                    sections &&
                    sections.map((section, index) => (
                      <SectionItem
                        section={section}
                        index={index}
                        notes={notes.filter(
                          (note) => note.sectionId === section.id
                        )}
                        listItem={listItem}
                        editSection={this.props.editSection}
                        deleteSection={this.props.deleteSection}
                        theme={theme}
                        sortBy={this.state.sortBy}
                      />
                    ))}
                  <InfiniteScroll
                    loadMore={() => this.getNotes()}
                    hasMore={notes.length < totalCount}
                    loader={<Loader />}
                    useWindow={false}
                  >
                    {notes &&
                      notes.map((note, index) =>
                        notebookId
                          ? !note.sectionId && listItem(note, index)
                          : listItem(note, index)
                      )}
                  </InfiniteScroll>
                </>
              )}
            </div>
          ) : (
            <div> {notes.map((note) => cardItem(note))}</div>
          )
        ) : (
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 60,
              //marginTop: this.props.displayActionHeader ? "150px" : "0px",
            }}
            description={<span>No note found.</span>}
          >
            {/* <Button type="primary" >Create Now</Button> */}
          </Empty>
        )}
      </div>
    );
  }
}

AllNotes.defaultProps = {
  displayHeader: true,
  displayActionHeader: true,
};

AllNotes.propTypes = {
  auth: propTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default withRouter(connect(mapStateToProps, {})(AllNotes));
