import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Avatar, Button, message } from "antd";
import TinyEditor from "../editor/TinyEditor";

export default class NoteviewUnauth extends Component {
  render() {
    const { note, theme } = this.props;
    return (
      <div>
        <div style={{ padding: "0px 20px" }}>
          {note.temp && (
            <div
              style={{
                display: "inline-grid",
                gridTemplateColumns: "50px auto",
                margin: "0px 10px",
                gridGap: "5px",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "600",
                cursor: "pointer",
              }}
            >
              <div>
                <Avatar
                  style={{ display: "inline-block" }}
                  size={50}
                  src={
                    note.temp &&
                    `https://${note.temp.author.display_picture.downloadUrl}`
                  }
                />
              </div>
              <div>
                <Link
                  style={{
                    display: "inline-block",
                    margin: "10px",
                    color: theme.color,
                  }}
                  to={`/${note.temp.author.username}`}
                >
                  {note.temp.author.name}
                  <div />@{note.temp.author.username}
                </Link>
              </div>
            </div>
          )}
          <div style={{ float: "right", margin: "20px" }}>
            <Button
              type={this.props.userId ? "primary" : "link"}
              onClick={() => this.props.onSave(note)}
              style={{ width: "90px" }}
              icon={
                note.temp.hasSaved ? (
                  <i className="fas fa-bookmark"></i>
                ) : (
                  <i className="far fa-bookmark"></i>
                )
              }
            >
              &nbsp;{" "}
              {note.temp.saverCount === 0
                ? " Save"
                : note.temp.saverCount === 1
                ? " 1 Save"
                : ` ${note.temp.saverCount} saves`}
            </Button>
          </div>
        </div>
        {note.temp && note.temp.notebook && (
          <div
            style={{
              marginTop: "25px",
              fontSize: "18px",
              padding: "0 20px",
            }}
          >
            <Link to={`/?notebook=${note.notebookId}`} target="_blank">
              <i className="fal fa-book"></i>&nbsp;{" "}
              <span style={{ fontWeight: "500" }}>
                {note.temp.notebook.name}
              </span>
            </Link>
          </div>
        )}
        <div className="noteview-title">{note.title}</div>
        <TinyEditor
          readOnly={true}
          content={note.htmlContent}
          theme={"light"}
          handleChange={(e) => {}}
          fontSize={note.info1.fontSize}
          lineHeight={note.info1.lineHeight}
        />
        <div>
          <Button
            style={{ float: "right" }}
            type="text"
            size="large"
            icon={<i className="fal fa-share"></i>}
            onClick={() => {
              message.success("Link copied.");
              navigator.clipboard.writeText(
                `${process.env.REACT_APP_HOST_ADDRESS}/?note=${note.id}`
              );
            }}
          >
            &nbsp;Share
          </Button>
        </div>
      </div>
    );
  }
}
