import isEmpty from "../validation/is-empty";

import {
  SET_CURRENT_USER,
  CHANGE_THEME,
  SET_CONNECTION_ERROR,
  SET_DEVICE,
  TOGGLE_MOBILE_SIDEBAR,
} from "../actions/types";
import { light, dark } from "../themes/themes";

const initialState = {
  isAuthenticated: false,
  user: {},
  theme:
    localStorage.theme && localStorage.theme === "dark"
      ? { mode: "dark", ...dark }
      : { mode: "light", ...light },
  isDisconnected: !window.navigator.onLine,
  isSmallDevice:
    window.innerWidth <= 900 ||
    document.documentElement.clientWidth <= 900 ||
    document.getElementsByTagName("body")[0].clientWidth <= 900,
  mobileSidebarVisible: false,
};

export default function foo(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case CHANGE_THEME:
      return {
        ...state,
        theme:
          // localStorage.theme === "dark"
          state.theme.mode === "light"
            ? { mode: "dark", ...dark }
            : { mode: "light", ...light },
      };
    case SET_CONNECTION_ERROR:
      return {
        ...state,
        isDisconnected: action.payload,
      };
    case SET_DEVICE:
      return {
        ...state,
        isSmallDevice:
          window.innerWidth <= 900 ||
          document.documentElement.clientWidth <= 900 ||
          document.getElementsByTagName("body")[0].clientWidth <= 900,
      };
    case TOGGLE_MOBILE_SIDEBAR:
      return {
        ...state,
        mobileSidebarVisible: !state.mobileSidebarVisible,
      };
    default:
      return state;
  }
}
