import React, { Component } from "react";
import axios from "axios";
import { Input, Button } from "antd";
import Loader from "../common/Loader";

export class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      loading: false,
      message: null,
      success: null,
    };
  }
  submit() {
    if (this.state.email.length === 0)
      return this.setState({ success: false, message: "Enter Email" });

    this.setState({ loading: true, message: null });
    axios
      .post(`${process.env.REACT_APP_API_HOST}/api/users/forgot-password`, {
        email: this.state.email,
      })
      .then((res) => {
        if (res.data.success)
          this.setState({
            loading: false,
            success: true,
            message:
              "If your email is registered, you would have recieved a link to reset your password.",
          });
        else
          this.setState({
            loading: false,
            success: false,
            message:
              "There is some problem with the server. Please try again later.",
          });
      });
  }
  render() {
    const { message, loading, success } = this.state;
    return (
      <div
        style={{
          margin: "80px auto",
          width: "40%",
          minWidth: "350px",
          padding: "15px",
          backgroundColor: "#fff",
          borderRadius: "15px",
        }}
      >
        <h2>Enter your email:</h2>
        <div style={{ margin: "15px 0px" }}>
          <Input onChange={(e) => this.setState({ email: e.target.value })} />
        </div>
        <p>We will send you a link to create new password.</p>
        <Button
          style={{ marginTop: "5px" }}
          type="primary"
          onClick={() => this.submit()}
        >
          Submit
        </Button>
        {loading ? (
          <Loader />
        ) : message ? (
          <div
            style={{
              fontSize: "22px",
              marginTop: "15px",
              color: success ? "blue" : "red",
              textAlign: "center",
            }}
          >
            {message}
          </div>
        ) : null}
      </div>
    );
  }
}

export default ForgotPassword;
