import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Tabs } from "antd";
import Search from "../search/Search";
import SeeAllNotes from "./SeeAllNotes";
import SeeAllNotebooks from "./SeeAllNotebooks";

import "./explore.css";

const { TabPane } = Tabs;

export class Explore extends Component {
  render() {
    const { auth } = this.props;
    const { theme, isSmallDevice } = auth;

    // console.log(this.props.exploreOption);
    // console.log(auth);

    const styles = {
      trendingTopicsItem: isSmallDevice
        ? {
            fontSize: "22px",
            display: "inline-block",
          }
        : {
            borderRadius: "55px",
            // border: `2px solid rgba(0,0,0,0)`,
            fontSize: "22px",
            display: "inline-flex",
            padding: "5px 10px",
            margin: "5px",
            fontWeight: "500",
            alignItems: "center",
            gap: "5px",
            boxSizing: "border-box",
            color: theme.color,
          },
      trendingTopicsItemIcon: {
        display: "inline-grid",
        fontSize: "30px",
        height: "40px",
        width: "40px",
        borderRadius: "20px",
        // border: "5px solid #d2d2d2",
        alignContent: "center",
        padding: "1px",
        fontWeight: "300",
        color: theme.primaryColor,
      },
    };

    return (
      <div
        style={{
          backgroundColor: theme.backgroundColor,
          color: theme.color,
          // height: "100%",
        }}
      >
        {this.props.exploreOption === "notes" ? (
          <SeeAllNotes
            saveNote={(nId, nbId) => this.props.saveNote(nId, nbId)}
            userId={auth.user.id}
            theme={theme}
            userNotebooks={this.props.userNotebooks}
            reloadNote={this.props.reloadNote}
            isSmallDevice={isSmallDevice}
          />
        ) : this.props.exploreOption === "notebooks" ? (
          <SeeAllNotebooks
            saveNote={(nbId) => this.props.saveNotebook(nbId)}
            userId={auth.user.id}
            theme={theme}
            isSmallDevice={isSmallDevice}
            reloadNotebook={this.props.reloadNotebook}
          />
        ) : (
          <>
            {" "}
            <div
              className="explore-search-container"
              style={{
                fontSize: isSmallDevice ? "32px" : "52px",
                fontWeight: "500",
                textAlign: "center",
                padding: "140px 10px",
                background:
                  theme.mode === "light"
                    ? `linear-gradient(0deg, rgba(256, 256, 256, 0.1), rgba(256, 256, 256, 0.1)), url(https://cdn.notebuq.com/file/ntbq-01/resources/explore/ex-sb2.jpg)`
                    : `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(https://cdn.notebuq.com/file/ntbq-01/resources/explore/ex-sb2.jpg)`,
                height: "500px",
                backgroundSize: "cover",
                backgroundColor: "rgba(0,0,0,0.2)",
              }}
            >
              <div>What do you want to read today?</div>
              <div
                style={{ width: "70%", margin: "20px auto", maxWidth: "600px" }}
              >
                {/* <Input size="large" placeholder="Type to search" /> */}
                <Search
                  size="large"
                  placeholder="Type to search"
                  bordered={true}
                  style={{
                    textAlign: "left",
                  }}
                />
              </div>
            </div>
            <div className="explore-trending-tags" style={{ padding: "40px" }}>
              <div
                style={{
                  fontSize: "32px",
                  textAlign: "center",
                  margin: "20px 0px",
                }}
              >
                Trending Topics
              </div>
              <div
                className="explore-trending-topics-container"
                style={{
                  padding: isSmallDevice ? "0 10px" : "0 150px",
                  margin: "0 auto",
                  textAlign: "center",
                }}
              >
                <Link
                  to={`/tag/fitness`}
                  target="_blank"
                  style={styles.trendingTopicsItem}
                >
                  <div style={styles.trendingTopicsItemIcon}>
                    <div>
                      <i class="far fa-hashtag"></i>
                    </div>
                  </div>
                  Fitness
                </Link>
                <Link
                  to={"/tag/nutrition"}
                  target="_blank"
                  style={styles.trendingTopicsItem}
                >
                  <div style={styles.trendingTopicsItemIcon}>
                    <div>
                      <i class="far fa-hashtag"></i>
                    </div>
                  </div>
                  Nutrition
                </Link>
                <Link
                  to={"/tag/productivity"}
                  target="_blank"
                  style={styles.trendingTopicsItem}
                >
                  <div style={styles.trendingTopicsItemIcon}>
                    <div>
                      <i class="far fa-hashtag"></i>
                    </div>
                  </div>
                  Productivity
                </Link>
                <Link
                  to={"/tag/business"}
                  target="_blank"
                  style={styles.trendingTopicsItem}
                >
                  <div style={styles.trendingTopicsItemIcon}>
                    <div>
                      <i class="far fa-hashtag"></i>
                    </div>
                  </div>
                  Business
                </Link>
                <Link
                  to={"/tag/technology"}
                  target="_blank"
                  style={styles.trendingTopicsItem}
                >
                  <div style={styles.trendingTopicsItemIcon}>
                    <div>
                      <i class="far fa-hashtag"></i>
                    </div>
                  </div>
                  Technology
                </Link>
                <Link
                  to={"/tag/finance"}
                  target="_blank"
                  style={styles.trendingTopicsItem}
                >
                  <div style={styles.trendingTopicsItemIcon}>
                    <div>
                      <i class="far fa-hashtag"></i>
                    </div>
                  </div>
                  Finance
                </Link>
                <Link
                  to={"/tag/health"}
                  target="_blank"
                  style={styles.trendingTopicsItem}
                >
                  <div style={styles.trendingTopicsItemIcon}>
                    <div>
                      <i class="far fa-hashtag"></i>
                    </div>
                  </div>
                  Health
                </Link>
                <Link
                  to={"/tag/self_growth"}
                  target="_blank"
                  style={styles.trendingTopicsItem}
                >
                  <div style={styles.trendingTopicsItemIcon}>
                    <div>
                      <i class="far fa-hashtag"></i>
                    </div>
                  </div>
                  Self Growth
                </Link>
                <Link
                  to={"/tag/travel"}
                  target="_blank"
                  style={styles.trendingTopicsItem}
                >
                  <div style={styles.trendingTopicsItemIcon}>
                    <div>
                      <i class="far fa-hashtag"></i>
                    </div>
                  </div>
                  Travel
                </Link>
                <Link
                  to={"/tag/novel"}
                  target="_blank"
                  style={styles.trendingTopicsItem}
                >
                  <div style={styles.trendingTopicsItemIcon}>
                    <div>
                      <i class="far fa-hashtag"></i>
                    </div>
                  </div>
                  Novel
                </Link>
                <Link
                  to={"/tag/shortstories"}
                  target="_blank"
                  style={styles.trendingTopicsItem}
                >
                  <div style={styles.trendingTopicsItemIcon}>
                    <div>
                      <i class="far fa-hashtag"></i>
                    </div>
                  </div>
                  Short Stories
                </Link>
                <Link
                  to={"/tag/fiction"}
                  target="_blank"
                  style={styles.trendingTopicsItem}
                >
                  <div style={styles.trendingTopicsItemIcon}>
                    <div>
                      <i class="far fa-hashtag"></i>
                    </div>
                  </div>
                  Fiction
                </Link>
              </div>
            </div>
            <div
              className="explore-see-all-content"
              style={{
                margin: isSmallDevice ? "0 10px" : "20px auto",
                maxWidth: "1200px",
              }}
            >
              <Tabs
                defaultActiveKey={localStorage.explore_tab || "notebooks"}
                onChange={(e) => localStorage.setItem("explore_tab", e)}
                centered
                // type="card"
                size={isSmallDevice ? "small" : "large"}
                tabBarStyle={{ fontSize: "28px", color: theme.color }}
              >
                <TabPane
                  tab={
                    <span
                      style={
                        isSmallDevice
                          ? { fontSize: "12px" }
                          : { fontSize: "16px" }
                      }
                    >
                      <i
                        style={{ fontWeight: "300" }}
                        className="far fa-newspaper"
                      ></i>
                      &nbsp; &nbsp;Articles & Notes
                    </span>
                  }
                  key="notes"
                >
                  <SeeAllNotes
                    saveNote={(nId, nbId) => this.props.saveNote(nId, nbId)}
                    userId={auth.user.id}
                    theme={theme}
                    isSmallDevice={isSmallDevice}
                    userNotebooks={this.props.userNotebooks}
                    reloadNote={this.props.reloadNote}
                  />
                </TabPane>
                <TabPane
                  tab={
                    <span
                      style={
                        isSmallDevice
                          ? { fontSize: "12px" }
                          : { fontSize: "16px" }
                      }
                    >
                      <i
                        style={{ fontWeight: "300" }}
                        className="far fa-book"
                      ></i>
                      &nbsp; &nbsp;Books & Notebooks
                    </span>
                  }
                  key="notebooks"
                >
                  <SeeAllNotebooks
                    saveNotebook={(nbId) => this.props.saveNotebook(nbId)}
                    userId={auth.user.id}
                    theme={theme}
                    isSmallDevice={isSmallDevice}
                    reloadNotebook={this.props.reloadNotebook}
                  />
                </TabPane>
              </Tabs>
            </div>
          </>
        )}
        <div style={{ height: "100px" }} />
      </div>
    );
  }
}

Explore.defaultProps = {
  saveNote: () => {},
  saveNootebook: () => {},
  userNotebooks: [],
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Explore);
