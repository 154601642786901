import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import propTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Dashboard from "../dashboard/Dashboard";
import Main from "../unauthorized/Main";
import "./styles/landing.less";
import { Button, Space, notification } from "antd";
import {
  LeftCircleOutlined,
  RightCircleOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import makeCarousel from "react-reveal/makeCarousel";
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "./LandingComponents/Footer";
import Slider from "@ant-design/react-slick";
import Slide from "react-reveal/Slide";
import TopTitleAnimation from "./LandingComponents/TopTitleAnimation";

export class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typistKey: 0,
    };
  }

  componentDidMount() {
    AOS.init({
      duration: 500,
      offset: 400,
      once: true,
    });
    // console.log(this.props.match);
    if (
      !(this.props.auth && this.props.auth.isAuthenticated) &&
      !(
        ["note", "notebook"].includes(
          this.props.location.search.substr(
            1,
            this.props.location.search.indexOf("=") - 1
          )
        ) ||
        this.props.match.params.profile ||
        this.props.match.params.hashtag ||
        this.props.match.path === "/explore" ||
        this.props.match.path === "/explore/:option"
      )
    )
      notification.open({
        message: "Read Forever Offer! 🎉",
        description: <div>Join now and get 50 books for FREE. </div>,
        icon: <SmileOutlined style={{ color: "#108ee9" }} />,
        placement: "bottomRight",
        duration: null,
      });
  }

  componentDidUpdate(prevProps, prevState) {
    // Object.entries(this.props).forEach(
    //   ([key, val]) =>
    //     prevProps[key] !== val && console.log(`Prop '${key}' changed`)
    // );
    // if (this.state) {
    //   Object.entries(this.state).forEach(
    //     ([key, val]) =>
    //       prevState[key] !== val && console.log(`State '${key}' changed`)
    //   );
    // }
  }

  render() {
    const { isSmallDevice, theme } = this.props.auth;

    const CarouselUI = ({
      children,
      style,
      childrenStyle,
      position,
      total,
      handleClick,
      dotsStyle,
      dotAlias = [],
      dotAliasStyle,
      dotAliasSelectedStyle,
    }) => (
      <div style={style}>
        <span style={dotsStyle}>
          {Array(...Array(total)).map((val, index) => (
            <span key={index} onClick={handleClick} data-position={index}>
              {dotAlias[index] ? (
                <span
                  style={{
                    ...dotAliasStyle,
                    ...(index === position && dotAliasSelectedStyle),
                  }}
                >
                  {dotAlias[index]}
                </span>
              ) : index === position ? (
                "● "
              ) : (
                "○ "
              )}
            </span>
          ))}
        </span>
        <div style={childrenStyle}>{children}</div>
        <div
          className="landing-carousel-arrow"
          style={
            isSmallDevice
              ? { display: "none" }
              : {
                  zIndex: "100",
                  position: "absolute",
                  top: "50%",
                  left: "40px",
                  width: "40px",
                  cursor: "pointer",
                  fontSize: "3em",
                }
          }
          onClick={handleClick}
          data-position={position - 1}
        >
          <LeftCircleOutlined />
        </div>
        <div
          className="landing-carousel-arrow"
          style={
            isSmallDevice
              ? { display: "none" }
              : {
                  zIndex: "100",
                  position: "absolute",
                  top: "50%",
                  right: "40px",
                  width: "40px",
                  cursor: "pointer",
                  fontSize: "3em",
                }
          }
          right
          onClick={handleClick}
          data-position={position + 1}
        >
          <RightCircleOutlined />
        </div>
      </div>
    );
    const Carousel = makeCarousel(CarouselUI);

    const style = {
      // Carousel styles
      carouselContainer: {
        margin: "0 auto",
        position: "relative",
        overflow: "hidden",
        width: "100%",
        height: "600px",
      },
      childrenStyle: isSmallDevice
        ? {
            // width: "90%",
            position: "relative",
            backgroundColor: "rgba(245,245,245,0.5)",

            // height: "100%",
            // border: "1px solid blue",
          }
        : {
            width: "85%",
            position: "relative",
            height: "100%",
            // border: "1px solid blue",
            margin: "0 auto",
            overflow: "hidden",
            // backgroundColor: "rgba(245,245,245,0.5)",
          },
      slide: isSmallDevice
        ? {}
        : {
            margin: "0px",
            display: "grid",
            gridTemplateColumns: "1fr 3fr",
            gridGap: "20px",
            // border: "1px solid red",
            height: "100%",
          },
      listContainer: isSmallDevice
        ? {
            margin: "20px auto",
            width: "80%",
            fontSize: "14px",
          }
        : {
            margin: "4rem 20px",
            width: "100%",
            fontSize: "14px",
            lineHeight: "2",
          },
      listItem: {
        margin: "15px 5px",
        // display: "inline-block",
      },
      listIcon: {
        color: theme.primaryColor,
      },
      imageContainer: isSmallDevice
        ? { marginTop: "80px", textAlign: "center" }
        : {
            padding: "2.5rem",
            boxSizing: "border-box",
          },
      sliderImage: isSmallDevice
        ? { width: "90%", boxShadow: "1px 2px 15px -3px rgba(0,0,0,0.75)" }
        : {
            width: "100%",
            // border: "1px solid #d2d2d2",
            boxShadow: "2px 2px 15px -6px rgba(0,0,0,0.75)",
            // margin: "10px",
            // marginTop: "10px",
          },
      // Carousel Styles ends

      rowHeading: isSmallDevice
        ? {
            margin: "40px 10px 30px 10px",
            padding: "10px",
            fontSize: "28px",
            textAlign: "center",
          }
        : {
            margin: "80px 0px 30px 0px",
            padding: "10px 100px",
            fontSize: "46px",
            fontWeight: "500",
            textAlign: "center",
          },

      // Feature styles starts
      featureItem: isSmallDevice
        ? {
            display: "grid",
            gridTemplateColumns: "1fr 5fr",
            padding: "10px",
            margin: "10px 0px",
          }
        : {
            display: "grid",
            gridTemplateColumns: "1fr 5fr",
            padding: "10px",
          },
      featureIcon: {
        fontSize: "30px",
        fontWeight: "200",
        color: theme.primaryColor,
        textAlign: "center",
      },
      featureHeading: {
        fontSize: "18px",
        fontWeight: "300",
      },
      featureDescription: {
        // width: "75%",
        fontSize: "14px",
      },
      diffContainer: isSmallDevice ? {} : { margin: "0 25%" },
      diffItem: isSmallDevice
        ? {
            display: "grid",
            margin: "20px 0px",
            padding: "10px 20px",
            color: "#666",
            // backgroundColor: theme.backgroundColor2,
            lineHeight: "2.0",
            textAlign: "center",
          }
        : {
            display: "grid",
            gridTemplateColumns: "1fr 4fr",
            margin: "20px 0px",
            padding: "10px 20px",
            color: "#666",
            // backgroundColor: theme.backgroundColor2,
            lineHeight: "2.0",
            borderRadius: "10px",
            // boxShadow:
          },
      diffIcon: {
        textAlign: "center",
        fontSize: "40px",
      },
      diffHeading: { fontSize: "24px" },
      diffDescription: {},
    };

    const landingContent = (
      <>
        <Helmet>
          <title>Notebuq - Read. Write. Publish </title>
        </Helmet>
        <div
          className="landing-container2"
          style={{
            fontFamily: "Helvetica, Arial, Sans-Serif",
            color: "#555",
            background: "#fff",
            // scrollSnapType: "y mandatory",
            // scrollSnapPoints: "repeat(800px)",
            // overflowX: "hidden",
          }}
        >
          <div
            className="landing-top"
            style={
              isSmallDevice
                ? {}
                : {
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                  }
            }
          >
            <div
              className="landing-top-main"
              style={
                isSmallDevice
                  ? {
                      fontSize: "32px",
                      fontWeight: "500",
                      padding: "30px",
                      paddingTop: "110px",
                      textAlign: "center",
                    }
                  : {
                      fontSize: "2.8rem",
                      fontWeight: "500",
                      padding: "100px",
                      paddingTop: "160px",
                      // textAlign: "center",
                    }
              }
            >
              <div
                style={
                  isSmallDevice
                    ? { height: "180px" }
                    : { minHeight: "14rem", fontSize: "40px" }
                }
              >
                <TopTitleAnimation
                  lineDelay={2000}
                  key={this.state.typistKey}
                  onTypingDone={() =>
                    this.setState({ typistKey: this.state.typistKey + 1 })
                  }
                />
              </div>
              {/* Your Knowledge Network and Personal Library. */}
              <div
                style={
                  isSmallDevice
                    ? {
                        margin: "15px auto",
                        border: "2px solid lightblue",
                        width: "15%",
                      }
                    : {
                        margin: "15px 0",
                        border: "2px solid lightblue",
                        width: "15%",
                      }
                }
              ></div>
              <div
                style={
                  isSmallDevice
                    ? { fontSize: "20px" }
                    : {
                        fontSize: "20px",
                        maxWidth: "440px",
                        padding: "20px 0px",
                      }
                }
              >
                Notebuq is your all-in-one destination for reading and writing
              </div>
              <div>
                <Space>
                  <Link to={`/join`}>
                    <Button type="primary">Join For Free</Button>
                  </Link>
                  <Link to={`login`}>
                    <Button>Sign In</Button>
                  </Link>
                </Space>
              </div>
            </div>
          </div>
          <div style={style.rowHeading}>
            Read. Write. Publish.
            <div
              style={{
                border: "1px solid lightblue",
                width: "15%",
                margin: "20px auto",
              }}
            />
            <div
              style={
                isSmallDevice
                  ? { fontSize: "18px" }
                  : { width: "45%", margin: "10px auto", fontSize: "18px" }
              }
            >
              Notebuq is an all-in-one app for creating, finding and sharing
              notes and notebooks with some, everyone or keeping it private.
            </div>
            <div data-aos="slide-up" data-aos-offset="150">
              <img
                style={
                  isSmallDevice
                    ? {
                        width: "90%",
                        margin: "40px auto",
                        boxShadow: "2px 2px 15px -6px rgba(0,0,0,0.75)",
                      }
                    : {
                        marginTop: "40px",
                        width: "60%",
                        boxShadow: "2px 2px 15px -6px rgba(0,0,0,0.75)",
                      }
                }
                alt="Find and publish ideas"
                src={`https://cdn.notebuq.com/file/ntbq-01/resources/landing/lht1.png`}
              />
            </div>
          </div>
          <div style={isSmallDevice ? {} : { height: "40px" }}></div>
          <div className="landing-use-cases">
            <div style={{ ...style.rowHeading, marginBottom: "20px" }}>
              A place of endless possibilities
              <div
                style={{
                  border: "1px solid lightblue",
                  width: "15%",
                  margin: "20px auto",
                }}
              />
              <div style={{ textAlign: "center", fontSize: "14px" }}>
                See all{" "}
                <Link
                  style={{ color: theme.primaryColor, fontWeight: "500" }}
                  to={`/solutions`}
                >
                  <strong>solutions</strong>
                </Link>
              </div>
            </div>
            <div
              data-aos="fade-up"
              data-aos-offset={isSmallDevice ? "200" : "600"}
            >
              <Carousel
                // data-aos="zoom-out-up"
                defaultWait={15000}
                style={style.carouselContainer}
                childrenStyle={style.childrenStyle}
                dotsStyle={{
                  display: "block",
                  textAlign: "center",
                  width: "100%",
                  zIndex: "100",
                  height: "60px",
                }}
                dotAlias={["Community", "Work/Business", "Study", "Personal"]}
                dotAliasStyle={
                  isSmallDevice
                    ? {
                        margin: "0.5rem",
                        padding: "0.5rem",
                        fontSize: "1.4rem",
                        cursor: "pointer",
                      }
                    : {
                        margin: "40px",
                        padding: "10px",
                        fontSize: "20px",
                        cursor: "pointer",
                      }
                }
                dotAliasSelectedStyle={{
                  borderBottom: `2px solid ${theme.primaryColor}`,
                  color: theme.primaryColor,
                }}
              >
                <Slide right>
                  <div style={style.slide}>
                    <div style={style.listContainer}>
                      <ul className="fa-ul">
                        <li style={style.listItem}>
                          <i
                            className="fa-li fa fa-check-circle"
                            style={style.listIcon}
                          ></i>
                          Find and read articles, blogs and books about the
                          things that you're passionate about.
                        </li>
                        <li style={style.listItem}>
                          <i
                            className="fa-li fa fa-check-circle"
                            style={style.listIcon}
                          ></i>
                          Publish stories, articles, reviews about things you
                          find interesting.
                        </li>
                        <li style={style.listItem}>
                          <i
                            className="fa-li fa fa-check-circle"
                            style={style.listIcon}
                          ></i>
                          Write and publish a book that you've always wanted to.
                        </li>
                        <li style={style.listItem}>
                          <i
                            className="fa-li fa fa-check-circle"
                            style={style.listIcon}
                          ></i>
                          Read and connect with writers, authors and publishers
                          around the world.
                        </li>
                      </ul>
                    </div>
                    <div style={style.imageContainer}>
                      <img
                        style={style.sliderImage}
                        src={`https://cdn.notebuq.com/file/ntbq-01/resources/landing/lc1.png`}
                        alt="Study"
                      />
                    </div>
                  </div>
                </Slide>
                <Slide right>
                  <div style={style.slide}>
                    <div style={style.listContainer}>
                      <ul className="fa-ul">
                        <li style={style.listItem}>
                          <i
                            className="fa-li fa fa-check-circle"
                            style={style.listIcon}
                          ></i>
                          Share and collaborate on notes or notebooks to build
                          things better and faster
                        </li>
                        <li style={style.listItem}>
                          <i
                            className="fa-li fa fa-check-circle"
                            style={style.listIcon}
                          ></i>
                          Create shared meeting notes to never lose sight of
                          what's important
                        </li>
                        <li style={style.listItem}>
                          <i
                            className="fa-li fa fa-check-circle"
                            style={style.listIcon}
                          ></i>
                          Build a knowledge base that can be shared accross your
                          organization, sub-organization or team
                        </li>
                        <li style={style.listItem}>
                          <i
                            className="fa-li fa fa-check-circle"
                            style={style.listIcon}
                          ></i>
                          Publish articles and stories about your company to
                          connect with customers
                        </li>
                      </ul>
                    </div>
                    <div style={style.imageContainer}>
                      <img
                        style={style.sliderImage}
                        src={`https://cdn.notebuq.com/file/ntbq-01/resources/landing/lw1.png`}
                        alt="Study"
                      />
                    </div>
                  </div>
                </Slide>
                <Slide right>
                  <div style={style.slide}>
                    <div style={style.listContainer}>
                      <ul className="fa-ul">
                        <li style={style.listItem}>
                          <i
                            className="fa-li fa fa-check-circle"
                            style={style.listIcon}
                          ></i>
                          One spot solution for students to find, create and
                          manage notes.
                        </li>
                        <li style={style.listItem}>
                          <i
                            className="fa-li fa fa-check-circle"
                            style={style.listIcon}
                          ></i>
                          Share notes and notebooks with friends
                        </li>
                        <li style={style.listItem}>
                          <i
                            className="fa-li fa fa-check-circle"
                            style={style.listIcon}
                          ></i>
                          Collaborate for projects
                        </li>
                        <li style={style.listItem}>
                          <i
                            className="fa-li fa fa-check-circle"
                            style={style.listIcon}
                          ></i>
                          Gather your research findings
                        </li>
                      </ul>
                    </div>
                    <div style={style.imageContainer}>
                      <img
                        style={style.sliderImage}
                        src={`https://cdn.notebuq.com/file/ntbq-01/resources/landing/ls1.png`}
                        alt="Study"
                      />
                    </div>
                  </div>
                </Slide>
                <Slide right>
                  <div style={style.slide}>
                    <div style={style.listContainer}>
                      <ul className="fa-ul">
                        <li style={style.listItem}>
                          <i
                            className="fa-li fa fa-check-circle"
                            style={style.listIcon}
                          ></i>
                          Save ideas and insights from articles and books in
                          your personal notebooks.
                        </li>
                        <li style={style.listItem}>
                          <i
                            className="fa-li fa fa-check-circle"
                            style={style.listIcon}
                          ></i>
                          Save your favourite books to read them whenever you
                          want.
                        </li>
                        <li style={style.listItem}>
                          <i
                            className="fa-li fa fa-check-circle"
                            style={style.listIcon}
                          ></i>
                          Create your personal knowledge base
                        </li>
                        <li style={style.listItem}>
                          <i
                            className="fa-li fa fa-check-circle"
                            style={style.listIcon}
                          ></i>
                          Maintain your private journal
                        </li>
                        <li style={style.listItem}>
                          <i
                            className="fa-li fa fa-check-circle"
                            style={style.listIcon}
                          ></i>
                          Plan trips. Manage Events. List goes on...
                        </li>
                      </ul>
                    </div>
                    <div style={style.imageContainer}>
                      <img
                        style={style.sliderImage}
                        src={`https://cdn.notebuq.com/file/ntbq-01/resources/landing/lp1.png`}
                        alt="Study"
                      />
                    </div>
                  </div>
                </Slide>
              </Carousel>
            </div>
          </div>
          <div style={isSmallDevice ? {} : { height: "40px" }} />
          <div className="landing-features-container">
            <div style={style.rowHeading} id="features">
              Features
            </div>
            <div
              data-aos="slide-up"
              data-aos-offset={isSmallDevice ? "200" : "600"}
              style={
                isSmallDevice
                  ? {
                      margin: "0 10px",
                      // backgroundColor: theme.backgroundColor2,
                      padding: "20px",
                    }
                  : {
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr",
                      gridGap: "40px",
                      // backgroundColor: theme.backgroundColor2,
                      margin: "0 100px",
                      padding: "20px",
                    }
              }
            >
              <div style={style.featureItem}>
                <div style={style.featureIcon}>
                  <i className="fal fa-hashtag"></i>
                </div>
                <div>
                  <div style={style.featureHeading}>Hashtags</div>
                  <div style={style.featureDescription}>
                    Follow hashtags of your interests and keep up with the
                    latest trends in information about things that matter to
                    you. Add hashtags to your notes to get them featured.
                  </div>
                </div>
              </div>
              <div style={style.featureItem}>
                <div style={style.featureIcon}>
                  <i className="fal fa-user-check"></i>
                </div>
                <div>
                  <div style={style.featureHeading}>Follow authors</div>
                  <div style={style.featureDescription}>
                    Create your knowledge network by following your favourite
                    authors and interacting with other like minded people.
                  </div>
                </div>
              </div>
              <div style={style.featureItem}>
                <div style={style.featureIcon}>
                  <i className="fal fa-bookmark"></i>
                </div>
                <div>
                  <div style={style.featureHeading}>Save</div>
                  <div style={style.featureDescription}>
                    Save notes, articles and notebooks by others in your library
                    so that you can read them whenever you want.
                  </div>
                </div>
              </div>
              <div style={style.featureItem}>
                <div style={style.featureIcon}>
                  <i className="fal fa-users"></i>
                </div>
                <div>
                  <div style={style.featureHeading}>Publish</div>
                  <div style={style.featureDescription}>
                    Make your notes or notebooks public for your followers. Use
                    hashtags to gain more followers.
                  </div>
                </div>
              </div>
              <div style={style.featureItem}>
                <div style={style.featureIcon}>
                  <i className="fal fa-user-friends"></i>
                </div>
                <div>
                  <div style={style.featureHeading}>Share</div>
                  <div style={style.featureDescription}>
                    Share notes and notebooks with people you know, give them
                    viewing or editing rights as required.
                  </div>
                </div>
              </div>
              <div style={style.featureItem}>
                <div style={style.featureIcon}>
                  <i className="fal fa-people-carry"></i>
                </div>
                <div>
                  <div style={style.featureHeading}>Collaborate</div>
                  <div style={style.featureDescription}>
                    Collaborate on notes or notebooks with your team to get work
                    done better and faster.
                  </div>
                </div>
              </div>

              <div style={style.featureItem}>
                <div style={style.featureIcon}>
                  <i className="fal fa-text"></i>
                </div>
                <div>
                  <div style={style.featureHeading}>Rich Text Editor</div>
                  <div style={style.featureDescription}>
                    Create rich notes, add images, embed videos, and much more
                    with our rich text editor that includes almost all formating
                    options. Distracton free and full screen editor modes.
                  </div>
                </div>
              </div>
              <div style={style.featureItem}>
                <div style={style.featureIcon}>
                  <i className="fal fa-book"></i>
                </div>
                <div>
                  <div style={style.featureHeading}>Notebooks</div>
                  <div style={style.featureDescription}>
                    Organize your notes in notebooks. Customize the notebook's
                    title, description, or cover as you like. Further sort your
                    notes in notebooks in sections.
                  </div>
                </div>
              </div>
              <div style={style.featureItem}>
                <div style={style.featureIcon}>
                  <i className="fal fa-lock"></i>
                </div>
                <div>
                  <div style={style.featureHeading}>Protection</div>
                  <div style={style.featureDescription}>
                    Protection provides an extra layer of security for your
                    personal content. Turn on protection on your notebooks to
                    make it and it's content completely private and totally
                    secure.
                  </div>
                </div>
              </div>
              <div style={style.featureItem}>
                <div style={style.featureIcon}>
                  <i className="fal fa-books"></i>
                </div>
                <div>
                  <div style={style.featureHeading}>Notebook Sets</div>
                  <div style={style.featureDescription}>
                    Organize your notebooks in sets based on how or where you
                    use them. You can create separate sets for study,
                    work-related and personal notebooks.
                  </div>
                </div>
              </div>
              <div style={style.featureItem}>
                <div style={style.featureIcon}>
                  <i className="fal fa-laptop"></i>
                </div>
                <div>
                  <div style={style.featureHeading}>Access anywhere</div>
                  <div style={style.featureDescription}>
                    Access your notes and notebooks anywhere, anytime (Dark mode
                    included) and on any device. It's completely free.
                  </div>
                </div>
              </div>
              <div style={style.featureItem}>
                <div style={style.featureIcon}>
                  <i className="far fa-road"></i>
                </div>
                <div>
                  <div style={style.featureHeading}>More on the way...</div>
                  <div style={style.featureDescription}>
                    Many cool features are on the way. We are constantly looking
                    for suggestions for new features and improvements. So if you
                    have any, please
                    <a href={`mailto: feedback@notebuq.com`}>
                      &nbsp;
                      <span style={{ color: theme.primaryColor }}>
                        provide feedback
                      </span>
                    </a>
                    .{" "}
                  </div>
                </div>
              </div>
            </div>
            {/* <div
              style={{
                fontSize: "12px",
                float: "right",
                padding: "0 100px",
                textAlign: "end",
              }}
            >
              *: in beta
            </div> */}
          </div>
          <div style={{ height: "40px" }} />
          <div style={{ backgroundColor: theme.primaryColor, color: "#fff" }}>
            <div
              style={{
                ...style.rowHeading,
                fontSize: "36px",
                fontWeight: "400",
              }}
            >
              What makes Notebuq different?
            </div>
          </div>
          <div
            className="landing-diff-container"
            style={style.diffContainer}
            data-aos="fade-up"
            data-aos-offset={isSmallDevice ? "200" : "500"}
          >
            {/* <div className="landing-diff-item" style={style.diffItem}>
              <div className="landing-diff-icon" style={style.diffIcon}>
                <i className="fal fa-books"></i>
              </div>
              <div>
                <div className="landing-diff-heading" style={style.diffHeading}>
                  Find things to read
                </div>
                <div
                  className="landing-diff-description"
                  style={style.diffDescription}
                >
                  Notebuq connects you with the writer and publishers across the
                  world who write about things and topics that you love.
                </div>
              </div>
            </div> */}
            <div className="landing-diff-item" style={style.diffItem}>
              <div className="landing-diff-icon" style={style.diffIcon}>
                <i className="fal fa-typewriter"></i>
              </div>
              <div>
                <div className="landing-diff-heading" style={style.diffHeading}>
                  Publishing for everyone
                </div>
                <div
                  className="landing-diff-description"
                  style={style.diffDescription}
                >
                  Write that book you've always wanted to publish. We will get
                  it to the right audience. Publish articles, stories or reviews
                  about things you find interesting.
                </div>
              </div>
            </div>
            <div className="landing-diff-item" style={style.diffItem}>
              <div className="landing-diff-icon" style={style.diffIcon}>
                <i className="fal fa-books"></i>
              </div>
              <div>
                <div className="landing-diff-heading" style={style.diffHeading}>
                  Find organized information
                </div>
                <div
                  className="landing-diff-description"
                  style={style.diffDescription}
                >
                  There is a ton of information out there but finding the right
                  and organized information when you need it is no easy task. On
                  notebuq, you can find published notes and notebooks that are
                  organized and will give you exactly what you are looking for
                  in much clear and organized way.
                </div>
              </div>
            </div>
            <div className="landing-diff-item" style={style.diffItem}>
              <div className="landing-diff-icon" style={style.diffIcon}>
                <i className="fal fa-paper-plane"></i>
              </div>
              <div>
                <div className="landing-diff-heading" style={style.diffHeading}>
                  Store unlimited amount of content
                </div>
                <div
                  className="landing-diff-description"
                  style={style.diffDescription}
                >
                  On notebuq, the sky is the limit for your learning and growth
                  because you can create and save as many notes and notebooks as
                  you want in your personal library. No size limit on note or
                  anything.
                </div>
              </div>
            </div>
            <div className="landing-diff-item" style={style.diffItem}>
              <div className="landing-diff-icon" style={style.diffIcon}>
                <i className="far fa-phone-laptop"></i>
              </div>
              <div>
                <div className="landing-diff-heading" style={style.diffHeading}>
                  Sync unlimited devices
                </div>
                <div
                  className="landing-diff-description"
                  style={style.diffDescription}
                >
                  There is no limit to the number of devices you can sync and
                  access notebuq on.
                  <br /> It's all completely free.
                </div>
              </div>
            </div>
            <div className="landing-diff-item" style={style.diffItem}>
              <div className="landing-diff-icon" style={style.diffIcon}>
                <i className="fad fa-feather-alt"></i>
              </div>
              <div>
                <div className="landing-diff-heading" style={style.diffHeading}>
                  Simple and light, yet powerful and agile
                </div>
                <div
                  className="landing-diff-description"
                  style={style.diffDescription}
                >
                  Simple, clear, and distraction free UI with advanced and
                  sophisticated features.
                  <br /> Designed with love for design.
                </div>
              </div>
            </div>
          </div>
          {/* <div style={{ height: "40px" }} /> */}
          <div style={{ backgroundColor: theme.primaryColor, color: "#fff" }}>
            <div
              style={{
                ...style.rowHeading,
                fontSize: "36px",
                fontWeight: "400",
              }}
            >
              A community that has always got your back.
            </div>
          </div>
          <div
            data-aos="slide-up"
            style={{
              textAlign: "center",
              margin: "50px auto",
              fontSize: "20px",
              maxWidth: "600px",
            }}
          >
            Join our community to take part in discussions and talk about about
            books, writing, and to meet other amazing people like yourself.
            <div style={{ margin: "20px", textAlign: "center" }}>
              <a href={`https://dsc.gg/notebuq`} target="blank">
                <Button
                  size="large"
                  type="primary"
                  style={{ margin: "20px 0px" }}
                >
                  Join us on Discord&nbsp;
                  {/* <img
                    style={{ maxHeight: "100%" }}
                    src={DiscordLogo}
                    alt="Discord"
                  /> */}
                </Button>
              </a>
              <div style={{ fontSize: "18px", margin: "20px 0px" }}>
                <div style={{ height: "10px" }} />
                {/* You will meet other amazing people like yourself to hang out
                with. */}
                You will also be an active part of Notebuq development.
              </div>
            </div>
            {/* <div style={{ height: "40px" }} /> */}
          </div>
          <div
            style={{
              display: "none",
              backgroundImage: `url("https://cdn.notebuq.com/file/ntbq-01/resources/landing/team.jpg")`,
              backgroundSize: "cover",
              backgroundPosition: "0% 40%",
              width: "100%",
              height: "400px",
            }}
          ></div>
          <div
            style={{
              backgroundColor: theme.primaryColor,
              color: "#fff",
              marginTop: "-20px",
            }}
          >
            <div
              style={
                isSmallDevice
                  ? { ...style.rowHeading }
                  : {
                      ...style.rowHeading,
                      fontSize: "36px",
                      fontWeight: "500",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }
              }
            >
              <div
                style={
                  isSmallDevice
                    ? {}
                    : {
                        display: "inline-block",
                        marginRight: "10px",
                        // marginTop: "20px",
                        width: "400px",
                        textAlign: "right",
                      }
                }
              >
                A place to{" "}
              </div>
              <div
                style={
                  isSmallDevice
                    ? {
                        overflow: "hidden",
                      }
                    : {
                        display: "inline-block",
                        overflow: "hidden",
                        textAlign: "left",
                        // marginTop: "20px",
                        width: "450px",
                      }
                }
              >
                <Slider
                  dots={false}
                  infinite={true}
                  speed={400}
                  slidesToShow={1}
                  slidesToScroll={1}
                  vertical
                  autoplay
                  arrows={false}
                  adaptiveHeight
                  useCss={false}
                >
                  <span>find ideas.</span>
                  <span>create ideas.</span>
                  <span>share ideas.</span>
                  <span>maximize productivity.</span>
                  <span>manage your work.</span>
                  <span>manage your team.</span>
                  <span>manage your information.</span>
                  <span>organize everyday life.</span>
                  <span>gain clarity.</span>
                </Slider>
              </div>
            </div>
          </div>

          {/* <div style={{ height: "20px" }} /> */}
          <div style={{ overflowX: "hidden" }}>
            <div
              data-aos="flip-up"
              data-aos-offset={isSmallDevice ? "200" : "600"}
              // data-aos-delay="500"
            >
              <div
                style={
                  isSmallDevice
                    ? {
                        fontSize: "32px",
                        marginTop: "40px",
                        textAlign: "center",
                        padding: "20px",
                      }
                    : {
                        ...style.rowHeading,
                        marginTop: "40px",
                        fontSize: "36px",
                      }
                }
              >
                Sign up and take control of information in your life.
              </div>
              <div style={{ textAlign: "center" }}>
                <Link to={"/join"}>
                  <Button
                    style={{
                      margin: "0 20px",
                      padding: "0px 20px",
                    }}
                    type="primary"
                    size="large"
                  >
                    Join For Free
                  </Button>
                </Link>
                <Link to={"/login"}>
                  <Button
                    style={{ margin: "0 20px", padding: "0px 10px" }}
                    size="large"
                  >
                    Sign In
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          <div
            style={{
              // height: "1px",
              backgroundColor: theme.primaryColor,
              marginTop: "80px",
            }}
          />
          <div className="landing-bottom" style={{}}></div>
          <Footer />
          {/* <div
            style={
              isSmallDevice
                ? {
                    backgroundColor: "rgba(10,10,10,0.6)",
                    height: "120px",
                    width: "300px",
                    position: "fixed",
                    bottom: "50px",
                    right: "50px",
                    borderRadius: "5px",
                    textAlign: "center",
                    color: "#fff",
                    fontSize: "22px",
                  }
                : {
                    backgroundColor: "rgba(10,10,10,0.6)",
                    height: "120px",
                    width: "300px",
                    position: "fixed",
                    bottom: "50px",
                    right: "50px",
                    borderRadius: "5px",
                    textAlign: "center",
                    color: "#fff",
                    fontSize: "22px",
                  }
            }
          >
            <div
              style={{
                fontSize: "inherit",
                fontWeight: "500",
                padding: "10px",
                color: "inherit",
              }}
            >
              Join now and get 50 books for FREE
            </div>
            <Link to="/explore">
              <Button
                onClick={() => localStorage.setItem("explore_tab", "notebooks")}
                size="small"
              >
                See Free Books
              </Button>
            </Link>
          </div> */}
        </div>
      </>
    );

    return this.props.auth && this.props.auth.isAuthenticated ? (
      <Dashboard />
    ) : ["note", "notebook"].includes(
        this.props.location.search.substr(
          1,
          this.props.location.search.indexOf("=") - 1
        )
      ) ||
      this.props.match.params.profile ||
      this.props.match.params.hashtag ||
      this.props.match.path === "/explore" ||
      this.props.match.path === "/explore/:option" ? (
      <Main
        profile={this.props.match.params.profile}
        hashtag={this.props.match.params.hashtag}
        explore={
          this.props.match.path === "/explore" ||
          this.props.match.path === "/explore/:option"
        }
        exploreOption={this.props.match.params.option}
      />
    ) : (
      landingContent
    );
  }
}

Landing.propTypes = {
  auth: propTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default withRouter(connect(mapStateToProps)(Landing));
