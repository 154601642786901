import React, { Component } from "react";
import axios from "axios";

import { Upload, Button, message } from "antd";
// import { UploadOutlined } from "@ant-design/icons";

let executeOnce = true;

class UpdateDisplayPicture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
    };
  }

  submitFile = (event) => {
    //event.preventDefault();
    // Validating file
    let file = this.state.file;
    let fileExt = file.name
      .substring(file.name.lastIndexOf(".") + 1)
      .toLowerCase();

    if (
      ["jpg", "jpeg", "gif", "png", "tiff", "tif"].indexOf(
        fileExt.toLowerCase()
      ) === -1
    ) {
      message.error("Upload Failed. Only image files are accepted.");
      return false;
    } else if (file.size > 2 * 1024 * 1024) {
      message.error("Upload Failed. Max file size allowed: 2mb.");
      return false;
    }

    const formData = new FormData();
    formData.append("display-picture", this.state.file);
    axios
      .post(
        `${process.env.REACT_APP_API_HOST}/api/users/current/edit/dp`,
        formData,
        {
          // headers: {
          //   "Content-Type": "multipart/form-data",
          // },
        }
      )
      .then((res) => {
        // console.log(res);
        // console.log(res.data);
        // handle your response
        if (res.data.success) {
          message.success(res.data.message);
          this.props.afterUpload();
        } else {
          message.error(
            "Error updating display picture. Please try again later."
          );
        }
      })
      .catch((error) => {
        // console.log(error);
        // handle your error
        message.error(
          "Error updating display picture. Please try again later."
        );
      });
  };

  handleFileUpload = (event) => {
    //console.log(event.event.target.files[0]);
    this.setState({ file: event.event.target.files[0] });
  };

  onChange = (info) => {
    if (info.file.status === "uploading" && executeOnce) {
      this.setState({ file: info.file.originFileObj });
      executeOnce = false;
      this.submitFile();
    }
  };

  render() {
    return (
      <div>
        <Upload
          accept="image/*"
          onChange={this.onChange}
          showUploadList={false}
        >
          <Button
            title={this.props.title}
            shape={this.props.shape}
            type={this.props.type}
            size={this.props.size}
            ghost={this.props.ghost}
          >
            <i class="fas fa-pencil-alt"></i>
            {this.props.text}
          </Button>
        </Upload>
      </div>
    );
  }
}

export default UpdateDisplayPicture;
