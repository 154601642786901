import axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Avatar,
  Button,
  Tooltip,
  Result,
  message,
  Space,
  Tabs,
  Empty,
  // Dropdown,
  // Menu,
} from "antd";
import Loader from "../common/Loader";
// import moment from "moment";
import NotebookItem from "./Feed/NotebookItem";
import NoteItem from "./Feed/NoteItem";
// import notFoundImage from "./../../assets/notFound.png";

const { TabPane } = Tabs;

export class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: this.props.username,
      profile: null,
      notes: null,
      notebooks: null,
      loadingProfile: false,
      loadingNotes: false,
      loadingNotebooks: false,
      profileNotFound: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.username !== this.props.username)
      this.setState({ username: this.props.username });
    if (prevState.username !== this.state.username) this.getProfile();

    if (this.props.reloadProfile) this.getProfile();
    if (this.props.reloadNote)
      this.setState({
        notes: this.state.notes.map((note) =>
          note.id === this.props.reloadNote.id ? this.props.reloadNote : note
        ),
      });
    if (this.props.reloadNotebook) {
      let notebooks = this.state.notebooks;
      notebooks = notebooks.map((n) => {
        if (n.id === this.props.reloadNotebook) {
          n.temp.hasSaved = !n.temp.hasSaved;
          return n;
        } else return n;
      });
      this.setState({ notebooks });
    }
  }

  componentDidMount() {
    this.getProfile();
    this.getNotes();
    this.getNotebooks();
  }

  getProfile() {
    if (!this.state.profile) this.setState({ loadingProfile: true });
    axios
      .get(`${process.env.REACT_APP_API_HOST}/api/users/${this.state.username}`)
      .then((res) => {
        // console.log(res.data);
        if (res.data.success) {
          this.setState({
            profile: res.data.profile,
            loadingProfile: false,
          });
        } else {
          if (res.data.notFound) this.setState({ notFound: true });
        }
      })
      .catch((err) =>
        message.error("Error connecting to server. Please reload this page.")
      );
  }

  getNotes() {
    if (!this.state.notes) this.setState({ loadingNotes: true });
    axios
      .get(`${process.env.REACT_APP_API_HOST}/api/notes/${this.state.username}`)
      .then((res) => {
        // console.log(res.data);
        if (res.data.success) {
          this.setState({
            notes: res.data.notes,
            loadingNotes: false,
          });
        }
      })
      .catch((err) =>
        message.error("Error connecting to server. Please reload this page.")
      );
  }
  getNotebooks() {
    if (!this.state.notebooks) this.setState({ loadingNotebooks: true });
    axios
      .get(
        `${process.env.REACT_APP_API_HOST}/api/notebooks/${this.state.username}`
      )
      .then((res) => {
        // console.log(res.data);
        if (res.data.success) {
          this.setState({
            notebooks: res.data.notebooks,
            loadingNotebooks: false,
          });
        }
      })
      .catch((err) =>
        message.error("Error connecting to server. Please reload this page.")
      );
  }

  render() {
    const {
      loadingProfile,
      loadingNotes,

      profile,
      notebooks,
      notes,
      notFound,
    } = this.state;

    const { theme, userId, isSmallDevice } = this.props;

    const socialIconStyles = { color: "#777" };

    const profileHeader = profile && (
      <div
        className="profile-header"
        style={{
          borderBottom: "1px solid #d2d2d2",
          paddingBottom: "30px",
          display: "grid",
          gridTemplateColumns: "3fr 7fr",
          gridGap: "10px",
        }}
      >
        <div
          className="profile-avatar-container"
          style={{
            padding: "0 auto",
            textAlign: "center",
          }}
        >
          <Avatar
            size={180}
            //   style={{ width: "100%", height: "100%" }}
            src={`https://${profile.display_picture.downloadUrl}`}
          />
        </div>
        <div>
          <div
            style={{
              fontSize: "32px",
              fontWeight: "500",
            }}
          >
            {profile.name}
            {this.props.userId !== profile.id && (
              <Button
                icon={<i className="fas fa-user-plus"></i>}
                type={"primary"}
                ghost={profile.temp.userFollows}
                style={{ float: "right", marginTop: "9px" }}
                onClick={() => this.props.follow(profile.username)}
              >
                &nbsp;{profile.temp.userFollows ? "Following" : "Follow"}
              </Button>
            )}
          </div>
          <div style={{ fontSize: "22px", fontWeight: "500" }}>
            @{profile.username}
          </div>
          <div style={{ height: "30px" }} />
          {profile.location && (
            <div
              style={{
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              {profile.location.citycountry}
            </div>
          )}
          {profile.bio && profile.bio.length > 0 && (
            <div
              style={{
                fontSize: "14px",
                fontWeight: "500",
                marginTop: "10px",
                whiteSpace: "pre-wrap",
                maxWidth: "600px",
              }}
            >
              {profile.bio}
            </div>
          )}
          <div
            className="profile-socials"
            style={{ fontSize: "18px", marginTop: "10px" }}
          >
            <Space size={14}>
              {profile.social.website && (
                <Tooltip title={profile.social.website}>
                  <a href={`https://${profile.social.website}`}>
                    <span style={socialIconStyles}>
                      <i class="fas fa-globe-americas"></i>
                    </span>
                  </a>
                </Tooltip>
              )}
              {profile.social.instagram && (
                <a href={`https://${profile.social.instagram}`}>
                  <span style={socialIconStyles}>
                    <i class="fab fa-instagram"></i>
                  </span>
                </a>
              )}
              {profile.social.facebook && (
                <a href={`https://${profile.social.facebook}`}>
                  <span style={socialIconStyles}>
                    <i class="fab fa-facebook"></i>
                  </span>
                </a>
              )}
              {profile.social.twitter && (
                <a href={`https://${profile.social.twitter}`}>
                  <span style={socialIconStyles}>
                    <i class="fab fa-twitter"></i>
                  </span>
                </a>
              )}

              {profile.social.youtube && (
                <a href={`https://${profile.social.youtube}`}>
                  <span style={socialIconStyles}>
                    <i class="fab fa-youtube"></i>
                  </span>
                </a>
              )}
              {profile.social.linkedin && (
                <a href={`https://${profile.social.linkedin}`}>
                  <span style={socialIconStyles}>
                    <i class="fab fa-linked-in"></i>
                  </span>
                </a>
              )}
            </Space>
          </div>
        </div>
      </div>
    );

    let content = profile && (
      <div
        className="profile-container"
        style={{
          padding: "30px 30px",
          maxWidth: "900px",
          margin: "0 auto",
          backgroundColor: theme.backgroundColor,
          color: theme.color,
        }}
      >
        {profileHeader}
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            justifyItems: "center",
            marginTop: "6px",
            paddingBottom: "6px",
            borderBottom: "1px solid #d2d2d2",
            width: "100%",
            fontWeight: "500",
          }}
        >
          <div>
            {profile.temp.followersCount}{" "}
            {profile.temp.followersCount === 1 ? "follower" : "followers"}
          </div>
          <div>{notebooks && notebooks.length} notebooks</div>
          <div>{notes && notes.length} notes</div>
        </div>
        <div className="profile-content">
          <Tabs
            centered
            defaultActiveKey={notebooks && notebooks.length === 0 && "notes"}
            tabBarStyle={{ color: theme.color }}
          >
            <TabPane tab="Notebooks" key="notebooks">
              {notebooks && notebooks.length === 0 ? (
                <div>
                  <Empty
                    // image={notFoundImage}
                    image={null}
                    // imageStyle={{
                    //   height: 60,
                    // }}
                    description={
                      <span
                        style={{
                          color: theme.color,
                          fontSize: "22px",
                          fontWeight: "500",
                          display: "block",
                          paddingBottom: "60px",
                        }}
                      >
                        {this.props.userId !== profile.id
                          ? "No notebooks found"
                          : "Your Published Notebooks will appear here."}
                      </span>
                    }
                  >
                    {this.props.userId === profile.id && (
                      <Link to={`/`}>
                        <Button type="primary">Create Now</Button>
                      </Link>
                    )}
                  </Empty>
                </div>
              ) : (
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: isSmallDevice ? "1fr" : "1fr 1fr",
                    gap: "30px",
                  }}
                >
                  {notebooks &&
                    notebooks.map((notebook) => (
                      <NotebookItem
                        key={notebook.id}
                        notebook={notebook}
                        theme={theme}
                        saveNotebook={(nbId) => this.props.saveNotebook(nbId)}
                        userId={this.props.userId}
                      />
                    ))}
                </div>
              )}
            </TabPane>
            <TabPane tab="Notes" key="notes">
              {notes && notes.length === 0 ? (
                <div>
                  {" "}
                  <Empty
                    // image={notFoundImage}
                    image={null}
                    // imageStyle={{
                    //   height: 60,
                    // }}
                    description={
                      <span
                        style={{
                          display: "block",
                          color: theme.color,
                          fontSize: "22px",
                          fontWeight: "500",
                          paddingBottom: "60px",
                        }}
                      >
                        {this.props.userId !== profile.id
                          ? "No notes found"
                          : "Your published notes will appear here."}
                      </span>
                    }
                  >
                    {this.props.userId === profile.id && (
                      <Link to={`/?n`}>
                        <Button type="primary">Create Now</Button>
                      </Link>
                    )}
                  </Empty>
                </div>
              ) : (
                <div>
                  {notes &&
                    notes.map((note) => (
                      <NoteItem
                        note={note}
                        userId={userId}
                        theme={theme}
                        style={{ margin: "40px auto" }}
                        userNotebooks={this.props.userNotebooks}
                        saveNote={(noteId, notebookId) =>
                          this.props.saveNote(noteId, notebookId)
                        }
                      />
                    ))}
                </div>
              )}
            </TabPane>
          </Tabs>
        </div>
      </div>
    );

    return (loadingProfile || loadingNotes || loadingProfile) && !notFound ? (
      <Loader />
    ) : profile ? (
      content
    ) : (
      <div
        style={{
          padding: "100px",
          backgroundColor: theme.backgroundColor,
          color: theme.color,
        }}
      >
        <Result
          status="404"
          title={
            <span style={{ color: theme.color, fontSize: "26px" }}>404</span>
          }
          subTitle={
            <span style={{ color: theme.color, fontSize: "22px" }}>
              Sorry, the page you visited does not exist.
            </span>
          }
          extra={
            <Link to={`/`} style={{ color: theme.color }}>
              <Button type="primary">Back Home</Button>
            </Link>
          }
        />
      </div>
    );
  }
}

export default Profile;
