import React, { Component } from "react";
import { connect } from "react-redux";
import propTypes from "prop-types";
import { withRouter } from "react-router-dom";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroller";
import Loader from "../../common/Loader";
import { message, Empty } from "antd";
import notFound from "../../../assets/notFound.png";
import NoteItem from "./NoteItem";

export class FeedRightPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notes: [],
      limit: 30,
      offset: 0,
      totalCount: 0,
      sortBy: "updatedAt",
      sortOrder: "DESC",
      loading: false,
    };
  }
  componentDidMount() {
    this.getNotes();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.reloadNotes)
      this.setState({
        notes: this.state.notes.map((note) =>
          note.id === this.props.reloadNotes.id ? this.props.reloadNotes : note
        ),
      });
  }

  getNotes() {
    if (this.state.notes.length === 0) this.setState({ loading: true });
    axios
      .get(`${process.env.REACT_APP_API_HOST}/api/notes/feed`, {
        params: {
          offset: this.state.offset,
          limit: this.state.limit,
          sortBy: this.state.sortBy,
          sortOrder: this.state.sortOrder,
        },
      })
      .then((res) => {
        // console.log(res.data);
        if (res.data.success) {
          this.setState(
            {
              notes: [...this.state.notes, ...res.data.notes],
              totalCount: res.data.count,
              offset: this.state.notes.length + res.data.notes.length,
              loading: false,
            },
            () => {
              if (this.state.notes.length === 0)
                window.location.href = "/explore";
            }
          );
        } else message.error(res.data.message);
      })
      .catch((err) => {
        console.log(err);
        message.error("Some error occured. Please try again.");
      });
  }

  render() {
    const { theme, isSmallDevice } = this.props.auth;
    const { topics } = this.props;
    const { notes, totalCount, loading } = this.state;

    return (
      <div
        className="feed-right-panel-container"
        style={{
          overflow: "auto",
          backgroundColor: theme.backgroundColor,
          color: theme.color,
          height: "100%",
          padding: isSmallDevice ? "0 30px" : "0 100px",
        }}
      >
        {loading ? (
          <Loader />
        ) : notes && notes.length > 0 ? (
          <InfiniteScroll
            loadMore={() => this.getNotes()}
            hasMore={notes.length < totalCount}
            loader={<Loader />}
            useWindow={false}
          >
            {notes &&
              notes.length > 0 &&
              notes.map((note) => (
                <NoteItem
                  note={note}
                  topics={topics}
                  userId={this.props.userId}
                  userNotebooks={this.props.userNotebooks}
                  theme={theme}
                  saveNote={(noteId, notebookId) =>
                    this.props.saveNote(noteId, notebookId)
                  }
                  style={{ margin: "30px auto" }}
                />
              ))}
          </InfiniteScroll>
        ) : (
          <Empty
            // image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            image={notFound}
            imageStyle={{
              height: 300,
              marginTop: "80px",
            }}
            description={
              <div>
                <div style={{ fontSize: "22px", fontWeight: "500" }}>
                  Your feed is empty
                </div>
                <span style={{ fontSize: "14px", color: theme.color }}>
                  Notes and Articles from Topics and People you follow will
                  appear here.
                  <br />
                  Search topics or people you know.
                </span>
              </div>
            }
          ></Empty>
        )}
      </div>
    );
  }
}

FeedRightPanel.propTypes = {
  auth: propTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default withRouter(connect(mapStateToProps)(FeedRightPanel));
