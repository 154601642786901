import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import propTypes from "prop-types";
import Loader from "../common/Loader";
import { Input, Button } from "antd";

export class CreateNewPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: false,
      message: null,
      password1: "",
      password2: "",
    };
  }

  submit() {
    if (this.state.password1 !== this.state.password2) {
      this.setState({ message: "Passwords do not match" });
      return;
    }

    if (this.state.password2.length < 6) {
      this.setState({
        message: "Password must be atleast 6 characters or more",
      });
      return;
    }

    if (this.props.match.params.token) {
      axios
        .post(`${process.env.REACT_APP_API_HOST}/api/users/create-password/`, {
          token: this.props.match.params.token,
          newpassword: this.state.password2,
        })
        .then((res) => {
          if (res.data.success) {
            this.setState({
              loading: false,
              success: true,
              message: res.data.message,
            });
          } else if (res.data.errors) {
            this.setState({
              loading: false,
              success: false,
              message: res.data.errors.message,
            });
          }
        });
    } else {
      return this.setState({ message: "Invalid Request" });
    }
  }
  render() {
    const { loading, success, message } = this.state;
    let form = (
      <div
        style={{
          margin: "40px auto",
          width: "400px",
          padding: "15px",
          minWidth: "350px",
          backgroundColor: "#fff",
          borderRadius: "15px",
        }}
      >
        <h3>Create New Password</h3>
        <h5 style={{ marginTop: "30px" }}>New Password: </h5>
        <Input.Password
          name="pasword1"
          onChange={(e) => {
            this.setState({ password1: e.target.value, errors: null });
          }}
        />
        <h5 style={{ marginTop: "30px" }}>Confirm New Password: </h5>
        <Input.Password
          name="password2"
          onChange={(e) => {
            this.setState({ password2: e.target.value, errors: null });
          }}
        />
        <Button
          style={{ margin: "30px 0px" }}
          type="primary"
          onClick={() => this.submit()}
        >
          Submit
        </Button>
        {loading ? (
          <Loader />
        ) : (
          message && (
            <div
              style={{
                fontSize: "22px",
                marginTop: "15px",
                color: success ? "blue" : "red",
                textAlign: "center",
              }}
            >
              {message}
            </div>
          )
        )}
      </div>
    );

    return (
      <div
        style={{
          marginTop: "80px",
        }}
      >
        {form}
      </div>
    );
  }
}

CreateNewPassword.propTypes = {
  auth: propTypes.object.isRequired,
};

const mapStateToProps = (store) => ({
  auth: store.auth,
});

export default connect(mapStateToProps)(CreateNewPassword);
