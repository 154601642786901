import axios from "axios";
import React, { Component } from "react";
import propTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import { Alert, Input, Form, Button, Steps, message, Tooltip } from "antd";
import Loader from "../common/Loader";
import "./styles/onboard.less";
import { Link } from "react-router-dom";

const { Step } = Steps;

export class Onboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email: "",
      name: "",
      username: "",
      password: "",
      error: null,
      formError: true,
      current: 0,
      usernameErrorMessage: null,
      isUsernameAvailable: null,
      checkingUsername: false,
      accountCreated: false,
    };
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.checkUsername = this.checkUsername.bind(this);
    this.submitRegistration = this.submitRegistration.bind(this);
  }
  componentDidMount() {
    this.setState({ loading: true });
    axios
      .post(
        `${process.env.REACT_APP_API_HOST}/api/users/verify-email/${this.props.match.params.token}`
      )
      .then((res) => {
        if (res.data.success && !res.data.emailChange) {
          this.setState({ loading: false, email: res.data.data.email });
        } else if (!res.data.success) {
          this.setState({ loading: false, error: res.data.message });
        } else this.setState({ loading: false, error: "Invalid token." });
      });
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (
  //     prevProps.auth.isAuthenticated !== this.props.auth.isAuthenticated &&
  //     this.props.auth.isAuthenticated
  //   ) {
  //     this.props.history.push("/dashboard");
  //   }
  //   if (this.props.errors && Object.keys(this.props.errors).length > 0) {
  //     console.log(this.props.errors);
  //     return this.setState({ errors: this.props.errors });
  //   }
  // }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push("/");
    }
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  async onChangeUsername(e) {
    this.setState({ checkingUsername: true });
    await this.checkUsername(e.target.value);
    this.setState({ username: e.target.value.toLowerCase() });
    if (e.target.value.length === 0 || this.state.usernameErrorMessage)
      this.setState({ formError: true });
    else this.setState({ formError: false });
  }

  checkUsername(username) {
    axios
      .post(`${process.env.REACT_APP_API_HOST}/api/users/check-username`, {
        usernameToCheck: username,
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({
            usernameErrorMessage: null,
            isUsernameAvailable: true,
            checkingUsername: false,
            formError: false,
          });
        } else {
          this.setState({
            usernameErrorMessage: res.data.message,
            checkingUsername: false,
            formError: true,
          });
        }
      });
  }

  submitRegistration() {
    axios
      .post(`${process.env.REACT_APP_API_HOST}/api/users/register`, {
        email: this.state.email,
        name: this.state.name,
        username: this.state.username,
        password: this.state.password,
      })
      .then((res) => {
        if (res.data.success) {
          message.success(`Welcome, ${this.state.name.split(" ")[0]}`);
          this.props.loginUser(res.data, true);
        } else {
          this.setState({ error: true });
        }
      });
  }
  render() {
    const {
      loading,
      error,
      email,
      current,
      formError,
      name,
      username,
      password,
      usernameErrorMessage,
      isUsernameAvailable,
      checkingUsername,
      accountCreated,
    } = this.state;

    const next = () => {
      this.setState({ current: current + 1 });
      if (
        //   (this.state.current === 0 && this.state.name.length === 0) ||
        (this.state.current === 0 && this.state.username.length === 0) ||
        (this.state.current === 1 && this.state.password.length === 0)
      ) {
        this.setState({ formError: true });
      }
    };
    const prev = () => {
      this.setState({ current: current - 1 });
      if (this.state.current - 1 === 0 && this.state.name.length > 0)
        this.setState({ formError: false });
      if (this.state.current - 1 === 1 && this.state.isUsernameAvailable)
        this.setState({ formError: false });
    };

    const steps = [
      {
        title: "Name",
        content: (
          <div className="onboard-step-form">
            <h4>Let's start with your name</h4>
            <Form>
              <Form.Item
                rules={[{ required: true, message: "Your name is required." }]}
              >
                <Input
                  value={name}
                  onChange={(e) => {
                    this.setState({ name: e.target.value });
                    if (e.target.value.length === 0)
                      this.setState({ formError: true });
                    else this.setState({ formError: false });
                  }}
                  placeholder="Please Enter your name"
                  onPressEnter={() => next()}
                />
              </Form.Item>
            </Form>
          </div>
        ),
      },
      {
        title: "Username",
        content: (
          <div className="onboard-step-form">
            <Form>
              <Form.Item
                hasFeedback={isUsernameAvailable === null ? false : true}
                //validateStatus={errors.username ? "error" : "success"}
                validateStatus={
                  usernameErrorMessage
                    ? "error"
                    : // : formError
                    // ? "error"
                    checkingUsername
                    ? "validating"
                    : isUsernameAvailable === null
                    ? "success"
                    : isUsernameAvailable
                    ? "success"
                    : "error"
                }
                help={
                  usernameErrorMessage
                    ? usernameErrorMessage
                    : // : formError
                    // ? JSON.stringify(errors.username).replace(/"/g, "")
                    isUsernameAvailable === null
                    ? null
                    : !isUsernameAvailable
                    ? "This username is not available"
                    : null
                }
                validateTrigger={this.onChangeUsername}
              >
                <Tooltip title="Username can contain lowercase alphabets, numerals, . and _. Uppercase character will be converted to lowercase.">
                  <Input
                    defaultValue={username}
                    placeholder="Please choose a username"
                    onChange={this.onChangeUsername}
                    onPressEnter={() => !formError && next()}
                  />
                </Tooltip>
              </Form.Item>
            </Form>
          </div>
        ),
      },
      {
        title: "Password",
        content: (
          <div className="onboard-step-form">
            <Form>
              <Form.Item
                rules={[
                  { required: true, message: "Please input your password!" },
                  { min: 6, message: "Password must be minimum 6 characters." },
                ]}
              >
                {/* <Tooltip title="Password must contain atleast 6 characters"> */}
                <Input.Password
                  value={password}
                  onChange={(e) => {
                    this.setState({ password: e.target.value });
                    if (e.target.value.length < 6)
                      this.setState({ formError: true });
                    else this.setState({ formError: false });
                  }}
                  onPressEnter={() => !formError && this.submitRegistration()}
                />
                {/* </Tooltip> */}
                <div style={{ fontSize: "12px" }}>
                  Password must contain atleast 6 characters
                </div>
              </Form.Item>
            </Form>
          </div>
        ),
      },
    ];

    return (
      <div className="register-container">
        <h2>User Onboarding</h2>
        {loading ? (
          // eslint-disable-next-line jsx-a11y/alt-text
          <Loader />
        ) : accountCreated ? (
          <>
            <Alert
              style={{ textAlign: "left" }}
              message="Success"
              description={"Account successfully created. Sign in to continue."}
              type="success"
              showIcon
            />
            <div style={{ height: "25px" }} />
            <Link to={"/login"}>
              <Button type="primary">Sign In</Button>
            </Link>
          </>
        ) : error ? (
          <Alert
            style={{ textAlign: "left" }}
            message="Error"
            description={error}
            type="error"
            showIcon
          />
        ) : (
          <>
            <div style={{ fontSize: "16px", marginTop: "30px" }}>
              Hi, <i>{email}</i>!
            </div>
            <div style={{ height: "25px" }} />
            <div style={{ fontSize: "18px", fontWeight: "500" }}>
              We need some info to set up your account.
            </div>
            <div style={{ height: "30px" }} />
            <Steps current={current} status={formError}>
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <div className="steps-content">{steps[current].content}</div>

            <div className="steps-action">
              {current > 0 && (
                <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
                  Previous
                </Button>
              )}
              {current < steps.length - 1 && (
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={() => next()}
                  disabled={formError}
                >
                  Next
                </Button>
              )}
              {current === steps.length - 1 && (
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={this.submitRegistration}
                  disabled={formError}
                >
                  Create My Account
                </Button>
              )}
            </div>
            <div style={{ height: "25px" }} />
          </>
        )}
      </div>
    );
  }
}

Onboard.propTypes = {
  loginUser: propTypes.func.isRequired,
  auth: propTypes.object.isRequired,
  errors: propTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
export default withRouter(connect(mapStateToProps, { loginUser })(Onboard));
