import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  //  Tooltip
} from "antd";
import ProfileBoxItem from "./ProfileBoxItem";

function NotebookItem({ notebook, style, userId, theme, saveNotebook }) {
  return (
    <div
      className="notebook-item"
      style={{
        border: `1px solid ${theme.borderColor1}`,
        display: "grid",
        gridTemplateColumns: "5fr 6fr",
        gap: "5px",
        // height: "240px",
        borderRadius: "5px",
        minHeight: "0",
        minWidth: "0",
        // maxWidth: "500px",
        height: "280px",
        ...style,
      }}
    >
      <div
        style={{
          width: "200px",
          overflow: "hidden",
        }}
      >
        <Link to={`/?notebook=${notebook.id}`}>
          {notebook.info1 && notebook.info1.cover ? (
            <div style={{ height: "100%" }}>
              <img
                // style={{ height: "100%" }}
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                }}
                src={notebook.info1.cover.downloadUrl}
                alt={notebook.name}
              />
            </div>
          ) : (
            <div
              style={{
                background:
                  "linear-gradient(103deg, rgba(34,193,195,1) 0%, rgba(253,121,45,1) 100%)",
                position: "relative",
                height: "100%",
              }}
            >
              <div
                style={{
                  color: "#fff",
                  fontSize: "16px",
                  fontWeight: "500",
                  padding: "20px 10px",
                  maxHeight: "250px",
                  maxWidth: "200px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {notebook.name}
              </div>
              <div
                style={{
                  marginTop: "80px",
                  fontSize: "14px",
                  position: "absolute",
                  bottom: "20px",
                  textAlign: "center",
                  width: "100%",
                  color: "#fff",
                }}
              >
                <div>{notebook.temp.owner.name}</div>
                <div>({notebook.temp.owner.username})</div>
              </div>
            </div>
          )}
        </Link>
      </div>

      <div
        style={{
          position: "relative",
          padding: "5px",
          overflow: "hidden",
          minWidth: "0",
        }}
      >
        <Link to={`/?notebook=${notebook.id}`}>
          <div
            style={{
              fontSize: "18px",
              fontWeight: "500",
              maxHeight: "100px",
              textOverflow: "ellipsis",
              overflow: "hidden",
              wordWrap: "break-word",
              color: theme.color,
            }}
          >
            {notebook.name}
          </div>
        </Link>
        <span
          style={{
            float: "right",
            fontSize: "16px",
            fontWeight: "500",
            // display: "none",
          }}
        >
          {/* <Tooltip
            title={`Saved by ${notebook.temp.saverCount} ${
              notebook.temp.saverCount === 1 ? "person" : "people"
            }`}
          >
            {notebook.temp.saverCount}&nbsp;
          </Tooltip> */}
          <Button
            size="small"
            type="link"
            onClick={() => {
              if (notebook.ownerId === userId) return;
              saveNotebook(notebook.id);
            }}
            style={{
              padding: "0",
              display: notebook.ownerId === userId && "none",
            }}
          >
            {notebook.temp.hasSaved ? (
              <i className="fas fa-bookmark"></i>
            ) : (
              <i className="fal fa-bookmark"></i>
            )}
          </Button>
        </span>
        <div style={{ fontSize: "12px", color: "#919191" }}>
          {notebook.temp.notesCount} notes
        </div>
        <div
          style={{
            margin: "10px",
            fontSize: "14px",
            padding: "5px",
            // wordWrap: "break-word",
            textOverflow: "ellipsis",
            overflow: "hidden",
            maxWidth: "90%",
            maxHeight: "48%",
            zIndex: "6",
          }}
        >
          <div
            className="feed-note-item-description"
            style={{
              overflow: "auto",
              height: "100%",
              width: "100%",
              color: theme.color,
              whiteSpace: "pre-wrap",
              wordBreak: "break-word",
              fontSize: "12px",
              filter:
                theme.mode === "light" ? `brightness(1.4)` : `brightness(0.8)`,
            }}
          >
            {notebook.description
              ? notebook.description.length > 180
                ? notebook.description.substr(0, 180)
                : notebook.description
              : null}
            {/* {notebook.description} */}
          </div>
        </div>
        <div
          style={{
            padding: "10px 0px",
            backgroundColor: theme.backgroundColor,
            zIndex: "7",
          }}
        >
          {/* by:{" "} */}
          <div
            style={{
              position: "absolute",
              bottom: "5px",
              fontSize: "16px",
              background:
                theme.mode === "light"
                  ? "rgba(256,256,256,1)"
                  : "rgba(0,0,0,1)",
            }}
          >
            <ProfileBoxItem user={notebook.temp.owner} theme={theme} />{" "}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotebookItem;
