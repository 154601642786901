import React, { Component } from "react";
import {
  Input,
  Button,
  Tooltip,
  // Select,
  Menu,
  Dropdown,
  InputNumber,
  Modal,
  message,
  Popover,
  Cascader,
} from "antd";
import {
  FontSizeOutlined,
  LineHeightOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import axios from "axios";
// import Editor from "./Editor";
import TinyEditor from "./TinyEditor";
import "./editorwrapper.less";
// import Editor from "./Editor";

// const { Option } = Select;
const { TextArea } = Input;

export class EditorWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noteId: this.props.note ? this.props.note.id : null,
      title: this.props.note ? this.props.note.title : "",
      deltaContent: this.props.note ? this.props.note.deltaContent : null,
      htmlContent: this.props.note ? this.props.note.htmlContent : "",
      textContent: this.props.note ? this.props.note.textContent : "",
      editorToolbarVisible:
        localStorage.editorToolbarVisible === "true" || false,
      editorFontSize:
        this.props.note &&
        this.props.note.info1 &&
        this.props.note.info1.fontSize
          ? this.props.note.info1.fontSize
          : 18,
      editorLineHeight:
        this.props.note &&
        this.props.note.info1 &&
        this.props.note.info1.lineHeight
          ? this.props.note.info1.lineHeight
          : 1.8,
      saving: false,
      saved: true,
      error: false,
      selectedNotebookId: this.props.note
        ? this.props.note.notebookId
        : this.props.notebooks &&
          this.props.notebooks.filter((notebook) => notebook.isDefault)[0].id,
      selectedSectionId: this.props.note ? this.props.note.sectionId : null,
      selectNewNotebook: false,
      defaultSaveTime: 1, //autosave time in seconds
      timerRunning: false,
      showDisconnectedPopover: false,
      editorKey: 1,
      optimizeReadability: localStorage.optimizeReadability || true,
      savingError: null,
      cascaderKey: 0,
    };

    this.saveChanges = this.saveChanges.bind(this);
    this.startSaveTimer = this.startSaveTimer.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isDisconnected && !this.props.isDisconnected) {
      this.setState({ showDisconnectedPopover: false });
      this.saveChanges();
    }

    if (
      this.state.noteId &&
      prevState.selectedNotebookId !== this.state.selectedNotebookId
    )
      this.saveChanges();
    // If notebook of the note was changed
    if (
      prevProps.note &&
      this.props.note &&
      prevProps.note.id === this.props.note.id &&
      prevProps.note.notebookId !== this.props.note.notebookId
    )
      this.props.notebooksChanged([
        this.props.note.notebookId,
        prevProps.note.notebookId,
      ]);

    if (
      (prevProps.theme.mode !== this.props.theme.mode ||
        prevState.editorToolbarVisible !== this.state.editorToolbarVisible) &&
      document.getElementsByClassName("tox-toolbar__primary")[0]
    ) {
      document.getElementsByClassName(
        "tox-toolbar__primary"
      )[0].style.backgroundColor = `${
        this.props.theme.mode === "dark"
          ? this.props.theme.backgroundColor2
          : this.props.theme.backgroundColor
      }`;
    }
    if (
      prevState.editorFontSize !== this.state.editorFontSize ||
      prevState.editorLineHeight !== this.state.editorLineHeight ||
      prevState.editorToolbarVisible !== this.state.editorToolbarVisible ||
      prevProps.theme.mode !== this.props.theme.mode
    ) {
      this.setState({ editorKey: this.state.editorKey + 1 });
      localStorage.setItem(
        "editorToolbarVisible",
        this.state.editorToolbarVisible
      );
    }
    // if (
    //   prevState.editorLineHeight !== this.state.editorLineHeight ||
    //   prevState.editorToolbarVisible !== this.state.editorToolbarVisible
    // ) {
    //   this.setState({ editorKey: this.state.editorKey + 1 });
    // }
    // If new notebook was created from the editor, select the newly created notebook
    if (
      prevProps.notebooks.length < this.props.notebooks.length &&
      this.state.selectNewNotebook
    ) {
      let newNotebook = this.props.notebooks.filter(
        (notebook) =>
          prevProps.notebooks.filter(
            (prevNotebook) => prevNotebook.id === notebook.id
          ).length === 0
      )[0];
      if (newNotebook) {
        this.setState({
          selectedNotebookId: newNotebook.id,
          selectNewNotebook: false,
        });
      }
    }
  }

  saveChanges() {
    // console.log("Now saving");

    this.setState({ saving: true, saved: false, savingError: null });
    axios
      .post(`${process.env.REACT_APP_API_HOST}/api/notes/create`, {
        noteId: this.state.noteId,
        notebookId: this.state.selectedNotebookId,
        sectionId: this.state.selectedSectionId,
        title: this.state.title,
        htmlContent: this.state.htmlContent,
        deltaContent: this.state.deltaContent,
        textContent: this.state.textContent,
        fontSize: this.state.editorFontSize,
        lineHeight: this.state.editorLineHeight,
      })
      .then((response) => {
        // console.log(response.data.note.temp.notebook.name);
        if (response.data.success) {
          this.setState({
            noteId: response.data.note.id,

            saving: false,
            saved: true,
            timerRunning: false,
          });
          this.props.onUpdate(response.data.note, true);
          // console.log(response.data.note.htmlContent);
        } else {
          if (response.data) {
            message.error(response.data.message);
            this.setState({
              savingError: response.data.message,
              saving: false,
            });
          }
        }
      })
      .catch((err) => {
        message.error("Unable to save changes.");
        this.setState({ savingError: "Unable to reach server." });
      });
  }

  startSaveTimer() {
    if (this.props.isDisconnected)
      return this.setState({ showDisconnectedPopover: true });

    if (!this.state.saving) this.setState({ saving: true });

    if (this.timer) clearTimeout(this.timer);
    this.timer = setTimeout(
      () => this.saveChanges(),
      1000 * this.state.defaultSaveTime
    );
  }

  getTextContent(htmlContent) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = htmlContent;
    return tmp.textContent || tmp.innerText || "";
  }

  render() {
    const { note, theme, isSmallDevice, notebooks } = this.props;
    const { optimizeReadability } = this.state;
    // console.log(this.props.notebooks);
    // console.log(note && note.sectionId);
    // console.log(note && note.id);

    const ellipsisMenu = (
      <Menu>
        <Menu.Item
          key="3"
          danger
          icon={<i className="fas fa-times"></i>}
          onClick={() =>
            Modal.confirm({
              title: "Are you absolutely sure you want to DELETE this note?",
              icon: <ExclamationCircleOutlined />,
              content: "This deletion is PERMANENT.",
              okText: "Yes",
              okType: "danger",
              cancelText: "No",
              onOk: () => {
                this.props.deleteNote(this.props.note);
              },
              onCancel() {},
            })
          }
        >
          &nbsp;Delete
        </Menu.Item>
      </Menu>
    );

    return (
      <div
        className="editor-wrapper"
        style={{
          height: "100%",
          width: "100%",
          overflow: "auto",
          backgroundColor: theme.backgroundColor,
          color: theme.color,
        }}
      >
        <div
          className="editor-wrapper-toolbox"
          style={
            isSmallDevice
              ? {
                  // display: "grid",
                  // justifyItems: "center",
                  fontSize: "12px",
                  borderBottom: "1px solid #d1d1d1",
                  padding: "5px 0px",
                  textAlign: "center",
                }
              : {
                  // width: "100%",
                  padding: "0px 15px",
                  display: "grid",
                  gridAutoFlow: "column",
                  alignItems: "center",
                  fontSize: "12px",
                  borderBottom: "1px solid #d1d1d1",
                  height: "40px",
                }
          }
        >
          <div
            style={
              isSmallDevice
                ? { display: "inline-block", margin: "5px 50px" }
                : {}
            }
          >
            {(!this.props.note ||
              this.props.note.authorId === this.props.userId) && (
              <>
                In: &nbsp;
                {/* <Select
                  key={this.state.selectedNotebookId}
                  bordered={false}
                  defaultValue={() => {
                    let selectedNotebook =
                      this.props.notebooks &&
                      this.props.notebooks.length > 0 &&
                      this.props.notebooks.filter(
                        (notebook) =>
                          notebook.id === this.state.selectedNotebookId
                      )[0];
                    if (selectedNotebook) return selectedNotebook.id;
                    else return "";
                  }}
                  style={{ width: 140, color: theme.color }}
                  onChange={(e) => {
                    if (e === "1") {
                      this.props.newNotebook();
                      this.setState({ selectNewNotebook: true });
                    } else {
                      this.setState({ selectedNotebookId: e });
                    }
                  }}
                  dropdownMatchSelectWidth={200}
                >
                  {this.props.notebooks &&
                    this.props.notebooks.map((notebook) => (
                      <Option key={notebook.id} value={notebook.id}>
                        {notebook && notebook.name}
                      </Option>
                    ))}
                  <Option style={{ borderTop: "1px solid #d2d2d2" }} key={1}>
                    {" "}
                    New Notebook
                  </Option>
                </Select> */}
                <Cascader
                  key={this.state.cascaderKey}
                  bordered={false}
                  allowClear={false}
                  expandTrigger="hover"
                  style={
                    isSmallDevice
                      ? {
                          width: "180px",
                          color: theme.color,
                          backgroundColor: theme.backgroundColor,
                        }
                      : {
                          width: "40%",
                          color: theme.color,
                          backgroundColor: theme.backgroundColor,
                        }
                  }
                  options={
                    !notebooks
                      ? []
                      : [
                          {
                            value: "newnotebook",
                            label: "Create new notebook",
                          },
                          ...notebooks.map((notebook) => {
                            return {
                              value: notebook.id,
                              label:
                                note && note.notebookId === notebook.Id
                                  ? `${notebook.name} ✓`
                                  : notebook.name,
                              children: [
                                {
                                  value: null,
                                  label:
                                    note &&
                                    note.notebookId === notebook.id &&
                                    !note.sectionId
                                      ? `None ✓`
                                      : "None",
                                },
                                ...notebook.sections.map((section) => {
                                  return {
                                    value: section.id,
                                    label:
                                      note && note.sectionId === section.id
                                        ? `${section.title} ✓`
                                        : section.title,
                                  };
                                }),
                              ],
                            };
                          }),
                        ]
                  }
                  defaultValue={
                    note
                      ? [note.notebookId, note.sectionId]
                      : [
                          this.state.selectedNotebookId,
                          this.state.selectedSectionId,
                        ]
                  }
                  // value={
                  //   note
                  //     ? [note.notebookId, note.sectionId]
                  //     : [this.state.selectedNotebookId, null]
                  // }
                  onChange={(v) => {
                    if (v[0] === "newnotebook") {
                      this.props.newNotebook();
                      this.setState({
                        selectNewNotebook: true,
                        cascaderKey: this.state.cascaderKey + 1,
                      });
                      return;
                    } else {
                      if (!this.props.note) {
                        this.setState({
                          selectedNotebookId: v[0],
                          selectedSectionId: v[1],
                        });
                        return;
                      } else
                        this.props.moveNote({
                          note: this.props.note,
                          notebookId: v[0],
                          sectionId: v[1],
                        });
                      // this.setState({
                      //   cascaderKey: this.state.cascaderKey + 1,
                      // });
                    }
                  }}
                />
              </>
            )}
            <Tooltip
              title={
                this.state.editorToolbarVisible ? (
                  <span> Hide Toolbar</span>
                ) : (
                  <span> Show Toolbar</span>
                )
              }
            >
              <Button
                style={
                  isSmallDevice
                    ? { margin: "5px", color: theme.color }
                    : { margin: "5px 5px", color: theme.color }
                }
                type="text"
                icon={
                  this.state.editorToolbarVisible ? (
                    <i className="far fa-chevron-double-up"></i>
                  ) : (
                    <i className="far fa-chevron-double-down"></i>
                  )
                }
                // ghost={theme.mode === "dark"}
                onClick={(e) => {
                  this.setState({
                    editorToolbarVisible: !this.state.editorToolbarVisible,
                    editorKey: this.state.editorKey + 1,
                  });
                }}
              ></Button>
            </Tooltip>
            {!isSmallDevice && (
              <Tooltip
                title={
                  optimizeReadability
                    ? "Readability Optimized"
                    : "Optimize Readability"
                }
              >
                <Button
                  type={isSmallDevice ? "link" : "text"}
                  // ghost={theme.mode === "dark"}
                  style={{
                    color: optimizeReadability
                      ? theme.primaryColor
                      : theme.color,
                    height: "40px",
                  }}
                  onClick={() =>
                    this.setState(
                      {
                        optimizeReadability: !optimizeReadability,
                      },
                      () =>
                        localStorage.setItem(
                          "optimizeReadability",
                          this.state.optimizeReadability
                        )
                    )
                  }
                  icon={<i className="far fa-glasses"></i>}
                ></Button>
              </Tooltip>
            )}
            <Dropdown
              overlay={ellipsisMenu}
              trigger={["click"]}
              arrow
              placement="bottomRight"
            >
              <Button
                //size="small"
                type="text"
                style={{
                  marginRight: isSmallDevice ? "0px" : "10px",
                  color: theme.color,
                }}
                // ghost={theme.mode === "dark"}
                icon={<i className="far fa-ellipsis-h"></i>}
              ></Button>
            </Dropdown>
          </div>
          <div
            style={
              isSmallDevice
                ? { display: "inline-block", margin: "5px 50px" }
                : {}
            }
          >
            <div
              style={{
                display: "grid",
                gridAutoFlow: "column",
                alignItems: "center",
                justifyContent: "end",
                gridGap: "20px",
                maxWidth: "100%",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  width: "60px",
                  color: theme.color,
                }}
              >
                {this.props.isDisconnected ? (
                  <span>
                    <Popover
                      color={theme.mode === "dark" && theme.backgroundColor2}
                      visible={this.state.showDisconnectedPopover}
                      content={
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            color: theme.color,
                            fontWeight: "500",
                            // backgroundColor: theme.backgroundColor,
                          }}
                        >
                          <ExclamationCircleOutlined /> Changes cannot be saved
                        </div>
                      }
                    >
                      <ExclamationCircleOutlined /> Offline
                    </Popover>
                  </span>
                ) : this.state.savingError ? (
                  <Tooltip title={this.state.savingError}>
                    <span>
                      <ExclamationCircleOutlined /> Error
                    </span>
                  </Tooltip>
                ) : this.state.saving ? (
                  "Saving"
                ) : this.state.saved && this.state.noteId ? (
                  <>
                    <i
                      className="far fa-check-circle"
                      style={{ color: "green" }}
                    ></i>
                    &nbsp;Saved
                  </>
                ) : (
                  <span />
                )}
              </div>

              {(!note || note.authorId === this.props.userId) && (
                <>
                  <Tooltip
                    title={
                      note
                        ? note.isProtected
                          ? "In protected notebook"
                          : note.sharing === "published"
                          ? "Note is published"
                          : note.notebookSharing === "published"
                          ? "In published notebook."
                          : note.sharing === "shared"
                          ? "Note is shared"
                          : note.notebookSharing === "shared"
                          ? "In shared notebook"
                          : "Share"
                        : "Share"
                    }
                  >
                    <Button
                      icon={
                        !note ? (
                          <i className="far fa-lock"></i>
                        ) : note.sharing === "private" &&
                          note.notebookSharing === "private" ? (
                          <i className="far fa-lock"></i>
                        ) : (note.sharing === "shared" &&
                            note.notebookSharing === "shared") ||
                          (note.sharing === "private" &&
                            note.notebookSharing === "shared") ||
                          (note.shairng === "shared" &&
                            note.notebookSharing === "private") ? (
                          <i className="fas fa-user-friends"></i>
                        ) : (
                          <i className="far fa-globe"></i>
                        )
                      }
                      // ghost={theme.mode === "dark"}
                      style={{
                        // fontSize: "12px",
                        width: isSmallDevice ? "auto" : "80px",
                        color: theme.color,
                      }}
                      // size="small"
                      type="text"
                      // disabled={!this.props.selectedNote}
                      onClick={() => {
                        if (!note)
                          message.warn("Please write something to share");
                        else this.props.showSharingModal();
                      }}
                    >
                      {!isSmallDevice && <>&nbsp;</>}
                      {isSmallDevice
                        ? null
                        : !note
                        ? "Only You"
                        : note.sharing === "private" &&
                          note.notebookSharing === "private"
                        ? "Only You"
                        : note.sharing === "published" ||
                          note.notebookSharing === "published"
                        ? "Public"
                        : "Shared"}
                    </Button>
                  </Tooltip>
                  {note && !note.isProtected && (
                    <Tooltip
                      title={
                        note
                          ? note.sharing === "published"
                            ? "Note is published"
                            : note.notebookSharing === "published"
                            ? "Note is in published notebook."
                            : null
                          : null
                      }
                    >
                      <Button
                        type="primary"
                        icon={<i className="fas fa-users"></i>}
                        style={{
                          width: isSmallDevice ? "auto" : "120px",
                          height: !isSmallDevice && "40px",
                          // fontSize: "12px",
                        }}
                        // size="small"
                        // disabled={!this.props.selectedNote}
                        onClick={() => {
                          if (!this.props.note)
                            message.warn("Please write something to publish");
                          else this.props.publishNote();
                        }}
                        ghost={note && note.sharing === "published"}
                      >
                        &nbsp;
                        {isSmallDevice
                          ? null
                          : note && note.sharing === "published"
                          ? "Published"
                          : "Publish"}
                      </Button>
                    </Tooltip>
                  )}
                  {note && (
                    <div>
                      <Tooltip title="Done Editing" mouseEnterDelay={3}>
                        <Button
                          size="large"
                          type="link"
                          style={{
                            height: "40px",
                            margin: "0px",
                            // border: "none",
                          }}
                          onClick={() => this.props.doneEditing()}
                        >
                          <i className="fas fa-check"></i>
                        </Button>
                      </Tooltip>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div
          className="note-editor-container"
          style={
            isSmallDevice
              ? { width: "100%", padding: "10px 5px" }
              : {
                  padding: this.state.optimizeReadability
                    ? localStorage.leftPanelDisplay === "false"
                      ? "0px 18%"
                      : "0px 10%"
                    : "0px",
                  width: "100%",
                  overflow: "auto",
                  height: "calc(100% - 60px)",
                  position: "relative",
                }
          }
        >
          <TextArea
            placeholder="Title"
            defaultValue={this.state.title}
            bordered={false}
            onKeyDown={(e) => {
              if (e.code === "ArrowDown" || e.code === "Enter")
                window.tinymce.activeEditor.focus();
            }}
            style={{
              color: theme.color,
              fontSize: "32px",
              width: "100%",
              position: "relative",
              borderBottom: "1px solid #d1d1d1",

              // maxWidth: "100%",
            }}
            onChange={(e) => {
              this.setState({ title: e.target.value });
              this.startSaveTimer();
            }}
            autoSize={{ minRows: 1, maxRows: 4 }}
          />
          <>
            {this.state.editorToolbarVisible && (
              <div
                style={{
                  padding: "5px 15px",
                  ...(theme.mode === "light"
                    ? {
                        border: "1px solid #d1d1d1",
                        borderBottom: "none",
                        borderTop: "none",
                      }
                    : { border: "none" }),
                  boxSizing: "content-box",
                }}
              >
                <Tooltip title="Text Size">
                  <FontSizeOutlined />
                </Tooltip>
                &nbsp;
                <InputNumber
                  style={{ width: "50px", color: theme.color }}
                  bordered={theme.mode === "dark" ? false : true}
                  size="small"
                  step={2}
                  min={1}
                  max={72}
                  keyboard={true}
                  value={this.state.editorFontSize}
                  onChange={(value) => this.setState({ editorFontSize: value })}
                />
                &nbsp; &nbsp; &nbsp;
                <Tooltip title="Line Height">
                  <LineHeightOutlined />
                </Tooltip>
                &nbsp;
                <InputNumber
                  style={{ width: "60px", color: theme.color }}
                  bordered={theme.mode === "dark" ? false : true}
                  size="small"
                  min={1}
                  max={3}
                  step={0.05}
                  keyboard={true}
                  value={this.state.editorLineHeight}
                  onChange={(value) =>
                    this.setState({ editorLineHeight: value })
                  }
                />
                &nbsp;
                <Button
                  type="link"
                  size="small"
                  style={{ colr: theme.color }}
                  onClick={() =>
                    this.setState({
                      editorFontSize: 18,
                      editorLineHeight: 1.8,
                    })
                  }
                >
                  Reset
                </Button>
                {this.state.textContent &&
                  this.state.textContent.length &&
                  this.state.textContent.length > 1 && (
                    <span
                      style={
                        isSmallDevice
                          ? { display: "block", color: "#919191" }
                          : { float: "right", color: "#919191" }
                      }
                    >
                      {this.state.textContent ? (
                        this.state.textContent.length === 0 ? (
                          ""
                        ) : (
                          this.state.textContent.match(/(\w+)/g) &&
                          `${this.state.textContent.match(/(\w+)/g).length} ${
                            this.state.textContent.match(/(\w+)/g) &&
                            this.state.textContent.match(/(\w+)/g).length === 1
                              ? "word"
                              : "words"
                          } `
                        )
                      ) : (
                        <span />
                      )}{" "}
                      &nbsp;
                      {this.state.textContent.trim &&
                        this.state.textContent.trim().length}{" "}
                      {this.state.textContent.trim &&
                      this.state.textContent.trim().length === 1
                        ? "char"
                        : "chars"}
                    </span>
                  )}
              </div>
            )}
          </>
          <TinyEditor
            key={this.state.editorKey}
            showToolbar={this.state.editorToolbarVisible}
            content={this.state.htmlContent}
            theme={theme}
            handleChange={(e) => {
              this.setState({
                htmlContent: e,
                textContent: this.getTextContent(e),
              });
              this.startSaveTimer();
            }}
            noteId={this.state.noteId}
            fontSize={this.state.editorFontSize}
            lineHeight={this.state.editorLineHeight}
            isSmallDevice={isSmallDevice}
          />
          {/* <Editor
            key={this.state.editorKey}
            showToolbar={this.state.editorToolbarVisible}
            htmlContent={this.state.htmlContent}
            theme={theme}
            onChange={(e) => {
              this.setState({
                htmlContent: e,
                textContent: this.getTextContent(e),
              });
              this.startSaveTimer();
            }}
            noteId={this.state.noteId}
            fontSize={this.state.editorFontSize}
            lineHeight={this.state.editorLineHeight}
            isSmallDevice={isSmallDevice}
          /> */}
          {/* <div style={{ height: "25px" }} /> */}
        </div>
      </div>
    );
  }
}

EditorWrapper.defaultProps = {
  note: null,
};

export default EditorWrapper;
