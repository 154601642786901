import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import propTypes from "prop-types";
import axios from "axios";
import moment from "moment";
import {
  editProfile,
  getCurrentProfile,
  changePassword,
} from "../../actions/profileActions";
import { refreshUserToken, logoutUser } from "../../actions/authActions";
import store from "../../store";
import isEmpty from "../../validation/is-empty";
import Loader from "../common/Loader";
import "./styles/updateprofile.less";
import UpdateDisplayPicture from "./UpdateDisplayPicture";
import {
  Avatar,
  Button,
  Form,
  Input,
  Tooltip,
  DatePicker,
  message,
  Modal,
  Select,
  Collapse,
} from "antd";
import { UserOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
// import themes from "../../themes/themes";

const { Option } = Select;
const { confirm } = Modal;
const { Panel } = Collapse;

class UpdateProfile extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      initialEmail: "",
      emailVerified: null,
      displaySocialInputs: true,
      username: "",
      bio: "",
      location: null,
      bioPreview: "",
      birthDate: null,
      showChangeName: false,
      showChangeEmail: false,
      showDatePicker: false,
      showChangeLocation: false,
      twitter: "",
      facebook: "",
      youtube: "",
      instagram: "",
      linkedin: "",
      website: "",
      errors: {},
      hasDp: false,
      displayChangePassword: false,
      currentPassword: "",
      newPassword1: "",
      newPassword2: "",
      checkingHandle: false,
      isHandleAvailable: true,
      editAccountInfo: false,
      citiesData: [],
      mailSent: null,
      showDeleteModal: false,
    };
    this.onChange = this.onChange.bind(this);
    this.profileSubmit = this.profileSubmit.bind(this);
    this.changePasswordSubmit = this.changePasswordSubmit.bind(this);
    this.removeDisplayPicture = this.removeDisplayPicture.bind(this);
    this.getCities = this.getCitites.bind(this);
  }

  componentDidMount() {
    if (this.props.auth && this.props.auth.isAuthenticated) {
      this.getProfile();
    } else this.props.history.push(`/login`);
  }

  async getProfile() {
    this.setState({ loading: true });
    axios
      .get(`${process.env.REACT_APP_API_HOST}/api/users/current/profile`, {
        data: {},
      })
      .then((res) => {
        let profile = {};
        profile = res.data;
        if (profile.location) {
          this.setState({ location: profile.location });
        }
        if (profile.birthDate) {
          this.setState({ birthDate: profile.birthDate });
        }
        profile.username = !isEmpty(profile.username) ? profile.username : "";
        profile.bio = !isEmpty(profile.bio) ? profile.bio : "";
        profile.social = !isEmpty(profile.social) ? profile.social : {};
        profile.twitter = !isEmpty(profile.social.twitter)
          ? profile.social.twitter
          : "";
        profile.facebook = !isEmpty(profile.social.facebook)
          ? profile.social.facebook
          : "";
        profile.linkedin = !isEmpty(profile.social.linkedin)
          ? profile.social.linkedin
          : "";
        profile.youtube = !isEmpty(profile.social.youtube)
          ? profile.social.youtube
          : "";
        profile.instagram = !isEmpty(profile.social.instagram)
          ? profile.social.instagram
          : "";
        profile.website = !isEmpty(profile.social.website)
          ? profile.social.website
          : "";

        // Set component fields state
        this.setState({
          name: profile.name,
          email: profile.email,
          initialEmail: profile.email,
          emailVerified: profile.emailVerified,
          username: profile.username,
          bio: profile.bio,
          bioPreview: profile.bio,
          twitter: profile.twitter,
          facebook: profile.facebook,
          linkedin: profile.linkedin,
          youtube: profile.youtube,
          website: profile.website,
          instagram: profile.instagram,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ errors: err, loading: false });
      });
  }

  async onChange(e) {
    if (!e.location && !e.birthDate) this.setState(e);

    if (e.birthDate) {
      await this.setState({ birthDate: moment(e.birthDate).format() });
    }

    //Checking if profile handle is available
    if (e.username) {
      this.setState({ checkingHandle: true });
      axios
        .post(`${process.env.REACT_APP_API_HOST}/api/users/check-username`, {
          user: this.props.auth.user,
          usernameToCheck: e.username,
        })
        .then((res) => {
          if (res.data.success) {
            this.setState({ checkingHandle: false, isHandleAvailable: true });
          } else {
            this.setState({ checkingHandle: false, isHandleAvailable: false });
          }
        })
        .catch((err) => console.log(err));
    }
  }

  handleChange(e) {
    e.persist();
    this.setState({ [e.target.name]: e.target.value });
  }

  changePasswordSubmit() {
    if (
      this.state.currentPassword === "" ||
      this.state.newPassword1 === "" ||
      this.state.newPassword2 === ""
    ) {
      message.warning("Please enter all the fields");
      return;
    }
    if (
      this.state.newPassword1 === this.state.newPassword2 &&
      this.state.newPassword1 !== ""
    ) {
      let newData = {
        password: this.state.currentPassword,
        newpassword: this.state.newPassword1,
      };
      this.props.changePassword(newData, this.props.history);
    } else {
      message.error("Passwords do not match.");
      return;
    }
  }

  async profileSubmit(e) {
    this.setState({ errors: {} });
    const newProfile = {
      name: this.state.name,
      email: this.state.email,
      username: this.state.username,
      bio: this.state.bio,
      location: this.state.location,
      birthDate: this.state.birthDate,
      twitter: this.state.twitter,
      facebook: this.state.facebook,
      youtube: this.state.youtube,
      instagram: this.state.instagram,
      linkedin: this.state.linkedin,
      website: this.state.website,
    };
    new Promise((resolve, reject) => {
      this.props.editProfile(newProfile, this.props.history);
      resolve();
      reject();
    }).then(() => this.props.refreshUserToken());
  }

  removeDisplayPicture = (user) => {
    axios
      .post(
        `${process.env.REACT_APP_API_HOST}/api/users/current/edit/dp/delete`
      )
      .then((res) => {
        if (res.data.success) {
          message.success("Display Picture Removed Successfully.");
          return user;
        }
      })
      .then((user) => {
        store.dispatch(refreshUserToken());
      })
      .catch((err) => console.log(err));
  };

  getCitites = (value) => {
    if (value.length < 2) return;
    axios
      .get(`${process.env.REACT_APP_API_HOST}/api/tpservices/getCities`, {
        params: { prefix: value },
      })
      .then((res) => {
        this.setState({ citiesData: res.data });
      })
      .catch((err) => console.log(err));
    //console.log(value);
  };

  resendEmail = () => {
    if (!this.state.mailSent)
      axios
        .post(
          `${process.env.REACT_APP_API_HOST}/api/users/resend-verification-email`
        )
        .then((res) => {
          if (res.data.success) {
            this.setState({ mailSent: true });
          } else {
            message.warn(res.data.errors.message);
          }
        })
        .catch((err) => {
          message.error("Some error occured. Please try again later.");
        });
    else {
      message.warn("Mail already sent.");
    }
  };

  onAccountDelete = () => [
    axios
      .delete(`${process.env.REACT_APP_API_HOST}/api/users/delete-my-account`)
      .then((res) => {
        if (res.data.success) {
          message.success("Your account was deleted.");
          this.props.logoutUser();
        } else {
          message.warn(`Some error occured. Please try again later.`);
        }
      }),
  ];

  render() {
    const { errors, displaySocialInputs, displayChangePassword } = this.state;
    const { user, theme } = this.props.auth;
    const {
      checkingHandle,
      isHandleAvailable,
      showDatePicker,
      showChangeLocation,
      showChangeName,
      showChangeEmail,
    } = this.state;

    // if (this.state.location === null)
    //   this.setState({ showChangeLocation: true });

    // if (this.state.birthDate === null) this.setState({ showDatePicker: true });

    let cities = [];
    if (this.state.citiesData)
      cities = this.state.citiesData.map((d, index) => (
        <Option key={index}>{d.citycountry}</Option>
      ));

    let socialInputs = <div />;
    let changePasswordView = <div />;

    const confirmAccountInfoChange = () => {
      confirm({
        title: "Are you sure you want to change your account information?",
        icon: <ExclamationCircleOutlined />,
        content: "",
        okText: "Yes",
        onOk: () => {
          this.profileSubmit();
        },
        onCancel() {
          return;
        },
      });
    };

    const removeDP = () => {
      this.removeDisplayPicture(user);
    };

    const toggleShowNameChange = () => {
      this.setState({ showChangeName: !this.state.showChangeName });
    };
    const toggleShowEmailChange = () => {
      this.setState({ showChangeEmail: !this.state.showChangeEmail });
    };

    const toggleShowDatePicker = () => {
      this.setState({ showDatePicker: !this.state.showDatePicker });
    };

    const toggleShowLocationChange = () => {
      this.setState({ showChangeLocation: !this.state.showChangeLocation });
    };

    const selectCity = async (e) => {
      await this.setState({ location: this.state.citiesData[parseInt(e)] });
    };

    const onDeleteClick = () => {
      this.onAccountDelete();
    };

    if (displayChangePassword || true) {
      changePasswordView = (
        <div className="update-profile-changepassword">
          <Form
            /* labelCol={{ span: 10 }}
            wrapperCol={{ span: 24 }} */
            name="change-password"
            onFinish={this.changePasswordSubmit}
            onValuesChange={this.onChange}
            hideRequiredMark="true"
            colon="false"
            layout="vertical"
            size="middle"
          >
            <Form.Item
              name="currentPassword"
              label=""
              //rules={[{ required: true, message: "Required" }]}
              validateStatus={errors.currentpassword ? "error" : "success"}
              help={
                errors.currentpassword
                  ? JSON.stringify(errors.currentpassword).replace(/"/g, "")
                  : null
              }
              validateTrigger={this.changePasswordSubmit}
            >
              <Input.Password
                value={this.state.currentPassword}
                placeholder="Enter CURRENT password"
              />
            </Form.Item>
            <Form.Item
              name="newPassword1"
              label=""
              //rules={[{ required: true, message: "Required" }]}
              validateStatus={errors.newpassword ? "error" : "success"}
              help={
                errors.newpassword
                  ? JSON.stringify(errors.newpassword).replace(/"/g, "")
                  : null
              }
              validateTrigger={this.changePasswordSubmit}
            >
              <Input.Password
                value={this.state.newPassword1}
                placeholder="Enter NEW password"
              />
            </Form.Item>
            <Form.Item
              name="newPassword2"
              label=""
              //rules={[{ required: true, message: "Required" }]}
            >
              <Input.Password
                value={this.state.currentPassword}
                placeholder="Confirm NEW password"
              />
            </Form.Item>
            <Form.Item //wrapperCol={{ offset: 8, span: 16 }}
            >
              <Button type="primary" htmlType="submit">
                Change Password
              </Button>
            </Form.Item>
          </Form>
        </div>
      );
    }

    if (displaySocialInputs) {
      socialInputs = (
        <div>
          <Form.Item
            name="website"
            //label="website"
            validateStatus={errors.website ? "error" : "success"}
            help={
              errors.website
                ? JSON.stringify(errors.website).replace(/"/g, "")
                : null
            }
            validateTrigger={this.profileSubmit}
          >
            <span>
              <Input
                addonBefore={<i className="fas fa-globe"></i>}
                placeholder="Paste Your website link"
                value={this.state.website}
              />
            </span>
          </Form.Item>
          <Form.Item
            name="youtube"
            //label="Youtube"
            validateStatus={errors.youtube ? "error" : "success"}
            help={
              errors.youtube
                ? JSON.stringify(errors.youtube).replace(/"/g, "")
                : null
            }
            validateTrigger={this.profileSubmit}
          >
            <span>
              <Input
                addonBefore={<i className="fab fa-youtube"></i>}
                placeholder="Paste Your youtube link"
                value={this.state.youtube}
              />
            </span>
          </Form.Item>
          <Form.Item
            name="twitter"
            //label="Twitter"
            validateStatus={errors.twitter ? "error" : "success"}
            help={
              errors.twitter
                ? JSON.stringify(errors.twitter).replace(/"/g, "")
                : null
            }
            validateTrigger={this.profileSubmit}
          >
            <span>
              <Input
                addonBefore={<i className="fab fa-twitter"></i>}
                value={this.state.twitter}
                placeholder="Paste Your twitter link"
              />
            </span>
          </Form.Item>

          <Form.Item
            name="facebook"
            //label="Facebook"
            validateStatus={errors.facebook ? "error" : "success"}
            help={
              errors.facebook
                ? JSON.stringify(errors.facebook).replace(/"/g, "")
                : null
            }
            validateTrigger={this.profileSubmit}
          >
            <span>
              <Input
                addonBefore={<i className="fab fa-facebook-square"></i>}
                placeholder="Paste Your facebook link"
                value={this.state.facebook}
              />
            </span>
          </Form.Item>

          <Form.Item
            name="instagram"
            // label="Instagram"
            validateStatus={errors.instagram ? "error" : "success"}
            help={
              errors.instagram
                ? JSON.stringify(errors.instagram).replace(/"/g, "")
                : null
            }
            validateTrigger={this.profileSubmit}
          >
            <span>
              <Input
                addonBefore={<i className="fab fa-instagram"></i>}
                placeholder="Paste Your instagram link"
                value={this.state.instagram}
              />
            </span>
          </Form.Item>

          <Form.Item
            name="linkedin"
            //label="LinkedIn"
            validateStatus={errors.linkedin ? "error" : "success"}
            help={
              errors.linkedin
                ? JSON.stringify(errors.linkedin).replace(/"/g, "")
                : null
            }
            validateTrigger={this.profileSubmit}
          >
            <span>
              <Input
                addonBefore={<i className="fab fa-linkedin-in"></i>}
                placeholder="Paste Your linkedin link"
                value={this.state.linkedin}
              />
            </span>
          </Form.Item>
        </div>
      );
    }

    return (
      <div
        className="update-profile-wrapper"
        style={{ backgroundColor: theme.backgroundColor2 }}
      >
        {this.state.loading ? (
          <Loader />
        ) : (
          <div
            className="update-profile-container"
            // style={{
            //   backgroundColor: theme.backgroundColor,
            //   color: theme.color,
            // }}
          >
            <div
              className="update-profile-header"
              style={{
                marginBottom: "10px",
                textAlign: "center",
                fontSize: "26px",
              }}
            >
              Edit Your Profile
            </div>
            <hr style={{ margin: "0", padding: "0" }} />
            <div className="update-profile-row">
              <div className="update-profile-col">
                {/* <div className="lead text-center">Your Profile</div>
              <hr style={{}} /> */}
                <div
                  className="update-profile-avatar-bio-container"
                  style={{ marginTop: "25px" }}
                >
                  <div className="update-profile-avatar-container">
                    <Button
                      danger
                      size="small"
                      //type="link"
                      shape="circle"
                      title="Remove display picture"
                      icon={<i className="fas fa-times"></i>}
                      //onClick={() => this.removeDisplayPicture(user)}
                      onClick={() => {
                        confirm({
                          title:
                            "Are you sure you want to remove your display picture?",
                          icon: <ExclamationCircleOutlined />,
                          okText: "Yes",
                          okType: "danger",
                          cancelText: "No",
                          content: "",
                          onOk() {
                            removeDP();
                          },
                          onCancel() {},
                        });
                      }}
                    />
                    <Avatar
                      size={128}
                      style={{
                        display: "block",
                        margin: "auto",
                      }}
                      src={`https://${user.display_picture.downloadUrl}`}
                      icon={<UserOutlined />}
                    />
                    <div style={{ float: "right" }}>
                      <UpdateDisplayPicture
                        style={{}}
                        text=""
                        type="primary"
                        ghost={true}
                        shape="circle"
                        title="Update display picture"
                        afterUpload={() => store.dispatch(refreshUserToken())}
                      />
                    </div>
                  </div>
                  <br className="update-profile-avatar-name-breaker"></br>
                  <div>
                    <div
                      style={{
                        textAlign: "center",
                        fontWeight: "600",
                        marginTop: "25px",
                        fontSize: "18px",
                      }}
                    >
                      @{user.username}
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        fontWeight: "600",
                        marginTop: "10px",
                        fontSize: "16px",
                      }}
                    >
                      {user.name}
                    </div>
                    <br className="update-profile-avatar-name-breaker"></br>

                    {/* <div className="h5 text-center mt-5 ">Your Bio</div> */}
                    <div
                      className="update-profile-bio"
                      style={{
                        maxWidth: "60%",
                        margin: "0 auto",
                        wordWrap: "break-word",
                        textAlign: "center",
                      }}
                    >
                      {this.state.bioPreview.length > 0 ? (
                        <i>{this.state.bioPreview}</i>
                      ) : (
                        "[Update your bio]"
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="update-profile-col-account">
                {/* <div className="lead">Edit Your Personal Settings</div>
              <hr style={{}} /> */}
                <Form
                  //labelCol={{ span: 8 }}
                  //wrapperCol={{ span: 16 }}
                  name="edit-user-info"
                  onFinish={this.profileSubmit}
                  onValuesChange={this.onChange}
                  hideRequiredMark="true"
                  colon="false"
                  layout="vertical"
                  size="middle"
                >
                  <Form.Item name="name" label={<strong>Full Name</strong>}>
                    {!showChangeName ? (
                      this.state.name
                    ) : (
                      <span>
                        <Input
                          name="name"
                          defaultValue={this.state.name}
                          onChange={(e) => this.handleChange(e)}
                        />
                      </span>
                    )}
                    <Button
                      onClick={toggleShowNameChange}
                      style={{ float: "right" }}
                      type="link"
                      size="small"
                    >
                      {this.state.name ? "Change Name" : "Enter Name"}
                    </Button>
                  </Form.Item>

                  <Form.Item name="email" label={<strong>Email</strong>}>
                    {!showChangeEmail ? (
                      this.state.email
                    ) : (
                      <span>
                        <Input
                          name="email"
                          onChange={(e) => this.handleChange(e)}
                          defaultValue={this.state.email}
                        />
                      </span>
                    )}
                    <Button
                      onClick={toggleShowEmailChange}
                      style={{ float: "right" }}
                      type="link"
                      size="small"
                    >
                      {this.state.email ? "Change Email" : "Enter Email"}
                    </Button>
                    {!this.state.emailVerified ? (
                      <div style={{ color: "red" }}>
                        <span>
                          <i className="far fa-times-circle"></i>
                          &nbsp; Not Verified
                        </span>
                        <Button
                          onClick={() => this.resendEmail()}
                          style={
                            {
                              //float: "right"
                            }
                          }
                          size="small"
                          type="link"
                        >
                          Resend
                        </Button>
                      </div>
                    ) : (
                      <div style={{ color: "green" }}>
                        <i className="far fa-check-circle"></i>
                        &nbsp; Verified
                      </div>
                    )}
                    {this.state.mailSent && (
                      <span style={{ color: "green" }}>
                        Mail sent. Check your email.
                      </span>
                    )}
                  </Form.Item>
                  <Form.Item
                    name="birthDate"
                    label={
                      <Tooltip
                        style={{ float: "left" }}
                        title="This helps us connect you more with people of your age."
                      >
                        <strong>Date of Birth</strong>{" "}
                        <i className="far fa-question-circle"></i>
                      </Tooltip>
                    }
                    layout="horizontal"
                  >
                    <div style={{ width: "100%", display: "inline" }}>
                      {this.state.birthDate
                        ? moment(this.state.birthDate).format("MMM Do, YYYY")
                        : "Birth Date Not Entered"}
                      <Button
                        onClick={toggleShowDatePicker}
                        style={{ float: "right" }}
                        type="link"
                        size="small"
                      >
                        {this.state.birthDate
                          ? "Change Birth Date"
                          : "Enter Birth Date"}
                      </Button>
                    </div>
                    {showDatePicker ? (
                      <DatePicker
                        onChange={(e) =>
                          this.setState({
                            birthDate: moment(e).format(),
                            showDatePicker: false,
                          })
                        }
                      />
                    ) : null}
                  </Form.Item>

                  <Form.Item
                    layout="horizontal"
                    name="location"
                    label={
                      <Tooltip
                        style={{ float: "left" }}
                        title="This helps us connect you more with people near you."
                      >
                        <strong>Location</strong>{" "}
                        <i className="far fa-question-circle"></i>
                      </Tooltip>
                    }
                  >
                    <div style={{ width: "100%" }}>
                      {this.state.location
                        ? this.state.location.citycountry
                        : "Location Not Entered"}
                      <Button
                        onClick={toggleShowLocationChange}
                        style={{ float: "right" }}
                        type="link"
                        size="small"
                      >
                        {this.state.location
                          ? "Change Location"
                          : "Enter Location"}
                      </Button>
                    </div>
                    {showChangeLocation ? (
                      <Select
                        showSearch
                        //style={this.props.style}
                        placeholder={
                          this.state.location
                            ? this.state.location.citycountry
                            : "Select Your City"
                        }
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onSearch={this.getCities}
                        onSelect={selectCity}
                        loading={true}
                        //onChange={(e) => console.log(e)}
                        notFoundContent={null}
                      >
                        {cities}
                      </Select>
                    ) : null}
                  </Form.Item>
                </Form>

                <Collapse
                  // defaultActiveKey={["1"]}
                  accordion
                  ghost
                  style={{
                    backgroundColor: "#fff",
                    // border: "0px",
                    margin: "20px -30px",
                  }}
                >
                  <Panel header={<strong>Change Password</strong>} key="1">
                    {changePasswordView}
                  </Panel>
                  <Panel header={<strong>Danger Zone</strong>} key="2">
                    <Button
                      type="link"
                      size="small"
                      style={{
                        color: "red",
                        /* position: "absolute",
                        bottom: "35px", */
                      }}
                      icon={<i className="far fa-trash-alt"></i>}
                      onClick={() =>
                        confirm({
                          title:
                            "Are you ABSOLUTELY sure you want to delete your account?",
                          icon: <ExclamationCircleOutlined />,
                          content:
                            "Confirming this will permanently delete your account. Once you do this, there is no going back.",
                          okText: "Yes",
                          okType: "danger",
                          cancelText: "No",
                          onOk() {
                            onDeleteClick();
                          },
                          onCancel() {},
                        })
                      }
                    >
                      &nbsp;Delete My Account
                    </Button>
                  </Panel>
                </Collapse>
              </div>

              <div className="update-profile-col-handle">
                {/* <div className="lead">Your External Links</div>
              <hr style={{}} /> */}
                <Form
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 24 }}
                  name="edit-profile"
                  onFinish={confirmAccountInfoChange}
                  onValuesChange={this.onChange}
                  hideRequiredMark="true"
                  colon="false"
                  layout="vertical"
                  size="middle"
                >
                  <Form.Item
                    name="username"
                    label={<strong>Username</strong>}
                    hasFeedback
                    //validateStatus={errors.handle ? "error" : "success"}
                    validateStatus={
                      errors.handle
                        ? "error"
                        : checkingHandle
                        ? "validating"
                        : isHandleAvailable
                        ? "success"
                        : "error"
                    }
                    help={
                      errors.handle
                        ? JSON.stringify(errors.handle).replace(/"/g, "")
                        : !isHandleAvailable
                        ? "This handle is not available"
                        : null
                    }
                    validateTrigger={this.profileSubmit}
                  >
                    <span>
                      <Input
                        addonBefore="@"
                        value={this.state.username}
                        placeholder="Profile handle is required"
                      />
                    </span>
                  </Form.Item>
                  <Form.Item
                    name="bio"
                    label={<strong>Profile Bio</strong>}
                    validateTrigger={this.profileSubmit}
                  >
                    <span>
                      <Input.TextArea
                        //rows={2}
                        onChange={(e) => {
                          if (e.target.value.length > 300) e.preventDefault();
                          if (this.state.bio.length > 300)
                            this.setState({
                              bio: this.state.bio.substr(0, 300),
                            });
                        }}
                        maxLength={300}
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        showCount
                        value={this.state.bio}
                        placeholder="Write something about yourself (max: 300 characters)"
                      />
                    </span>
                  </Form.Item>

                  <div>
                    <Button
                      type="link"
                      size="small"
                      block={true}
                      onClick={() => {
                        this.setState((prevState) => ({
                          displaySocialInputs: !prevState.displaySocialInputs,
                        }));
                      }}
                      style={{ marginBottom: "15px" }}
                    >
                      {!displaySocialInputs
                        ? "Show Social Network Links"
                        : "Hide Social Network Links"}
                    </Button>
                  </div>
                  {socialInputs}
                  <div className="mt-2">
                    <Form.Item>
                      <div className="text-right">
                        <Button type="primary" htmlType="submit">
                          Save and Submit
                        </Button>
                      </div>
                    </Form.Item>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        )}
        <div style={{ height: "15px" }} />
      </div>
    );
  }
}

UpdateProfile.propTypes = {
  getCurrentProfile: propTypes.func.isRequired,
  editProfile: propTypes.func.isRequired,
  // setCurrentUser: PropTypes.func.isRequired,
  refreshUserToken: propTypes.func.isRequired,
  logoutUser: propTypes.func.isRequired,
  profile: propTypes.object.isRequired,
  auth: propTypes.object.isRequired,
  errors: propTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  editProfile,
  getCurrentProfile,
  refreshUserToken,
  changePassword,
  logoutUser,
})(withRouter(UpdateProfile));
