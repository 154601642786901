import React, { Component } from "react";
import axios from "axios";
import { Tabs, message, Empty } from "antd";
import AllNotes from "./AllNotes";
import MyNotebooks from "./MyNotebooks";
import Loader from "../common/Loader";
const { TabPane } = Tabs;
export class Shared extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingNotes: false,
      loadingNotebooks: false,
      notes: [],
      totalNotesCount: 0,
      notebooks: null,
      offset: 0,
      limit: 50,
      sortByNotes: "createdAt",
      sortOrderNotes: "DSC",
      sortByNoteooks: "createdAt",
      sortOrderNotebooks: "DSC",
    };
  }

  componentDidMount() {
    this.getSharedNotes();
  }

  getSharedNotes() {
    this.setState({ loadingNotes: true });
    axios
      .get(`${process.env.REACT_APP_API_HOST}/api/notes/shared`, {
        params: {
          limit: this.state.limit,
          offset: this.state.offset,
        },
      })
      .then((res) => {
        // console.log(res.data);
        if (res.data.success) {
          let notesView = res.data.notesView || [];
          let notesEdit = res.data.notesEdit || [];
          this.setState({
            // notesView: res.data.notesView,
            // notesEdit: res.data.notesEdit,
            loadingNotes: false,
            totalNotesCount: res.data.totalNotesCount,
            notes: [...notesView, ...notesEdit].sort((a, b) => {
              return this.state.sortByNotes === "createdAt"
                ? a.createdAt - b.createdAt
                : a.updatedAt - b.updatedAt;
            }),
          });
        }
      })
      .catch((err) => {
        console.log(err);
        message.error("Problem connecting to server. Please reload this page.");
      });
  }

  getSharedNotebooks(obj = null) {
    axios
      .get(`${process.env.REACT_APP_API_HOST}/api/notebooks/shared`, {
        params: {
          limit: this.state.limit,
          offset: this.state.offset,
          sortBy: obj && obj.sortBy,
          sortOrder: obj && obj.sortOrder,
          searchQuery: obj && obj.searchQuery,
        },
      })
      .then((res) => {
        // console.log(res.data);
        if (res.data.success)
          this.setState({
            notebooks: [
              ...res.data.notebooksView,
              ...res.data.notebooksEdit,
            ].sort((a, b) => {
              return this.state.sortByNotebooks === "createdAt"
                ? a.createdAt - b.createdAt
                : a.updatedAt - b.updatedAt;
            }),
            loadingNotebooks: false,
          });
      })
      .catch((err) =>
        message.error("Problem connecting to server. Please reload this page.")
      );
  }

  render() {
    const { loadingNotes, loadingNotebooks, notes, notebooks } = this.state;
    const { theme } = this.props;
    return (
      <div>
        <div
          style={{
            position: "absolute",
            top: "0",
            width: "100%",
            height: "40px",
            borderBottom: "2px solid #d1d1d1",
            fontSize: "20px",
            fontWeight: "500",
            fontFamily: "Helvetica, Arial, Sans-Serif",
            padding: "5px 10px",
            color: theme.color,
          }}
        >
          Shared with me
        </div>
        <div style={{ padding: "0px" }}>
          <Tabs
            onChange={(e) => {
              if (e === "notebooks" && !notebooks) this.getSharedNotebooks();
            }}
            type="line"
            centered={true}
            size="middle"
            tabBarGutter={5}
            tabBarStyle={{
              fontSize: "14px",
              fontWeight: "500",
              backgroundColor: "#eeeeee",
              marginBottom: "0",
              borderBottom: "1px solid #fff",
            }}
          >
            <TabPane tab="Notes" key="notes">
              {loadingNotes ? (
                <Loader />
              ) : notes && notes.length === 0 ? (
                <Empty
                  image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                  imageStyle={{
                    height: 60,
                  }}
                  description={
                    <span style={{ color: theme.color }}>
                      Notes shared with you will appear here.
                    </span>
                  }
                ></Empty>
              ) : (
                <AllNotes
                  theme={theme}
                  displayHeader={false}
                  notes={this.state.notes}
                  leftPanelWidth={this.props.leftPanelWidth}
                  selectNote={this.props.selectNote}
                  notebooks={this.props.notebooks}
                  isResizing={this.props.isResizing}
                  deleteNote={this.props.deleteNote}
                  noteUpdated={this.props.noteEdited}
                  selectedNote={this.props.selectedNote}
                  noteDeleted={this.props.noteDeleted}
                  getNotes={(obj) => this.getSharedNotes(obj)}
                  totalCount={this.state.totalNotesCount}
                />
              )}
            </TabPane>
            <TabPane tab="Notebooks" key="notebooks">
              {loadingNotebooks ? (
                <Loader />
              ) : notebooks && notebooks.length === 0 ? (
                <Empty
                  image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                  imageStyle={{
                    height: 60,
                  }}
                  description={
                    <span style={{ color: theme.color }}>
                      Notebooks shared with you will appear here.
                    </span>
                  }
                ></Empty>
              ) : (
                <MyNotebooks
                  displayHeader={false}
                  notebooks={notebooks}
                  getNotebooks={(obj) => this.getSharedNotebooks(obj)}
                  selectNote={(note) => this.props.selectNote(note)}
                  reverseNotebooks={() =>
                    this.setState({ notebooks: this.state.notebooks.reverse() })
                  }
                  selectedNote={this.state.selectedNote}
                  // noteUpdated={this.props.noteEdited}
                  // leftPanelWidth={this.props.leftPanelWidth}
                  shared={true}
                  selectNotebook={this.props.selectNotebook}
                  onNotebookRename={(id, newName) => {
                    let notebooks = this.state.notebooks.map((n) => {
                      if (n.id === id) n.name = newName;
                      return n;
                    });
                    this.setState({ notebooks });
                  }}
                />
              )}
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default Shared;
