import React from "react";
import ReactDOMServer from "react-dom/server";
import ContentEditable from "react-contenteditable";
// import sanitizeHtml from "sanitize-html";
import EmojiPicker from "emoji-picker-react";
import SlashCommands, {
  getCaretCoordinates,
  checkNewLine,
  Placeholder,
} from "./elements/SlashCommands";
// import Checklist from "./elements/Checklist";
import "./elements/expand.scss";
import "./elements/checklist.scss";
import "./elements/table.scss";
// import "./editableBlock.css";
import SelectionToolbar, { SelectionChange } from "./elements/SelectionToolbar";
// import TableOptionToolbar from "./elements/TableOptionToolbar";
import { SaveSelection, RestoreSelection, uploadHandler } from "./utils";
import {
  addGeneralElementEvents,
  addCheckboxEvents,
  addImageResizeEvents,
} from "./elementEvents";
import axios from "axios";
import { DeleteColumnOutlined, DeleteRowOutlined } from "@ant-design/icons";

export default class EditableBlock extends React.Component {
  constructor() {
    super();
    this.state = {
      html: ``,
      editable: true,
      displaySlashCommands: false,
      slashCommandsPosition: { x: null, y: null },
      slashCommandOpen: false,
      displayPlaceholder: true,
      placeholderEscaped: false,
      selection: null,
      displayEmojiPicker: null,
      //   newLine: true
    };
  }

  componentDidMount() {
    this.setState({ html: this.props.htmlContent });
    if (!this.props.readOnly) {
      // Adding event listeners
      addGeneralElementEvents();
      addCheckboxEvents();
      addImageResizeEvents();
    } else {
      // Removing event listeners
      addGeneralElementEvents(true);
      addCheckboxEvents(true);
      addImageResizeEvents(true);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Hide emoji picker on outside click
    if (!prevState.displayEmojiPicker && this.state.displayEmojiPicker) {
      let hideEmojiPicker = () => {
        this.setState({ displayEmojiPicker: false });
      };
      // console.log(document.getElementsByClassName("emoji-picker-react")[0]);
      window.addEventListener("click", (e) => {
        if (
          document.getElementsByClassName("emoji-picker-react")[0] &&
          !document
            .getElementsByClassName("emoji-picker-react")[0]
            .contains(e.target)
        ) {
          hideEmojiPicker();
        }
      });
    }

    if (prevProps.readOnly !== this.props.readOnly) {
      if (this.props.readOnly) {
        // Removing event listeners
        addGeneralElementEvents(true);
        addCheckboxEvents(true);
        addImageResizeEvents(true);
      } else {
        // Adding event listeners
        addGeneralElementEvents();
        addCheckboxEvents();
        addImageResizeEvents();
      }
    }
  }

  handleEditorChange = (e) => {
    if (
      this.state.html.length === 0 ||
      this.state.html === "<br>" ||
      this.state.html === "&nbsp;"
    )
      this.setState({ html: `<div>${e.target.value}</div>` });
    else this.setState({ html: e.target.value });
    this.props.onChange(e.target.value);
    // this.showPlaceholder();
  };

  showPlaceholder = () => {
    // Disabling temporarily
    return;
    // if (this.state.placeholderEscaped) return;
    // // This is to prevent 2 placeholders appearing at same time
    // if (
    //   this.state.html === "" ||
    //   this.state.html.length === 1 ||
    //   this.state.html === "&nbsp;"
    // )
    //   return;
    // if (checkNewLine()) {
    //   const { x, y } = getCaretCoordinates();
    //   // console.log("New line. Pos: ", { x, y });
    //   this.setState({
    //     displayPlaceholder: true,
    //     placeholderPosition: { x, y },
    //   });
    // }
  };

  toggleEditable = () => {
    this.setState({ editable: !this.state.editable });
  };

  focusEditor = (value) => {
    // console.log(value);
    this.setState({
      displaySlashCommands: false,
      slashCommandsPosition: { x: null, y: null },
      slashCommandOpen: false,
    });
    // var el = document.getElementById("ed-editor-id");
    // el.focus();
    RestoreSelection(this.state.selection);
    if (value) document.execCommand("insertText", false, value);
  };

  slashCommandAction(selection, searchValue) {
    this.focusEditor();
    this.setState({ displayPlaceholder: false });
    // console.log(selection);
    switch (selection) {
      case "Image": {
        if (!checkNewLine()) document.execCommand("insertLineBreak", false, "");
        document.getElementById("fileInput").click();

        break;
      }
      case "Table": {
        if (!checkNewLine()) document.execCommand("insertLineBreak", false, "");
        document.execCommand(
          "insertHTML",
          true,
          // ` <div class="ed-action-item"><input class="ed-action-item-checkbox" type="checkbox" />&nbsp;<br></div>`
          `<div class="ed-table"><table class="table is-hoverable is-bordered">
          <tbody>
          <tr>
          <th data-placeholder="Header"></th>
          <th data-placeholder="Header"></th>
          <th data-placeholder="Header"></th>
          </tr>
  <tr>
    <td></td>
    <td></td>
    <td></td>
  </tr>
  <tr>
    <td></td>
    <td></td>
    <td></td>
  </tr>
  </tbody>
</table>
<div contenteditable="false" class="element-table-options">
    <button class="table-action-add-row-before" data-tooltip="Add row before selected cell"><i class="far fa-arrow-to-top"></i></button>
    <button class="table-action-add-row-after" data-tooltip="Add row after selected cell"><i class="far fa-arrow-from-top"></i></button>
    <button class="table-action-delete-current-row" data-tooltip="Delete current row">${ReactDOMServer.renderToStaticMarkup(
      <DeleteRowOutlined />
    )}</button>
    <button class="table-action-add-column-before" data-tooltip="Add column before selected cell"><i class="far fa-arrow-to-left"></i></button>
    <button class="table-action-add-column-after" data-tooltip="Add column after selected cell"><i class="far fa-arrow-from-left"></i></button>
    <button class="table-action-delete-current-column" data-tooltip="Delete current column">${ReactDOMServer.renderToStaticMarkup(
      <DeleteColumnOutlined />
    )}</button>
    <button class="table-action-toggle-stripes" data-tooltip="Toggle stripes"><i class="fad fa-align-justify"></i></button>
    <button class="table-action-toggle-borders" data-tooltip="Toggle borders"><i class="far fa-border-all"></i></button>
    <button class="table-action-delete-table" data-tooltip="Delete table"><i class="far fa-trash-alt"></i></button>
</div>
</div>`
        );
        break;
      }

      case "Numbered List": {
        if (!checkNewLine()) document.execCommand("insertLineBreak", false, "");
        document.execCommand("insertorderedlist", true, ``);
        break;
      }
      case "Bullet List": {
        if (!checkNewLine()) document.execCommand("insertLineBreak", false, "");
        document.execCommand("insertunorderedlist", true, ``);
        break;
      }
      case "Action Item": {
        if (!checkNewLine()) document.execCommand("insertLineBreak", false, "");
        document.execCommand(
          "insertHTML",
          true,
          // ` <div class="ed-action-item"><input class="ed-action-item-checkbox" type="checkbox" />&nbsp;<br></div>`
          `<div><input class="ed-action-item-checkbox" type="checkbox" />&nbsp;<br></div>`
        );
        break;
      }
      case "Expand": {
        if (!checkNewLine()) document.execCommand("insertLineBreak", false, "");
        this.setState({ displayPlaceholder: false });
        // let htmlstring = ReactDOMServer.renderToStaticMarkup(
        //   <Expand
        //     dataHeader={"Wanna see magic? Click me."}
        //     dataDetails={"Tadaa!"}
        //   />
        // );
        document.execCommand(
          "insertHTML",
          true,
          `<div class="ed-expand"><details class="expand__details"><summary class="expand__summary"><div class="expand__summary__content">Wanna see magic? Click me.</div></summary><div class="expand__data"><p>Tadaa!</p></div></details><button contenteditable="false" class="element-delete-btn"><i class="far fa-trash-alt"></i></button></div>&nbsp;`
        );

        break;
      }
      case "Columns": {
        if (!checkNewLine()) document.execCommand("insertLineBreak", false, "");
        document.execCommand(
          "insertHTML",
          true,
          `<div class="ed-columns columns">
                  <div class="column" style="border: 1px solid #d1d1d1; border-radius: 5px; margin:5px;" data-placeholder="Press enter to create new column"></div>
                  <div class="column" style="border: 1px solid #d1d1d1; border-radius: 5px; margin:5px;" data-placeholder="Press Shift + Enter to go to next line"></div>
                 </div><div></div>`
        );
        break;
      }
      case "Quote": {
        if (!checkNewLine()) document.execCommand("insertLineBreak", false, "");
        axios
          .get("https://api.quotable.io/random")
          .then((res) =>
            document.execCommand(
              "insertHTML",
              true,
              `<div class="ed-blockquote"><blockquote>${res.data.content} - <span style="font-weight: 600">${res.data.author}</span></blockquote></div>&nbsp;`
            )
          )
          .catch((err) =>
            document.execCommand(
              "insertHTML",
              true,
              `<div class="ed-blockquote"><blockquote><br/></blockquote></div>&nbsp;`
            )
          );

        break;
      }
      case "Code Snippet": {
        if (!checkNewLine()) document.execCommand("insertLineBreak", false, "");
        document.execCommand(
          "insertHTML",
          true,
          `<div class="ed-code-snippet"><pre><code><p><br/></p></code></pre></div>&nbsp;`
        );
        break;
      }
      case "Emoji": {
        const { x, y } = getCaretCoordinates();
        this.setState({
          slashCommandsPosition: { x, y },
          displayEmojiPicker: true,
          selection: SaveSelection(),
        });
        break;
      }
      case "Tag/Status": {
        document.execCommand(
          "insertHTML",
          true,
          `<div class="ed-tag"><span class="tag is-medium"><span contenteditable={false} />Tag Label</span></div>&nbsp;`
        );
        break;
      }
      case "Message Box": {
        document.execCommand(
          "insertHTML",
          true,
          `<div class="ed-message"><div class="message is-medium"><div class="message-header"><p>Message title</p></div><div class="message-body" data-placeholder="Type your message here (Use shift + Enter to go to next line)"></div></div></div>&nbsp;`
        );
        break;
      }
      case "Info Message Box": {
        document.execCommand(
          "insertHTML",
          true,
          `<div class="ed-message"><div class="message is-medium is-info"><div class="message-header"><p>Message title</p></div><div class="message-body" data-placeholder="Type your message here (Use shift + Enter to go to next line)"></div></div></div>&nbsp;`
        );
        break;
      }
      case "Warning Message Box": {
        document.execCommand(
          "insertHTML",
          true,
          `<div class="ed-message"><div class="message is-medium is-warning"><div class="message-header"><p>Message title</p></div><div class="message-body" data-placeholder="Type your message here (Use shift + Enter to go to next line)"></div></div></div>&nbsp;`
        );
        break;
      }
      case "Success Message Box": {
        document.execCommand(
          "insertHTML",
          true,
          `<div class="ed-message"><div class="message is-medium is-success"><div class="message-header"><p>Message title</p></div><div class="message-body" data-placeholder="Type your message here (Use shift + Enter to go to next line)"></div></div></div>&nbsp;`
        );
        break;
      }
      case "Danger Message Box": {
        document.execCommand(
          "insertHTML",
          true,
          `<div class="ed-message"><div class="message is-medium is-danger"><div class="message-header"><p>Message title</p></div><div class="message-body" data-placeholder="Type your message here (Use shift + Enter to go to next line)"></div></div></div>&nbsp;`
        );
        break;
      }
      case "Panel": {
        if (!checkNewLine()) document.execCommand("insertLineBreak", false, "");
        document.execCommand(
          "insertHTML",
          true,
          `<div class="ed-panel notification"></div>`
        );
        break;
      }
      case "Info Panel": {
        if (!checkNewLine()) document.execCommand("insertLineBreak", false, "");
        document.execCommand(
          "insertHTML",
          true,
          `<div class="ed-panel notification is-info"><p></p></div>`
        );
        break;
      }
      case "Info Panel Light": {
        if (!checkNewLine()) document.execCommand("insertLineBreak", false, "");
        document.execCommand(
          "insertHTML",
          true,
          `<div class="ed-panel notification is-info is-light"><p></p></div>`
        );
        break;
      }
      case "Success Panel": {
        if (!checkNewLine()) document.execCommand("insertLineBreak", false, "");
        document.execCommand(
          "insertHTML",
          true,
          `<div class="ed-panel notification is-success"><p><p/></div>`
        );
        break;
      }
      case "Success Panel Light": {
        if (!checkNewLine()) document.execCommand("insertLineBreak", false, "");
        document.execCommand(
          "insertHTML",
          true,
          `<div class="ed-panel notification is-success is-light"><p><p/></div>`
        );
        break;
      }
      case "Warning Panel": {
        if (!checkNewLine()) document.execCommand("insertLineBreak", false, "");
        document.execCommand(
          "insertHTML",
          true,
          `<div class="ed-panel notification is-warning"></div>`
        );
        break;
      }
      case "Warning Panel Light": {
        if (!checkNewLine()) document.execCommand("insertLineBreak", false, "");
        document.execCommand(
          "insertHTML",
          true,
          `<div class="ed-panel notification is-warning is-light"></div>`
        );
        break;
      }
      case "Danger Panel": {
        if (!checkNewLine()) document.execCommand("insertLineBreak", false, "");
        document.execCommand(
          "insertHTML",
          true,
          `<div class="ed-panel notification is-danger"></div>`
        );
        break;
      }
      case "Danger Panel Light": {
        if (!checkNewLine()) document.execCommand("insertLineBreak", false, "");
        document.execCommand(
          "insertHTML",
          true,
          `<div class="ed-panel notification is-danger is-light"></div>`
        );
        break;
      }
      case "Heading 1": {
        if (!checkNewLine()) document.execCommand("insertLineBreak", false, "");
        document.execCommand("formatBlock", true, `h1`);
        break;
      }
      case "Heading 2": {
        if (!checkNewLine()) document.execCommand("insertLineBreak", false, "");
        document.execCommand("formatBlock", true, `h2`);
        break;
      }
      case "Heading 3": {
        if (!checkNewLine()) document.execCommand("insertLineBreak", false, "");
        document.execCommand("formatBlock", true, `h3`);
        break;
      }
      case "Heading 4": {
        if (!checkNewLine()) document.execCommand("insertLineBreak", false, "");
        document.execCommand("formatBlock", false, "h4");
        break;
      }
      case "Divider": {
        if (!checkNewLine()) document.execCommand("insertLineBreak", false, "");
        document.execCommand("insertHTML", false, `<hr />`);
        break;
      }
      default:
        return;
    }
  }

  render = () => {
    const { readOnly, theme } = this.props;
    const {
      displaySlashCommands,
      slashCommandsPosition,
      displayPlaceholder,
      placeholderPosition,
      displayEmojiPicker,
    } = this.state;
    // console.log(displayPlaceholder);

    return (
      <div
        className="ed-wrapper"
        id="ed-wrapper-id"
        style={{ maxWidth: "100%", margin: "auto" }}
      >
        <ContentEditable
          className="ed-editor"
          id="ed-editor-id"
          style={{
            fontFamily: "Helvetica, Arial, sans-serif",
            fontSize: this.props.fontSize || "18px",
            lineHeight: this.props.lineHeight || "1.8",
            wordWrap: "break-word",
            whiteSpace: "pre-line",
            minHeight: "500px",
            outline: "none",
            border: this.props.showToolbar
              ? `1px solid ${theme.borderColor1}`
              : "none",
            borderRadius: "0px",
            position: "relative",
            backgroundColor: theme ? theme.backgroundColor : "#fff",
            color: theme ? theme.color : "#555",
            // For demo page
            ...(!this.props.key && {
              maxWidth: "850px",
              border: "1px solid #d1d1d1",
              margin: "20px auto",
              minHeight: "800px",
            }),
          }}
          tagName="pre"
          html={this.state.html} // innerHTML of the editable div
          disabled={readOnly} // use true to disable edition
          onChange={this.handleEditorChange} // handle innerHTML change
          onFocus={(e) => {
            // e.stopPropagation();
            // this.showPlaceholder();
          }}
          onBlur={() => this.setState({ displayPlaceholder: false })}
          onSelect={() => SelectionChange()}
          // Check css
          data-placeholder={`Type '/' for options`}
          onKeyDown={(e) => {
            // console.log(displayPlaceholder);
            // console.log(e);
            if (displayPlaceholder && e.key !== "Enter") {
              this.setState({
                displayPlaceholder: false,
                placeholderPosition: { x: null, y: null },
                placeholderEscaped:
                  this.state.placeholderEscaped || e.key === "Escape",
              });
            }
            if (e.key === "Tab") {
              e.preventDefault();
              document.execCommand("insertHTML", false, "&emsp;&emsp;");
            }
            if (e.key === "/") {
              // e.preventDefault();
              const { x, y } = getCaretCoordinates();
              this.setState({
                displaySlashCommands: true,
                slashCommandsPosition: { x, y },
                selection: SaveSelection(),
                // To remove placeholder when menu appears on empty editor
                ...(this.state.html.length === 0 && { html: `&nbsp;` }),
              });

              // document.addEventListener("click", () =>
              //   this.setState({ displaySlashCommands: false })
              // );
              return;
            }
            // else if (e.key === "Enter") {
            //   document.execCommand("insertLineBreak");
            //   e.preventDefault();
            //   return;
            // }
            else return;
          }}
          // onClick={(e) => {
          //   if (!e.isDefaultPrevented) this.showPlaceholder();
          // }}
        />

        {this.props.sourceVisible && (
          <>
            <h3>source</h3>
            <textarea
              // className="editable"
              value={this.state.html}
              onChange={this.handleChange}
              style={{ width: "100%", height: "400px" }}

              // onBlur={this.sanitize}
            />
          </>
        )}
        {displaySlashCommands && (
          <SlashCommands
            position={slashCommandsPosition}
            onBlur={(value) => this.focusEditor(value)}
            onSelect={(selection, searchValue) => {
              if (this.state.htmlContent === "") {
                this.setState(
                  { htmlContent: `<div>&nbsp;</div>` },
                  this.slashCommandAction(selection, searchValue)
                );
              } else this.slashCommandAction(selection, searchValue);
            }}
          />
        )}
        {displayPlaceholder && (
          <Placeholder
            position={placeholderPosition}
            onBlur={() => this.focusEditor()}
          />
        )}
        <SelectionToolbar />
        {/* <TableOptionToolbar /> */}
        {displayEmojiPicker && (
          <EmojiPicker
            pickerStyle={{
              position: "absolute",
              top: slashCommandsPosition.y,
              left: slashCommandsPosition.x,
            }}
            onEmojiClick={(event, emoji) => {
              RestoreSelection(this.state.selection);
              document.execCommand("insertHTML", false, emoji.emoji);
              this.setState({ displayEmojiPicker: false });
            }}
          />
        )}
        <input
          id="fileInput"
          type="file"
          style={{ display: "none" }}
          multiple
          onChange={(e) => {
            // console.log(e.target.files);
            uploadHandler(e.target.files, "test-id");
          }}
        />
      </div>
    );
  };
}

EditableBlock.defaultProps = {
  readOnly: false,
  htmlContent: ``,
  onChange: () => {},
  sourceVisible: false,
};
