import React, { useState } from "react";
import Typist from "react-typist";
import "./TopTitleAnimation.css";

export function TopTitleAnimation(props) {
  let [typingDelay, setTypingDelay] = useState(60);
  let [stdTypingDelay, setStdTypingDelay] = useState(5);
  return (
    <Typist
      key={props.typistKey}
      avgTypingDelay={typingDelay}
      stdTypingDelay={stdTypingDelay}
      onLineTyped={(line, index) => {
        setTypingDelay(5);
        setStdTypingDelay(5);
      }}
      onCharacterTyped={(character, charIdx) => {
        if (character === `🔙`) {
          return;
        }
        if (typingDelay !== 60) {
          setTypingDelay(60);
          //   setStdTypingDelay(35);
        }
      }}
      onTypingDone={() => props.onTypingDone()}
      cursor={{
        show: false,
        // blink: true,
      }}
    >
      Publish articles and books.
      <Typist.Delay ms={props.lineDelay} />
      <Typist.Backspace count={60} delay={500} />
      <span>Find latest books and articles on any topic. </span>
      <Typist.Delay ms={props.lineDelay} />
      <Typist.Backspace count={63} delay={500} />
      {/* <span>Read all your blogs at one place. </span>
      <Typist.Delay ms={props.lineDelay} />
      <Typist.Backspace count={63} delay={500} /> */}
      Create your personal library.
      <Typist.Delay ms={props.lineDelay} />
      <Typist.Backspace count={30} delay={500} />
      Read and connect with writers around the world.
      <Typist.Delay ms={props.lineDelay} />
      <Typist.Backspace count={80} delay={500} />
      <span>Your knowledge network. </span>
      <Typist.Delay ms={props.lineDelay} />
      <Typist.Backspace count={24} delay={500} />
      <span>Create your personal and professional knowledge base. </span>
      <Typist.Delay ms={props.lineDelay} />
      <Typist.Backspace count={60} delay={500} />
      <span>Share and collaborate with your team to get the work done. </span>
      <Typist.Delay ms={props.lineDelay} />
      <Typist.Backspace count={65} delay={500} />
      <span>
        Manage all your information <br /> <Typist.Delay ms={1200} /> at one
        place.{" "}
      </span>
      <Typist.Delay ms={props.lineDelay} />
      <Typist.Backspace count={84} delay={500} />
    </Typist>
  );
}

export default TopTitleAnimation;
