import React, { Component } from "react";
import axios from "axios";
import Loader from "../common/Loader";
import { message, Pagination, Input, Empty } from "antd";
import InfiniteScroll from "react-infinite-scroller";
import NoteItem from "../dashboard/Feed/NoteItem";

const hasImage = (note) =>
  note.info2 && note.info2.imageArray && note.info2.imageArray.length > 0;

export class SeeAllNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notes: [],
      count: null,
      offset: 0,
      limit: 20,
      sortBy: "updatedAt",
      sortOrder: "DESC",
      searchQuery: "",
      searchFocused: false,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.getNotes();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.reloadNote !== this.props.reloadNote &&
      this.props.reloadNote
    ) {
      let notes = this.state.notes.map((n) =>
        n.id === this.props.reloadNote.id ? this.props.reloadNote : n
      );
      this.setState({ notes });
    }
  }

  getNotes() {
    // console.log("Offset: ", this.state.offset, "limit:", this.state.limit);
    axios
      .get(
        !this.props.hashtag
          ? `${process.env.REACT_APP_API_HOST}/api/notes/all`
          : `${process.env.REACT_APP_API_HOST}/api/hashtags/${this.props.hashtag}/notes`,
        {
          params: {
            offset: this.state.offset,
            limit: this.state.limit,
            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder,
            searchQuery: this.state.searchQuery,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);

        if (res.data.success) {
          let notes = res.data.notes.sort((a, b) => {
            // A has cover, B doesn't have cover
            if (hasImage(a) && !hasImage(b)) return -1;
            // B has cover, A doesn't have cover
            else if (!hasImage(a) && hasImage(b)) return 1;
            // Either both have cover or none have cover
            else return 0;
          });
          this.setState({
            notes: this.props.useInfinieScroll
              ? [...notes, ...res.data.notes]
              : notes,
            loading: false,
            count: res.data.count,
            offset: this.state.offset + res.data.notes.length,
          });
        } else {
          if (res.data) message.error(res.data.message);
          else {
            message.error("Some error occured. Please try again later.");
          }
        }
      });
  }

  render() {
    const { loading, notes, count, offset, limit, searchFocused } = this.state;
    const { theme, isSmallDevice, userId } = this.props;
    // console.log("Page", parseInt(offset / limit) + 1);
    return (
      <div style={{ padding: isSmallDevice ? "20px 0px" : "50px 30px" }}>
        {/* <div style={{ fontSize: "36px", textAlign: "center", margin: "20px" }}>
          See All Published Notes
        </div> */}
        <div
          style={{
            display: "grid",
            gridTemplateColumns: isSmallDevice ? "1fr" : "3fr 1fr",
          }}
        >
          <div>
            <Input
              style={{
                maxWidth: "400px",
                borderLeft: `2px solid ${theme.borderColor1}`,
                height: "40px",
              }}
              onChange={(e) =>
                this.setState({ searchQuery: e.target.value }, () =>
                  this.getNotes()
                )
              }
              onFocus={() => this.setState({ searchFocused: true })}
              onBlur={() => this.setState({ searchFocused: false })}
              bordered={searchFocused}
              placeholder="Search in articles, notes and stories"
            />
          </div>

          <div
            style={{
              display: this.props.useInfiniteScroll ? "none" : "inline-block",
              justifySelf: "end",
              paddingTop: "6px",
              backgroundColor: theme.mode === "dark" ? "#cdcdcd" : "inherit",
              borderRadius: "5px",
              // border: `2px solid ${theme.borderColor1}`,
            }}
          >
            <Pagination
              size="small"
              total={count}
              pageSize={limit}
              current={parseInt(offset / limit) + 1}
              showSizeChanger={false}
              onChange={(p) =>
                this.setState({ offset: limit * (p - 1) }, () => {
                  // console.log("Offset: ", this.state.offset);
                  return this.getNotes();
                })
              }
              // onShowSizeChange={(n) => console.log("New size", n)}
            />
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : this.props.useInfinieScroll ? (
          <InfiniteScroll
            loadMore={() => {
              console.log("Loading more...");
              return this.getNotes();
            }}
            hasMore={notes.length < count}
            loader={<Loader />}
            useWindow={false}
          >
            <div
              style={{
                // padding: "60px 15px",
                display: "grid",
                gridTemplateColumns: isSmallDevice ? "1fr" : "1fr 1fr",
                gap: "30px",
                // padding: !userId && !isSmallDevice ? "0px 5%" : "0px",
                maxWidth: "980px",
                margin: "50px auto",
              }}
            >
              {notes && notes.length > 0 ? (
                notes.map((note) => (
                  <NoteItem
                    note={note}
                    userId={userId}
                    theme={theme}
                    userNotebooks={this.props.userNotebooks}
                    saveNote={(noteId, notebookId) =>
                      this.props.saveNote(noteId, notebookId)
                    }
                  />
                ))
              ) : (
                <Empty
                  image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                  imageStyle={{
                    height: 60,
                  }}
                  description={
                    <span style={{ color: theme.color }}>No notes found.</span>
                  }
                ></Empty>
              )}
            </div>
          </InfiniteScroll>
        ) : (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: isSmallDevice ? "1fr" : "1fr 1fr",
              gap: isSmallDevice ? "20px" : "40px",
              // padding: !userId && !isSmallDevice ? "0px 5%" : "0px",
              maxWidth: "980px",
              margin: "30px auto",
            }}
          >
            {notes.map((note) => (
              <NoteItem
                key={note.id}
                note={note}
                userId={this.props.userId}
                userNotebooks={this.props.userNotebooks || []}
                theme={theme}
                saveNote={(noteId, notebookId) =>
                  this.props.saveNote(noteId, notebookId)
                }
              />
            ))}
          </div>
        )}
        <div style={{ textAlign: "center" }}>
          <div
            style={{
              display: this.props.useInfiniteScroll ? "none" : "inline-block",
              margin: "40px",
              padding: "5px",
              backgroundColor: theme.mode === "dark" ? "#cdcdcd" : "inherit",
              borderRadius: "5px",
            }}
          >
            <Pagination
              size="small"
              total={count}
              pageSize={limit}
              showSizeChanger={false}
              current={parseInt(offset / limit) + 1}
              onChange={(p) =>
                this.setState({ offset: limit * (p - 1) }, () => {
                  // console.log("Offset: ", this.state.offset);
                  return this.getNotebooks();
                })
              }

              // onShowSizeChange={(n) => console.log("New size", n)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default SeeAllNotes;

SeeAllNotes.defaultProps = {
  showSearch: true,
};
