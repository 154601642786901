import React, { Component } from "react";
import axios from "axios";
import Loader from "../common/Loader";
import { message, Pagination, Input, Empty } from "antd";
import InfiniteScroll from "react-infinite-scroller";

import NotebookItem from "../dashboard/Feed/NotebookItem";

export class SeeAllNotebooks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notebooks: [],
      offset: 0,
      limit: 20,
      sortBy: "updatedAt",
      sortOrder: "DESC",
      count: 0,
      searchQuery: "",
      searchFocused: false,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.getNotebooks();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.reloadNotebook) {
      let notebooks = this.state.notebooks;
      notebooks = notebooks.map((n) => {
        if (n.id === this.props.reloadNotebook) {
          n.temp.hasSaved = !n.temp.hasSaved;
          return n;
        } else return n;
      });
      this.setState({ notebooks });
    }
  }

  getNotebooks() {
    axios
      .get(
        !this.props.hashtag
          ? `${process.env.REACT_APP_API_HOST}/api/notebooks/all`
          : `${process.env.REACT_APP_API_HOST}/api/hashtags/${this.props.hashtag}/notebooks`,
        {
          params: {
            offset: this.state.offset,
            limit: this.state.limit,
            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder,
            searchQuery: this.state.searchQuery,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        if (res.data.success) {
          this.setState({
            notebooks: res.data.notebooks,
            loading: false,
            count: res.data.count,
          });
        } else {
          message.error("Some error occured. Please try again.");
        }
      })
      .catch((err) =>
        message.error("Unable to fetch notebooks. Please try again.")
      );
  }
  render() {
    const { notebooks, searchFocused, count, limit, offset, loading } =
      this.state;
    const { theme, isSmallDevice, userId } = this.props;
    return (
      <div style={{ padding: isSmallDevice ? "50px 0px" : "50px 30px" }}>
        <div style={{ paddingBottom: "30px" }}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: isSmallDevice ? "1fr" : "2fr 1fr",
            }}
          >
            <div>
              <Input
                style={{
                  maxWidth: "400px",
                  borderLeft: `2px solid ${theme.borderColor1}`,
                  height: "40px",
                }}
                onChange={(e) =>
                  this.setState({ searchQuery: e.target.value }, () =>
                    this.getNotebooks()
                  )
                }
                onFocus={() => this.setState({ searchFocused: true })}
                onBlur={() => this.setState({ searchFocused: false })}
                bordered={searchFocused}
                placeholder="Search in books and notebooks"
              />
            </div>
            <div
              style={{
                display: this.props.useInfiniteScroll ? "none" : "inline-block",
                justifySelf: "end",
                paddingTop: "6px",
                backgroundColor: theme.mode === "dark" ? "#cdcdcd" : "inherit",
                borderRadius: "5px",
              }}
            >
              <Pagination
                size="small"
                total={count}
                pageSize={limit}
                showSizeChanger={false}
                current={parseInt(offset / limit) + 1}
                onChange={(p) =>
                  this.setState({ offset: limit * (p - 1) }, () => {
                    // console.log("Offset: ", this.state.offset);
                    return this.getNotebooks();
                  })
                }

                // onShowSizeChange={(n) => console.log("New size", n)}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: isSmallDevice ? "1fr" : "1fr 1fr",
            gap: isSmallDevice ? "20px" : "40px",
            padding: !userId && !isSmallDevice ? "0px" : "0px",
          }}
        >
          {loading ? (
            <Loader />
          ) : this.props.useInfinieScroll ? (
            <InfiniteScroll
              loadMore={() => this.getNotebooks()}
              hasMore={!notebooks ? true : notebooks.length < count}
              loader={<Loader />}
              useWindow={false}
            >
              <div
                style={{
                  padding: "60px 15px",
                  ...(notebooks &&
                    notebooks.length > 0 && {
                      display: "grid",
                      gridTemplateColumns: isSmallDevice ? "1fr" : "1fr 1fr",
                      gridGap: "15px",
                    }),
                }}
              >
                {notebooks && notebooks.length > 0 ? (
                  notebooks.map((notebook) => (
                    <NotebookItem
                      notebook={notebook}
                      userId={userId}
                      theme={theme}
                      userNotebooks={this.props.userNotebooks}
                      saveNotebook={(notebookId, notebookbookId) =>
                        this.props.saveNotebook(notebookId, notebookbookId)
                      }
                    />
                  ))
                ) : (
                  <Empty
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{
                      height: 60,
                    }}
                    description={
                      <span style={{ color: theme.color }}>
                        No notebooks found with this hashtag.
                      </span>
                    }
                  ></Empty>
                )}
              </div>
            </InfiniteScroll>
          ) : (
            notebooks.map((notebook) => (
              <NotebookItem
                key={notebook.id}
                notebook={notebook}
                theme={theme}
                saveNotebook={(nbId) => this.props.saveNotebook(nbId)}
                userId={this.props.userId}
              />
            ))
          )}
        </div>
        <div style={{ textAlign: "center" }}>
          <div
            style={{
              display: this.props.useInfiniteScroll ? "none" : "inline-block",
              marginTop: "40px",
              padding: "5px",
              backgroundColor: theme.mode === "dark" ? "#cdcdcd" : "inherit",
              borderRadius: "5px",
            }}
          >
            <Pagination
              size="small"
              total={count}
              pageSize={limit}
              showSizeChanger={false}
              current={parseInt(offset / limit) + 1}
              onChange={(p) =>
                this.setState({ offset: limit * (p - 1) }, () => {
                  // console.log("Offset: ", this.state.offset);
                  return this.getNotebooks();
                })
              }

              // onShowSizeChange={(n) => console.log("New size", n)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default SeeAllNotebooks;
