import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Input,
  message,
  Button,
  notification,
  Dropdown,
  Menu,
  Popconfirm,
} from "antd";
import ProfileBoxItem from "../dashboard/Feed/ProfileBoxItem";
import axios from "axios";
import moment from "moment";
import ReplyItem from "./ReplyItem";

export const CommentItem = ({
  auth,
  comment,
  subjectType,
  subject,
  sendLike = () => {},
  afterCommentAdd = () => {},
  afterCommentEdit = () => {},
  afterCommentDelete = () => {},
}) => {
  const {
    theme,
    //  isSmallDevice
  } = auth;
  const [text, setText] = useState("");
  const [textAreaFocussed, setTextAreaFocussed] = useState(false);
  const [hasLikedComment, setHasLikedComment] = useState(
    comment ? comment.temp.hasLiked : false
  );
  const [commentLikesCount, setCommentLikesCount] = useState(
    comment ? comment.temp.likesCount : null
  );
  const [commentRepliesCount, setCommentRepliesCount] = useState(
    comment ? comment.temp.replyCount : null
  );
  const [showAddReply, setShowAddReply] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editText, setEditText] = useState(comment ? comment.content : "");
  var commentDeleteButtonRef = React.createRef();

  const addComment = (sType = subjectType, s = subject) => {
    axios
      .post(
        `${process.env.REACT_APP_API_HOST}/api/comments/add/${sType}/${s.id}`,
        { text }
      )
      .then((res) => {
        if (res.data.success) {
          setText("");
          if (sType === "comment") {
            setReplies([...replies, res.data.comment]);
            setCommentRepliesCount(replies.length + 1);
            afterCommentAdd(null, true);
          } else afterCommentAdd(res.data.comment);

          notification["success"]({
            message: "Success",
            description: `Your ${
              sType === "comment" ? "reply" : "comment"
            } was posted.`,
            placement: "bottomRight",
          });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        message.error("Unable to reach servers.");
      });
  };

  const editComment = (sType = subjectType, c = comment, t = editText) => {
    if (t.trim() === c.content) {
      message.success("No changes to save");
      return setEditMode(false);
    }
    axios
      .post(`${process.env.REACT_APP_API_HOST}/api/comments/edit/`, {
        commentId: c.id,
        text: t,
      })
      .then((res) => {
        if (res.data.success) {
          setText("");
          setEditMode(false);
          if (sType === "comment") {
            setReplies(
              replies.map((reply) =>
                reply.id === res.data.comment.id ? res.data.comment : reply
              )
            );
          } else afterCommentEdit(res.data.comment);

          notification["success"]({
            message: "Success",
            description: `Comment changes were saved.`,
            placement: "bottomRight",
          });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        message.error("Unable to reach servers.");
      });
  };
  const deleteComment = (sType = subjectType, c = comment) => {
    axios
      .post(`${process.env.REACT_APP_API_HOST}/api/comments/delete/`, {
        commentId: c.id,
      })
      .then((res) => {
        if (res.data.success) {
          if (sType === "comment") {
            // Reply is deleted
            setReplies(replies.filter((reply) => reply.id !== c.id));
            setCommentRepliesCount(replies.length - 1);
            afterCommentDelete(null, true);
          }
          // Comment is deleted
          else afterCommentDelete(c, false, commentRepliesCount);

          notification["success"]({
            message: "Success",
            description: res.data.message,
            placement: "bottomRight",
          });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        message.error("Unable to reach servers.");
      });
  };

  const [showReplies, setShowReplies] = useState(false);
  const [replies, setReplies] = useState([]);
  const [limit, setLimit] = useState(50);
  const [offset, setOffset] = useState(0);

  const getReplies = (showAfterLoad = false) => {
    axios
      .get(
        `${process.env.REACT_APP_API_HOST}/api/comments/comment/${comment.id}`,
        {
          params: {
            limit,
            offset,
            sortBy: "createdAt",
            sortOrder: "ASC",
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          setOffset(offset + res.data.comments.length);
          setReplies([...replies, ...res.data.comments]);
          setLimit(limit);
          if (showAfterLoad) setShowReplies(true);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        message.error("Unable to reach servers.");
      });
  };

  const commentForm = (sType, s) => (
    <div style={{ margin: "10px 0px" }}>
      <ProfileBoxItem user={auth.user} />
      <Input.TextArea
        style={{
          margin: "5px",
          marginLeft: "50px",
          width: "calc(100% - 50px)",
          borderRadius: "5px",
        }}
        placeholder={`Add your ${sType === "comment" ? "reply" : "comment"}`}
        onChange={(e) => setText(e.target.value)}
        value={text}
        autoSize
        onPressEnter={(e) =>
          e.ctrlKey && text.trim().length > 0 && addComment(sType, s)
        }
        onFocus={() => setTextAreaFocussed(true)}
        onBlur={() => setTextAreaFocussed(false)}
      />
      {text.length > 0 && (
        <div
          style={{
            marginTop: "-5px",
            padding: "5px 0px",
          }}
        >
          <Button
            // size="small"
            type="primary"
            style={{
              float: "right",
              borderRadius: "5px",
              // padding: "5px 10px",
            }}
            onClick={() => addComment(sType, s)}
          >
            Post
          </Button>
          <span
            style={{
              display: textAreaFocussed ? "inline-block" : "none",
              float: "right",
              fontSize: "12px",
              margin: "6px 10px",
            }}
          >
            Press Ctl/Cmd + ↵ to Post
          </span>
          <div style={{ height: "20px" }}></div>
        </div>
      )}
    </div>
  );

  const ellipsisMenu = (c) => (
    <Menu>
      <Menu.Item
        icon={<i className="fal fa-edit"></i>}
        onClick={() => {
          setEditMode(true);
        }}
      >
        &nbsp;&nbsp;Edit
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item danger onClick={() => commentDeleteButtonRef.click()}>
        <Popconfirm
          title="Are you sure to delete this comment?"
          onConfirm={() => deleteComment(c)}
          // icon={<i className="fal fa-times" style={{ color: "red" }}></i>}
          onCancel={() => {}}
          okText="Yes"
          cancelText="No"
        >
          <div ref={(el) => (commentDeleteButtonRef = el)}>
            <span>
              <i className="fas fa-trash"></i>
            </span>{" "}
            &nbsp;&nbsp; Delete
          </div>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  return (
    <div style={{ marginBottom: "15px", paddingBottom: "5px" }}>
      {comment && comment.temp ? (
        <>
          {" "}
          <div>
            <ProfileBoxItem user={comment.temp.commenter} />
            <Dropdown overlay={ellipsisMenu(comment)} trigger={["click"]}>
              <Button
                size="small"
                type="text"
                style={{
                  display:
                    comment.commenterId === auth.user.id
                      ? "inline-block"
                      : "none",
                  padding: "0px",
                  float: "right",
                  width: "30px",
                  color: theme.color,
                }}
                icon={<i className="fas fa-ellipsis-h"></i>}
              />
            </Dropdown>
            <div
              style={{
                dsiplay: "inline-block",
                float: "right",
                marginRight:
                  comment.commenterId !== auth.user.id ? "40px" : "10px",
              }}
            >
              {comment.info1 && comment.info1.isEdited ? "(edited)" : null}
              &nbsp;&nbsp;
              {moment(comment.createdAt).fromNow()}
            </div>
          </div>
          <div
            style={{
              margin: "2px",
              marginLeft: "50px",
              width: "calc(100% - 50px)",
              borderRadius: "5px",
              fontSize: "16px",
              // fontWeight: "500",
              color: theme.color,
              padding: "10px",
              paddingBottom: "4px",
              backgroundColor: theme.backgroundColor2,
            }}
          >
            {editMode ? (
              <div>
                {" "}
                <Input.TextArea
                  // defaultValue={comment.content}
                  value={editText}
                  onChange={(e) => setEditText(e.target.value)}
                  onPressEnter={(e) => {
                    if (!e.ctrlKey) return;
                    if (text.trim().length > 0)
                      return message.warn("Empty comment cannot be posted.");
                    else editComment();
                  }}
                />
                <div style={{ textAlign: "right" }}>
                  <Button
                    size="small"
                    icon={<i className="fal fa-times"></i>}
                    style={{ margin: "0px 5px" }}
                    onClick={() => setEditMode(false)}
                  >
                    &nbsp;Cancel
                  </Button>
                  <Button
                    onClick={() => editComment()}
                    type="primary"
                    size="small"
                    icon={<i className="fal fa-check"></i>}
                  >
                    &nbsp;Save
                  </Button>
                </div>
              </div>
            ) : (
              comment.content
            )}
            <div
              style={{
                marginTop: "15px",
                padding: "0 0px",
                fontSize: "14px",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  // border: "1px solid red",
                  // borderRight: `1px solid ${theme.borderColor}`,
                }}
              >
                <Button
                  size="small"
                  type="text"
                  onClick={() => {
                    sendLike(comment);
                    setCommentLikesCount(
                      hasLikedComment
                        ? commentLikesCount - 1
                        : commentLikesCount + 1
                    );
                    setHasLikedComment(!hasLikedComment);
                  }}
                  style={{
                    color: hasLikedComment ? theme.primaryColor : theme.color,
                    fontWeight: "500",
                    width: "60px",
                    // border: "1px solid red",
                    padding: "0px",
                  }}
                  icon={
                    hasLikedComment ? (
                      <i className="fas fa-thumbs-up"></i>
                    ) : (
                      <i className="fal fa-thumbs-up"></i>
                    )
                  }
                >
                  &nbsp;{hasLikedComment ? "Liked" : "Like"}
                </Button>
                <Button
                  style={{
                    padding: "0px",
                    color: theme.color,
                    // fontSize: "14px",
                    marginLeft: "5px",
                    fontWeight: "500",
                  }}
                  type="link"
                >
                  {commentLikesCount === 0 ? (
                    <span>{/* Be the first one to like */}</span>
                  ) : commentLikesCount === 1 ? (
                    "1 like"
                  ) : (
                    `${commentLikesCount} likes`
                  )}{" "}
                </Button>
              </div>
              {/* <div
                style={{
                  border: "1px solid red",
                  width: "20px",
                  display: "inline-block",
                }}
              ></div> */}
              <div
                style={{
                  // borderBottom: `1px solid ${theme.borderColor1}`
                  display: "inline-block",
                  // border: "1px solid red",
                  float: "right",
                }}
              >
                &nbsp;
                <Button
                  style={{
                    padding: "0px",
                    color: theme.color,
                    fontWeight: "500",
                  }}
                  type="link"
                  onClick={() => {
                    getReplies(true);
                  }}
                >
                  {commentRepliesCount === 0 ? (
                    <span> </span>
                  ) : commentRepliesCount === 1 ? (
                    "1 reply"
                  ) : (
                    `${commentRepliesCount} replies`
                  )}{" "}
                </Button>
                <Button
                  style={{
                    color: theme.color,
                    float: "right",
                    width: "60px",
                    fontWeight: "500",
                  }}
                  type="link"
                  onClick={() => {
                    setShowReplies(true);
                    setShowAddReply(true);
                  }}
                >
                  Reply
                </Button>
              </div>
            </div>
            <div style={{}}>
              <div
                style={{
                  display: showReplies ? "block" : "none",
                  padding: "10px",
                }}
              >
                <Button
                  type="text"
                  size="small"
                  style={{
                    display: "block",
                    padding: "0px",
                    marginBottom: replies.length > 0 ? "10px" : "0px",
                  }}
                  onClick={() => {
                    setShowReplies(false);
                    setReplies([]);
                    setOffset(0);
                  }}
                  className="comments-text-button"
                >
                  {replies.length > 0 ? "Hide replies" : "Hide"}
                </Button>

                {replies.map((reply) => (
                  <ReplyItem
                    key={reply.id}
                    comment={reply}
                    sendLike={() => sendLike(reply)}
                    editComment={(sType, r, text) =>
                      editComment(sType, r, text)
                    }
                    deleteComment={(s, c) => deleteComment("comment", c)}
                  />
                ))}
                {replies.length < commentRepliesCount && (
                  <Button
                    type="text"
                    onClick={() => getReplies(true)}
                    style={{ padding: "0px" }}
                    className="comments-text-button"
                  >
                    {replies.length === 0
                      ? "Load replies"
                      : "Load more replies"}
                  </Button>
                )}
                {showAddReply || replies.length > 0
                  ? commentForm("comment", comment)
                  : null}
              </div>
            </div>
          </div>
        </>
      ) : (
        commentForm()
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CommentItem);
