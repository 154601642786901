import React, { Component } from "react";
import axios from "axios";
import Loader from "../common/Loader";

export class VerifyEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: false,
      message: null,
    };
  }
  componentDidMount() {
    this.setState({ loading: true });
    if (this.props.match.params.token) {
      axios
        .post(
          `${process.env.REACT_APP_API_HOST}/api/users/verify-email/${this.props.match.params.token}`
        )
        .then((res) => {
          if (res.data.success && res.data.emailChange) {
            this.setState({
              loading: false,
              success: true,
              message: res.data.message,
            });
          } else if (!res.data.success) {
            this.setState({
              loading: false,
              success: false,
              message: res.data.message,
            });
          } else {
            this.setState({
              loading: false,
              success: false,
              message: "Invalid token.",
            });
          }
        });
    }
  }
  render() {
    const { loading, success, message } = this.state;
    const content = loading ? (
      <Loader />
    ) : (
      <div className="register-container">
        <div style={success ? { color: "lightgreen" } : { color: "red" }}>
          <h2>{message}</h2>
        </div>
      </div>
    );
    return content;
  }
}

export default VerifyEmail;
