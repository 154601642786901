import React, { useState } from "react";
import { createPopper } from "@popperjs/core";
import "./selectionToolbar.scss";
import { Popover } from "antd";
import { SketchPicker } from "react-color";
import { SaveSelection, RestoreSelection } from "../utils";

export default function SelectionToolbar({ newSelection }) {
  const [selection, setSelection] = useState(null);
  const [link, setLink] = useState(null);

  const styles = {
    buttonGroup: {
      display: "inline-block",
      borderRight: "1px solid #d1d1d1",
      padding: "0 2px",
    },
  };

  // console.log(window.getSelection());

  const linkView = (
    <>
      <div style={{ backgroundColor: "#fff", margin: "-5px" }}>
        <input
          className="input is-medium"
          type="text"
          placeholder="Type url and press enter"
          defaultValue={link}
          onChange={(e) =>
            e.target.value.length > 0
              ? e.target.value.indexOf("http://") === 0 ||
                e.target.value.indexOf("https://") === 0
                ? setLink(e.target.value)
                : setLink(`https://${e.target.value}`)
              : setLink("")
          }
          onFocus={(e) => {
            e.preventDefault();
            setSelection(SaveSelection());
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              RestoreSelection(selection);
              if (link && link.length > 0) {
                document.execCommand("createLink", false, link);
              } else document.execCommand("unlink", false, null);
            }
          }}
        ></input>{" "}
        {/* <button className="button is-white">
          <i className="far fa-check"></i>
        </button> */}
      </div>
    </>
  );

  const defaultView = (
    <>
      <div style={styles.buttonGroup}>
        <Popover
          content={linkView}
          //   visible={linkVisible}
          trigger={["click"]}
          overlayStyle={{
            padding: "0px",
          }}
          overlayInnerStyle={{}}
          destroyTooltipOnHide
        >
          <button
            className={`button   ${
              document.queryCommandState("createLink")
                ? `is-info is-light`
                : `is-white`
            } is-medium`}
            onMouseDown={(e) => {
              e.preventDefault();
              // Sets original link
              if (window.getSelection() && window.getSelection().anchorNode) {
                setLink(window.getSelection().anchorNode.parentNode.href);
              }
            }}
          >
            <i className="far fa-link"></i>
          </button>
        </Popover>
      </div>
      <div style={styles.buttonGroup}>
        <button
          className={`button ${
            document.queryCommandState("bold") ? `is-info is-light` : `is-white`
          } is-medium`}
          onMouseDown={(e) => {
            e.preventDefault();
            document.execCommand("bold", false);
          }}
        >
          <i className="far fa-bold"></i>
        </button>
        <button
          className={`button   ${
            document.queryCommandState("italic")
              ? `is-info is-light`
              : `is-white`
          } is-medium`}
          onMouseDown={(e) => {
            e.preventDefault();
            document.execCommand("italic", false);
          }}
        >
          <i className="far fa-italic"></i>
        </button>
        <button
          className={`button   ${
            document.queryCommandState("underline")
              ? `is-info is-light`
              : `is-white`
          } is-medium`}
          onMouseDown={(e) => {
            e.preventDefault();
            document.execCommand("underline", false);
          }}
        >
          <i className="far fa-underline"></i>
        </button>
      </div>
      <div style={styles.buttonGroup}>
        <Popover
          placement="right"
          content={
            <div style={{ margin: "-12px -15px" }}>
              <SketchPicker
                color={document.queryCommandValue("foreColor")}
                onChange={(color) => {
                  RestoreSelection(selection);
                  let cell = window.getSelection().anchorNode.parentElement;
                  if (
                    cell.tagName === "TD" ||
                    cell.tagName === "TH" ||
                    cell.parentElement.tagName === "TD" ||
                    cell.parentElement.tagName === "TH"
                  ) {
                    if (!cell.tagName === "TD" && !cell.tagName === "TH")
                      cell = cell.parentElement;

                    cell.style.color = color.hex;
                  } else if (
                    cell.parentElement.classList &&
                    cell.parentElement.classList.value.includes("tag ")
                  ) {
                    cell.parentElement.style.color = color.hex;
                  } else {
                    document.execCommand("styleWithCss", false, true);
                    document.execCommand("foreColor", false, color.hex);
                  }
                }}
              />
            </div>
          }
          //   visible={linkVisible}
          trigger={["click"]}
          style={{
            padding: "0px",
          }}
        >
          <button
            className="button is-white is-medium"
            id="selection-toolbar-text-color-btn"
            style={{
              border: `2px solid rgba(0,0,0,0)`,
              // boxSizing: "border-box",
              // color: document.queryCommandValue("foreColor"),
            }}
            onMouseDown={(e) => {
              e.preventDefault();
              setSelection(SaveSelection());
            }}
          >
            <i className="far fa-font"></i>
          </button>
        </Popover>
        <Popover
          placement="right"
          content={
            <div style={{ margin: "-12px -15px" }}>
              <SketchPicker
                color={document.queryCommandValue("backColor")}
                onChange={(color) => {
                  RestoreSelection(selection);
                  let cell = window.getSelection().anchorNode.parentElement;
                  if (
                    cell.tagName === "TD" ||
                    cell.tagName === "TH" ||
                    cell.parentElement.tagName === "TD" ||
                    cell.parentElement.tagName === "TH"
                  ) {
                    if (!cell.tagName === "TD" && !cell.tagName === "TH")
                      cell = cell.parentElement;

                    cell.style.backgroundColor = color.hex;
                  } else if (
                    cell.parentElement.classList &&
                    cell.parentElement.classList.value.includes("tag ")
                  ) {
                    cell.parentElement.style.backgroundColor = color.hex;
                  } else {
                    document.execCommand("styleWithCss", false, true);
                    document.execCommand("backColor", false, color.hex);
                  }
                }}
              />
            </div>
          }
          //   visible={linkVisible}
          trigger={["click"]}
          style={{
            padding: "0px",
          }}
        >
          <button
            className="button is-white is-medium"
            id="selection-toolbar-highlight-btn"
            style={
              {
                // border: `2px solid ${document.queryCommandValue("backColor")}`,
                // border: `2px solid ${
                //   window.getSelection().focusNode.parentElement &&
                //   window.getSelection().focusNode.parentElement.style
                //     .backgroundColor
                // }`,
              }
            }
            onMouseDown={(e) => {
              e.preventDefault();
              setSelection(SaveSelection());
            }}
          >
            <i className="far fa-highlighter"></i>
          </button>
        </Popover>
      </div>
      <div style={styles.buttonGroup}>
        <Popover
          content={
            <div style={{ margin: "-10px -12px" }}>
              <button
                className="button is-white is-medium"
                onClick={() => document.execCommand("justifyLeft", false)}
              >
                {" "}
                <i className="far fa-align-left"></i>
              </button>
              <button
                className="button is-white is-medium"
                onClick={() => document.execCommand("justifyCenter", false)}
              >
                {" "}
                <i className="far fa-align-center"></i>
              </button>
              <button
                className="button is-white is-medium"
                onClick={() => document.execCommand("justifyRight", false)}
              >
                {" "}
                <i className="far fa-align-right"></i>
              </button>
              <button
                className="button is-white is-medium"
                onClick={() => document.execCommand("justifyFull", false)}
              >
                {" "}
                <i className="far fa-align-justify"></i>
              </button>
            </div>
          }
          //   visible={linkVisible}
          trigger={["click"]}
          overlayStyle={{
            padding: "0px",
          }}
          overlayInnerStyle={{}}
          destroyTooltipOnHide
        >
          <button className="button is-white is-medium">
            <i
              className={`far fa-align-${
                document.queryCommandState("justifyRight")
                  ? `right`
                  : document.queryCommandState("justifyCenter")
                  ? `center`
                  : document.queryCommandState("justifyFull")
                  ? `justify`
                  : `left`
              }`}
            ></i>
          </button>
        </Popover>
      </div>
      <div style={{ ...styles.buttonGroup, borderRight: "none" }}>
        <Popover
          content={
            <div style={{ margin: "-10px -12px" }}>
              <div style={styles.buttonGroup}>
                <button
                  className={`button   ${
                    document.queryCommandValue("formatBlock", false, "h1") ===
                    "h1"
                      ? `is-info is-light`
                      : `is-white`
                  } is-medium`}
                  onClick={() =>
                    document.execCommand("formatBlock", false, "h1")
                  }
                >
                  {" "}
                  <i className="far fa-h1"></i>
                </button>
                <button
                  className={`button   ${
                    document.queryCommandValue("formatBlock", false, "h2") ===
                    "h2"
                      ? `is-info is-light`
                      : `is-white`
                  } is-medium`}
                  onClick={() =>
                    document.execCommand("formatBlock", false, "h2")
                  }
                >
                  {" "}
                  <i className="far fa-h2"></i>
                </button>
                <button
                  className={`button   ${
                    document.queryCommandValue("formatBlock", false, "h3") ===
                    "h3"
                      ? `is-info is-light`
                      : `is-white`
                  } is-medium`}
                  onClick={() =>
                    document.execCommand("formatBlock", false, "h3")
                  }
                >
                  {" "}
                  <i className="far fa-h3"></i>
                </button>
                <button
                  className={`button   ${
                    document.queryCommandValue("formatBlock", false, "h4") ===
                    "h4"
                      ? `is-info is-light`
                      : `is-white`
                  } is-medium`}
                  onClick={() =>
                    document.execCommand("formatBlock", false, "h4")
                  }
                >
                  {" "}
                  <i className="far fa-h4"></i>
                </button>
                <button
                  className={`button   ${
                    document.queryCommandValue("formatBlock", false, "p") ===
                    "p"
                      ? `is-info is-light`
                      : `is-white`
                  } is-medium`}
                  onClick={() =>
                    document.execCommand("formatBlock", false, "p")
                  }
                >
                  {" "}
                  <i className="far fa-text"></i>
                </button>
                <button
                  className={`button   ${
                    document.queryCommandValue(
                      "formatBlock",
                      false,
                      "blockquote"
                    ) === "blockquote"
                      ? `is-info is-light`
                      : `is-white`
                  } is-medium`}
                  onClick={() =>
                    document.execCommand("formatBlock", false, "blockquote")
                  }
                >
                  {" "}
                  <i className="far fa-quote-right"></i>
                </button>
              </div>
              <div style={styles.buttonGroup}>
                <button
                  className={`button   ${
                    document.queryCommandState("strikethrough")
                      ? `is-info is-light`
                      : `is-white`
                  } is-medium`}
                  onClick={() => document.execCommand("strikethrough", false)}
                >
                  {" "}
                  <i className="far fa-strikethrough"></i>
                </button>
                <button
                  className={`button   ${
                    document.queryCommandState("superscript")
                      ? `is-info is-light`
                      : `is-white`
                  } is-medium`}
                  onClick={() => document.execCommand("superscript", false)}
                >
                  {" "}
                  <i className="far fa-superscript"></i>
                </button>
                <button
                  className={`button   ${
                    document.queryCommandState("subscript")
                      ? `is-info is-light`
                      : `is-white`
                  } is-medium`}
                  onClick={() => document.execCommand("subscript", false)}
                >
                  {" "}
                  <i className="far fa-subscript"></i>
                </button>
              </div>
              <button
                className="button is-white is-medium"
                onClick={() => document.execCommand("indent", false)}
              >
                {" "}
                <i className="far fa-indent"></i>
              </button>
              <button
                className="button is-white is-medium"
                onClick={() => document.execCommand("outdent", false)}
              >
                {" "}
                <i className="far fa-outdent"></i>
              </button>
            </div>
          }
          //   visible={linkVisible}
          trigger={["click"]}
          overlayStyle={{
            padding: "0px",
          }}
          overlayInnerStyle={{}}
          destroyTooltipOnHide
        >
          <button className="button is-white is-medium">
            <i className={`far fa-ellipsis-h`}></i>
          </button>
        </Popover>
      </div>

      {/* </Space> */}
    </>
  );

  return (
    <div
      id="tooltip"
      role="tooltip"
      style={{
        border: "1px solid #d1d1d1",
        borderRadius: "5px",
        backgroundColor: "#fff",
        fontSize: "16px",
        padding: "2px",
        zIndex: "120",
      }}
    >
      {defaultView}
    </div>
  );
}

export function SelectionChange() {
  const selection = window.getSelection();
  //   console.log(selection);

  const tooltip = document.querySelector("#tooltip");

  const virtualElement = {
    getBoundingClientRect: () => ({
      width: 0,
      height: 0,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    }),
  };

  const popper = createPopper(virtualElement, tooltip, {
    placement: "top",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  });
  try {
    const rect = selection.getRangeAt(0).getBoundingClientRect();
    virtualElement.getBoundingClientRect = () => rect;
    popper.update();
    if (
      rect.width > 0 &&
      selection.toString().trim().length > 0
      // && selection.anchorNode === selection.focusNode
    ) {
      tooltip.classList.add("tooltip-visible");
      document.getElementById(
        "selection-toolbar-highlight-btn"
      ).style.backgroundColor =
        selection.focusNode.parentElement.style.backgroundColor;
      document.getElementById(
        "selection-toolbar-text-color-btn"
      ).style.borderColor = selection.focusNode.parentElement.style.color;
      // SelectionToolbar({ newSelection: selection });
    } else {
      tooltip.classList.remove("tooltip-visible");
    }
  } catch (err) {
    return;
  }
}
