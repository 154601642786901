import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { message, Button, notification, Result, Tabs, Tooltip } from "antd";
import SeeAllNotes from "../../explore/SeeAllNotes";
import SeeAllNotebooks from "../../explore/SeeAllNotebooks";

const { TabPane } = Tabs;

export class Hashtag extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hashtag: this.props.hashtag,
      hashtagObject: null,
      notes: [],
      offset: 0,
      limit: 30,
      sortBy: "createdAt",
      sortOrder: "DESC",
      totalCount: 0,
      notFound: false,
    };
  }
  componentDidMount() {
    if (!this.state.hashtag)
      return message.error("Some error occured. Please reload this page.");
    this.getHashtagDetails();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.reloadHashtagInfo) this.getHashtagDetails();
    if (this.props.reloadNote) {
      this.setState({
        notes: this.state.notes.map((note) =>
          note.id === this.props.reloadNote.id ? this.props.reloadNote : note
        ),
      });
    }
  }

  getHashtagDetails() {
    axios
      .get(
        `${process.env.REACT_APP_API_HOST}/api/hashtags/${this.state.hashtag}`
      )
      .then((res) => {
        // console.log(res.data);
        if (res.data.success) {
          this.setState({
            hashtagObject: res.data.hashtag,
          });
        } else if (res.data.notFound) return this.setState({ notFound: true });
        else message.error(res.data.message);
      });
  }

  followHashtag() {
    axios
      .post(
        `${process.env.REACT_APP_API_HOST}/api/hashtags/follow/${this.state.hashtag}`
      )
      .then((res) => {
        // console.log(res.data);
        if (res.data.success) {
          this.getHashtagDetails();
          notification["success"]({
            message: "Success",
            description: res.data.message,
            placement: "bottomRight",
          });
        } else message.error(res.data.message);
      });
  }

  render() {
    const { theme, userId, isSmallDevice } = this.props;
    const { hashtagObject, notFound } = this.state;

    const content = notFound ? (
      <div
        style={{
          padding: "100px 0px",
          backgroundColor: theme.backgroundColor,
          color: theme.color,
          marginTop: !this.props.userId && "-60px",
        }}
      >
        <Result
          status="404"
          title={
            <span style={{ color: theme.color, fontSize: "26px" }}>404</span>
          }
          subTitle={
            <span style={{ color: theme.color, fontSize: "22px" }}>
              Sorry, the page you visited does not exist.
            </span>
          }
          extra={
            <Link to={`/`} style={{ color: theme.color }}>
              <Button type="primary">Back Home</Button>
            </Link>
          }
        />
      </div>
    ) : (
      hashtagObject && (
        <>
          <div style={{ backgroundColor: theme.backgroundColor }}>
            <div
              className="hashtag-header"
              style={{
                padding: "10px 25px",
                borderBottom: "1px solid #d1d1d1",
                position: "relative",
              }}
            >
              <div
                style={{
                  fontSize: "60px",
                  fontWeight: "400",
                  textAlign: "center",
                  //borderBottom: "1px solid #d1d1d1",
                }}
              >
                {hashtagObject.title}
              </div>
              <div style={{ margin: "10px" }}>
                {hashtagObject && (
                  <div
                    style={{
                      display: "inline-block",
                      fontWeight: "500",
                      fontSize: "16px",
                      margin: "10px",
                      textAlign: "center",

                      // textAlign: "center",
                    }}
                  >
                    <div style={{ display: "inline-block", margin: "5px" }}>
                      <Tooltip mouseEnterDelay={2} title={"Books/Notebooks"}>
                        {hashtagObject.temp.notebooksCount}{" "}
                        <i className="far fa-book"></i>
                      </Tooltip>
                      {/* {hashtagObject.temp.notebooksCount === 1
                        ? "notebook"
                        : "notebooks"} */}
                    </div>
                    &bull;
                    <div style={{ display: "inline-block", margin: "5px" }}>
                      <Tooltip mouseEnterDelay={2} title={"Notes/Artices"}>
                        {hashtagObject.temp.notesCount}{" "}
                        <i className="far fa-newspaper"></i>
                      </Tooltip>
                      {/* {hashtagObject.temp.notesCount === 1 ? "note" : "notes"} */}
                    </div>
                  </div>
                )}
                <div
                  style={{
                    display: "inline-block",
                    float: "right",
                    fontWeight: "500",
                  }}
                >
                  <Button
                    type="primary"
                    ghost={hashtagObject.temp && hashtagObject.temp.userFollows}
                    icon={<i className="fas fa-plus"></i>}
                    onClick={() => this.props.followHashtag()}
                    style={{ width: "120px" }}
                  >
                    &nbsp;
                    {hashtagObject.temp && hashtagObject.temp.userFollows
                      ? "Following"
                      : "Follow"}
                  </Button>
                  <div style={{ marginTop: "5px", textAlign: "center" }}>
                    {hashtagObject.temp && hashtagObject.temp.followersCount}{" "}
                    {hashtagObject.temp &&
                    hashtagObject.temp.followersCount === 1
                      ? "follower"
                      : "followers"}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              margin: isSmallDevice ? "0 10px" : "20px auto",
              maxWidth: "1200px",
              // border: "1px solid red",
            }}
          >
            <Tabs
              defaultActiveKey={localStorage.explore_tab}
              onChange={(e) => localStorage.setItem("hashtag_tab", e)}
              centered
              // type="card"
              size={isSmallDevice ? "small" : "middle"}
              tabBarStyle={{ fontSize: "28px", color: theme.color }}
            >
              <TabPane
                tab={
                  <span style={{ fontSize: "16px" }}>
                    <i
                      style={{ fontWeight: "300" }}
                      className="far fa-newspaper"
                    ></i>
                    &nbsp; &nbsp;Articles & Notes
                  </span>
                }
                key="notes"
              >
                <SeeAllNotes
                  saveNote={(nId, nbId) => this.props.saveNote(nId, nbId)}
                  userId={userId}
                  theme={theme}
                  isSmallDevice={isSmallDevice}
                  userNotebooks={this.props.userNotebooks}
                  reloadNote={this.props.reloadNote}
                  useInfiniteScroll={false}
                  hashtag={this.props.hashtag}
                />
              </TabPane>
              <TabPane
                tab={
                  <span style={{ fontSize: "16px" }}>
                    <i
                      style={{ fontWeight: "300" }}
                      className="far fa-book"
                    ></i>
                    &nbsp; &nbsp;Books & Notebooks
                  </span>
                }
                key="notebooks"
              >
                <SeeAllNotebooks
                  saveNotebook={(nbId) => this.props.saveNotebook(nbId)}
                  userId={userId}
                  theme={theme}
                  isSmallDevice={isSmallDevice}
                  useInfiniteScroll={false}
                  hashtag={this.props.hashtag}
                  reloadNotebook={this.props.reloadNotebook}
                />
              </TabPane>
            </Tabs>
          </div>
        </>
      )
    );

    return (
      <div
        className="hashtag-container"
        style={{
          backgroundColor: theme.backgroundColor,
          color: theme.color,
          padding: "25px 0px",
          height: "100%",
          overflow: "auto",
        }}
      >
        {content}
      </div>
    );
  }
}

export default Hashtag;
