import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

import {
  createStateSyncMiddleware,
  initMessageListener,
} from "redux-state-sync";

const initialState = {};

const config = {
  // All actions will be triggered in other tabs except 'CLEAR_POSTS', 'GET_POSTS', 'GET_POST'
  //blacklist: ["CLEAR_POSTS", "GET_POSTS", "GET_POST"],
  whitelist: ["SET_CURRENT_USER", "CHANGE_THEME"],
  //predicate: (action) => action.type !== "CLEAR_POSTS",
};
const middleware = [thunk, createStateSyncMiddleware(config)];

let composeEnhancers;
if (process.env.NODE_ENV === "development")
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
else composeEnhancers = compose;

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
);

// initStateWithPrevTab(store);
initMessageListener(store);

export default store;
