module.exports = {
  dark: {
    backgroundColor: "#121212", //rgba(18,18,18,1)
    // backgroundColor: "#001529",
    // backgroundColor2: "rgba(30,30,30)",
    backgroundColor2: "#1e1e1e",
    backgroundColor3: "rgba(50,50,50)",
    color: "rgba(256,256,256,0.75)",
    color1: "#dddddd",
    hoverColor1: "#222",
    // hoverColor1: "#002649",
    borderColor1: "#454545",
    collapseColor: "#353535",
    primaryColor: "#3d8dd9",
    // primaryColor: "#5d90c0",
    primaryColor2: "#5d90c0",
  },
  light: {
    backgroundColor: "#ffffff",
    backgroundColor2: "#ededed",
    backgroundColor3: "rgba(225,225,225)",
    color: "#444",
    color2: "#dddddd",
    // hoverColor1: "#f1f1f1",
    hoverColor1: "aliceblue",
    borderColor1: "#d2d2d2",
    collapseColor: "rgba(245,245,245,0.8)",
    primaryColor: "#3d8dd9",
    // primaryColor: "#5d90c0",
    primaryColor2: "#5d90c0",
  },
};
