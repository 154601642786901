import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import propTypes from "prop-types";
import axios from "axios";

import {
  logoutUser,
  changeTheme,
  setConnectionError,
  setDevice,
  toggleMobileSidebar,
} from "../../actions/authActions";
import {
  Button,
  Menu,
  Dropdown,
  Avatar,
  Switch,
  Popover,
  Space,
  message,
  Badge,
  Tooltip,
} from "antd";
import { SyncOutlined } from "@ant-design/icons";
import logo from "../../assets/logo-light.png";
import "./styles/navbar.less";
import Search from "../search/Search";
import moment from "moment";

export class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      avatarMenuVisible: false,
      showConnectionAlert: false,
      showMobileSearch: false,
      guestMobilePopoverVisible: false,
      transparent: false,
      notifications: [],
      unreadNotificationsCount: 0,
      notificationOffset: 0,
      notificationLimit: 50,
      loadingNotifications: false,
      totalNotificationsCount: 0,
    };
  }

  handleScroll = () => {
    if (window.scrollY > 40) {
      this.setState({ transparent: false });
    } else {
      this.setState({ transparent: true });
    }
  };

  componentDidMount = () => {
    // Get notifications
    this.getNotifications();

    // Scroll Event for transparent bar
    if (!this.props.auth.isAuthenticated) {
      this.setState({ transparent: true });
      window.addEventListener("scroll", this.handleScroll);
    }

    setInterval(() => this.checkConnection(), 1000);
    window
      .matchMedia("(min-width: 900px)")
      .addEventListener("change", () => this.props.setDevice());

    let hideAvatarMenu = () => {
      this.setState({ avatarMenuVisible: false });
    };
    let hideSearch = () => this.setState({ showMobileSearch: false });

    let hideMobileSidebar = () =>
      this.props.auth.mobileSidebarVisible && this.props.toggleMobileSidebar();

    window.addEventListener("click", (e) => {
      if (
        document.getElementById("nav-avatar-menu") &&
        !document.getElementById("nav-avatar-menu").contains(e.target)
      ) {
        hideAvatarMenu();
      }
      if (
        document.getElementById("nav-container-auth-id") &&
        !document.getElementById("nav-container-auth-id").contains(e.target)
      ) {
        hideSearch();
      }
      if (
        document.getElementById("sidebar-container-id") &&
        !document.getElementById("sidebar-container-id").contains(e.target)
      ) {
        hideMobileSidebar();
      }
    });

    // console.log(document.getElementsByClassName("nav-container-auth")[0]);
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.searchQuery !== this.state.searchQuery &&
      this.state.searchQuery.length > 0
    )
      this.search();

    if (!prevProps.auth.isAuthenticated && this.props.auth.isAuthenticated)
      this.setState({ transparent: false });
    if (prevProps.auth.isAuthenticated && !this.props.auth.isAuthenticated) {
      this.setState({ transparent: true });
      window.addEventListener("scroll", this.handleScroll);
    }
  }

  checkConnection() {
    if (!window.navigator.onLine) {
      this.props.setConnectionError(true);
      this.setState({ showConnectionAlert: true });
    } else if (this.props.auth.isDisconnected) {
      this.props.setConnectionError(false);
      setTimeout(() => this.setState({ showConnectionAlert: false }), 1500);
    } else return;
  }

  getNotifications(
    offset = this.state.notificationOffset,
    limit = this.state.notificationLimit
  ) {
    if (!this.props.auth.isAuthenticated) return;
    this.setState({ loadingNotifications: true });
    axios
      .get(`${process.env.REACT_APP_API_HOST}/api/notifications`, {
        params: {
          offset,
          limit,
        },
      })
      .then((res) => {
        // console.log(res.data);
        if (res.data.success) {
          this.setState({
            notifications: [
              ...this.state.notifications,
              ...res.data.notifications,
            ],
            notificationOffset:
              this.state.notifications.length + res.data.notifications.length,
            unreadNotificationsCount: res.data.unreadNotificationsCount,
            loadingNotifications: false,
            totalNotificationsCount: res.data.totalNotificationsCount,
          });
        } else message.error("Some error occured while loading notifications.");
      })
      .catch((err) => message.error("Couldn't load notifications."));
  }

  markNotificationsSeen(
    notifications = this.state.notifications.map((n) => n.id)
  ) {
    if (notifications.length === 0) return;
    axios
      .post(`${process.env.REACT_APP_API_HOST}/api/notifications/seen`, {
        notifications,
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({
            unreadNotificationsCount: 0,
          });
        }
      });
  }

  deleteNotifications;

  render() {
    const { user, theme, isDisconnected, isSmallDevice } = this.props.auth;
    const {
      showConnectionAlert,
      showMobileSearch,
      avatarMenuVisible,
      unreadNotificationsCount,
      notifications,
      loadingNotifications,
      totalNotificationsCount,
    } = this.state;

    const avatarMenu = (
      <div
        className="nav-avatarmenu-container"
        id="nav-avatar-menu"
        style={{
          border: "1px solid #d2d2d2",
          width: "200px",
          boxShadow: "0px 0px 25px -15px rgba(0, 0, 0, 0.65)",
          WebkitBoxShadow: "0px 0px 25px -15px rgba(0, 0, 0, 0.65)",
          MozBoxShadow: "0px 0px 25px -15px rgba(0, 0, 0, 0.65)",
        }}
      >
        {user && (
          <div
            style={{
              padding: "10px 25px",
              fontWeight: "500",
              backgroundColor: theme.backgroundColor,
              color: theme.color,
              borderBottom: "1px solid #d2d2d2",
            }}
          >
            <Link style={{ color: theme.color }} to={`/${user.username}`}>
              <div>{user.name}</div>
              <div>@{user.username}</div>
            </Link>
          </div>
        )}
        <Menu theme={theme.mode} style={{ width: "100%", textAlign: "left" }}>
          <Menu.Item icon={<i className="far fa-adjust"></i>}>
            &nbsp;Appearence
            <Switch
              style={{ float: "right", marginTop: "10px" }}
              checked={theme.mode === "light"}
              checkedChildren={<i className="fas fa-sun"></i>}
              unCheckedChildren={<i className="fas fa-moon"></i>}
              //size="small"
              onChange={(e) => this.props.changeTheme()}
            />
          </Menu.Item>
          <Menu.Item
            icon={<i className="far fa-user-cog"></i>}
            onClick={() => this.setState({ avatarMenuVisible: false })}
          >
            <Link to={"/account-settings"} style={{ color: theme.color }}>
              &nbsp;Account Settings
            </Link>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            icon={<i className="far fa-sign-out-alt"></i>}
            onClick={() => {
              this.setState({ avatarMenuVisible: false });
              this.props.logoutUser();
            }}
          >
            &nbsp;Logout
          </Menu.Item>
        </Menu>
      </div>
    );
    const guestLinks = (
      <>
        <div className="nav-menu" style={isSmallDevice ? {} : {}}>
          <Space
            size={50}
            style={
              isSmallDevice
                ? {
                    margin: "10px auto",
                    textAlign: "center",
                    width: "60%",
                  }
                : {}
            }
          >
            <Link to={"/explore"}>
              <div
                style={{
                  fontWeight: "500",
                  // color: theme.primaryColor
                }}
              >
                Explore
              </div>
            </Link>
            <Link to={`/solutions`}>
              {" "}
              <div
                style={{
                  fontWeight: "500",
                  // color: theme.primaryColor
                }}
              >
                Solutions
              </div>
            </Link>
            <Link to={`/about`}>
              {" "}
              <div
                style={{
                  fontWeight: "500",
                  // color: theme.primaryColor
                }}
              >
                About
              </div>
            </Link>
          </Space>
        </div>
        <div
          className="nav-actions"
          style={isSmallDevice ? { textAlign: "center" } : {}}
        >
          <Button
            size="large"
            type="primary"
            style={
              isSmallDevice
                ? {}
                : {
                    float: "right",
                    padding: "0 35px",
                  }
            }
          >
            <Link to={"/join"}>Join For Free</Link>
            {/* &nbsp;<i className="fas fa-arrow-right"></i>*/}
          </Button>
          <Button
            size="large"
            type="link"
            style={
              isSmallDevice
                ? {}
                : {
                    float: "right",
                    padding: "0 35px",
                  }
            }
          >
            <Link to={"/login"}>Sign In</Link>
            {/* &nbsp;<i className="fas fa-arrow-right"></i>*/}
          </Button>
        </div>
      </>
    );

    const authContent = (
      <>
        {isSmallDevice && (
          <div
            style={{
              fontSize: "26px",
              justifySelf: "center",
              alignSelf: "center",
            }}
          >
            <Button
              icon={<i className="fas fa-bars"></i>}
              style={{ color: theme.color }}
              type="link"
              size="large"
              onClick={(e) => {
                e.nativeEvent.stopImmediatePropagation();
                this.props.toggleMobileSidebar();
              }}
            />
          </div>
        )}
        <div
          style={{
            justifySelf: isSmallDevice && "center",
            display: isSmallDevice
              ? showMobileSearch
                ? "none"
                : "block"
              : "block",
          }}
        >
          <Link
            to={`/`}
            className="nav-logo-auth"
            style={{
              fontSize: "1.8rem",
              backgroundColor: theme.backgroundColor,
              color: theme.color,
              fontFamily: "Helvetica, Arail, Sans-Serif",
            }}
          >
            <img
              src={logo}
              alt="Notebuq"
              style={{
                height: "32px",
                marginLeft: "-16px",
                marginTop: "2px",
                filter: theme.mode === "dark" ? "brightness(140%)" : "none",
              }}
            />
            {/* &nbsp;Notebuq */}
          </Link>
        </div>
        {/* Search */}
        <div
          className="nav-search"
          style={{
            width: "100%",
            display: isSmallDevice
              ? showMobileSearch
                ? "block"
                : "none"
              : "block",
          }}
        >
          <div
            style={{
              borderLeft: `2px solid ${theme.borderColor1}`,
              // marginLeft: "50px",
              margin: isSmallDevice ? "0" : "0 auto",
              width: "min(100%, 450px)",
            }}
          >
            <Search />
          </div>
        </div>
        <div style={{ justifySelf: "center" }}>
          {isSmallDevice ? (
            <div
              style={{
                fontSize: "26px",
                justifySelf: "center",
                alignSelf: "center",
              }}
            >
              <Button
                icon={<i className="fas fa-search"></i>}
                style={{ color: theme.color }}
                type="link"
                size="large"
                onClick={() =>
                  this.setState({
                    showMobileSearch: !this.state.showMobileSearch,
                  })
                }
              />
            </div>
          ) : (
            <>
              <Popover
                trigger="click"
                placement="bottomRight"
                overlayInnerStyle={{
                  backgroundColor: theme.backgroundColor,
                  border: `1px solid ${theme.borderColor1}`,
                  // borderTop: "none",
                  borderRadius: "5px",
                  overflow: "hidden",
                }}
                arrowContent={null}
                overlayStyle={{}}
                title={
                  <div
                    style={{
                      borderBottom: `1px solid ${theme.borderColor1}`,
                      margin: "0px -16px",
                      padding: "0px 10px",
                      color: theme.color,
                      backgroundColor: theme.backgroundColor,
                    }}
                  >
                    Notifications
                    <Tooltip
                      title={"Refresh notifications"}
                      mouseEnterDelay={3}
                    >
                      <Button
                        size="small"
                        type="link"
                        style={{
                          color: theme.color,
                          marginLeft: "10px",
                          float: "right",
                          fontWeight: "500",
                          paddingTop: "0px",
                        }}
                        icon={<SyncOutlined spin={loadingNotifications} />}
                        onClick={() =>
                          this.setState(
                            { notificationOffset: 0, notifications: [] },
                            () => this.getNotifications()
                          )
                        }
                      />
                    </Tooltip>
                    {/* <span style={{ float: "right" }}>
                      <Button
                        size="small"
                        type="link"
                        style={{ color: theme.color }}
                        icon={<i className="fal fa-trash-alt"></i>}
                        onClick={() => this.deleteNotifications()}
                      />
                    </span> */}
                  </div>
                }
                content={
                  <div
                    style={{
                      width: "400px",
                      minHeight: "80px",
                      maxHeight: "500px",
                      overflow: "hidden",
                      overflowY: "auto",
                      margin: "-15px -16px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        overflow: "auto",
                        boxSizing: "content-box",
                        // overflowY: "auto",
                        // overflowX: "hidden",
                      }}
                    >
                      {notifications.length === 0 ? (
                        <div
                          style={{ textAlign: "center", paddingTop: "30px" }}
                        >
                          {" "}
                          <h4>No notifications to display.</h4>
                        </div>
                      ) : (
                        notifications.map((notification) => (
                          <div key={notification.id}>
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 3fr",
                                padding: "6px 10px",
                                backgroundColor: notification.seen
                                  ? theme.backgroundColor
                                  : theme.mode === "dark"
                                  ? theme.backgroundColor2
                                  : "aliceblue",
                                // margin: "0px -15px",
                                minHeight: "70px",
                                borderBottom: `1px solid ${theme.borderColor1}`,
                                boxSizing: "border-box",
                              }}
                            >
                              <div
                                style={{
                                  justifySelf: "center",
                                  alignSelf: "center",
                                }}
                              >
                                <Link to={notification.link}>
                                  {notification.media &&
                                  notification.media.imgUrl ? (
                                    <Avatar
                                      size={40}
                                      src={
                                        notification.media &&
                                        `https://${notification.media.imgUrl}`
                                      }
                                    />
                                  ) : (
                                    <span
                                      style={{
                                        color: theme.primaryColor,
                                        fontSize: "26px",
                                      }}
                                    >
                                      {[
                                        "noteLiked",
                                        "notebookLiked",
                                        "commentLiked",
                                      ].includes(notification.type) ? (
                                        <i className="fad fa-thumbs-up"></i>
                                      ) : notification.type === "gotComment" ? (
                                        <i className="fad fa-comments-alt"></i>
                                      ) : notification.type === "gotFollow" ? (
                                        <i className="fad fa-user-plus"></i>
                                      ) : null}
                                    </span>
                                  )}
                                </Link>
                              </div>
                              <div>
                                <Link
                                  to={notification.link}
                                  style={{
                                    color: theme.color,
                                  }}
                                >
                                  <div style={{ fontWeight: "500" }}>
                                    {notification.title}
                                  </div>
                                  <div style={{ fontSize: "14px" }}>
                                    {notification.description}
                                  </div>
                                </Link>
                              </div>
                              <div />
                              <div
                                style={{ textAlign: "right", color: "#676767" }}
                              >
                                {moment(notification.createdAt).fromNow()}
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                    {notifications.length < totalNotificationsCount && (
                      <div style={{ padding: "5px", textAlign: "center" }}>
                        <Button
                          type="text"
                          size="small"
                          style={{ fontWeight: "500" }}
                          onClick={() => this.getNotifications()}
                        >
                          Load more notifications
                        </Button>
                      </div>
                    )}
                  </div>
                }
              >
                <Button
                  type="link"
                  // size="large"
                  style={{
                    display: "inline-block",
                    marginLeft: "-20px",
                    marginRight: "20px",
                    marginTop: "6px",
                  }}
                  onClick={() => this.markNotificationsSeen()}
                >
                  <Badge size="small" count={unreadNotificationsCount}>
                    <i
                      style={{ fontSize: "20px", color: theme.color }}
                      className={"fal fa-bell"}
                    ></i>
                  </Badge>
                </Button>
              </Popover>
              <div style={{ display: "inline-block" }}>
                <Dropdown
                  overlay={avatarMenu}
                  trigger={["click"]}
                  visible={avatarMenuVisible}
                  arrow
                >
                  <Avatar
                    style={{ marginTop: "-10px" }}
                    onMouseUp={(e) => (e.cancelBubble = true)}
                    onClick={(e) => {
                      e.nativeEvent.stopImmediatePropagation();
                      e.cancelBubble = true;
                      this.setState({
                        avatarMenuVisible: !avatarMenuVisible,
                      });
                    }}
                    size={40}
                    src={
                      this.props.auth.isAuthenticated &&
                      `https://${this.props.auth.user.display_picture.downloadUrl}`
                    }
                  />
                </Dropdown>
              </div>
            </>
          )}
        </div>
      </>
    );
    return this.props.auth.isAuthenticated ? (
      <>
        <div
          className="nav-container-auth"
          id="nav-container-auth-id"
          style={{
            backgroundColor: theme.backgroundColor,
            color: theme.color,
            boxShadow:
              theme.mode === "light"
                ? "1px 0px 4px 1px rgba(0, 0, 0, 0.3)"
                : "1px 0px 4px 1px rgba(256, 256, 256, 0.7)",
            padding: isSmallDevice ? "0 50px" : "0 100px",
            position: "fixed",
            top: "0",
            width: "100%",
            zIndex: "110",
            display: "grid",
            gridTemplateRows: "1fr",
            gridTemplateColumns: "1fr 3fr 1fr",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "space-between",
            height: "50px",
          }}
        >
          {authContent}
        </div>
        {showConnectionAlert && (
          <div
            style={{
              width: "100%",
              zIndex: "109",
              height: "22px",

              textAlign: "center",
              fontWeight: "600",
              // position: "fixed",
              // top: "60px",
              // borderBottom: "1px solid #d1d1d1",
              backgroundColor: "red",
            }}
          >
            {isDisconnected ? (
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  backgroundColor: "beige",
                }}
              >
                {" "}
                Disconnected.{" "}
                <span style={{ fontWeight: "400" }}>You're offline.</span>
              </div>
            ) : (
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  backgroundColor: "green",
                  color: "white",
                }}
              >
                Back online
              </div>
            )}
          </div>
        )}
      </>
    ) : (
      <>
        <Popover
          content={<div style={{ height: "80px" }}>{guestLinks}</div>}
          visible={this.state.guestMobilePopoverVisible}
          placement="bottom"
        >
          <div
            className="nav-container-guest"
            style={
              isSmallDevice
                ? {
                    height: "50px",
                    boxShadow: this.state.transparent
                      ? "0px 0px 0px 0px rgba(0,0,0,0)"
                      : "1px 0px 4px 1px rgba(0,0,0,0.3)",
                    position: "fixed",
                    top: "0",
                    width: "100%",
                    zIndex: "110",
                    backgroundColor: this.state.transparent
                      ? "rgba(0,0,0,0)"
                      : "#fefefe",
                    display: "grid",
                    gridTemplateRows: "1fr",
                    gridTemplateColumns: "1fr 2fr 1fr",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }
                : {
                    height: "40px",
                    boxShadow: this.state.transparent
                      ? "0px 0px 0px 0px rgba(0,0,0,0)"
                      : "1px 0px 4px 1px rgba(0,0,0,0.3)",
                    position: "fixed",
                    top: "0",
                    width: "100%",
                    // padding: "0px 10px",
                    paddingLeft: "100px",
                    zIndex: "110",
                    backgroundColor: this.state.transparent
                      ? "rgba(0,0,0,0)"
                      : "#fefefe",
                    display: "grid",
                    gridTemplateRows: "1fr",
                    gridTemplateColumns: "1fr 2fr 2fr",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }
            }
          >
            {isSmallDevice && <div />}
            <div
              style={
                isSmallDevice
                  ? {
                      textAlign: "center",
                    }
                  : {}
              }
            >
              <Link className="nav-logo-guest" to={`/`}>
                <img
                  src={logo}
                  alt="Notebuq"
                  style={{
                    height: "32px",
                    marginLeft: "-16px",
                    // marginRight: "5px",
                  }}
                />
                {/* &nbsp;Notebuq */}
              </Link>
            </div>
            {isSmallDevice ? (
              <div style={{ justifySelf: "center" }}>
                <Button
                  onClick={() =>
                    this.setState({
                      guestMobilePopoverVisible:
                        !this.state.guestMobilePopoverVisible,
                    })
                  }
                  type="text"
                  icon={<i className="fas fa-bars"></i>}
                ></Button>
              </div>
            ) : (
              guestLinks
            )}
          </div>
        </Popover>
      </>
    );
  }
}

Navbar.propTypes = {
  auth: propTypes.object.isRequired,
  logoutUser: propTypes.func.isRequired,
  changeTheme: propTypes.func.isRequired,
  setConnectionError: propTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default withRouter(
  connect(mapStateToProps, {
    logoutUser,
    changeTheme,
    setConnectionError,
    setDevice,
    toggleMobileSidebar,
  })(Navbar)
);
