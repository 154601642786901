import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import propTypes from "prop-types";

export class Footer extends Component {
  render() {
    const { theme, isSmallDevice } = this.props.auth;
    return (
      <div>
        <div
          className="landing-footer"
          style={{
            // height: "300px",
            padding: "20px",
            // paddingBottom: "20px",
            backgroundColor: theme.primaryColor,
            display: "grid",
            gridTemplateColumns: isSmallDevice ? "1fr 1fr" : "1fr 1fr 1fr 3fr",
            gridGap: "20px",
            color: "#ededed",
          }}
        >
          <div className="landing-footer-column">
            <div className="landing-footer-column-heading">Product</div>
            <div className="landing-footer-column-list-container">
              <div className="landing-footer-column-listItem">
                <Link to={"/features"}>Features</Link>
              </div>
              <div className="landing-footer-column-listItem">
                <Link to={"/solutions"}>Use Cases</Link>
              </div>
              <div className="landing-footer-column-listItem">
                <a href={"mailto: feedback@notbuq.com"}>Feedback</a>
              </div>
            </div>
          </div>
          <div className="landing-footer-column">
            <div className="landing-footer-column-heading">Company</div>
            <div className="landing-footer-column-list-container">
              <div className="landing-footer-column-listItem">
                <Link to={"/about"}>About</Link>
              </div>
              <div className="landing-footer-column-listItem">
                <Link to={"/blog"}>Blog</Link>
              </div>
              <div className="landing-footer-column-listItem">
                <Link to={"/contact"}>Contact Us</Link>
              </div>
            </div>
          </div>
          <div className="landing-footer-column">
            <div className="landing-footer-column-heading">Legal</div>
            <div className="landing-footer-column-list-container">
              <div className="landing-footer-column-listItem">
                <Link to={`/pages/terms`}>Terms of Use</Link>
              </div>
              <div className="landing-footer-column-listItem">
                <Link to={`/pages/privacy-policy`}>Privacy</Link>
              </div>
            </div>
          </div>
          <div
            className="landing-footer-column"
            style={{ position: "relative" }}
          >
            <div style={{ display: "inline-block" }}>
              <div
                className="landing-footer-column-heading"
                style={{ fontWeight: "500" }}
              >
                {" "}
                Keep in touch
              </div>

              <div
                style={{
                  padding: "0px",
                  fontSize: "28px",
                  width: "60%",
                }}
              >
                <a href="https://instagram.com/notebuq_" target="blank">
                  <i
                    style={{ color: "#ededed" }}
                    className="fab fa-instagram"
                  ></i>
                </a>
              </div>
            </div>
            <span
              style={{
                position: "absolute",
                bottom: "0",
                right: "0",
              }}
            >
              &copy; Notebuq.com {new Date().getFullYear()}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

Footer.propTypes = {
  auth: propTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default withRouter(connect(mapStateToProps)(Footer));
