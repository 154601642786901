import React, { Component } from "react";
import { connect } from "react-redux";
import propTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { Button, Tabs, Empty, Avatar } from "antd";
import notFound from "../../../assets/notFound.png";

const { TabPane } = Tabs;

export class FeedLeftPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: this.props.auth && this.props.auth.user.id,
    };
  }

  componentDidMount() {
    // if (!this.state.userId)
    //   return message.error("Some problem occured. Please reload.");
    this.props.getFollowing();
  }

  componentDidUpdate(prevProps, prevState) {}

  render() {
    const { theme, isSmallDevice } = this.props;
    const { topics, people } = this.props;

    const content = (
      <Tabs
        centered
        tabBarStyle={{
          backgroundColor: theme.backgroundColor,
          color: theme.color,
          marginBottom: "0px",
          display: "flex",
        }}
        defaultActiveKey={topics && topics.length === 0 ? "people" : "topics"}
      >
        <TabPane
          tab={
            <span>
              <i className="fal fa-hashtag"></i>&nbsp;Topics
            </span>
          }
          key="topics"
        >
          {topics && topics.length > 0 ? (
            topics.map((topic) => {
              return (
                <Link
                  onMouseOver={() => this.setState({ hoverOn: topic.id })}
                  onMouseOut={() => this.setState({ hoverOn: null })}
                  // className="feedleftpanel-topic-item"
                  key={topic.id}
                  style={{
                    // height: "50px",
                    padding: "5px",
                    display: "block",
                    borderBottom: `1px solid ${
                      theme.mode === "dark" ? theme.borderColor1 : "#dedede"
                    }`,
                    backgroundColor:
                      this.state.hoverOn === topic.id
                        ? theme.hoverColor1
                        : theme.backgroundColor,
                  }}
                  onClick={() => {
                    if (isSmallDevice) this.props.closeLeftPanel();
                  }}
                  to={`/tag/${topic.title.substr(1, topic.title.length - 1)}`}
                >
                  <span
                    style={{
                      display: "inline-block",
                      padding: "0px 10px",
                      fontWeight: "500",
                      fontSize: "14px",
                      backgroundColor: "inherit",
                      color: theme.color,
                    }}
                  >
                    <div>{topic.title}</div>
                  </span>
                </Link>
              );
            })
          ) : (
            <Empty
              // image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              image={notFound}
              imageStyle={{
                height: 120,
                marginTop: "20px",
              }}
              description={
                <span style={{ fontSize: "14px", color: theme.color }}>
                  You do not follow any topic
                </span>
              }
            ></Empty>
          )}
        </TabPane>
        <TabPane
          tab={
            <span>
              <i className="fal fa-user-friends"></i>&nbsp;People
            </span>
          }
          key="poeple"
        >
          {people && people.length > 0 ? (
            people.map((person) => {
              return (
                <Link
                  onMouseOver={() => this.setState({ hoverOn: person.id })}
                  onMouseOut={() => this.setState({ hoverOn: null })}
                  key={person.id}
                  style={{
                    // backgroundColor: theme.backgroundColor,
                    color: theme.color,
                    display: "block",
                    // height: "50px",
                    padding: "1px 10px",
                    borderBottom: `1px solid ${
                      theme.mode === "dark" ? theme.borderColor1 : "#dedede"
                    }`,
                    backgroundColor:
                      this.state.hoverOn === person.id
                        ? theme.hoverColor1
                        : theme.backgroundColor,
                  }}
                  onClick={() => {
                    if (isSmallDevice) this.props.closeLeftPanel();
                  }}
                  to={`/${person.username}`}
                >
                  <div style={{ display: "inline-block" }}>
                    <Link to={`/${person.username}`}>
                      <Avatar
                        src={`https://${person.display_picture.downloadUrl}`}
                      />
                    </Link>
                  </div>
                  <div
                    style={{
                      display: "inline-block",
                      padding: "0px 10px",
                      fontWeight: "500",
                      fontSize: "14px",
                    }}
                  >
                    <Link
                      to={`/${person.username}`}
                      style={{ color: theme.color }}
                    >
                      {person.name}&nbsp; <span>(@{person.username})</span>
                    </Link>
                  </div>
                </Link>
              );
            })
          ) : (
            <Empty
              // image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              image={notFound}
              imageStyle={{
                height: 120,
                marginTop: "20px",
              }}
              description={
                <span style={{ fontSize: "14px", color: theme.color }}>
                  You do not follow any user
                </span>
              }
            ></Empty>
          )}
        </TabPane>
      </Tabs>
    );

    return (
      <div>
        {/* Header */}
        <div
          style={{
            color: theme.color,
            position: "absolute",
            top: "0",
            width: "100%",
            height: "40px",
            borderBottom: "2px solid #d1d1d1",
            fontSize: "20px",
            fontWeight: "500",
            fontFamily: "Roboto",
            padding: "5px 10px",
            // display: this.props.displayHeader ? "block" : "none",
          }}
        >
          Following&nbsp;
          {this.props.leftPanelWidth > 290 && (
            <Button
              size="small"
              type="primary"
              style={{ float: "right", marginRight: "35px", marginTop: "5px" }}
              onClick={() => this.props.expandedView()}
            >
              Feed
            </Button>
          )}
        </div>
        {content}
      </div>
    );
  }
}

FeedLeftPanel.propTypes = {
  auth: propTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default withRouter(connect(mapStateToProps)(FeedLeftPanel));
