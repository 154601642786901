import React from "react";
import EditableBlock from "./components/EditableBlock";
import "./editor.scss";

export default class AxeEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <EditableBlock {...this.props} />
      </div>
    );
  }
}

// AxeEditor.defaultProps = {
//   content: [{ component: "EditableBlock", data: "" }],
//   editable: false,
// };
