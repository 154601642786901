// export const GET_ERRORS = "GET_ERRORS";
// export const SET_CURRENT_USER = "SET_CURRENT_USER";
// export const GET_PROFILE = "GET_PROFILE";
// export const PROFILE_LOADING = "PROFILE_LOADING";
// export const PROFILE_NOT_FOUND = "PROFILE_NOT_FOUND";
// export const CLEAR_PROFILE = "CLEAR_PROFILE";
// export const CLEAR_PROFILES = "CLEAR_PROFILES";
// export const GET_PROFILES = "GET_PROFILES";
// export const POST_LOADING = "POST_LOADING";
// export const GET_POSTS = "GET_POSTS";
// export const CLEAR_POSTS = "CLEAR_POSTS";
// export const GET_POST = "GET_POST";
// export const ADD_POST = "ADD_POST";
// export const DELETE_POST = "DELETE_POST";
// export const LIKE_POST = "LIKE_POST";
// export const FETCH_FEED = "FETCH_FEED";
// export const EDIT_POST = "EDIT_POST";

module.exports = {
  GET_ERRORS: "GET_ERRORS",
  SET_CURRENT_USER: "SET_CURRENT_USER",
  CHANGE_THEME: "CHANGE_THEME",
  SET_CONNECTION_ERROR: "SET_CONNECTION_ERROR",
  SET_DEVICE: "SET_DEVICE",
  TOGGLE_MOBILE_SIDEBAR: "TOGGLE_MOBILE_SIDEBAR",
  GET_PROFILE: "GET_PROFILE",
  PROFILE_LOADING: "PROFILE_LOADING",
  PROFILE_NOT_FOUND: "PROFILE_NOT_FOUND",
  CLEAR_PROFILE: "CLEAR_PROFILE",
  CLEAR_PROFILES: "CLEAR_PROFILES",
  GET_PROFILES: "GET_PROFILES",
};
