import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import NotFound from "../common/NotFound";
import Loader from "../common/Loader";
import { Avatar, Space, Button, message, Modal } from "antd";
import NoteviewUnauth from "./NoteviewUnauth";
import NotebookView from "../dashboard/Notebookview";
import Login from "../layout/Login";
import Profile from "../dashboard/Profile";
import Hashtag from "../dashboard/Feed/Hashtag";
import Explore from "../explore/Explore";

import "./main.css";

export class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      n: 0,
      note: null,
      notebook: null,
      loading: false,
      notFound: false,
      authModalVisible: false,
    };
  }
  componentDidMount() {
    // This is to invoke componentDidUpdate method
    if (!(this.props.profile || this.props.hashtag || this.props.explore))
      this.setState({ loading: true });
  }
  componentDidUpdate(prevProps, prevState) {
    //   if (this.props.auth.isAuthenticated) {
    //     window.history.pushState({}, null, `/${window.location.search}`);
    //     return window.location.reload();
    //   }

    if (this.state.loading) {
      let path = window.location.search;
      if (
        path.substr(1, path.lastIndexOf("=") - 1) === "note" &&
        (this.state.note
          ? this.state.note.id !== path.substr(path.indexOf("=") + 1)
          : true)
      ) {
        if (this.state.notebook) this.setState({ notebook: null });
        this.getNotes(`${path.substr(path.indexOf("=") + 1)}`);
      }
      if (
        path.substr(1, path.lastIndexOf("=") - 1) === "notebook" &&
        (this.state.notebook
          ? this.state.notebook.id !== path.substr(path.indexOf("=") + 1)
          : true)
      ) {
        if (this.state.note) this.setState({ note: null });
        this.getNotebook(`${path.substr(path.indexOf("=") + 1)}`);
      }
    }
  }

  getNotes(id) {
    axios
      .get(`${process.env.REACT_APP_API_HOST}/api/notes/`, {
        params: {
          noteId: id,
        },
      })
      .then((res) => {
        if (res.data.success) {
          //   console.log(res.data);
          this.setState({
            note: res.data.note,
            loading: false,
            notebook: null,
            notFound: false,
          });
        } else this.setState({ loading: false, notFound: true });
      });
  }

  getNotebook(id) {
    axios
      .get(`${process.env.REACT_APP_API_HOST}/api/notebooks/`, {
        params: {
          id: id,
        },
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({
            notebook: res.data.notebook,
            loading: false,
            note: null,
            notFound: false,
          });
        } else this.setState({ loading: false, notFound: true });
      });
  }

  render() {
    const { note, notebook, loading, authModalVisible } = this.state;
    const { isSmallDevice, theme } = this.props.auth;

    const authModal = (
      <Modal
        visible={authModalVisible}
        onCancel={() => this.setState({ authModalVisible: false })}
        footer={null}
        destroyOnClose
        bodyStyle={{ padding: "0px", marginTop: "-50px" }}
      >
        <Login style={{ border: "none" }} />
      </Modal>
    );

    return loading ? (
      <Loader />
    ) : (
      <div
        style={
          isSmallDevice
            ? { padding: "10px" }
            : {
                // padding: "10px",
                // textAlign: "center",
                width: this.props.explore
                  ? "100%"
                  : !this.props.hashtag
                  ? "800px"
                  : "900px",
                margin: this.props.explore ? "0px" : "20px auto",
                ...(this.props.explore && { marginTop: "-50px" }),
              }
        }
      >
        {authModal}
        {this.props.explore ? (
          <Explore
            exploreOption={this.props.exploreOption}
            saveNote={(nId, nbId) => this.setState({ authModalVisible: true })}
            saveNotebook={(nbId) => this.setState({ authModalVisible: true })}
            userNotebooks={[]}
            reloadNote={null}
          />
        ) : this.props.profile ? (
          <Profile
            theme={theme}
            isSmallDevice={isSmallDevice}
            username={this.props.profile}
            userId={null}
            userNotebooks={[]}
            saveNote={(noteId, notebookId) =>
              this.setState({ authModalVisible: true })
            }
            follow={(username) => this.setState({ authModalVisible: true })}
            saveNotebook={(notebookId) =>
              this.setState({ authModalVisible: true })
            }
            reloadProfile={this.state.reloadProfile}
            reloadNotes={this.state.reloadNotes}
            reloadProfileNotebooks={this.state.reloadProfileNotebooks}
          />
        ) : this.props.hashtag ? (
          <Hashtag
            hashtag={this.props.hashtag}
            theme={theme}
            userId={null}
            isSmallDevice={isSmallDevice}
            userNotebooks={[]}
            saveNote={(noteId, notebookId) =>
              this.setState({ authModalVisible: true })
            }
            reloadNote={this.state.reloadNotes}
            followHashtag={() => this.setState({ authModalVisible: true })}
            reloadHashtagInfo={this.state.reloadProfile}

            // saveNotebook={(notebookId) => this.saveNotebook(notebookId)}
          />
        ) : note ? (
          <NoteviewUnauth
            note={note}
            theme={theme}
            onSave={() => this.setState({ authModalVisible: true })}
          />
        ) : notebook ? (
          <div>
            <div style={{ margin: "15px 15px" }}>
              {notebook.temp && (
                <div
                  style={{
                    display: "inline-grid",
                    gridTemplateColumns: "50px auto",
                    margin: "0px 20px",
                    gridGap: "5px",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                >
                  <div>
                    <Avatar
                      style={{ display: "inline-block" }}
                      size={50}
                      src={
                        notebook.temp &&
                        `https://${notebook.temp.owner.display_picture.downloadUrl}`
                      }
                    />
                  </div>
                  <div>
                    <Link
                      style={{
                        display: "inline-block",
                        margin: "10px",
                        color: theme.color,
                      }}
                      to={`/${notebook.temp.owner.username}`}
                    >
                      {notebook.temp.owner.name}
                      <div />@{notebook.temp.owner.username}
                    </Link>
                  </div>
                </div>
              )}
              <div style={{ float: "right", margin: "20px" }}>
                <Button
                  type={this.props.userId ? "primary" : "link"}
                  onClick={() => this.setState({ authModalVisible: true })}
                  style={{ width: "90px" }}
                  icon={
                    notebook.temp.hasSaved ? (
                      <i className="fas fa-bookmark"></i>
                    ) : (
                      <i className="far fa-bookmark"></i>
                    )
                  }
                >
                  &nbsp;
                  {notebook.temp.saverCount === 0
                    ? " Save"
                    : notebook.temp.saverCount === 1
                    ? " 1 Save"
                    : ` ${notebook.temp.saverCount} saves`}
                </Button>
              </div>
            </div>
            <NotebookView
              theme={theme}
              isSmallDevice={isSmallDevice}
              key={notebook.id}
              userId={null}
              notebook={notebook}
              selectNote={(noteId) => this.getNotes(noteId)}
            />
            <div>
              <Button
                style={{ float: "right" }}
                type="text"
                size="large"
                icon={<i className="fal fa-share"></i>}
                onClick={() => {
                  message.success("Link copied.");
                  navigator.clipboard.writeText(
                    `${process.env.REACT_APP_HOST_ADDRESS}/?notebook=${notebook.id}`
                  );
                }}
              >
                &nbsp;Share
              </Button>
            </div>
          </div>
        ) : (
          <NotFound />
        )}
        <div style={{ height: "200px" }} />
        <div
          style={{
            fontSize: isSmallDevice ? "20px" : "24px",
            paddingTop: isSmallDevice ? "10px" : "20px",
            textAlign: "center",
            position: "fixed",
            bottom: "0",
            left: "0",
            backgroundColor: "#fff",
            width: "100%",
            borderTop: "1px solid #d1d1d1",
            height: "160px",
          }}
        >
          {this.props.explore
            ? "Sign in to start publishing your own books and articles"
            : "Sign in to save it and find more like this"}
          <div style={{ marginTop: "20px", marginBottom: "80px" }}>
            <Space size={20}>
              <Link to="/login">
                <Button size="large">Sign In</Button>
              </Link>
              <Link to="/join">
                <Button size="large" type="primary">
                  Join for free
                </Button>
              </Link>
            </Space>
          </div>
        </div>
      </div>
    );
  }
}

Main.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
