import React, { Component } from "react";
import { Input, Button, Form, Alert, message, Tooltip } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { loginUser } from "../../actions/authActions";
import propTypes from "prop-types";
import logo from "../../assets/logo.png";
import { GoogleLogin } from "react-google-login";

import "./styles/register.less";
export class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailSendSuccess: false,
      timer: {},
      seconds: 30,
      error: null,
    };
    this.timer = 0;
    this.onSubmit = this.onSubmit.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }
  componentDidMount() {
    if (this.props.auth && this.props.auth.isAuthenticated) {
      this.props.history.push("/");
    }
  }

  handleGoogleLogin = (googleData) => {
    if (!googleData.tokenId) {
      return;
      // return message.error("You need to enable cookies to log in.");
    }
    axios
      .get(
        `${process.env.REACT_APP_API_HOST}/api/users/google-signin/${googleData.tokenId}`
      )
      .then(async (res) => {
        if (res.data.success) {
          this.props.loginUser(res.data, true);
        } else if (res.data) message.error(res.data.message);
        else message.error("Some error occured. Please try again.");
      })
      .catch((err) => {
        // console.log(err);
        message.error("Unable to reach servers. Please try again later.");
      });
  };

  startTimer() {
    if (this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      seconds,
    });

    // Check if we're at zero.
    if (seconds === 0) {
      clearInterval(this.timer);
    }
  }

  onSubmit(e, resend = false) {
    e.preventDefault();
    const emailFormat =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const emailIsValid = this.state.email.match(emailFormat);

    if (!emailIsValid) {
      this.setState({ showEmailError: true });
      return;
    } else {
      axios
        .post(`${process.env.REACT_APP_API_HOST}/api/users/join/`, {
          email: this.state.email.trim().toLowerCase(),
        })
        .then((res) => {
          if (res.data.success) {
            this.setState({ emailSendSuccess: true, seconds: 30 });
            this.startTimer();
            if (resend) {
              message.success("Resend Successful. Please check your inbox.");
            }
          } else {
            this.setState({ error: res.data.message });
          }
        });
    }
  }

  render() {
    const { emailSendSuccess, seconds, error, showEmailError } = this.state;
    const { theme } = this.props.auth;
    const emailForm = (
      <div className="registration-form">
        <br />
        <div>
          <GoogleLogin
            className="google-signup-button"
            clientId={`425609790781-otdsmsvcsj7f0lt1g6fao1u8r0mdjr00.apps.googleusercontent.com`}
            buttonText={<strong>Sign up with Google</strong>}
            onSuccess={this.handleGoogleLogin}
            onFailure={this.handleGoogleLogin}
            cookiePolicy={"single_host_origin"}
            prompt="consent"
            render={(renderProps) => (
              <Button
                onClick={renderProps.onClick}
                // disabled={renderProps.disabled}
                style={{ height: "40px", width: "100%", padding: "0 100px" }}
                size="large"
              >
                <div style={{ display: "inline-block" }}>
                  <img
                    width="20px"
                    style={{ marginTop: "-3px", marginRight: "15px" }}
                    alt="Google sign-in"
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
                  />
                </div>
                <div style={{ display: "inline-block", fontWeight: "500" }}>
                  Sign up with Google
                </div>
              </Button>
            )}
          />
          <div style={{ margin: "15px" }}>
            <h5>or</h5>
          </div>
        </div>
        <div
          style={{ fontSize: "16px", fontWeight: "500", marginBottom: "10px" }}
        >
          Sign up using email
        </div>
        <Form>
          <Form.Item
            name="Email"
            // rules={[
            //   {
            //     required: true,
            //     type: "email",
            //     message: "Please enter a valid email",
            //   },
            // ]}
          >
            <Input
              name="email"
              placeholder="Enter your email"
              onChange={(e) =>
                this.setState({
                  email: e.target.value.toLowerCase().trim(),
                  showEmailError: false,
                })
              }
              onPressEnter={this.onSubmit}
              addonAfter={
                <Tooltip
                  title={`We will send you a confirmation link which will take you to your sign up form.`}
                >
                  <i className="far fa-question-circle"></i>
                </Tooltip>
              }
            />
          </Form.Item>
          {showEmailError && (
            <div style={{ fontSize: "12px", color: "red", marginTop: "-18px" }}>
              Please enter a valid email
            </div>
          )}
          <Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              style={{ width: "100%", marginTop: "0px" }}
              onClick={this.onSubmit}
            >
              Continue
            </Button>
          </Form.Item>
          <p>
            By continuing you are agreeing to our{" "}
            <Link to={"/pages/terms"}> Terms of Service </Link> and &nbsp;
            <Link to={"/pages/privacy-policy"}>Privacy Policy.</Link>
          </p>
        </Form>
        <div style={{ marginTop: "25px", fontSize: "14px" }}>
          Already have an account?{" "}
          <Link
            to={`/login`}
            style={{
              fontWeight: "500",
              color: theme.primaryColor,
            }}
          >
            Sign in
          </Link>
        </div>
      </div>
    );
    return (
      <div className="register-container">
        <Link
          to={"/"}
          // style={{
          //   color: theme.color,
          // }}
        >
          <img
            src={logo}
            alt="Notebuq"
            style={{ height: "50px", margin: "10px" }}
          />
          {/* <div
            style={{
              fontSize: "30px",
              fontWeight: "500",
            }}
          >
            Notebuq
          </div> */}
        </Link>
        <div
          style={{
            maxWidth: "80%",
            margin: "0 auto",
            padding: "15px",
            fontSize: "20px",
          }}
        >
          Your personal and professional knowledge base
        </div>
        {/* <div style={{ height: "10px" }} />
        <h4>Create your account</h4> */}
        {!error ? (
          !emailSendSuccess ? (
            emailForm
          ) : (
            <div>
              <br />{" "}
              <Alert
                style={{ textAlign: "left" }}
                message="Success"
                description={`Confirmation mail sent to ${this.state.email}`}
                type="success"
                showIcon
              />
              <br />
              Please click on the confirmation link sent to your email to
              continue your account creation process. You may need to check your
              spam folder.
              <div style={{ height: "15px" }} />
              <p>
                Didn't recieve email?{" "}
                <i>
                  {seconds === 0 ? (
                    <Button
                      type="link"
                      size="small"
                      onClick={(e) => this.onSubmit(e, true)}
                    >
                      Resend
                    </Button>
                  ) : (
                    <>Please wait {seconds} seconds.</>
                  )}
                </i>
              </p>
            </div>
          )
        ) : (
          <Alert
            style={{ textAlign: "left" }}
            message="Error"
            description={this.state.error}
            type="success"
            showIcon
          />
        )}
        {}
      </div>
    );
  }
}

Register.propTypes = {
  loginUser: propTypes.func.isRequired,
  auth: propTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default withRouter(connect(mapStateToProps, { loginUser })(Register));
