import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Input, Button, Form, Checkbox, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import { withRouter } from "react-router";
import logo from "../../assets/logo.png";
import { GoogleLogin } from "react-google-login";

export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailOrUsername: "",
      password: "",
      errors: null,
      submitted: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.auth && this.props.auth.isAuthenticated) {
      this.props.history.push("/");
    }
  }

  handleGoogleLogin = (googleData) => {
    if (!googleData.tokenId) {
      return;
      // return message.error("You need to enable cookies to log in.");
    }
    axios
      .get(
        `${process.env.REACT_APP_API_HOST}/api/users/google-signin/${googleData.tokenId}`
      )
      .then(async (res) => {
        if (res.data.success) {
          this.props.loginUser(res.data, true);
        } else if (res.data) message.error(res.data.message);
        else message.error("Some error occured. Please try again.");
      })
      .catch((err) =>
        message.error("Unable to reach servers. Please try again later.")
      );
  };

  onChange(e) {
    this.setState(e);
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log(this.props.auth.isAuthenticated);
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/");
    }
    if (this.props.errors && prevProps.errors !== this.props.errors) {
      this.setState({ errors: this.props.errors });
    }
  }

  onSubmit(e) {
    //e.preventDefault();
    this.setState({ submitted: true });
    this.setState({ errors: {} });

    const userData = {
      emailOrUsername: this.state.emailOrUsername.trim().toLowerCase(),
      password: this.state.password,
    };

    this.props.loginUser(userData);
  }

  render() {
    const { errors } = this.state;
    const { theme } = this.props.auth;
    return (
      <div className="register-container" style={this.props.style}>
        <Link
          to={"/"}
          // style={{
          //   color: theme.color,
          // }}
        >
          <img
            src={logo}
            alt="Notebuq"
            style={{ height: "50px", margin: "10px" }}
          />
          {/* <div
            style={{
              fontSize: "30px",
              fontWeight: "500",
            }}
          >
            Notebuq
          </div> */}
        </Link>

        <div
          style={{
            maxWidth: "80%",
            margin: "0 auto",
            padding: "15px",
            fontSize: "20px",
          }}
        >
          Your personal and professional knowledge base
        </div>
        <div style={{ marginTop: "20px" }}>
          <GoogleLogin
            className="google-signup-button"
            clientId={`425609790781-otdsmsvcsj7f0lt1g6fao1u8r0mdjr00.apps.googleusercontent.com`}
            buttonText={<strong>Sign up with Google</strong>}
            onSuccess={this.handleGoogleLogin}
            onFailure={this.handleGoogleLogin}
            cookiePolicy={"single_host_origin"}
            prompt="consent"
            render={(renderProps) => (
              <Button
                onClick={renderProps.onClick}
                // disabled={renderProps.disabled}
                style={{ height: "40px", width: "100%", padding: "0 100px" }}
                size="large"
              >
                <div style={{ display: "inline-block" }}>
                  <img
                    width="20px"
                    style={{ marginTop: "-3px", marginRight: "15px" }}
                    alt="Google sign-in"
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
                  />
                </div>
                <div style={{ display: "inline-block", fontWeight: "500" }}>
                  Sign in with Google
                </div>
              </Button>
            )}
          />
          <div style={{ margin: "15px" }}>
            <h5>or</h5>
          </div>
        </div>
        <div style={{ fontSize: "16px", fontWeight: "500" }}>
          Sign in to your account
        </div>
        <div style={{ height: "5px" }} />
        <Form
          style={{ textAlign: "left" }}
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={this.onSubmit}
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: "Please your Email or Username",
              },
            ]}
            validateStatus={
              (this.state.submitted &&
                this.state.emailOrUsername.length === 0 &&
                "error") ||
              (errors && errors.email && "error")
            }
            help={this.state.submitted && errors && errors.email}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              onChange={(e) =>
                this.setState({ emailOrUsername: e.target.value })
              }
              placeholder="Email or Username"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please enter your Password" }]}
            validateStatus={
              (this.state.submitted &&
                this.state.password.length === 0 &&
                "error") ||
              (errors && errors.password && "error")
            }
            help={this.state.submitted && errors && errors.password}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              onChange={(e) => this.setState({ password: e.target.value })}
              // type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <Link
              className="login-form-forgot"
              to={"/forgot-password"}
              style={{ float: "right", fontWeight: "500" }}
            >
              Forgot password
            </Link>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              style={{ width: "100%" }}
              htmlType="submit"
              className="login-form-button"
            >
              Log in
            </Button>
            <br />
            <div
              style={{
                marginTop: "25px",
                fontSize: "14px",
                textAlign: "center",
              }}
            >
              Don't have an account?{" "}
              <Link
                to={`/join`}
                style={{
                  fontWeight: "500",
                  color: theme.primaryColor,
                }}
              >
                Sign Up
              </Link>
            </div>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

Login.propTypes = {
  loginUser: propTypes.func.isRequired,
  auth: propTypes.object.isRequired,
  errors: propTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default withRouter(connect(mapStateToProps, { loginUser })(Login));
