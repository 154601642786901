import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Space, message, Select } from "antd";
import axios from "axios";
import CommentItem from "./CommentItem";
import "./styles/actionpanel.less";

const { Option } = Select;

export const ActionPanel = ({
  subjectType,
  subject,
  auth,
  onShareClick = () => {},
}) => {
  // LIKES
  const [likesCount, setLikesCount] = useState(subject.temp.likesCount);
  const [hasLiked, setHasLiked] = useState(subject.temp.hasUserLiked);

  const sendLike = (sType = subjectType, s = subject) => {
    axios
      .post(`${process.env.REACT_APP_API_HOST}/api/likes/${sType}/${s.id}`)
      .then((res) => {
        if (res.data.success) {
          if (sType === "comment") return;
          if (hasLiked) setLikesCount(likesCount - 1);
          else setLikesCount(likesCount + 1);
          setHasLiked(!hasLiked);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => message.error("Unable to reach servers."));
  };

  //This count is for comments excluding replies
  const [mainCommentsCount, setMainCommentsCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("ASC");
  const [comments, setComments] = useState([]);

  const getComments = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_HOST}/api/comments/${subjectType}/${subject.id}`,
        {
          params: {
            offset,
            limit,
            sortBy,
            sortOrder,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          // console.log(res.data);
          setOffset(comments.length + res.data.comments.length);
          setComments([...comments, ...res.data.comments]);
          setMainCommentsCount(res.data.count);
          // following lines are just to avoid warning, no actual use
          setLimit(limit);
          setSortBy(sortBy);
          setSortOrder(sortOrder);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => message.error("Unable to reach servers."));
  };

  // COMMENTS

  useEffect(() => {
    getComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This is count of comments including replies
  const [commentsCount, setCommentsCount] = useState(
    subject.temp.commentsCount
  );

  useEffect(() => {
    if (offset === 0 && comments.length === 0) {
      getComments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  useEffect(() => {
    setOffset(0);
    setComments([]);
  }, [sortBy]);

  const {
    theme,
    // isSmallDevice
  } = auth;

  //   console.log(subject.temp.commentsCount);
  return (
    <div>
      <div style={{ marginBottom: "5px" }}>
        <span>
          {likesCount === 0 ? (
            <span>
              {" "}
              <i className="far fa-thumbs-up"></i>&nbsp;Be the first one to like
              this
            </span>
          ) : likesCount === 1 ? (
            "1 like"
          ) : (
            `${likesCount} likes`
          )}{" "}
        </span>
        <span style={{ float: "right" }}>
          {commentsCount === 0 ? (
            <span>No comments</span>
          ) : (
            <span>
              {commentsCount === 1 ? "1 comment" : `${commentsCount} comments`}
            </span>
          )}
        </span>
      </div>
      <hr style={{ margin: "0px 0px" }} />
      <Space>
        <Button
          onClick={() => sendLike()}
          type={hasLiked ? "text" : "text"}
          size="large"
          style={{
            width: "100px",
            fontSize: "18px",
            color: hasLiked ? theme.primaryColor : theme.color,
          }}
          //   ghost={hasLiked ? true : false}
          icon={
            hasLiked ? (
              <i className="fas fa-thumbs-up"></i>
            ) : (
              <i className="fal fa-thumbs-up"></i>
            )
          }
        >
          <span style={{ fontSize: "16px" }}>
            &nbsp;{hasLiked ? "Liked" : "Like"}
          </span>
        </Button>
      </Space>
      <Button
        icon={<i className="fal fa-share-square"></i>}
        style={{ float: "right" }}
        type="text"
        size="large"
        onClick={() => onShareClick()}
      >
        &nbsp;Share
      </Button>
      <div style={{ margin: "15px 0px" }}>
        {/* This is for adding comment form only */}
        <CommentItem
          key={"comment-form"}
          subjectType={subjectType}
          subject={subject}
          afterCommentAdd={(newComment, increaseCountOnly = false) => {
            if (!increaseCountOnly) setComments([newComment, ...comments]);
            setCommentsCount(commentsCount + 1);
          }}
        />
        <div
          style={{
            textAlign: "right",
            marginTop: "-20px",
            marginBottom: "10px",
            fontWeight: "500",
          }}
        >
          <Select
            defaultValue="lucy"
            style={{ width: 120, display: "none" }}
            onChange={(e) => {}}
          >
            <Option value="jack">Jack</Option>
            <Option value="lucy">Lucy</Option>
          </Select>
          <Select
            defaultValue={sortBy}
            size="small"
            bordered={false}
            style={{ width: "140px", color: theme.color }}
            onChange={(e) => {
              if (e === "popularity") setSortOrder("DESC");
              if (e === "createdAt") setSortOrder("ASC");
              setSortBy(e);
            }}
          >
            <Select.Option key="1" value="popularity">
              Popular
            </Select.Option>
            <Select.Option key="2" value="createdAt">
              Recent
            </Select.Option>
          </Select>
          {/* <Button
            icon={
              sortOrder === "DESC" ? (
                <i className="fas fa-sort-up" style={{ fontSize: "28px" }}></i>
              ) : (
                <i
                  className="fas fa-sort-down"
                  style={{ fontSize: "28px" }}
                ></i>
              )
            }
            size="large"
            type="text"
            style={{ padding: "0px" }}
            onClick={() => setSortOrder(sortOrder === "DESC" ? "ASC" : "DESC")}
          ></Button> */}
        </div>
        {/* Displaying Comments */}
        {comments.map((comment) => (
          <CommentItem
            key={comment.id}
            subjectType={subjectType}
            subject={subject}
            comment={comment}
            sendLike={(c) => sendLike("comment", c)}
            afterCommentAdd={(newComment, increaseCountOnly = false) => {
              if (!increaseCountOnly) setComments([newComment, ...comments]);
              setCommentsCount(commentsCount + 1);
            }}
            afterCommentEdit={(cmt) =>
              setComments(comments.map((c) => (c.id === cmt.id ? cmt : c)))
            }
            afterCommentDelete={(
              c,
              decreaseCountOnly = false,
              repliesCount = 0
            ) => {
              if (!decreaseCountOnly)
                setComments(comments.filter((cmt) => cmt.id !== c.id));
              setCommentsCount(commentsCount - 1 - repliesCount);
            }}
          />
        ))}
      </div>

      {comments.length < mainCommentsCount && (
        <Button
          type="text"
          onClick={() => getComments(true)}
          style={{ padding: "0px" }}
          className="comments-text-button"
        >
          {comments.length === 0 ? "Load comments" : "Load more comments"}
        </Button>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ActionPanel);
