import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import propTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "../LandingComponents/Footer";
import { light as theme } from "../../../themes/themes";

import "./solutions.less";

export class Solutions extends Component {
  componentDidMount() {
    AOS.init({
      duration: 1000,
      once: true,
      offset: 40,
    });
  }
  render() {
    const { isSmallDevice } = this.props.auth;
    const styles = {
      categoryGridContainer: isSmallDevice
        ? {
            overflow: "hidden",
          }
        : {
            display: "grid",
            gridTemplateColumns: "1fr",
            // border: "1px solid blue",
            gridGap: "20px 0px",
            overflow: "hidden",
            // justifyItems: "center",
          },
      categoryGridItem: isSmallDevice
        ? {
            fontSize: "22px",
            padding: "20px",
            color: "#555",
            overflow: "hidden",
          }
        : {
            fontSize: "24px",
            padding: "20px",
            paddingLeft: "50px",
            // border: "1px solid red",
            color: "#555",
            // display: "grid",
            // gridTemplateColumns: "1fr 1fr",
            // height: "250px",
            overflow: "hidden",
            // textAlign: "center",
          },
      categoryGridItemTitle: {
        fontWeight: "500",
      },
      categoryGridItemImageContainer: {
        // border: "1px solid red",
        transformOrigin: "left",
        transform: "skewX(-17deg)",
        marginRight: "-40px",
        overflow: "hidden",
      },
      categoryGridItemImage: {
        transform: "skewX(17deg)",
        width: "calc (100% + 40px)",
        marginLeft: "-40px",
      },
      detailItem: {
        overflow: "auto",
      },
      detailHeader: {
        fontSize: "42px",
        textAlign: "center",
        margin: "20px 0px",
        marginTop: "40px",
        backgroundColor: theme.primaryColor,
        color: "#fff",
        padding: "10px 0px",
      },
    };
    return (
      <div
        className="solutions-container"
        style={
          isSmallDevice
            ? {
                color: "#555",
                backgroundColor: "#fff",
                position: "relative",
                paddingTop: "160px",
                // width: "100%",
                // overflowX: "hidden",
                // overflowY: "auto",
              }
            : {
                // paddingTop: "40px",
                color: "#555",
                backgroundColor: "#fff",
              }
        }
      >
        <Helmet>
          <title>Solutions | Notebuq </title>
        </Helmet>
        <div
          className="solutions-top"
          style={{
            height: isSmallDevice ? "600px" : "750px",
            // paddingTop: isSmallDevice ? "150px" : "0px",
            backgroundImage: `url(${process.env.REACT_APP_CDN_ADDRESS}/file/ntbq-01/resources/pages/solutions/top_3_im3.png)`,
            backgroundPosition: isSmallDevice ? "48% 30%" : "0% 0%",
            backgroundSize: "cover",
            backgroundBlendMode: "darken",
            marginTop: "-50px",
          }}
        >
          <div
            style={
              isSmallDevice
                ? {
                    fontSize: "28px",
                    color: theme.primaryColor2,
                    textAlign: "center",
                    position: "absolute",
                    top: "80px",
                    padding: "10px 20px",
                    fontWeight: "500",
                  }
                : {
                    textAlign: "center",
                    paddingTop: "80px",
                    fontSize: "54px",
                    color: theme.primaryColor,
                    fontWeight: "500",
                    width: "max(75%, 900px)",
                    margin: "0 auto",
                    fontFamily: "Helvetica, Arial, Sans-Serif",
                  }
            }
            data-aos="fade"
          >
            Explore a world of endless possibilities.
          </div>
        </div>
        <div>
          <div
            style={{
              fontSize: "36px",
              textAlign: "center",
              marginTop: "40px",
              marginBottom: "20px",
              fontWeight: "500",
            }}
          >
            See solutions for
          </div>
          <div style={styles.categoryGridContainer}>
            <div
              className="solutions-category-item"
              style={styles.categoryGridItem}
              data-aos="fade-left"
            >
              <div
                className="solutions-category-item-title"
                style={styles.categoryGridItemTitle}
              >
                <a href="#community">Community</a>
              </div>{" "}
              A haven for readers and writers.
            </div>

            <div
              className="solutions-category-item"
              style={styles.categoryGridItem}
              data-aos="fade-left"
            >
              <div
                className="solutions-category-item-title"
                style={styles.categoryGridItemTitle}
              >
                <a href="#teams">Work and Teams</a>
              </div>{" "}
              Transform the way your organization keeps and shares information.
            </div>
            <div
              className="solutions-category-item"
              style={styles.categoryGridItem}
              data-aos="fade-left"
            >
              <div
                className="solutions-category-item-title"
                style={styles.categoryGridItemTitle}
              >
                <a href="#education">Education</a>
              </div>
              A new way to teach. A new way to learn.
              {/* <div style={styles.categoryGridItemImageContainer}>
                <img
                  style={{
                    ...styles.categoryGridItemImage,
                    marginTop: "-200px",
                  }}
                  alt="Education"
                  src={`https://f000.backblazeb2.com/file/ntbq-01/resources/pages/solutions/ct-ed.jpg`}
                />
              </div> */}
            </div>

            <div
              className="solutions-category-item"
              style={styles.categoryGridItem}
              data-aos="fade-left"
            >
              <div
                className="solutions-category-item-title"
                style={styles.categoryGridItemTitle}
              >
                <a href="#personal">Personal</a>
              </div>{" "}
              An ultimate note taking app for all your needs. Find and save
              ideas.
            </div>
          </div>
          <div style={styles.detailItem}>
            <div style={styles.detailHeader} id="community">
              <i className="fad fa-users"></i>
              &nbsp;Community
            </div>
            <div data-aos="slide-up">
              <div className="solutions-detail-info-item">
                <div>
                  <i className="fad fa-book"></i>&nbsp;Publish a book
                </div>
                <div className="solutions-detail-info-item-details">
                  Getting your book published by a traditional publisher can be
                  a daunting process, even if your book does gets published you
                  probably need to spend lots of efforts on marketing your book.
                  On notebuq, you just focus on your book and let us handle the
                  rest. You can create your audience and write books for them.
                  You can publish free or paid* books.
                </div>
              </div>
              <div className="solutions-detail-info-item">
                <div>
                  <i className="fad fa-file-invoice"></i>&nbsp;Publish articles
                  and stories
                </div>
                <div className="solutions-detail-info-item-details">
                  Publish articles, write stories, share experiences or write
                  reviews about things you find interesting to create your
                  audience. Use hashtags in your articles to get them seen by
                  others.
                </div>
              </div>
              <div className="solutions-detail-info-item">
                <div>
                  <i className="fad fa-lightbulb-on"></i>&nbsp;Find, share and
                  create ideas
                </div>
                <div className="solutions-detail-info-item-details">
                  Find ideas, articles and books to read about things you find
                  interesting. Follow topics that you're passionate about to
                  keep yourself updated with the latest information and
                  happenings.
                </div>
              </div>
              <div className="solutions-detail-info-item">
                <div>
                  <i className="fad fa-user-check"></i>&nbsp;Connect with
                  writers from around the world
                </div>
                <div className="solutions-detail-info-item-details">
                  Find and connect with the writers from around the world from
                  various fields who write about things you're passionate about.
                </div>
              </div>
              <div className="solutions-detail-info-item">
                <div>
                  <i className="fad fa-user-friends"></i>&nbsp;Join Public
                  Groups*
                </div>
                <div className="solutions-detail-info-item-details">
                  Join public groups and connect with people to discuss, share
                  your writings in the group and grow your audience.
                </div>
              </div>
            </div>
          </div>
          <div style={styles.detailItem}>
            <div style={styles.detailHeader} id="education">
              <i className="fad fa-microscope"></i>&nbsp;Education
            </div>
            <div data-aos="slide-up">
              <div className="solutions-detail-info-item">
                <div>
                  <i className="fad fa-users-class"></i>&nbsp;A new way to
                  teach: Groups*
                </div>
                <div className="solutions-detail-info-item-details">
                  Take your classes online by creating groups of students you
                  need to teach. <br /> Share notes and notebooks, give
                  assignments and review their submissions, all in one place.
                </div>
              </div>
              <div className="solutions-detail-info-item">
                <div>
                  <i className="fad fa-globe-stand"></i>&nbsp;A new way to learn
                </div>
                <div className="solutions-detail-info-item-details">
                  Find notes, books and notebooks on any topic.
                  <br />
                  Learn from authors and teachers around the world.
                </div>
              </div>
              <div className="solutions-detail-info-item">
                <div>
                  <i className="fad fa-chalkboard-teacher"></i>&nbsp;Take and
                  share lecture notes
                </div>
                <div className="solutions-detail-info-item-details">
                  Take and save your daily lecture notes at one place, add
                  images, files and audios*, access them anytime, anywhere and
                  never lose them. Share them with your friends.
                </div>
              </div>
              <div className="solutions-detail-info-item">
                <div>
                  <i className="fad fa-project-diagram"></i>&nbsp;Collaborate
                  for projects
                </div>
                <div className="solutions-detail-info-item-details">
                  Collaborate for school/university projects to prepare reports,
                  gather information and research on the topic.
                </div>
              </div>
              <div className="solutions-detail-info-item">
                <div>
                  <i className="fad fa-atom"></i>&nbsp;Publish your research
                  papers
                </div>
                <div className="solutions-detail-info-item-details">
                  Publish your research papers to get them seen throughout the
                  world and let other pioneers in your field find you.
                </div>
              </div>
            </div>
          </div>
          <div style={styles.detailItem}>
            <div style={styles.detailHeader} id="teams">
              {" "}
              <i className="fad fa-laptop"></i>&nbsp;Work and Teams
            </div>
            <div data-aos="slide-up">
              <div className="solutions-detail-info-item">
                <div>
                  <i className="fad fa-people-carry"></i>&nbsp;Share and
                  collaborate
                </div>
                <div className="solutions-detail-info-item-details">
                  Collaborate on notes and notebooks to get work done faster and
                  better.
                </div>
              </div>
              <div className="solutions-detail-info-item">
                <div>
                  <i className="fad fa-sitemap"></i>&nbsp;Groups and teams*
                </div>
                <div className="solutions-detail-info-item-details">
                  Create and manage teams and their information, agendas and
                  projects. There can be multiple teams(sub-groups) under an
                  organization each with their own knowledge base, agendas and
                  projects. See what each team is working on and what the
                  progress is. Organize information, work, agendas and projects
                  for your teams. Assign team leaders to manage teams and
                  maximize productivity.
                </div>
              </div>
              <div className="solutions-detail-info-item">
                <div>
                  <i className="fad fa-handshake"></i>&nbsp;Create shared
                  meeting notes
                </div>
                <div className="solutions-detail-info-item-details">
                  Set meeting agendas, share the note with participants and
                  document what has been discussed so that you and your team
                  don't lose sight of what's important.
                </div>
              </div>
              <div className="solutions-detail-info-item">
                <div>
                  <i className="fad fa-books"></i>&nbsp;Knowledge base for your
                  organization
                </div>
                <div className="solutions-detail-info-item-details">
                  Create a knowledge base for your organization that can be
                  shared across your organization or with any team or with any
                  specific member.
                </div>
              </div>
              <div className="solutions-detail-info-item">
                <div>
                  <i className="fad fa-megaphone"></i>&nbsp;Publish about your
                  company
                </div>
                <div className="solutions-detail-info-item-details">
                  Explore a new way of marketing by connecting with your
                  customers and fans by publishing stories and articles about
                  your company. Share stories and experiences, and tell people
                  about your new products.
                </div>
              </div>
            </div>
          </div>

          <div style={styles.detailItem}>
            <div style={styles.detailHeader} id="personal">
              {" "}
              <i className="fad fa-user-lock"></i>&nbsp;Personal
            </div>
            <div data-aos="slide-up">
              <div className="solutions-detail-info-item">
                <div>
                  <i className="fad fa-sticky-note"></i>&nbsp;Create and store
                  your personal ideas and notes
                </div>
                <div className="solutions-detail-info-item-details">
                  Notebuq is an ultimate note taking app for all your needs. Jot
                  down your private ideas, experiences and memories or anything
                  you feel like in your protected notebooks. Organize notes in
                  sections, in notebooks and organize notebooks in sets, any way
                  you like.
                </div>
              </div>
              <div className="solutions-detail-info-item">
                <div>
                  <i className="fad fa-book-reader"></i>&nbsp;Find things to
                  read
                </div>
                <div className="solutions-detail-info-item-details">
                  Read stories, articles, experiences, novels, and all kinds of
                  articles and books published on notebuq. Whether you're
                  fiction or a non-fiction reader, on notebuq you can always
                  find something to read.
                </div>
              </div>
              <div className="solutions-detail-info-item">
                <div>
                  <i className="fad fa-bookmark"></i>&nbsp;Save ideas, notes,
                  articles and books
                </div>
                <div className="solutions-detail-info-item-details">
                  Notebuq allows you to save published notes, articles and
                  notebooks so that you can refer to them whenever you want
                  without any hassle.
                </div>
              </div>
              <div className="solutions-detail-info-item">
                <div>
                  <i className="fad fa-tasks"></i>&nbsp;Organize everyday life
                </div>
                <div className="solutions-detail-info-item-details">
                  Organize everyday life by writing your to-dos and adding
                  reminders*. Forget forgetting things. Keep a journal about
                  what you want to do or have to do. Manage events, plan trips,
                  ...the list is endless.
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div style={{ fontSize: "26px", textAlign: "center" }}>
          ...the list goes on.
        </div> */}
        <div style={{ textAlign: "right", margin: "10px" }}>
          *: Features marked with * are currently in beta
        </div>
        <div
          data-aos="flip-up"
          data-aos-delay="600"
          style={{
            margin: isSmallDevice ? "20px" : "50px 250px",
            textAlign: "center",
            fontSize: "18px",
            border: `2px solid ${theme.primaryColor2}`,
            borderRadius: "10px",
            padding: "25px",
          }}
        >
          <div style={{ fontSize: "32px", fontWeight: "500" }}>
            We're listening
          </div>
          If you have any suggestion for features or how you might want to use
          notebuq, please{" "}
          <a href="mailto: feedback@notebuq.com">
            <span style={{ fontWeight: "500" }}>provide feedback.</span>
          </a>
        </div>
        <Footer />
      </div>
    );
  }
}

Solutions.propTypes = {
  auth: propTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default withRouter(connect(mapStateToProps)(Solutions));
