import React, { Component } from "react";
import { connect } from "react-redux";
import propTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { toggleMobileSidebar } from "../../actions/authActions";
// import { Fade, Slide } from "react-reveal";
import {
  Button,
  Modal,
  message,
  Input,
  Tooltip,
  Select,
  Avatar,
  notification,
  Switch,
} from "antd";

import {
  ExclamationCircleOutlined,
  CloseOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import Sidebar from "./Sidebar";
import MyNotebooks from "./MyNotebooks";
import AllNotes from "./AllNotes";
import Publications from "./Publications";
import Saved from "./Saved";
import Noteview from "./Noteview";
import Shared from "./Shared";
import Notebookview from "./Notebookview";
import Profile from "./Profile";
import Hashtag from "./Feed/Hashtag";
import FeedLeftPanel from "./Feed/FeedLeftPanel";
import FeedRightPanel from "./Feed/FeedRightPanel";
import Explore from "../explore/Explore";

import EditorWrapper from "../editor/EditorWrapper";
import "./styles/dashboard.less";
import Loader from "../common/Loader";
import axios from "axios";

import SplitPane from "react-split-pane";

var backPressed;

export class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leftPanelVisible:
        localStorage.leftPanelDisplay === "false" ? false : true,
      leftPanelDisplay: localStorage.leftPanelDisplay || "feed",
      leftPanelWidth: this.props.auth.isSmallDevice ? window.innerWidth : 360,
      editorVisible: true,
      isResizing: false,
      lastDownX: 0,
      notebooks: null,
      notes: null,
      loading: false,
      selectedNote: null,
      edit: false,
      search: false,
      searchQuery: "",
      sharingModalVisible: false,
      loadingSharers: false,
      userlist: [],
      viewers: [],
      viewersIds: [],
      editors: [],
      editorsIds: [],
      viewersCount: 0,
      editorsCount: 0,
      selected: null,
      noteEdited: false,
      notebooksChanged: null,
      noteDeleted: null,
      newNotebookModalVisible: false,
      newNotebook: {},
      isSidebarShrunk: true,
      selectedNotebook: null,
      rightPanelDisplay: null,
      profileUsername: null,
      reloadProfile: false,
      reloadNotes: false,
      reloadNotebook: false,
      backPressed: false,
      hashtag: null,
      people: null,
      topics: null,
      keyForEditor: 0,
      noteChanged: null,
      sets: null,
      notebooksParams: {
        sortBy: "updatedAt",
        sortOrder: "DESC",
        searchQuery: "",
        sharing: ["private", "published", "shared"],
      },
      notebooksSortBy: "updatedAt",
      notebooksSortOrder: "DESC",
    };
    this.containerRef = React.createRef();
    this.getNotebooks = this.getNotebooks.bind(this);
    this.getNotes = this.getNotes.bind(this);
    this.onNoteEdit = this.onNoteEdit.bind(this);
    this.deleteNote = this.deleteNote.bind(this);
    this.publishNote = this.publishNote.bind(this);
  }

  componentDidMount() {
    // console.log(this.props.match.params);

    this.setState({ loading: true });

    if (this.props.auth && !this.props.auth.isAuthenticated) {
      this.props.history.push("/login");
    } else {
      // Fetching User's notebooks
      this.getNotebooks();

      // Fetching note if noteId present in url
      if (
        this.props.match.path === "/explore" ||
        this.props.match.path === "/explore/:option"
      )
        return this.setState({
          selectedNote: null,
          selectedNotebook: null,
          rightPanelDisplay: "explore",
        });
      if (
        this.props.match.params.hashtag &&
        this.props.match.params.hashtag.length > 0
      )
        return this.setState({
          hashtag: this.props.match.params.hashtag,
          selectedNote: null,
          selectedNotebook: null,
          rightPanelDisplay: "hashtag",
        });
      if (this.props.match.params.profile)
        return this.setState({
          profileUsername: this.props.match.params.profile,
          selectedNote: null,
          selectedNotebook: null,
          rightPanelDisplay: "profile",
        });
      if (!this.props.location.search)
        return this.setState({ rightPanelDisplay: "feed" });
      let path = this.props.location.search;
      if (path.substr(1, path.lastIndexOf("=") - 1) === "note")
        this.getNotes(`${path.substr(path.indexOf("=") + 1)}`);
      if (path.substr(1, path.lastIndexOf("=") - 1) === "notebook")
        this.getNotebooks({
          id: `${path.substr(path.indexOf("=") + 1)}`,
        });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.noteDeleted) this.setState({ noteDeleted: null });
    // Used to push down the changes if note is edited
    if (prevState.noteEdited !== this.state.noteEdited) {
      this.setState({ noteEdited: false });
    }
    // Used to push down changed if the note is moved
    if (prevState.noteChanged !== this.state.noteChanged) {
      this.setState({ noteChanged: null });
    }

    if (this.state.reloadProfile) this.setState({ reloadProfile: false });
    if (this.state.reloadNotes) this.setState({ reloadNotes: false });
    if (this.state.reloadNotebook) this.setState({ reloadNotebook: false });

    if (this.state.notebooksChanged) {
      // console.log(this.state.notebooksChanged);
      this.setState({ notebooksChanged: null });
    }

    if (prevState.searchQuery !== this.state.searchQuery) this.getNotes();

    if (
      prevState.selectedNote !== this.state.selectedNote ||
      (prevState.selectedNotebook && !this.state.selectedNotebook)
    ) {
      if (this.props.auth.isSmallDevice && this.state.leftPanelVisible) {
        localStorage.setItem("leftPanelDisplay", false);
        this.setState({ leftPanelVisible: false });
      }
      if (
        this.state.selectedNote &&
        this.state.selectedNote.authorId === this.props.auth.user.id
      ) {
        this.setState({
          loadingSharers: true,
          selectedNotebook: null,
          // ...(prevState.selectedNote && { edit: false }),
        });
        this.getSharers(this.state.selectedNote.id);
      }
    }
    if (!prevState.selectedNotebook && this.state.selectedNotebook) {
      if (this.props.auth.isSmallDevice && this.state.leftPanelVisible) {
        localStorage.setItem("leftPanelDisplay", false);
        this.setState({ leftPanelVisible: false });
      }
      if (this.state.selectedNotebook.ownerId === this.props.auth.user.id) {
        this.setState({ loadingSharers: true, selectedNote: null });
        this.getSharers(this.state.selectedNotebook.id);
      }
    }
    // Managing history
    backPressed = false;
    await this.props.history.listen((loc, action) => {
      // console.log(action);
      if (action === "POP") backPressed = true;
    });

    if (this.state.rightPanelDisplay === "feed" && !backPressed) {
      window.history.pushState({ feed: true }, null, `/`);
      // console.log(window.history);
      return;
    }
    if (
      !this.state.selectedNote &&
      !prevState.edit &&
      this.state.edit &&
      !backPressed
    ) {
      if (window.history.state && window.history.state.edit) return;
      window.history.pushState({ edit: true }, null, `/`);
    }
    if (prevState.selectedNote !== this.state.selectedNote && !backPressed) {
      if (
        window.history.state &&
        this.state.selectedNote &&
        window.history.state.selectedNote === this.state.selectedNote.id
      )
        return;
      window.history.pushState(
        {
          selectedNote: this.state.selectedNote && this.state.selectedNote.id,
        },
        null,
        this.state.selectedNote
          ? `/?note=${this.state.selectedNote.id}`
          : this.state.selectedNotebook
          ? `/?notebook=${this.state.selectedNotebook.id}`
          : `/`
      );
    }
    if (
      prevState.selectedNotebook !== this.state.selectedNotebook &&
      !backPressed
    ) {
      if (
        window.history.state &&
        this.state.selectedNotebook &&
        window.history.state.selectedNotebook === this.state.selectedNotebook.id
      )
        return;
      window.history.pushState(
        {
          selectedNotebook:
            this.state.selectedNotebook && this.state.selectedNotebook.id,
        },
        null,
        this.state.selectedNotebook
          ? `/?notebook=${this.state.selectedNotebook.id}`
          : this.state.selectedNote
          ? `/?note=${this.state.selectedNote.id}`
          : `/`
      );
    }
  }

  getNotebooks(params = this.state.notebooksParams) {
    if (params !== this.state.notebooksParams)
      this.setState({
        notebooksParams: {
          sortOrder: params.sortOrder || this.state.notebooksParams.sortOrder,
          sortBy: params.sortBy || this.state.notebooksParams.sortBy,
          searchQuery:
            params.searchQuery || this.state.notebooksParams.searchQuery,
          sharing: params.sharing || this.state.notebooksParams.sharing,
        },
      });
    axios
      .get(`${process.env.REACT_APP_API_HOST}/api/notebooks`, {
        params,
      })
      .then((res) => {
        // console.log("Params: ", params, "Response: ", res.data);
        if (res.data.success) {
          if (params.id && res.data.notebook) {
            this.setState({
              selectedNotebook: res.data.notebook,
              rightPanelDisplay: "notebook",
              notebooks:
                this.state.notebooks &&
                this.state.notebooks.map((notebook) =>
                  notebook.id === res.data.notebook.id
                    ? res.data.notebook
                    : notebook
                ),
              sets: res.data.sets,
            });
          } else
            this.setState({
              notebooks: res.data.notebooks,
              loading: false,
              sets: res.data.sets,
            });
        } else res.data && message.error(res.data.message);
      });
  }

  getNotes(noteId) {
    axios
      .get(`${process.env.REACT_APP_API_HOST}/api/notes`, {
        params: {
          noteId,
        },
      })
      .then((res) => {
        // console.log(res.data);
        if (res.data.success) {
          if (noteId)
            this.setState({
              selectedNote: res.data.note,
              edit: false,
              selectedNotebook: null,
              rightPanelDisplay: null,
            });
        }
      });
  }

  onNoteEdit(updatedNote, isFirstSave) {
    // console.log(this.state.selectedNote);
    this.setState({
      selectedNote: updatedNote,
      noteEdited: true,
      ...(!this.state.selectedNote && { edit: true }),
    });
  }

  deleteNote(note) {
    if (!note) return;
    axios
      .post(`${process.env.REACT_APP_API_HOST}/api/notes/delete`, {
        id: note.id,
      })
      .then((res) => {
        if (res.data.success) {
          //this.getNotes();
          // console.log(res.data);

          this.setState({
            ...(this.state.selectedNote &&
              this.state.selectedNote.id === note.id && {
                selectedNote: null,
                edit: false,
              }),
            noteDeleted: note.id,
            notebooksChanged: [note.notebookId],
          });
          this.getNotebooks();
          // message.success(res.data.message);
          notification["success"]({
            message: "Success",
            description: res.data.message,
            placement: "bottomRight",
          });
          return true;
        } else {
          // message.error("There was some error. Please try again later.");
          notification["error"]({
            message: "Error",
            description: res.data.message,
            placement: "bottomRight",
          });
          return false;
        }
      });
  }

  copyNote({ note, notebookId, sectionId }) {
    axios
      .post(`${process.env.REACT_APP_API_HOST}/api/notes/copy`, {
        noteId: note.id,
        notebookId: notebookId,
        sectionId,
      })
      .then((res) => {
        // console.log(res.data);
        if (res.data.success) {
          // message.success(res.data.message);
          notification["success"]({
            message: "Success",
            description: res.data.message,
            placement: "bottomRight",
          });
          this.setState({
            notebooksChanged: [note.notebookId, notebookId],
            ...(this.state.selectedNote &&
              note.id === this.state.selectedNote.id && {
                selectedNote: res.data.note,
              }),
          });
          this.getNotebooks();
        } else {
          if (res.data.message) {
            // message.error(res.data.message);
            notification["error"]({
              message: "error",
              description: res.data.message,
              placement: "bottomRight",
            });
          }
        }
      });
  }

  moveNote({ note, notebookId, sectionId }) {
    // if (notebookId === note.notebookId) return;
    // console.log(note, notebookId, sectionId);
    // console.log("Move to: ", sectionId);

    axios
      .post(`${process.env.REACT_APP_API_HOST}/api/notes/move`, {
        noteId: note.id ? note.id : note,
        notebookId: notebookId,
        sectionId,
      })
      .then((res) => {
        // console.log(res.data);
        if (res.data.success) {
          // console.log("After moving: ", res.data.note.sectionId);
          // message.success(res.data.message);
          notification["success"]({
            message: "Success",
            description: res.data.message,
            placement: "bottomRight",
          });
          this.setState({
            notebooksChanged: [note.notebookId, notebookId],
            ...(this.state.selectedNote &&
              res.data.note.id === this.state.selectedNote.id && {
                selectedNote: res.data.note,
              }),
            noteChanged: res.data.note,
          });
          this.getNotebooks();
        } else {
          if (res.data.message) {
            // message.error(res.data.message);
            notification["error"]({
              message: "error",
              description: res.data.message,
              placement: "bottomRight",
            });
          }
        }
      });
  }

  getUsers(query) {
    if (query.length === 0) return;
    axios
      .get(`${process.env.REACT_APP_API_HOST}/api/users/list`, {
        params: {
          query,
        },
      })
      .then((res) => {
        // console.log(res.data);
        if (res.data.success) {
          if (res.data.users.length === 0) this.setState({ userlist: null });
          else this.setState({ userlist: res.data.users });
        } else {
          message.error(res.data.message);
        }
      });
  }

  shareNote() {
    this.setState({ sharingModalVisible: false });
    if (
      this.state.editorsIds &&
      this.state.editorsIds.includes(this.props.auth.user.id)
    ) {
      this.setState({
        editorsIds: this.state.editorsIds.filter(
          (userId) => userId !== this.props.auth.user.id
        ),
      });
      // console.log(this.state.editors);
    }
    if (
      this.state.viewersIds &&
      this.state.viewersIds.includes(this.props.auth.user.id)
    )
      this.setState({
        viewersIds: this.state.viewersIds.filter(
          (userId) => userId !== this.props.auth.user.id
        ),
      });
    axios
      .post(`${process.env.REACT_APP_API_HOST}/api/notes/share`, {
        noteId: this.state.selectedNote && this.state.selectedNote.id,
        usersView: this.state.viewersIds,
        usersEdit: this.state.editorsIds,
      })
      .then((res) => {
        // console.log(res.data);
        if (res.data.success) {
          this.setState(
            {
              selectedNote: res.data.note,
              // notebooksChanged: [res.data.note.notebookId],
              noteEdited: true,
            }
            // () => this.getSharers(res.data.note.id)
          );
          // message.success(res.data.message);
          notification["success"]({
            message: "Success",
            description: res.data.message,
            placement: "bottomRight",
          });
        } else {
          // message.error(res.data.message);
          notification["error"]({
            message: "Error",
            description: res.data.message,
            placement: "bottomRight",
          });
        }
      });
  }

  publishNote() {
    if (!this.state.selectedNote) return message.warn("Please selecte a note.");
    let publish = () =>
      axios
        .post(`${process.env.REACT_APP_API_HOST}/api/notes/publish`, {
          noteId: this.state.selectedNote && this.state.selectedNote.id,
        })
        .then((res) => {
          if (res.data.success) {
            this.setState({
              selectedNote: res.data.note,
              // notebooksChanged: [res.data.note.notebookId],
              noteEdited: true,
            });
            // message.success(res.data.message);
            notification["success"]({
              message: "Success",
              description: res.data.message,
              placement: "bottomRight",
            });
          } else {
            // message.error(res.data.message);
            notification["error"]({
              message: "Error",
              description: res.data.message,
              placement: "bottomRight",
            });
          }
        });

    Modal.confirm({
      title: `Are you sure you want to ${
        this.state.selectedNote.sharing === "published"
          ? "UNPUBLISH"
          : "PUBLISH"
      } this note?`,
      icon: <ExclamationCircleOutlined />,
      content:
        this.state.selectedNote.sharing === "published"
          ? ""
          : "CAUTION: Do not publish notes with private information.",
      okText: `${
        this.state.selectedNote.sharing === "published"
          ? "Yes, make it private"
          : "Publish"
      }`,
      onOk: () => {
        publish();
      },
      onCancel() {},
    });
  }

  shareNotebook() {
    if (!this.state.selectedNotebook)
      return message.warn("Please selecte a notebook.");

    let selectedNotebook = this.state.selectedNotebook;
    this.setState({ sharingModalVisible: false });
    if (selectedNotebook.isProtected) return;
    if (this.state.editorsIds.includes(this.props.auth.user.id)) {
      this.setState({
        editorsIds: this.state.editorsIds.filter(
          (userId) => userId !== this.props.auth.user.id
        ),
      });
      // console.log(this.state.editors);
    }
    if (this.state.viewersIds.includes(this.props.auth.user.id))
      this.setState({
        viewersIds: this.state.viewersIds.filter(
          (userId) => userId !== this.props.auth.user.id
        ),
      });
    axios
      .post(`${process.env.REACT_APP_API_HOST}/api/notebooks/share`, {
        notebookId: selectedNotebook && selectedNotebook.id,
        usersView: this.state.viewersIds,
        usersEdit: this.state.editorsIds,
      })
      .then((res) => {
        // console.log(res.data);
        if (res.data.success) {
          // this.setState({ selectedNote: res.data.note });
          if (!this.state.selectedNotebook) this.getNotebooks();
          else this.getNotebooks({ id: this.state.selectedNotebook.id });
          // message.success(res.data.message);
          notification["success"]({
            message: "Success",
            description: res.data.message,
            placement: "bottomRight",
          });
        } else {
          // message.error(res.data.message);
          notification["error"]({
            message: "Error",
            description: res.data.message,
            placement: "bottomRight",
          });
        }
      });
  }

  publishNotebook(notebook = null) {
    if (!notebook) return message.warn("Please select a notebook.");
    let publish = () =>
      axios
        .post(`${process.env.REACT_APP_API_HOST}/api/notebooks/publish`, {
          notebookId: notebook.id,
        })
        .then((res) => {
          // console.log(res.data);
          if (res.data.success) {
            // if (!this.state.selectedNotebook) this.getNotebooks();
            if (
              this.state.selectedNotebook &&
              this.state.selectedNotebook.id === notebook.id
            )
              this.getNotebooks({ id: this.state.selectedNotebook.id });
            this.getNotebooks();
            this.setState({ notebooksChanged: [notebook.id] });

            // message.success(res.data.message);
            notification["success"]({
              message: "Success",
              description: res.data.message,
              placement: "bottomRight",
            });
          } else {
            // message.error(res.data.message);
            notification["error"]({
              message: "Error",
              description: res.data.message,
              placement: "bottomRight",
            });
          }
        });
    Modal.confirm({
      title: `Are you sure you want to ${
        notebook.sharing === "published" ? "UNPUBLISH" : "PUBLISH"
      } this notebook?`,
      icon: <ExclamationCircleOutlined />,
      content:
        notebook.sharing === "published"
          ? ""
          : "CAUTION: Publishing this notebook will publish all its content.",
      okText: `${
        notebook.sharing === "published" ? "Yes, make it private" : "Publish"
      }`,
      onOk: () => {
        publish();
      },
      onCancel() {},
    });
  }

  addNotebook() {
    if (
      !this.state.newNotebook ||
      !this.state.newNotebook.name ||
      !this.state.newNotebook.name.length > 0
    )
      return;
    axios
      .post(`${process.env.REACT_APP_API_HOST}/api/notebooks/create`, {
        name: this.state.newNotebook.name,
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.success) {
          notification["success"]({
            message: "Success",
            description: res.data.message,
            placement: "bottomRight",
          });
          this.getNotebooks();
        } else {
          notification["error"]({
            message: "error",
            description: res.data.message,
            placement: "bottomRight",
          });
        }
      });
  }

  saveNote(noteId, notebookId = null) {
    if (!noteId) message.warn("Please select a note");
    axios
      .post(`${process.env.REACT_APP_API_HOST}/api/notes/save`, {
        noteId,
        notebookId,
      })
      .then((res) => {
        // console.log(res.data);
        if (res.data.success) {
          notification["success"]({
            message: "Success",
            description: res.data.message,
            placement: "bottomRight",
          });
          this.setState({
            notebooksChanged: [
              notebookId ||
                this.state.notebooks.filter(
                  (notebook) => notebook.isSaved === true
                )[0].id,
            ],
          });
          if (this.state.selectedNote)
            this.setState({
              selectedNote: res.data.note,
            });
          // if (this.state.profileUsername || this.state.hashtag)
          this.setState({ reloadNotes: res.data.note });
        } else {
          notification["error"]({
            message: "Error",
            description: res.data.message,
            placement: "bottomRight",
          });
        }
      });
  }

  getSharers(id) {
    if (!id) message.warn("Please select a note or a notebook.");
    axios
      .get(
        this.state.selectedNotebook
          ? `${process.env.REACT_APP_API_HOST}/api/notebooks/shared-with`
          : `${process.env.REACT_APP_API_HOST}/api/notes/shared-with`,
        {
          params: {
            id,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        if (res.data.success) {
          this.setState({
            loadingSharers: false,
            editors: res.data.editors,
            editorsIds: res.data.editors.map((editor) => editor.id),
            editorsCount: res.data.editorsCount,
            viewers: res.data.viewers,
            viewersIds: res.data.viewers.map((viewer) => viewer.id),
            viewersCount: res.data.viewersCount,
          });
        } else message.error(res.data.message);
      })
      .catch((err) =>
        message.error("Error connecting to server. Please reload this page.")
      );
  }

  editNotebook(newNotebook = {}) {
    if (!newNotebook) message.warn("Improper payload.");
    else {
      axios
        .post(`${process.env.REACT_APP_API_HOST}/api/notebooks/edit`, {
          id: newNotebook.id || this.state.selectedNotebook.id,
          ...newNotebook,
        })
        .then((res) => {
          // console.log(res.data);
          if (res.data.success) {
            this.getNotebooks({ id: this.state.selectedNotebook.id });
            this.getNotebooks();
            notification["success"]({
              message: "Success",
              description: res.data.message,
              placement: "bottomRight",
            });
          } else {
            notification["error"]({
              message: "error",
              description: res.data.message,
              placement: "bottomRight",
            });
          }
        });
    }
  }

  changeNotebookProtection(notebook) {
    if (!notebook) message.warn("Please select a notebook");

    let changeProtection = (notebook) =>
      axios
        .post(`${process.env.REACT_APP_API_HOST}/api/notebooks/protection`, {
          id: notebook.id,
        })
        .then((res) => {
          // console.log(res.data);
          if (res.data.success) {
            this.getNotebooks({ id: notebook.id });
            this.setState({ notebooksChanged: [notebook.id] });
            // this.getNotebooks();
            notification["success"]({
              message: "Success",
              description: res.data.message,
              placement: "bottomRight",
            });
          } else {
            notification["error"]({
              message: "error",
              description: res.data.message,
              placement: "bottomRight",
            });
          }
        });

    if (notebook.sharing !== "private")
      Modal.confirm({
        title: `This notebook is ${notebook.sharing}. Are you sure you want to turn ON protection?`,
        icon: <ExclamationCircleOutlined />,
        content:
          "After turning ON protection, no one except you will be able to view or edit this notebook and its content.",
        okText: `${
          notebook.isProtected ? "Turn OFF Protection" : "Turn ON Protection"
        }`,
        onOk: () => {
          changeProtection(notebook);
        },
        onCancel() {},
      });
    else changeProtection(notebook);
  }

  saveNotebook(notebookId) {
    if (!notebookId) return message.warn("Please select a notebook");

    axios
      .post(`${process.env.REACT_APP_API_HOST}/api/notebooks/save`, {
        notebookId,
      })
      .then((res) => {
        // console.log(res.data);
        if (res.data.success) {
          if (
            this.state.selectedNotebook &&
            this.state.selectedNotebook.id === notebookId
          )
            this.getNotebooks({ id: notebookId });
          this.setState({ reloadNotebook: notebookId });
          notification["success"]({
            message: "Success",
            description: res.data.message,
            placement: "bottomRight",
          });
        } else {
          notification["error"]({
            message: "Error",
            description: res.data.message,
            placement: "bottomRight",
          });
        }
      })
      .catch((err) =>
        message.error("Error reaching server. Please reload this page.")
      );
  }

  follow(username) {
    axios
      .post(`${process.env.REACT_APP_API_HOST}/api/users/follow/${username}`)
      .then((res) => {
        // console.log(res.data);
        if (res.data.success) {
          this.setState({ reloadProfile: true });
          this.getFollowing();
          notification["success"]({
            message: "Success",
            description: res.data.message,
            placement: "bottomRight",
          });
        } else {
          notification["error"]({
            message: "Error",
            description: res.data.message,
            placement: "bottomRight",
          });
        }
      })
      .catch((err) =>
        message.error(
          "There is some error in reaching server. Please reload this page."
        )
      );
  }

  followHashtag() {
    axios
      .post(
        `${process.env.REACT_APP_API_HOST}/api/hashtags/follow/${this.state.hashtag}`
      )
      .then((res) => {
        // console.log(res.data);
        if (res.data.success) {
          this.setState({ reloadProfile: true });
          this.getFollowing();
          notification["success"]({
            message: "Success",
            description: res.data.message,
            placement: "bottomRight",
          });
        } else message.error(res.data.message);
      });
  }

  getFollowing() {
    axios
      .get(`${process.env.REACT_APP_API_HOST}/api/users/following`)
      .then((res) => {
        // console.log(res.data);
        if (res.data.success) {
          this.setState({
            people: res.data.people,
            topics: res.data.topics,
          });
        } else message.error(res.data.message);
      });
  }

  deleteNotebook(notebook) {
    let deleteNotebook = () =>
      axios
        .post(`${process.env.REACT_APP_API_HOST}/api/notebooks/delete`, {
          notebookId: notebook.id,
        })
        .then((res) => {
          if (res.data.success) {
            notification["success"]({
              message: "Success",
              description: res.data.message,
              placement: "bottomRight",
            });
            this.getNotebooks();
          } else {
            notification["error"]({
              message: "error",
              description: res.data.message,
              placement: "bottomRight",
            });
          }
        });

    Modal.confirm({
      title: `Are you ABSOLUTELY sure you want to delete notebook ${notebook.name}?`,
      icon: <ExclamationCircleOutlined />,
      content: (
        <div
          style={{
            fontSize: "12px",
            marginTop: "10px",
          }}
        >
          <span style={{ fontWeight: "500" }}>WARNING:</span> Deleting this
          notebook will{" "}
          <span style={{ fontWeight: "500" }}>
            permanently delete all its contents.
          </span>
        </div>
      ),
      okText: "Delete",
      okType: "danger",
      onOk: () => {
        deleteNotebook();
      },
      onCancel() {},
    });
  }

  render() {
    const { user, theme, isDisconnected, isSmallDevice } = this.props.auth;
    const {
      selectedNotebook,
      selectedNote,
      leftPanelVisible,
      leftPanelDisplay,
      leftPanelWidth,
      rightPanelDisplay,
      profileUsername,
      edit,
      sets,
      isSidebarShrunk,
    } = this.state;

    // console.log(this.state.editorsCount);
    // console.log(this.state.editorsIds);
    // console.log(this.state.editors);

    const NewNotebookModal = (
      <Modal
        visible={this.state.newNotebookModalVisible}
        onCancel={(e) => this.setState({ newNotebookModalVisible: false })}
        title="Create New Notebook"
        okText="Create"
        onOk={() => {
          this.addNotebook();
          this.setState({ newNotebookModalVisible: false });
        }}
      >
        <div style={{ fontWeight: "500" }}>
          Name:{" "}
          <Input
            autoFocus
            onChange={(e) =>
              this.setState({
                newNotebook: {
                  ...this.state.newNotebook,
                  name: e.target.value,
                },
              })
            }
            onPressEnter={() => {
              this.addNotebook();
              this.setState({ newNotebookModalVisible: false });
            }}
          />
          <div style={{ height: "15px" }} />
        </div>
      </Modal>
    );

    const SharingModal = (
      <Modal
        title="Share"
        visible={this.state.sharingModalVisible}
        onCancel={() => this.setState({ sharingModalVisible: false })}
        onOk={() =>
          selectedNotebook ? this.shareNotebook() : this.shareNote()
        }
        okText={
          this.state.editors.length > 0 || this.state.viewers.length > 0
            ? "Share"
            : "Save"
        }
      >
        <div
          style={{
            fontSize: "16px",
            fontWeight: "500",
            // display: "table",
            width: "100%",
            overflowWrap: "break-word",
          }}
        >
          {(selectedNotebook || selectedNote) && (
            <table style={{ width: "100%" }}>
              <tr />
              <tr>
                <td>
                  <span>{selectedNotebook ? "Notebook:" : "Note:"}</span>
                </td>
                <td>
                  <span style={{ fontWeight: "500" }}>
                    {selectedNotebook
                      ? selectedNotebook.name
                      : selectedNote.title.length > 0
                      ? selectedNote.title
                      : "[Untitled]"}
                  </span>
                  {((selectedNotebook &&
                    selectedNotebook.sharing === "private") ||
                    (selectedNote &&
                      selectedNote.sharing === "private" &&
                      selectedNote.notebookSharing === "private")) && (
                    <span
                      style={{
                        fontWeight: "400",
                        marginLeft: "10px",
                        fontSize: "14px",
                      }}
                    >
                      <Tooltip placement="right" title="Private">
                        <i className="fas fa-lock"></i>
                      </Tooltip>
                    </span>
                  )}
                  {((selectedNotebook &&
                    selectedNotebook.sharing === "shared") ||
                    (selectedNote &&
                      ((selectedNote.sharing === "shared" &&
                        selectedNote.notebookSharing === "private") ||
                        (selectedNote.sharing === "private" &&
                          selectedNote.notebookSharing === "shared") ||
                        (selectedNote.sharing === "shared" &&
                          selectedNote.notebookSharing === "shared")))) && (
                    <span
                      style={{
                        fontWeight: "400",
                        marginLeft: "10px",
                        fontSize: "14px",
                      }}
                    >
                      <Tooltip
                        placement="right"
                        title={
                          selectedNote &&
                          selectedNote.notebookSharing === "shared"
                            ? "In shared notebook"
                            : "Shared"
                        }
                      >
                        <i className="fas fa-share-alt"></i>
                      </Tooltip>
                    </span>
                  )}
                  {((selectedNotebook &&
                    selectedNotebook.sharing === "published") ||
                    (selectedNote &&
                      (selectedNote.sharing === "published" ||
                        selectedNote.notebookSharing === "published"))) && (
                    <span
                      style={{
                        fontWeight: "400",
                        marginLeft: "10px",
                        fontSize: "14px",
                      }}
                    >
                      <Tooltip
                        placement="right"
                        title={
                          selectedNote &&
                          selectedNote.notebookSharing === "published"
                            ? "In published notebook"
                            : "Published"
                        }
                      >
                        <i className="fas fa-globe"></i>
                      </Tooltip>
                    </span>
                  )}
                </td>
              </tr>
              <tr style={{ height: "10px" }} />
              <tr>
                <td>
                  <span>Link:</span>
                </td>
                <td>
                  <Input
                    id="link"
                    value={
                      selectedNotebook
                        ? `${process.env.REACT_APP_HOST_ADDRESS}/?notebook=${selectedNotebook.id}`
                        : `${process.env.REACT_APP_HOST_ADDRESS}/?note=${this.state.selectedNote.id}`
                    }
                    readOnly
                    size="small"
                    //width={250}
                    style={{
                      //width: "100%"
                      // display: "table-cell",
                      marginRight: selectedNotebook ? "30px" : "0px",
                    }}
                    addonAfter={
                      <Tooltip title="Copy to clipboard">
                        <span
                          style={{ color: "green" }}
                          onClick={() => {
                            message.success("Link copied to clipboard.");
                            navigator.clipboard.writeText(
                              selectedNotebook
                                ? `${process.env.REACT_APP_HOST_ADDRESS}/?notebook=${selectedNotebook.id}`
                                : `${process.env.REACT_APP_HOST_ADDRESS}/?note=${this.state.selectedNote.id}`
                            );
                          }}
                        >
                          <i className="fas fa-clipboard"></i>
                        </span>
                      </Tooltip>
                    }
                  />
                </td>
              </tr>
              <tr style={{ height: "10px" }} />
              <tr>
                <td>
                  Protection:{" "}
                  <span style={{ fontSize: "12px", color: "#aaa" }}>
                    <Tooltip title="Protection provides extra layer of privacy. Protected notebooks cannot be shared or published.">
                      <i className="fas fa-info-circle"></i>
                    </Tooltip>
                  </span>
                </td>
                <td>
                  {" "}
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={selectedNotebook && selectedNotebook.isProtected}
                    onChange={() =>
                      selectedNotebook
                        ? this.changeNotebookProtection(selectedNotebook)
                        : message.warn(
                            "Protection can only be changed on notebooks."
                          )
                    }
                  />
                </td>
              </tr>
            </table>
          )}

          <div style={{ height: "20px" }} />
          <div
            style={{
              display:
                (selectedNotebook && selectedNotebook.isProtected && "none") ||
                (selectedNote && selectedNote.isProtected && "none"),
            }}
          >
            {/* Viewers Div  */}
            {this.state.loadingSharers ? (
              <Loader />
            ) : (
              <>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    border: "1px solid #d2d2d2",
                  }}
                >
                  <div style={{ width: "120px", margin: "5px" }}>
                    Can View: &nbsp;&nbsp;
                    {this.state.viewersCount}
                  </div>
                  <div
                    style={{
                      display:
                        this.state.viewersCount > 0 ? "inline-block" : "none",
                      //border: "1px solid #d1d1d1",
                      width: "100%",
                      maxHeight: "300px",
                      overflowY: "scroll",
                      fontSize: "14px",
                      //  fontWeight: "400",
                    }}
                  >
                    {this.state.viewers &&
                      this.state.viewers.map((user) => (
                        <div
                          style={{
                            display: "inline-block",
                            margin: "5px",
                            padding: "4px",
                            border: "1px solid #d1d1d1",
                          }}
                        >
                          <Link to={`/${user.username}`} target="_blank">
                            &nbsp;{user.name} (@{user.username})
                            <Avatar
                              src={`https://${user.display_picture.downloadUrl}`}
                              size={20}
                              style={{ float: "left" }}
                            />
                          </Link>
                          <Button
                            icon={<i class="far fa-times"></i>}
                            type="link"
                            size="small"
                            style={{ color: theme.color }}
                            onClick={() =>
                              this.setState({
                                viewers: this.state.viewers.filter(
                                  (viewer) => viewer.id !== user.id
                                ),
                                viewersIds: this.state.viewersIds.filter(
                                  (id) => id !== user.id
                                ),
                                viewersCount:
                                  parseInt(this.state.viewersCount) - 1,
                              })
                            }
                          ></Button>
                        </div>
                      ))}
                  </div>
                  <Select
                    //mode="multiple"
                    value={this.state.selected}
                    showSearch
                    placeholder="Enter name or username of people you want to share with"
                    style={{ width: "100%" }}
                    onSearch={(value) => this.getUsers(value)}
                    onSelect={(e) => {
                      if (!this.state.viewersIds.includes(e))
                        this.setState({
                          viewers: [
                            ...this.state.viewers,
                            this.state.userlist.filter(
                              (user) => user.id === e
                            )[0],
                          ],
                          viewersIds: [...this.state.viewersIds, e],
                          viewersCount: parseInt(this.state.viewersCount) + 1,
                          selected: null,
                        });
                      else {
                        this.setState({ selected: null });
                      }
                    }}
                    allowClear
                    notFoundContent={null}
                    // loading={!this.state.userlist}
                    filterOption={false}
                    //defaultValue={this.state.viewers}
                  >
                    {this.state.userlist &&
                      this.state.userlist.map((user) => (
                        <Select.Option
                          style={{ fontWeight: "500" }}
                          value={user.id}
                          key={user.id}
                        >
                          <div style={{ height: "20px", marginLeft: "5px" }}>
                            &nbsp;{user.name} (@{user.username})
                            <Avatar
                              src={`https://${user.display_picture.downloadUrl}`}
                              size={20}
                              style={{ float: "left" }}
                            />
                            {/* <br />
                      {user.name} */}
                          </div>
                        </Select.Option>
                      ))}
                  </Select>
                </div>
                <div style={{ height: "15px" }} />
                {/* Editors Div  */}
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    border: "1px solid #d2d2d2",
                  }}
                >
                  <div style={{ width: "120px", margin: "5px" }}>
                    Can Edit: &nbsp;&nbsp;
                    {this.state.editorsCount}
                  </div>
                  <div
                    style={{
                      display:
                        this.state.editorsCount > 0 ? "inline-block" : "none",
                      // border: "1px solid #d1d1d1",
                      width: "100%",
                      maxHeight: "300px",
                      overflowY: "scroll",
                      fontSize: "14px",
                      // fontWight: "500"
                    }}
                  >
                    {this.state.editors &&
                      this.state.editors.map((user) => (
                        <div
                          style={{
                            display: "inline-block",
                            margin: "5px",
                            padding: "4px",
                            border: "1px solid #d1d1d1",
                          }}
                        >
                          <Link to={`/${user.username}`} target="_blank">
                            &nbsp;{user.name} (@{user.username})
                            <Avatar
                              src={`https://${user.display_picture.downloadUrl}`}
                              size={20}
                              style={{ float: "left" }}
                            />
                          </Link>
                          <Button
                            icon={<i class="far fa-times"></i>}
                            type="link"
                            size="small"
                            style={{ color: theme.color }}
                            onClick={() =>
                              this.setState({
                                editors: this.state.editors.filter(
                                  (viewer) => viewer.id !== user.id
                                ),
                                editorsIds: this.state.editorsIds.filter(
                                  (id) => id !== user.id
                                ),
                                editorsCount:
                                  parseInt(this.state.editorsCount) - 1,
                              })
                            }
                          ></Button>
                        </div>
                      ))}
                  </div>
                  <Select
                    //mode="multiple"
                    value={this.state.selected}
                    showSearch
                    placeholder="Enter name or username of people you want to share with"
                    style={{ width: "100%" }}
                    onSearch={(value) => this.getUsers(value)}
                    onSelect={(e) => {
                      if (!this.state.editorsIds.includes(e))
                        this.setState({
                          editors: [
                            ...this.state.editors,
                            this.state.userlist.filter(
                              (user) => user.id === e
                            )[0],
                          ],
                          editorsIds: [...this.state.editorsIds, e],
                          editorsCount: parseInt(this.state.editorsCount) + 1,
                          selected: null,
                        });
                      else {
                        this.setState({ selected: null });
                      }
                    }}
                    allowClear
                    notFoundContent={null}
                    // loading={!this.state.userlist}
                    filterOption={false}
                    //defaultValue={this.state.viewers}
                  >
                    {this.state.userlist &&
                      this.state.userlist.map((user) => (
                        <Select.Option
                          style={{ fontWeight: "500" }}
                          value={user.id}
                          key={user.id}
                        >
                          <div style={{ height: "20px", marginLeft: "5px" }}>
                            &nbsp;{user.name} (@{user.username})
                            <Avatar
                              src={`https://${user.display_picture.downloadUrl}`}
                              size={20}
                              style={{ float: "left" }}
                            />
                            {/* <br />
                      {user.name} */}
                          </div>
                        </Select.Option>
                      ))}
                  </Select>
                </div>
              </>
            )}
            <div
              style={{
                fontSize: "12px",
                fontWeight: "500",
                border: "1px solid #d2d2d2",
                width: "80%",
                margin: "25px auto",
                display:
                  this.state.selectedNotebook ||
                  (this.state.selectedNote &&
                    this.state.selectedNote.sharing === "private")
                    ? "block"
                    : "none",
              }}
            >
              {this.state.selectedNotebook ? (
                <>CAUTION: Sharing this notebook will share all its content.</>
              ) : this.state.selectedNote &&
                this.state.selectedNote.sharing === "private" ? (
                <>
                  {" "}
                  CAUTION: We advise you to double check for any information
                  that might be private before you share this note.
                </>
              ) : null}
            </div>
          </div>
        </div>
      </Modal>
    );

    const leftPanelContent = () => {
      switch (leftPanelDisplay) {
        case "feed":
          return (
            <FeedLeftPanel
              theme={theme}
              isSmallDevice={isSmallDevice}
              closeLeftPanel={() => {
                localStorage.setItem("leftPanelDisplay", false);
                this.setState({ leftPanelVisible: false });
              }}
              leftPanelWidth={leftPanelWidth}
              expandedView={() => this.setState({ rightPanelDisplay: "feed" })}
              getFollowing={() => this.getFollowing()}
              topics={this.state.topics}
              people={this.state.people}
            />
          );
        case "myNotebooks":
          return (
            <MyNotebooks
              theme={theme}
              userId={user.id}
              notebooks={this.state.notebooks}
              newNotebook={() =>
                this.setState({ newNotebookModalVisible: true })
              }
              getNotebooks={(params) => this.getNotebooks(params)}
              leftPanelWidth={leftPanelWidth}
              selectNote={(note) =>
                this.setState({
                  selectedNote: note,
                  edit: false,
                  rightPanelDisplay: null,
                  selectedNotebook: null,
                })
              }
              reverseNotebooks={() =>
                this.setState({
                  notebooks: this.state.notebooks.reverse(),
                })
              }
              deleteNote={(note) => this.deleteNote(note)}
              // When note is moved or copied from one notebook to another
              notebooksChanged={this.state.notebooksChanged}
              // To update child all notes component on note delete or change
              noteDeleted={this.state.noteDeleted}
              noteUpdated={this.state.noteEdited}
              selectedNote={this.state.selectedNote}
              shareNotebook={(notebook) => {
                this.setState(
                  {
                    sharingModalVisible: true,
                  },
                  () => this.getNotebooks({ id: notebook.id })
                );
              }}
              showSharingModal={() =>
                this.setState({
                  sharingModalVisible: true,
                })
              }
              publishNotebookClick={(notebook) =>
                this.publishNotebook(notebook)
              }
              selectNotebook={(notebook) =>
                this.getNotebooks({ id: notebook.id })
              }
              changeProtection={(notebook) =>
                this.changeNotebookProtection(notebook)
              }
              publishNote={() => this.publishNote()}
              moveNote={(params) => this.moveNote(params)}
              copyNote={(params) => this.copyNote(params)}
              noteChanged={this.state.noteChanged}
              sets={sets}
              myNotebooks
              onNotebookRename={(id, newName) => {
                let notebooks = this.state.notebooks.map((n) => {
                  if (n.id === id) n.name = newName;
                  return n;
                });
                this.setState({ notebooks });
              }}
            />
          );
        case "allNotes":
          return (
            <AllNotes
              theme={theme}
              isSmallDevice={isSmallDevice}
              selectNote={(note) =>
                this.setState({
                  selectedNote: note,
                  edit: false,
                  rightPanelDisplay: null,
                  selectedNotebook: null,
                })
              }
              notebooks={this.state.notebooks}
              //onDeleteClick={this.onNoteDelete}
              leftPanelWidth={leftPanelWidth}
              isResizing={this.state.isResizing}
              deleteNote={(note) => this.deleteNote(note)}
              searchQuery={this.state.searchQuery}
              noteUpdated={this.state.noteEdited}
              selectedNote={this.state.selectedNote}
              noteDeleted={this.state.noteDeleted}
              showSharingModal={() =>
                this.setState({
                  sharingModalVisible: true,
                })
              }
              publish={() => this.publishNote()}
              moveNote={(params) => this.moveNote(params)}
              copyNote={(params) => this.copyNote(params)}
              noteChanged={this.state.noteChanged}
            />
          );
        case "shared":
          return (
            <Shared
              theme={theme}
              leftPanelWidth={leftPanelWidth}
              selectNote={(note) => this.getNotes(note.id)}
              notebooks={this.state.notebooks}
              isResizing={this.state.isResizing}
              deleteNote={(note) => this.deleteNote(note)}
              noteUpdated={this.state.noteEdited}
              selectedNote={this.state.selectedNote}
              noteDeleted={this.state.noteDeleted}
              selectNotebook={(notebook) =>
                this.getNotebooks({
                  id: notebook.id,
                })
              }
            />
          );
        case "saved":
          return (
            <Saved
              theme={theme}
              selectNote={(note) => this.getNotes(note.id)}
              selectedNote={this.state.selectedNote}
              noteDeleted={this.state.noteDeleted}
              reloadNotebook={this.state.reloadNotebook}
            />
          );
        case "publications":
          return <Publications />;
        default:
          return null;
      }
    };

    const rightPanelContent = () => {
      switch (rightPanelDisplay) {
        case "explore":
          return (
            <Explore
              exploreOption={this.props.match.params.option}
              saveNote={(nId, nbId) => this.saveNote(nId, nbId)}
              saveNotebook={(nbId) => this.saveNotebook(nbId)}
              userNotebooks={this.state.notebooks}
              reloadNote={this.state.reloadNotes}
              reloadNotebook={this.state.reloadNotebook}
            />
          );
        case "feed":
          return (
            <FeedRightPanel
              theme={theme}
              userNotebooks={this.state.notebooks}
              saveNote={(noteId, notebookId) =>
                this.saveNote(noteId, notebookId)
              }
              reloadNotes={this.state.reloadNotes}
              topics={this.state.topics}
              people={this.state.people}
            />
          );
        case "profile":
          return (
            <Profile
              theme={theme}
              isSmallDevice={isSmallDevice}
              username={profileUsername}
              userId={user.id}
              userNotebooks={this.state.notebooks}
              saveNote={(noteId, notebookId) =>
                this.saveNote(noteId, notebookId)
              }
              follow={(username) => this.follow(username)}
              saveNotebook={(notebookId) => this.saveNotebook(notebookId)}
              reloadProfile={this.state.reloadProfile}
              reloadNote={this.state.reloadNotes}
              reloadNotebook={this.state.reloadNotebook}
            />
          );
        case "hashtag":
          return (
            <Hashtag
              hashtag={this.state.hashtag}
              theme={theme}
              userId={user.id}
              isSmallDevice={isSmallDevice}
              userNotebooks={this.state.notebooks}
              saveNote={(noteId, notebookId) =>
                this.saveNote(noteId, notebookId)
              }
              reloadNote={this.state.reloadNotes}
              followHashtag={() => this.followHashtag()}
              reloadHashtagInfo={this.state.reloadProfile}
              saveNotebook={(nbId) => this.saveNotebook(nbId)}
              reloadNotebook={this.state.reloadNotebook}

              // saveNotebook={(notebookId) => this.saveNotebook(notebookId)}
            />
          );
        case "notebook":
          return (
            <Notebookview
              theme={theme}
              isSmallDevice={isSmallDevice}
              key={this.state.selectedNotebook.id}
              userId={user.id}
              notebook={this.state.selectedNotebook}
              showSharingModal={() =>
                this.setState({ sharingModalVisible: true })
              }
              publishNotebookClick={(notebook) =>
                this.publishNotebook(notebook)
              }
              editNotebook={(newNotebook) => this.editNotebook(newNotebook)}
              selectNote={(noteId) => this.getNotes(noteId)}
              saveNotebook={(notebookId) => this.saveNotebook(notebookId)}
              deleteNotebook={(notebook) => this.props.deleteNotebook(notebook)}
            />
          );
        default:
          return selectedNote ? (
            !edit ? (
              <Noteview
                theme={theme}
                isSmallDevice={isSmallDevice}
                note={this.state.selectedNote}
                notebook={
                  this.state.notebooks &&
                  this.state.notebooks.filter(
                    (notebook) =>
                      notebook.id === this.state.selectedNote.notebookId
                  )[0]
                }
                notebooks={this.state.notebooks}
                userId={user.id}
                onEdit={() => this.setState({ edit: true })}
                deleteNote={(note) => this.deleteNote(note)}
                isResizing={this.state.isResizing}
                sharingModalVisible={this.state.sharingModalVisible}
                showSharingModal={() =>
                  this.setState({ sharingModalVisible: true })
                }
                publishNote={() => this.publishNote()}
                moveNote={(params) => this.moveNote(params)}
                copyNote={(params) => this.copyNote(params)}
                saveNote={(noteId, notebookId) =>
                  this.saveNote(noteId, notebookId)
                }
                selectNotebook={(notebook) =>
                  this.getNotebooks({ id: notebook.id })
                }
              />
            ) : (
              <EditorWrapper
                theme={theme}
                isSmallDevice={isSmallDevice}
                isDisconnected={isDisconnected}
                leftPanelVisible={leftPanelVisible}
                leftPanelWidth={leftPanelWidth}
                notebooks={this.state.notebooks}
                note={this.state.selectedNote}
                userId={user.id}
                deleteNote={(note) => this.deleteNote(note)}
                onUpdate={(note) => this.onNoteEdit(note, false)}
                // key={
                //   this.state.selectedNote
                //     ? this.state.selectedNote.id
                //     : this.state.selectedNote
                // }
                key={this.state.noteDeleted || this.state.keyForEditor}
                showSharingModal={() =>
                  this.setState({ sharingModalVisible: true })
                }
                publishNote={() => this.publishNote()}
                newNotebook={() =>
                  this.setState({ newNotebookModalVisible: true })
                }
                notebooksChanged={(arr) =>
                  this.setState({ notebooksChanged: arr })
                }
                moveNote={(params) => this.moveNote(params)}
                doneEditing={() =>
                  this.setState({ edit: false, selectedNotebook: false })
                }
              />
            )
          ) : (
            <EditorWrapper
              theme={theme}
              isSmallDevice={isSmallDevice}
              isDisconnected={isDisconnected}
              leftPanelVisible={leftPanelVisible}
              leftPanelWidth={leftPanelWidth}
              notebooks={this.state.notebooks}
              userId={user.id}
              onUpdate={(note) => this.onNoteEdit(note, true)}
              // key={
              //   this.state.selectedNote
              //     ? this.state.selectedNote.id
              //     : this.state.selectedNote
              // }
              key={this.state.noteDeleted || this.state.keyForEditor}
              deleteNote={(note) => this.deleteNote(note)}
              newNotebook={() =>
                this.setState({ newNotebookModalVisible: true })
              }
              notebooksChanged={(arr) =>
                this.setState({ notebooksChanged: arr })
              }
              moveNote={(params) => this.moveNote(params)}
              doneEditing={() =>
                this.setState({ edit: false, selectedNotebook: false })
              }
            />
          );
      }
    };

    const content = (
      <>
        <Sidebar
          onShrink={(e) =>
            !isSmallDevice && this.setState({ isSidebarShrunk: e })
          }
          newnotepress={() => {
            this.props.toggleMobileSidebar();
            if (isSmallDevice) localStorage.setItem("leftPanelDisplay", false);
            this.setState({
              selectedNote: null,
              edit: false,
              rightPanelDisplay: null,
              selectedNotebook: null,
              ...(isSmallDevice && { leftPanelVisible: false }),
              keyForEditor: this.state.keyForEditor + 1,
            });
          }}
          onFeedClick={() => {
            this.props.toggleMobileSidebar();
            localStorage.setItem("leftPanelDisplay", "feed");
            this.setState({
              leftPanelVisible: true,
              leftPanelDisplay: "feed",
              // rightPanelDisplay: "feed",
            });
          }}
          myNotebooks={() => {
            this.props.toggleMobileSidebar();
            localStorage.setItem("leftPanelDisplay", "myNotebooks");
            this.setState({
              leftPanelVisible: true,
              leftPanelDisplay: "myNotebooks",
              edit: false,
            });
          }}
          allNotes={() => {
            this.props.toggleMobileSidebar();
            localStorage.setItem("leftPanelDisplay", "allNotes");
            this.setState({
              leftPanelVisible: true,
              leftPanelDisplay: "allNotes",
              edit: false,
            });
          }}
          publications={() => {
            this.props.toggleMobileSidebar();
            localStorage.setItem("leftPanelDisplay", "publications");
            this.setState({
              leftPanelVisible: true,
              leftPanelDisplay: "publications",
              edit: false,
            });
          }}
          saved={() => {
            this.props.toggleMobileSidebar();
            localStorage.setItem("leftPanelDisplay", "saved");
            this.setState({
              leftPanelVisible: true,
              leftPanelDisplay: "saved",
              edit: false,
            });
          }}
          shared={() => {
            this.props.toggleMobileSidebar();
            localStorage.setItem("leftPanelDisplay", "shared");
            this.setState({
              leftPanelVisible: true,
              leftPanelDisplay: "shared",
              edit: false,
            });
          }}
        />
        <div
          className="dashboard-container"
          style={{
            paddingLeft: isSmallDevice
              ? "0px"
              : isSidebarShrunk
              ? "70px"
              : "200px",
            maxWidth: "100%",
            width: "100%",
            backgroundColor: theme.backgroundColor2,
          }}
          ref={this.containerRef}
        >
          {/* Sharing Modal */}
          {SharingModal}
          {/* New Notebook Modal */}
          {NewNotebookModal}
          {/* Left Panel Div */}
          <SplitPane
            minSize={isSmallDevice ? 260 : 320}
            maxSize={isSmallDevice ? window.innerWidth : 760}
            split="vertical"
            defaultSize={isSmallDevice ? window.innerWidth : leftPanelWidth}
            style={{
              marginLeft: isSmallDevice
                ? "0px"
                : isSidebarShrunk
                ? "70px"
                : "200px",
              height: "calc(100vh - 50px)",
              overflow: "auto",
              // display: "auto",
              overflowX: "hidden",
            }}
            onDragFinished={(e) => {
              // console.log(e);
              this.setState({ leftPanelWidth: e });
            }}
            paneStyle={!leftPanelVisible ? { width: "100%" } : {}}
            pane2Style={{
              width: !isSmallDevice
                ? isSidebarShrunk
                  ? `${window.innerWidth - leftPanelWidth - 70}px`
                  : `${window.innerWidth - leftPanelWidth - 200}px`
                : "auto",
            }}
            allowResize={isSmallDevice ? false : leftPanelVisible}
          >
            {leftPanelVisible && (
              <div
                className="left-panel"
                id="left-panel-id"
                style={{
                  backgroundColor: theme.backgroundColor,
                }}
              >
                {leftPanelContent()}
                {/* Close Button */}
                <Button
                  icon={<i className="far fa-times"></i>}
                  style={{
                    position: "absolute",
                    top: "1px",
                    right: "2px",
                    color: theme.mode === "light" ? "#555555" : "#CCC",
                    zIndex: "5",
                  }}
                  size="large"
                  type="link"
                  onClick={() => {
                    localStorage.setItem("leftPanelDisplay", false);
                    this.setState({ leftPanelVisible: false });
                  }}
                ></Button>
              </div>
            )}
            {/* Right Panel Div */}
            <div
              className="right-panel"
              style={{
                backgroundColor: theme.backgroundColor2,
                color: theme.color,
                minWidth: isSmallDevice ? "100%" : "500px",
                overflow: "auto",
                width: "100%",
                maxWidth: "100%",
                // border: "1px solid red",
              }}
            >
              {rightPanelContent()}
            </div>
          </SplitPane>
        </div>
      </>
    );
    return this.state.loading ? <Loader /> : content;
  }
}

Dashboard.propTypes = {
  auth: propTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default withRouter(
  connect(mapStateToProps, { toggleMobileSidebar })(Dashboard)
);
