import React from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  Menu,
  Button,
  // Avatar,
} from "antd";
import moment from "moment";
import ProfileBoxItem from "./ProfileBoxItem";

function NoteItem({
  note,
  style,
  userId,
  theme,
  userNotebooks,
  saveNote,
  topics = null,
}) {
  let displayText = new DOMParser()
    .parseFromString(note.htmlContent, "text/html")
    .getElementsByTagName("p");
  displayText = Array.from(displayText).filter(
    (element) => element.textContent && element.textContent.trim().length > 0
  );

  displayText = (displayText[0] && displayText[0].textContent) || null;
  if (displayText && displayText.length > 200)
    displayText = displayText.substr(0, 200);

  let tagArray =
    note.hashtagsArray && topics
      ? topics.map((topic) =>
          note.hashtagsArray.filter((hashtag) => hashtag === topic.title)
        )[0]
      : note.hashtagsArray && note.hashtagsArray.map((hashtag) => hashtag);

  let displayTag = note.hashtagsArray && tagArray && tagArray[0];
  displayTag =
    displayTag || (note.hashtagsArray && note.hashtagsArray[0]) || null;

  return (
    <div
      className="feed-note-item"
      style={{
        border: `1px solid ${theme.borderColor1}`,
        // margin: "50px auto",
        maxWidth: "700px",
        width: "auto",
        borderRadius: "5px",
        color: theme.color,
        position: "relative",
        ...style,
      }}
    >
      <div
        style={{
          fontSize: "22px",
          fontWeight: "500",
          padding: "5px 15px",
          paddingRight: "25px",
        }}
      >
        <Link to={`/?note=${note.id}`} style={{ color: theme.color }}>
          {" "}
          {note.title || "[Untitled]"}{" "}
        </Link>
        {/* Save element */}
        <span
          style={{
            float: "right",
            fontSize: "16px",
            fontWeight: "500",
            // display: "none",
            position: "absolute",
            top: "5px",
            right: "10px",
          }}
        >
          {/* <Tooltip
            title={`Saved by ${note.temp.saverCount} ${
              note.temp.saverCount === 1 ? "person" : "people"
            }`}
          >
            {note.temp.saverCount}&nbsp;
          </Tooltip> */}
          <Dropdown
            // disabled={}
            overlay={
              note.authorId === userId ? (
                <Menu />
              ) : (
                <Menu>
                  {userNotebooks.map((notebook) => (
                    <Menu.Item
                      key={notebook.id}
                      onClick={() => {
                        if (note.authorId === userId) return;
                        saveNote(note.id, notebook.id);
                      }}
                    >
                      {note.temp &&
                      note.temp.savedIn &&
                      note.temp.savedIn.filter((n) => n.id === notebook.id)
                        .length > 0 ? (
                        <span style={{}}>
                          <i className="fas fa-check"></i>
                        </span>
                      ) : (
                        <span
                          style={{
                            display: "inline-block",
                            width: "15px",
                          }}
                        />
                      )}
                      &nbsp;&nbsp;{notebook.name}
                    </Menu.Item>
                  ))}
                </Menu>
              )
            }
          >
            <Button
              size="small"
              type="link"
              onClick={() => {
                if (note.authorId === userId) return;
                saveNote(note.id);
              }}
              style={{
                padding: "0",
                display: note.authorId === userId && "none",
              }}
            >
              {note.temp.savedIn && note.temp.savedIn.length > 0 ? (
                <i className="fas fa-bookmark"></i>
              ) : (
                <i className="fal fa-bookmark"></i>
              )}
            </Button>
          </Dropdown>
        </span>
        <div
          style={{
            fontSize: "12px",
            color: "#919191",
            margin: "5px 0px",
          }}
        >
          {moment(note.createdAt).format("DD MMM, YYYY")}
          {note.temp.notebook && (
            <div
              style={{
                fontSize: "12px",
                // marginTop: "5px",
                fontWeight: "500",
                float: "right",
                color: theme.color,
              }}
            >
              <Link to={`/?notebook=${note.temp.notebook.id}`}>
                {note.temp.notebook.name}
              </Link>
            </div>
          )}
        </div>
      </div>
      <div style={{ padding: "10px" }}>
        {/* by:{" "} */}
        <div style={{ marginLeft: "7px" }}>
          <ProfileBoxItem user={note.temp.author} theme={theme} />{" "}
          {displayTag && (
            <div
              style={{ float: "right", fontWeight: "500", marginTop: "10px" }}
            >
              <span style={{ fontWeight: "400" }}>in &nbsp;</span>
              <Link
                to={
                  displayTag &&
                  `/tag/${displayTag.substr(1, displayTag.length - 1)}`
                }
                style={{ color: theme.color }}
              >
                {displayTag}
              </Link>
            </div>
          )}
        </div>
        <div
          className="feed-note-item-description"
          style={{
            margin: "15px 25px",
            fontSize: "14px",
            lineHeight: "1.8",
            fontWeight: "400",
            fontFamily: "Helvetica, Arial, Sans-Serif",
            filter:
              theme.mode === "light" ? `brightness(1.4)` : `brightness(0.8)`,
            // fontWeight: "500",
          }}
        >
          {displayText}
        </div>
        {note.info2 && note.info2.imageArray && note.info2.imageArray[0] && (
          <div>
            <img src={`https://${note.info2.imageArray[0].url}`} alt="" />
          </div>
        )}
      </div>
    </div>
  );
}

export default NoteItem;
