import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Tooltip,
  Menu,
  Dropdown,
  InputNumber,
  Modal,
  Avatar,
  message,
  // Space,
} from "antd";
import {
  FontSizeOutlined,
  LineHeightOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import Loader from "../common/Loader";
// import Quill from "react-quill";
import moment from "moment";
import TinyEditor from "./../editor/TinyEditor";
import ActionPanel from "../actionPanel/ActionPanel";

const { SubMenu } = Menu;

export class Noteview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editortToolbarVisible: false,
      editorFontSize:
        this.props.note &&
        this.props.note.info1 &&
        this.props.note.info1.fontSize
          ? this.props.note.info1.fontSize
          : 18,
      editorLineHeight:
        this.props.note &&
        this.props.note.info1 &&
        this.props.note.info1.lineHeight
          ? this.props.note.info1.lineHeight
          : 2.0,
      editorKey: 0,
      optimizeReadability:
        localStorage.optimizeReadability === "false" ? false : true,
    };
  }
  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.note.id === this.props.note.id) {
      if (
        prevState.editorFontSize !== this.state.editorFontSize ||
        prevState.editorLineHeight !== this.state.editorLineHeight ||
        prevProps.theme.mode !== this.props.theme.mode
      ) {
        this.setState({ editorKey: this.state.editorKey + 1 });
      }
    } else {
      this.setState({
        editorFontSize:
          this.props.note &&
          this.props.note.info1 &&
          this.props.note.info1.fontSize
            ? this.props.note.info1.fontSize
            : 18,
        editorLineHeight:
          this.props.note &&
          this.props.note.info1 &&
          this.props.note.info1.lineHeight
            ? this.props.note.info1.lineHeight
            : 2.0,
      });
    }
  }

  render() {
    const { note, userId, theme, isSmallDevice } = this.props;

    // console.log(JSON.stringify(note.deltaContent));
    // console.log(note);
    // console.log(JSON.stringify(note.htmlContent))
    // console.log(note.htmlContent);

    const menuIconStyle = {
      display: "inline-block",
      width: "20px",
    };

    const dropdownMenu = (
      <Menu theme={theme.mode}>
        {this.props.notebooks &&
          Array.isArray(this.props.notebooks) &&
          this.props.notebooks.length > 0 && (
            <>
              <SubMenu
                key="copy"
                // icon={<i className="far fa-copy"></i>}
                title={
                  <>
                    <span style={menuIconStyle}>
                      <i className="far fa-copy"></i>
                    </span>{" "}
                    Copy to
                  </>
                }
              >
                {this.props.notebooks.map((notebook) => {
                  return (
                    <SubMenu
                      key={notebook.id}
                      title={
                        <>
                          <span>{notebook.name}</span>
                          {notebook.id === note.notebookId ? (
                            <span style={{ float: "left", width: "20px" }}>
                              <i className="fas fa-check"></i>
                            </span>
                          ) : (
                            <span
                              style={{
                                float: "left",
                                width: "20px",
                                height: "10px",
                              }}
                            />
                          )}
                        </>
                      }
                    >
                      <Menu.Item
                        key="0"
                        onClick={() =>
                          this.props.copyNote({
                            note,
                            notebookId: notebook.id,
                          })
                        }
                      >
                        <div style={{ width: "150px" }}>
                          <span>None</span>
                          {!note.sectionId ? (
                            <span style={{ float: "left", width: "20px" }}>
                              <i className="fas fa-check"></i>
                            </span>
                          ) : (
                            <span
                              style={{
                                float: "left",
                                width: "20px",
                                height: "10px",
                              }}
                            />
                          )}
                        </div>
                      </Menu.Item>
                      {notebook.sections.map((section) => (
                        <Menu.Item
                          key={section.id}
                          onClick={(e) => {
                            e.domEvent.stopPropagation();
                            this.props.copyNote({
                              note,
                              notebookId: notebook.id,
                              sectionId: section.id,
                            });
                          }}
                        >
                          <div style={{ width: "150px" }}>
                            <span>{section.title}</span>
                            {section.id === note.sectionId ? (
                              <span style={{ float: "left", width: "20px" }}>
                                <i className="fas fa-check"></i>
                              </span>
                            ) : (
                              <span
                                style={{
                                  float: "left",
                                  width: "20px",
                                  height: "10px",
                                }}
                              />
                            )}
                            <span
                              style={{
                                display: "inline-block",
                                float: "right",
                                height: "20px",
                                width: "20px",
                                borderRadius: "10px",
                                border: `1px solid ${theme.borderColor1}`,
                                backgroundColor: `${section.color}`,
                              }}
                            ></span>
                          </div>
                        </Menu.Item>
                      ))}
                    </SubMenu>
                  );
                })}
              </SubMenu>
              {this.props.userId === note.authorId && (
                <>
                  <SubMenu
                    // icon={<i className="fas fa-cut"></i>}
                    title={
                      <>
                        {" "}
                        <span style={menuIconStyle}>
                          <i className="fas fa-cut"></i>
                        </span>{" "}
                        Move to
                      </>
                    }
                  >
                    {this.props.notebooks.map((notebook) => {
                      return (
                        <SubMenu
                          key={notebook.id}
                          title={
                            <>
                              <span>{notebook.name}</span>
                              {notebook.id === note.notebookId ? (
                                <span style={{ float: "left", width: "20px" }}>
                                  <i className="fas fa-check"></i>
                                </span>
                              ) : (
                                <span
                                  style={{
                                    float: "left",
                                    width: "20px",
                                    height: "10px",
                                  }}
                                />
                              )}
                            </>
                          }
                        >
                          <Menu.Item
                            key="0"
                            onClick={() =>
                              this.props.moveNote({
                                note,
                                notebookId: notebook.id,
                              })
                            }
                          >
                            <div style={{ width: "150px" }}>
                              <span>None</span>
                              {!note.sectionId ? (
                                <span style={{ float: "left", width: "20px" }}>
                                  <i className="fas fa-check"></i>
                                </span>
                              ) : (
                                <span
                                  style={{
                                    float: "left",
                                    width: "20px",
                                    height: "10px",
                                  }}
                                />
                              )}
                            </div>
                          </Menu.Item>
                          {notebook.sections.map((section) => (
                            <Menu.Item
                              key={section.id}
                              onClick={(e) => {
                                e.domEvent.stopPropagation();
                                this.props.moveNote({
                                  note,
                                  notebookId: notebook.id,
                                  sectionId: section.id,
                                });
                              }}
                            >
                              <div style={{ width: "150px" }}>
                                <span>{section.title}</span>
                                {section.id === note.sectionId ? (
                                  <span
                                    style={{ float: "left", width: "20px" }}
                                  >
                                    <i className="fas fa-check"></i>
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      float: "left",
                                      width: "20px",
                                      height: "10px",
                                    }}
                                  />
                                )}
                                <span
                                  style={{
                                    display: "inline-block",
                                    float: "right",
                                    height: "20px",
                                    width: "20px",
                                    borderRadius: "10px",
                                    border: `1px solid ${theme.borderColor1}`,
                                    backgroundColor: `${section.color}`,
                                  }}
                                ></span>
                              </div>
                            </Menu.Item>
                          ))}
                        </SubMenu>
                      );
                    })}
                  </SubMenu>
                </>
              )}
            </>
          )}

        <Menu.Divider />
        <Menu.Item
          key="deletenote"
          danger
          icon={<i className="fas fa-times"></i>}
          onClick={() =>
            Modal.confirm({
              title: "Are you absolutely sure you want to DELETE this note?",
              icon: <ExclamationCircleOutlined />,
              content: "This deletion is PERMANENT.",
              okText: "Yes",
              okType: "danger",
              cancelText: "No",
              onOk: () => {
                this.props.deleteNote(this.props.note);
              },
              onCancel() {},
            })
          }
        >
          &nbsp;Delete
        </Menu.Item>
      </Menu>
    );

    const content = !note ? (
      <Loader />
    ) : (
      <div
        className="noteview-container"
        style={{
          backgroundColor: theme.backgroundColor2,
          color: theme.color,
          width: isSmallDevice && "100%",
        }}
      >
        <div
          className="noteview-header-actions"
          style={{
            backgroundColor: theme.backgroundColor,
            color: theme.color,
            zIndex: "5",
            width: "100%",
            height: "40px",
            borderBottom: `1px solid ${theme.borderColor1}`,
            position: "sticky",
            top: "0",
            padding: "0px 15px",
          }}
        >
          {/*  Header Actions */}
          <div
            style={{
              display: "inline-block",
              width: "100%",
              // border: "1px solid blue",
            }}
          >
            {note.temp && note.temp.notebook && (
              <Link to={`/?notebook=${note.temp.notebook.id}`}>
                <Tooltip
                  title="Go to notebook"
                  color={theme.mode === "dark" && "white"}
                >
                  <Button
                    icon={<i className="far fa-arrow-left"></i>}
                    style={
                      isSmallDevice
                        ? {
                            color: theme.color,
                            fontWeight: "400",
                            paddingTop: "7px",
                          }
                        : {
                            color: theme.color,
                            fontWeight: "400",
                          }
                    }
                    type="link"
                    size={isSmallDevice ? "middle" : "large"}
                    onClick={(e) => {
                      e.preventDefault();
                      note.temp && note.temp.notebook
                        ? this.props.selectNotebook(note.temp.notebook)
                        : message.error("Notebook not attatched");
                    }}
                  >
                    {/* &nbsp;&nbsp;Go to notebook */}
                  </Button>
                </Tooltip>
              </Link>
            )}
            <span
              style={{
                float: "right",
                display: "inline-block",
              }}
            >
              {!isSmallDevice && (
                <Tooltip
                  title={
                    this.state.optimizeReadability
                      ? "Readability Optimized"
                      : "Optimize Readability"
                  }
                >
                  <Button
                    type={isSmallDevice ? "link" : "text"}
                    ghost={theme.mode === "dark"}
                    style={{
                      color: this.state.optimizeReadability
                        ? theme.primaryColor
                        : theme.color,
                      height: "40px",
                      // borderLeft: `1px solid ${theme.borderColor1}`,
                      borderLeft:
                        note.authorId === userId ||
                        (note.temp &&
                          (note.temp.notePermission === "edit" ||
                            note.temp.notebookPermission === "edit"))
                          ? `1px solid ${theme.borderColor1}`
                          : "none",
                    }}
                    icon={<i className="far fa-glasses"></i>}
                    onClick={() =>
                      this.setState(
                        {
                          optimizeReadability: !this.state.optimizeReadability,
                        },
                        () =>
                          localStorage.setItem(
                            "optimizeReadability",
                            this.state.optimizeReadability
                          )
                      )
                    }
                  ></Button>
                </Tooltip>
              )}
              {(userId === note.authorId ||
                (note.temp &&
                  (note.temp.notePermission === "edit" ||
                    note.temp.notebookPermission === "edit"))) && (
                <Button
                  type={isSmallDevice ? "link" : "text"}
                  ghost={theme.mode === "dark"}
                  style={{
                    color: theme.color,
                    // fontSize: "14px",
                    height: "40px",
                    borderLeft: `1px solid ${theme.borderColor1}`,
                    borderRight: `1px solid ${theme.borderColor1}`,
                    // marginRight: "10px",
                    // ...(isSmallDevice && {
                    //   color: theme.color,
                    //   backgroundColor: theme.backgroundColor,
                    // }),
                  }}
                  icon={<i className="far fa-edit"></i>}
                  onClick={this.props.onEdit}
                >
                  &nbsp;
                  {" Edit"}
                </Button>
              )}

              {userId === note.authorId && (
                <>
                  <Tooltip
                    title={
                      note
                        ? note.isProtected
                          ? "In protected notebook"
                          : note.sharing === "published"
                          ? "Note is published"
                          : note.notebookSharing === "published"
                          ? "In published notebook."
                          : note.sharing === "shared"
                          ? "Note is shared"
                          : note.notebookSharing === "shared"
                          ? "In shared notebook"
                          : "Share"
                        : "Share"
                    }
                  >
                    <Button
                      icon={
                        note.sharing === "private" &&
                        note.notebookSharing === "private" ? (
                          <i className="far fa-lock"></i>
                        ) : (note.sharing === "shared" &&
                            note.notebookSharing === "shared") ||
                          (note.sharing === "private" &&
                            note.notebookSharing === "shared") ||
                          (note.sharing === "shared" &&
                            note.notebookSharing === "private") ? (
                          <i className="fas fa-user-friends"></i>
                        ) : (
                          <i className="far fa-globe"></i>
                        )
                      }
                      type={"text"}
                      // size="small"
                      style={{
                        // fontSize: "12px",
                        color: theme.color,
                        width: !isSmallDevice && "120px",
                        height: !isSmallDevice && "40px",

                        ...(isSmallDevice && {
                          color: theme.color,
                          backgroundColor: theme.backgroundColor,
                        }),
                      }}
                      onClick={() => this.props.showSharingModal()}
                    >
                      &nbsp;
                      {isSmallDevice
                        ? null
                        : note.isProtected
                        ? "Protected"
                        : note.sharing === "private" &&
                          note.notebookSharing === "private"
                        ? "Only You"
                        : note.sharing === "published" ||
                          note.notebookSharing === "published"
                        ? "Public"
                        : "Shared"}
                    </Button>
                  </Tooltip>
                  {!note.isProtected && (
                    <Button
                      style={{
                        height: !isSmallDevice && "38px",
                        width: !isSmallDevice && "120px",
                        ...(isSmallDevice && {
                          color: theme.primaryColor,
                          backgroundColor: theme.backgroundColor,
                        }),
                      }}
                      type={isSmallDevice ? "link" : "primary"}
                      icon={<i className="fas fa-users"></i>}
                      onClick={() => this.props.publishNote()}
                      ghost={
                        theme.mode === "dark" || note.sharing === "published"
                      }
                      disabled={note.isProtected}
                    >
                      &nbsp;
                      {isSmallDevice
                        ? null
                        : note.sharing === "published"
                        ? " Published"
                        : " Publish"}
                    </Button>
                  )}
                  <Dropdown overlay={dropdownMenu} tigger={["click"]}>
                    <Button
                      // ghost={theme.mode === "dark"}
                      type="text"
                      style={{
                        height: "40px",
                        borderRight: `1px solid ${theme.borderColor1}`,
                        borderLeft: `1px solid ${theme.borderColor1}`,
                        color: theme.color,
                      }}
                      icon={<i className="far fa-ellipsis-h"></i>}
                    />
                  </Dropdown>
                </>
              )}
              {/* Show/Hide Text Toolbar */}
              <span style={{ display: "none" }}>
                <div
                  style={{
                    display: "inline-block",
                    // float: "right"
                    // marginLeft: "10px",
                    // margin: "5px 0px",
                  }}
                >
                  {this.state.editorToolbarVisible && (
                    <div
                      style={{
                        padding: "2px 5px",
                        // border: "1px solid #d1d1d1",
                        display: "inline-block",
                      }}
                    >
                      <Tooltip title="Text Size">
                        <FontSizeOutlined />
                        &nbsp;
                        <InputNumber
                          style={{ width: "60px" }}
                          //bordered={false}
                          size="small"
                          step={2}
                          min={1}
                          max={72}
                          keyboard={true}
                          value={this.state.editorFontSize}
                          onChange={(value) =>
                            this.setState({ editorFontSize: value })
                          }
                        />
                      </Tooltip>
                      &nbsp; &nbsp;
                      <Tooltip title="Line Height">
                        <LineHeightOutlined />
                        &nbsp;
                        <InputNumber
                          style={{ width: "60px" }}
                          //bordered={false}
                          size="small"
                          min={1}
                          max={3}
                          step={0.05}
                          keyboard={true}
                          value={this.state.editorLineHeight}
                          onChange={(value) =>
                            this.setState({ editorLineHeight: value })
                          }
                        />
                      </Tooltip>
                      <Button
                        type="link"
                        size="small"
                        onClick={() =>
                          this.setState({
                            editorFontSize: 18,
                            editorLineHeight: 1.8,
                          })
                        }
                      >
                        Reset
                      </Button>
                    </div>
                  )}
                </div>
                <Tooltip
                  title={
                    this.state.editorToolbarVisible
                      ? "Hide Text Options"
                      : "Show Text Options"
                  }
                >
                  <Button
                    style={{ color: theme.color }}
                    type="link"
                    icon={
                      this.state.editorToolbarVisible ? (
                        <i className="far fa-chevron-right"></i>
                      ) : (
                        <i className="far fa-chevron-left"></i>
                      )
                    }
                    onClick={(e) => {
                      // console.log(this.state.showChangedContent);
                      this.setState(
                        {
                          editorToolbarVisible:
                            !this.state.editorToolbarVisible,
                          // showChangedContent: !this.state.showChangedContent,
                        }
                        // this.forceUpdate()
                      );
                    }}
                  ></Button>
                </Tooltip>
              </span>
              {note.authorId !== userId && (
                <Dropdown.Button
                  type="primary"
                  icon={<i className="fas fa-chevron-down"></i>}
                  style={
                    isSmallDevice
                      ? { float: "right", margin: "5px 0px" }
                      : {
                          float: "right",
                          margin: "5px 20px",
                          // height: "40px",
                        }
                  }
                  // ghost={note.temp.savedIn && note.temp.savedIn.length > 0}
                  onClick={() => this.props.saveNote(note.id)}
                  overlay={
                    <Menu onClick={() => {}}>
                      {this.props.notebooks.map((notebook) => (
                        <Menu.Item
                          key={notebook.id}
                          onClick={() =>
                            this.props.saveNote(note.id, notebook.id)
                          }
                        >
                          {note.temp &&
                          note.temp.savedIn &&
                          note.temp.savedIn.filter((n) => n.id === notebook.id)
                            .length > 0 ? (
                            <span style={{}}>
                              <i className="fas fa-check"></i>
                            </span>
                          ) : (
                            <span
                              style={{
                                display: "inline-block",
                                width: "15px",
                              }}
                            />
                          )}
                          &nbsp;&nbsp;{notebook.name}
                        </Menu.Item>
                      ))}
                    </Menu>
                  }
                >
                  <span style={{}}>
                    {note.temp.savedIn && note.temp.savedIn.length > 0 ? (
                      <>
                        {" "}
                        <i className="fas fa-bookmark"></i>&nbsp; Saved
                      </>
                    ) : (
                      <>
                        <i className="far fa-bookmark"></i>&nbsp; Save&nbsp;
                      </>
                    )}
                  </span>
                </Dropdown.Button>
              )}
            </span>
          </div>
          {/* Show/Hide Toolbar & Save Button */}
        </div>
        <div
          className="noteview-note-info"
          style={
            isSmallDevice
              ? {
                  // height: "80px",
                  backgroundColor: theme.backgroundColor,
                  margin: "10px 15px",
                  padding: "10px",
                }
              : {
                  // position: "sticky",
                  // right: "0",
                  // float: "right",
                  // textDecoration: "inherit",
                  // fontSize: "14px",
                  // border: "1px solid #d1d1d1",
                  // padding: "5px",
                  // //top: "0",
                  // width: "190px",
                  // margin: "10px 15px",
                  // zIndex: "2",
                  // backgroundColor: theme.backgroundColor,
                  backgroundColor: theme.backgroundColor,
                  // margin: "10px 15px",
                  padding: "0px 10px",
                }
          }
        >
          {/* {!isSmallDevice && <div />} */}
          {note.temp && (
            <div
              style={{
                display: "inline-grid",
                gridTemplateColumns: "50px auto",
                margin: "0px 10px",
                gridGap: "5px",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "600",
                cursor: "pointer",
              }}
            >
              <div>
                <Avatar
                  style={{ display: "inline-block" }}
                  size={50}
                  src={
                    note.temp &&
                    `https://${note.temp.author.display_picture.downloadUrl}`
                  }
                />
              </div>
              <div>
                <Link
                  style={{
                    display: "inline-block",
                    margin: "10px",
                    color: theme.color,
                  }}
                  to={`/${note.temp.author.username}`}
                >
                  {note.temp.author.name}
                  <div />@{note.temp.author.username}
                </Link>
              </div>
            </div>
          )}
          {/* {!isSmallDevice && <div style={{ height: "10px" }} />} */}
          <div
            style={
              isSmallDevice
                ? {
                    margin: "10px",
                  }
                : {
                    display: "inline-block",
                    float: "right",
                    position: "relative",
                  }
            }
          >
            {this.props.note ? (
              <>
                {(userId === note.authorId ||
                  note.temp.notebookPermission === "view" ||
                  note.temp.notebooPermission === "edit") && (
                  <>
                    <div style={{ marginTop: "3px" }}>
                      In:
                      <Link
                        to={
                          note.temp &&
                          note.temp.notebook &&
                          `/?notebook=${note.temp.notebook.id}`
                        }
                      >
                        <Button
                          type="link"
                          style={{ fontSize: "14px", padding: "0" }}
                          onClick={(e) => {
                            e.preventDefault();
                            note.temp && note.temp.notebook
                              ? this.props.selectNotebook(note.temp.notebook)
                              : message.error("Notebook not attatched");
                          }}
                        >
                          &nbsp;
                          {note.temp &&
                            note.temp.notebook &&
                            note.temp.notebook.name}
                        </Button>
                      </Link>
                    </div>
                  </>
                )}
                <span
                  style={
                    isSmallDevice
                      ? {}
                      : {
                          display: "inline-block",
                          marginTop:
                            userId === note.authorId || note.temp.notebook
                              ? "5px"
                              : "20px",
                        }
                  }
                >
                  {this.props.note.updatedAt === this.props.note.createdAt
                    ? "Written on "
                    : "Last edited "}
                  &nbsp;
                  <span
                    style={{
                      float: !isSmallDevice && "right",
                      fontWeight: "500",
                    }}
                  >
                    {moment(this.props.note.updatedAt).format("Do MMMM, YYYY")}
                  </span>
                </span>
                <div />
                {/* <br /> */}
                {/* Created:&nbsp;
                  <span
                    style={{
                      float: !isSmallDevice && "right",
                      fontWeight: "500",
                    }}
                  >
                    {moment(this.props.note.updatedAt).format("Do MMMM, YYYY")}
                  </span> */}
              </>
            ) : (
              <div />
            )}
          </div>
        </div>

        <>
          <div
            className="noteview-note-container"
            style={{
              padding: "0 0px",
              // marginTop: "5px",
              paddingTop: this.state.optimizeReadability ? "10px" : "0px",
              fontSize: "14px",
              position: "relative",
            }}
          >
            <div
              style={
                isSmallDevice
                  ? {
                      backgroundColor: theme.backgroundColor,
                      padding: "0 10px",
                    }
                  : {
                      padding: "0 25px",
                      paddingBottom: "25px",
                      margin: this.state.optimizeReadability
                        ? localStorage.leftPanelDisplay === "false"
                          ? "0px 20%"
                          : "0px 10%"
                        : "0px",
                      border: `1px solid ${theme.borderColor1}`,
                      borderLeft: this.state.optimizeReadability
                        ? `1px solid ${theme.borderColor1}`
                        : "none",
                      borderRight: this.state.optimizeReadability
                        ? `1px solid ${theme.borderColor1}`
                        : "none",
                      marginBottom: "15px",
                      boxSizing: "content-box",
                      backgroundColor: theme.backgroundColor,
                    }
              }
            >
              <div className="noteview-title">{note.title}</div>
              <TinyEditor
                key={this.state.editorKey}
                readOnly={true}
                content={note.htmlContent}
                theme={theme}
                handleChange={(e) => {}}
                fontSize={this.state.editorFontSize}
                lineHeight={this.state.editorLineHeight}
              />
              <ActionPanel
                subjectType="note"
                subject={note}
                onShareClick={() => {
                  message.success("Link copied.");
                  navigator.clipboard.writeText(
                    `${process.env.REACT_APP_HOST_ADDRESS}/?note=${note.id}`
                  );
                }}
              />
            </div>
            {/* <div style={{ height: "15px" }}></div> */}
          </div>
        </>

        {/* <div style={{ height: "10px" }}></div> */}
      </div>
    );
    return content;
  }
}

export default Noteview;
