import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import {
  Tooltip,
  Button,
  Input,
  Space,
  Dropdown,
  Menu,
  message,
  Modal,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Responsive, WidthProvider } from "react-grid-layout";

const ResponsiveGridLayout = WidthProvider(Responsive);

export default class Notebookview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      name: this.props.notebook ? this.props.notebook.name : "",
      description: "",
      notesOrder: [],
      newSectionData: [],
      arrangingSectionNotes: false,
      authModalVisible: false,
      tempCoverUrl: null,
    };
  }

  componentDidMount() {
    this.setInitialState();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.notebook !== this.props.notebook) this.setInitialState();
  }

  handleCoverChange(e) {
    var xhr,
      formData,
      fileName = "cover";
    // var id = `${noteId}-${fileName}-${index}`;
    if (!e.target.files[0] || !this.props.notebook || !this.props.notebook.id)
      return;
    const file = e.target.files[0];

    // console.log(file);
    let fileExt = file.name
      .substring(file.name.lastIndexOf(".") + 1)
      .toLowerCase();

    if (
      ["jpg", "jpeg", "gif", "png", "tiff", "tif"].indexOf(
        fileExt.toLowerCase()
      ) === -1
    ) {
      message.error("Upload Failed. Only image files are accepted.");
      return false;
    } else if (file.size > 2 * 1024 * 1024) {
      message.error("Upload Failed. Max file size allowed: 2mb.");
      return false;
    }

    xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open(
      "POST",
      `${process.env.REACT_APP_API_HOST}/api/notebooks/change-cover`
    );
    xhr.setRequestHeader("Authorization", localStorage.jwtToken);
    xhr.setRequestHeader("notebookid", this.props.notebook.id);
    xhr.setRequestHeader("fileext", fileExt);

    xhr.upload.onprogress = function (e) {
      // progress((e.loaded / e.total) * 100);
    };

    xhr.onload = () => {
      if (xhr.status === 403) {
        message.error("HTTP Error: " + xhr.status);
        return;
      }

      if (xhr.status < 200 || xhr.status >= 300) {
        message.error("HTTP Error: " + xhr.status);
        return;
      }

      let data = JSON.parse(xhr.responseText);

      if (data.success) {
        message.success(data.message);
        this.setState({ tempCoverUrl: `https://${data.downloadUrl}` }, () =>
          this.forceUpdate()
        );
      } else {
        message.error(data.message);
      }
      return;
    };

    xhr.onerror = function () {
      message.error(
        "Image upload failed due to a XHR Transport error. Code: " + xhr.status
      );
    };

    formData = new FormData();
    formData.append("file", file, fileName);

    xhr.send(formData);
  }

  removeCover() {
    let remove = () =>
      axios
        .post(`${process.env.REACT_APP_API_HOST}/api/notebooks/remove-cover`, {
          notebookId: this.props.notebook.id,
        })
        .then((res) => {
          if (res.data.success) {
            message.success(res.data.message);
            this.setState({ tempCoverUrl: "removed" });
            // window.location.reload();
          } else if (res.data) message.error(res.data.message);
          else message.error("Some error occured. Please try again later.");
        });

    Modal.confirm({
      title: `Are you sure you want to remove this cover?`,
      icon: <ExclamationCircleOutlined />,
      // content: "CAUTION: Do not publish notes with private information.",
      okText: `Yes, remove`,
      onOk: () => {
        remove();
      },
      okType: "danger",
      onCancel() {},
    });
  }

  setInitialState() {
    // console.log(this.props.notebook);
    this.setState(
      {
        editMode: false,
        name: this.props.notebook ? this.props.notebook.name : "",
        description:
          this.props.notebook && this.props.notebook.description
            ? this.props.notebook.description
            : "",
        notesOrder:
          this.props.notebook &&
          this.props.notebook.info1 &&
          this.props.notebook.info1.notesOrder
            ? // Checking if notesOrder has all the notes and sections in the notebook which might be created later
              this.props.notebook.info1.notesOrder.filter(
                (id) =>
                  (this.props.notebook.notes &&
                    this.props.notebook.notes.filter((n) => n.id === id)
                      .length > 0) ||
                  (this.props.notebook.sections &&
                    this.props.notebook.sections.filter((s) => s.id === id)
                      .length > 0)
              ) === this.props.notebook.info1.notesOrder
              ? this.props.notebook.info1.notesOrder
              : [
                  // Removing notes/sections that has been removed
                  ...this.props.notebook.info1.notesOrder.filter(
                    (id) =>
                      (this.props.notebook.notes &&
                        this.props.notebook.notes.filter((n) => n.id === id)
                          .length > 0) ||
                      (this.props.notebook.sections &&
                        this.props.notebook.sections.filter((s) => s.id === id)
                          .length > 0)
                  ),
                  // Adding notes and sections that have been created
                  ...this.props.notebook.notes
                    .filter(
                      (note) =>
                        !this.props.notebook.info1.notesOrder.includes(note.id)
                    )
                    .map((n) => n.id),
                  ...this.props.notebook.sections
                    .filter(
                      (section) =>
                        !this.props.notebook.info1.notesOrder.includes(
                          section.id
                        )
                    )
                    .map((s) => s.id),
                ]
            : this.props.notebook && this.props.notebook.notes
            ? [
                ...this.props.notebook.notes.map(
                  (note) => !note.sectionId && note.id
                ),
                ...this.props.notebook.sections.map((s) => s.id),
              ]
            : [],
        newSectionData: [],
        arrangingSectionNotes: false,
      },
      () => {
        // Removing notes that are in sections from notesOrder
        let newNotesOrder = this.state.notesOrder.filter((id) =>
          this.props.notebook.notes.filter((n) => n.id === id).length > 0
            ? // This note is in a section
              !this.props.notebook.notes.filter((n) => n.id === id)[0].sectionId
            : // This is a section
              true
        );
        // Flattening array cause they were getting
        newNotesOrder = [].concat(...newNotesOrder);
        this.setState({ notesOrder: newNotesOrder });
      }
    );
  }

  render() {
    const { notebook, theme } = this.props;
    const { editMode, notesOrder, tempCoverUrl } = this.state;
    const { sections } = notebook;

    // console.log(notebook);
    // console.log(this.state.notesOrder);

    const noteItem = (note, index, length) => {
      return note && note.id ? (
        <div
          style={{
            fontSize: "16px",
            fontWeight: "500",
            // borderBottom:
            //   length && index === length - 1 ? "none" : "1px solid #d2d2d2",
            padding: "10px 15px",
            cursor: "pointer",
          }}
          onClick={() => this.props.userId && this.props.selectNote(note.id)}
        >
          <Link
            to={`/?note=${note.id}`}
            style={{ color: theme.color }}
            onClick={(e) => this.props.userId && e.preventDefault()}
          >
            {parseInt(index) + 1}. {note.title}
            <span style={{ fontSize: "12px", float: "right" }}>
              {moment(note.updatedAt).format("MMMM Do, YYYY")}
            </span>
          </Link>
        </div>
      ) : null;
    };

    const sectionItem = (section, index) => {
      if (!section) return null;
      let notes = notebook.notes.filter(
        (note) => note.sectionId === section.id
      );
      let notesOrder =
        section.info1 && section.info1.notesOrder
          ? section.info1.notesOrder.filter(
              (id) => notes.filter((n) => n.id === id).length > 0
            ) === section.info1.notesOrder
            ? section.info1.notesOrder
            : [
                // Removing notes that has been removed from section
                ...section.info1.notesOrder.filter(
                  (id) => notes.filter((n) => n.id === id).length > 0
                ),
                // Adding new notes that has been created but are not in notesorder
                ...notes
                  .filter((note) => !section.info1.notesOrder.includes(note.id))
                  .map((note) => note.id),
              ]
          : notes.map((note) => note.id);

      // console.log(section.info1.notesOrder.length === notes.length);

      return (
        <div
          style={{
            fontSize: "16px",
            fontWeight: "500",
            // borderBottom: "1px solid #d2d2d2",
            padding: "10px 15px",
            cursor: "pointer",
          }}
        >
          <div style={{ fontSize: "22px" }}>{section.title}</div>
          {notesOrder.map((id, index) =>
            noteItem(
              notes.filter((note) => note.id === id)[0],
              index,
              notes.length
            )
          )}
        </div>
      );
    };

    const sectionEditItem = (section, index) => {
      let notes = notebook.notes.filter(
        (note) => note.sectionId === section.id
      );
      let notesOrder =
        section.info1 && section.info1.notesOrder
          ? section.info1.notesOrder.filter(
              (id) => notes.filter((n) => n.id === id).length > 0
            ) === section.info1.notesOrder
            ? section.info1.notesOrder
            : [
                // Removing notes that has been removed from section
                ...section.info1.notesOrder.filter(
                  (id) => notes.filter((n) => n.id === id).length > 0
                ),
                // Adding new notes that has been created but are not in notesorder
                ...notes
                  .filter((note) => !section.info1.notesOrder.includes(note.id))
                  .map((note) => note.id),
              ]
          : notes.map((note) => note.id);

      return (
        <div
          style={{
            fontSize: "16px",
            fontWeight: "500",
            // borderBottom: "1px solid #d2d2d2",
            padding: "10px 15px",
            // paddingBottom: "20px",
            cursor: "pointer",
            // border: "1px solid red",
          }}
          key={section.id}
        >
          {" "}
          <div style={{ fontSize: "22px" }}>{section.title}</div>
          {
            <ResponsiveGridLayout
              rowHeight={40}
              isBounded={true}
              onDragStop={(e) => {
                let newOrder = [];
                for (var i = 0; i < e.length; i++) {
                  newOrder[e[i].y] = e[i].i;
                }
                newOrder = newOrder.filter((id) => id);
                let newSectionData = this.state.newSectionData.filter(
                  (s) => s.id !== section.id
                );
                let sectionEle = newSectionData.filter(
                  (s) => s.id === section.id
                );
                if (sectionEle.length > 0) {
                  sectionEle = sectionEle[0];
                  newSectionData.push({ ...sectionEle, notesOrder: newOrder });
                } else {
                  newSectionData.push({
                    id: section.id,
                    notesOrder: newOrder,
                    title: section.title,
                  });
                }
                // console.log(newSectionData);
                this.setState({ newSectionData });
              }}
              breakpoints={{
                lg: 1200,
                md: 996,
                sm: 768,
                xs: 480,
                xxs: 0,
              }}
              cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
            >
              {notesOrder.map((id, index) => {
                let note = notes.filter((n) => n.id === id)[0];
                return !note ? null : (
                  <div
                    className="editmode-section-note-item"
                    style={{
                      fontSize: "16px",
                      fontWeight: "450",
                      // borderBottom: "1px solid #d2d2d2",
                      padding: "10px 15px",
                      cursor: "pointer",
                      // border: "1px solid blue",
                      // height: "50px",
                    }}
                    key={note.id}
                    data-grid={{
                      x: 0,
                      y: index,
                      w: 10,
                      h: 1,
                      isResizable: false,
                    }}
                    // onClick={(e) => console.log("i was clicked")}
                    onPointerDown={(e) =>
                      this.setState({ arrangingSectionNotes: true })
                    }
                    onPointerUp={(e) =>
                      this.setState({ arrangingSectionNotes: false })
                    }
                  >
                    {note.title}
                  </div>
                );
              })}
            </ResponsiveGridLayout>
          }
        </div>
      );
    };

    return (
      notebook &&
      notebook.temp && (
        <>
          <div
            style={{
              height: "100%",
              backgroundColor: this.props.userId
                ? theme.backgroundColor2
                : theme.backgroundColor,
              color: theme.color,
            }}
          >
            <div
              className="notebook-view-header"
              style={{
                height: "50px",
                position: "sticky",
                top: "0",
                width: "100%",
                zIndex: "20",
                padding: "10px 15px",
                borderBottom: this.props.userId
                  ? `1px solid ${theme.borderColor1}`
                  : "none",
                backgroundColor: theme.backgroundColor,
                display: this.props.userId ? "auto" : "none",
              }}
            >
              {this.props.userId === notebook.ownerId ? (
                <Space size={20}>
                  <Tooltip
                    title={
                      notebook.isProtected ? "Notebook is protected" : "Share"
                    }
                  >
                    <Button
                      icon={
                        notebook.sharing === "private" ? (
                          <i className="fas fa-lock"></i>
                        ) : notebook.sharing === "shared" ? (
                          <i className="fas fa-share-alt"></i>
                        ) : (
                          <i className="fas fa-globe"></i>
                        )
                      }
                      type={
                        notebook.sharing === "private" ? "default" : "primary"
                      }
                      style={{ fontSize: "inherit" }}
                      ghost={notebook.sharing === "shared"}
                      onClick={() => this.props.showSharingModal()}
                    >
                      &nbsp;
                      {notebook.sharing === "private"
                        ? "Only You"
                        : notebook.sharing === "published"
                        ? "Public"
                        : "Shared"}
                    </Button>
                  </Tooltip>
                  <Tooltip
                    title={
                      notebook.isProtected &&
                      "Protected notebooks can't be published."
                    }
                  >
                    <Button
                      disabled={notebook.isProtected}
                      type="primary"
                      icon={<i className="fas fa-users"></i>}
                      onClick={() => this.props.publishNotebookClick(notebook)}
                      ghost={notebook.sharing === "published"}
                    >
                      &nbsp;
                      {notebook.sharing === "published"
                        ? "Published"
                        : "Publish"}
                    </Button>
                  </Tooltip>
                  <Dropdown
                    trigger={["click"]}
                    overlay={
                      <Menu>
                        <Menu.Item
                          key="edit"
                          // icon={<i className="fas fa-times"></i>}
                          onClick={() => this.setState({ editMode: true })}
                        >
                          {" "}
                          <span
                            style={{ display: "inline-block", width: "25px" }}
                          >
                            <i className="far fa-pencil"></i>
                          </span>
                          Edit Notebook
                        </Menu.Item>
                        <Menu.Divider />
                        <Menu.Item
                          key="7"
                          danger
                          // icon={<i className="fas fa-times"></i>}
                          onClick={() => this.props.deleteNotebook(notebook)}
                        >
                          {" "}
                          <span
                            style={{ display: "inline-block", width: "25px" }}
                          >
                            <i className="fas fa-times"></i>
                          </span>
                          Delete Notebook
                        </Menu.Item>
                      </Menu>
                    }
                    tigger={["click"]}
                  >
                    <Button
                      //style={{ float: "right" }}
                      icon={<i className="fas fa-ellipsis-h"></i>}
                    />
                  </Dropdown>
                </Space>
              ) : (
                <div style={{ float: "right" }}>
                  <Button
                    type={this.props.userId ? "primary" : "link"}
                    onClick={() => this.props.saveNotebook(notebook.id)}
                    style={{ width: "90px" }}
                    icon={
                      notebook.temp.hasSaved ? (
                        <i className="fas fa-bookmark"></i>
                      ) : (
                        <i className="far fa-bookmark"></i>
                      )
                    }
                  >
                    &nbsp;&nbsp;{notebook.temp.saverCount}&nbsp;Saves
                  </Button>
                </div>
              )}
            </div>

            <div
              style={{
                margin: this.props.userId ? "20px" : "2px 10px",
                border: this.props.userId
                  ? "none"
                  : `1px solid ${theme.borderColor1}`,
                borderRadius: "5px",
                boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.25)",
                backgroundColor: theme.backgroundColor,
              }}
            >
              <div
                className="notebook-view-header"
                style={{
                  padding: "15px",
                  textAlign: "center",
                  // borderBottom: `1px solid ${theme.borderColor1}`,
                  position: "relative",
                }}
              >
                {this.props.userId === notebook.ownerId && (
                  <>
                    <Tooltip title={"Remove Cover"}>
                      <Button
                        type="link"
                        // shape="circle"
                        size={"large"}
                        style={{
                          display:
                            editMode && notebook.info1 && notebook.info1.cover
                              ? "inline-block"
                              : "none",
                          color: "red",
                          position: "absolute",
                          top: "0",
                          left: "0",
                        }}
                        icon={<i className="fal fa-trash-alt"></i>}
                        onClick={(e) => {
                          this.removeCover();
                        }}
                      />
                    </Tooltip>

                    <div style={{ position: "absolute", top: "0", right: "0" }}>
                      <Tooltip title={"Discard Changes"}>
                        <Button
                          type="link"
                          // shape="circle"
                          size={"large"}
                          icon={<i className="fal fa-times"></i>}
                          style={{
                            color: "red",
                            display: editMode ? "inline-block" : "none",
                          }}
                          onClick={(e) => this.setInitialState()}
                        />
                      </Tooltip>
                      <Tooltip
                        title={
                          notebook.info1 && notebook.info1.cover
                            ? "Change Cover"
                            : "Set Cover"
                        }
                      >
                        <Button
                          type="link"
                          // shape="circle"
                          size={"large"}
                          style={{
                            display: editMode ? "inline-block" : "none",
                            margin: "0 10px",
                          }}
                          icon={<i className="fal fa-image"></i>}
                          onClick={(e) => {
                            document
                              .getElementById("notebook-cover-change-input")
                              .click();
                          }}
                        />
                      </Tooltip>
                      <Tooltip title={editMode ? "Save" : "Edit"}>
                        <Button
                          type="primary"
                          // shape="circle"
                          size={editMode ? "large" : "medium"}
                          icon={
                            !editMode ? (
                              <i className="fal fa-pencil-alt"></i>
                            ) : (
                              <i className="fal fa-save"></i>
                            )
                          }
                          onClick={(e) => {
                            if (this.state.editMode) {
                              this.props.editNotebook({ ...this.state });
                              this.setState({ editMode: false });
                            } else this.setState({ editMode: true });
                          }}
                        />
                      </Tooltip>
                    </div>
                  </>
                )}
                {tempCoverUrl === "removed" ? null : (notebook.info1 &&
                    notebook.info1.cover) ||
                  (tempCoverUrl && tempCoverUrl !== "removed") ? (
                  <div
                    style={{
                      margin: this.props.userId ? "-16px" : "-2px -10px",
                      marginBottom: "20px",
                    }}
                  >
                    <img
                      alt={notebook.title}
                      src={
                        !tempCoverUrl
                          ? notebook.info1.cover.downloadUrl
                          : tempCoverUrl
                      }
                      style={{ maxWidth: "min(100%, 600px)" }}
                    />
                  </div>
                ) : null}
                <div
                  style={{
                    fontSize: "32px",
                    fontWeight: "500",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    wordWrap: "break-word",
                  }}
                >
                  {editMode ? (
                    <Input
                      size="large"
                      defaultValue={this.state.name}
                      onChange={(e) => this.setState({ name: e.target.value })}
                      placeholder="Enter name for notebook"
                      style={{ width: "50%" }}
                    />
                  ) : (
                    notebook.name
                  )}
                </div>
                by
                <Link
                  to={`/${notebook.temp.owner.username}`}
                  style={{ color: theme.color, fontWeight: "500" }}
                >
                  {" "}
                  <Tooltip
                    placement="right"
                    title={`@${notebook.temp.owner.username}`}
                  >
                    {notebook.temp.owner.name}
                  </Tooltip>
                </Link>
              </div>
              {this.state.description.length > 0 || editMode ? (
                editMode ? (
                  <div style={{ padding: "25px" }}>
                    <Input.TextArea
                      defaultValue={this.state.description}
                      onChange={(e) =>
                        this.setState({ description: e.target.value })
                      }
                      placeholder="Write description for this notebook (First 300 chars will be shown on the card)"
                      showCount
                      autoSize
                    />
                  </div>
                ) : (
                  <div style={{ padding: "25px" }}>{notebook.description}</div>
                )
              ) : null}

              <div className="notebook-view-content">
                <div
                  style={{
                    padding: "5px 15px",
                    borderBottom: editMode && `1px solid ${theme.borderColor1}`,
                    // borderTop: "2px solid #d1d1d1",
                  }}
                >
                  {notebook.temp.notesCount}{" "}
                  {notebook.temp.notesCount === 1 ? "note" : "notes"}
                </div>
                {notebook.notes && notebook.notes.length && !editMode ? (
                  notesOrder.map((id, index) =>
                    notebook.notes.filter((note) => note.id === id).length > 0
                      ? noteItem(
                          ...notebook.notes.filter(
                            (note) => note.id === id && !note.sectionId
                          ),
                          index
                        )
                      : sectionItem(
                          notebook.sections.filter(
                            (section) => section.id === id
                          )[0],
                          index
                        )
                  )
                ) : (
                  <ResponsiveGridLayout
                    // className="layout"
                    //cols={notebook.notes.length}
                    rowHeight={40}
                    // width={1000}
                    isBounded={true}
                    onDragStop={(e) => {
                      if (!e) return;
                      let newOrder = [];
                      for (var i = 0; i < e.length; i++) {
                        newOrder[e[i].y] = e[i].i;
                      }
                      // Removing empty spaces
                      newOrder = newOrder.filter((id) => id);
                      this.setState({ notesOrder: newOrder });
                    }}
                    breakpoints={{
                      lg: 1200,
                      md: 996,
                      sm: 768,
                      xs: 480,
                      xxs: 0,
                    }}
                    cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
                    isDraggable={!this.state.arrangingSectionNotes}
                  >
                    {notesOrder.map((id, index) =>
                      id &&
                      notebook.notes &&
                      notebook.notes.filter((note) => note.id === id).length ===
                        0 ? (
                        <div
                          style={{ border: `1px solid ${theme.borderColor1}` }}
                          key={id}
                          data-grid={{
                            x: 0,
                            y: index,
                            w: 10,
                            h:
                              notebook.notes.filter((n) => n.sectionId === id)
                                .length + 2,
                            isResizable: false,
                            // static: this.state.arrangingSectionNotes,
                          }}
                        >
                          {sectionEditItem(
                            ...(sections &&
                              sections.filter((s) => s.id === id)),
                            index
                          )}
                        </div>
                      ) : (
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: "450",
                            // borderBottom: "1px solid #d2d2d2",
                            padding: "5px 15px",
                            cursor: "pointer",
                          }}
                          key={id}
                          data-grid={{
                            x: 0,
                            y: index,
                            w: 10,
                            h: 1,
                            isResizable: false,
                          }}
                        >
                          {notebook.notes &&
                            notebook.notes
                              .filter((note) => note.id === id)
                              .map((note) => note.title)}
                        </div>
                      )
                    )}
                  </ResponsiveGridLayout>
                )}
              </div>
            </div>
            <input
              id="notebook-cover-change-input"
              type="file"
              style={{ display: "none" }}
              onChange={(e) => this.handleCoverChange(e)}
            />
            <div style={{ height: "15px" }} />
          </div>
        </>
      )
    );
  }
}
