import React from "react";
import { Select, Input } from "antd";
import { position, offset } from "caret-pos";
import {
  code,
  cool,
  image,
  quote,
  tag,
  numList,
  bulletList,
  checkList,
  table,
} from "../static";

const { Option } = Select;

const menuOptions = [
  {
    title: "Action Item",
    description: "Create task or a todo",
    img: {
      src: checkList,
      alt: `Create checklist for tasks and actions`,
    },
  },
  {
    title: "Image",
    description: "Add image to your note",
    img: {
      src: image,
      alt: `Insert image`,
    },
    action: "",
  },
  {
    title: "Numbered List",
    description: "Insert ordered list",
    img: {
      src: numList,
      alt: `Insert numbered list`,
    },
  },
  {
    title: "Bullet List",
    description: "Insert unordered list",
    img: {
      src: bulletList,
      alt: `Insert bullet list`,
    },
  },
  {
    title: "Columns",
    description: "Insert columns to create grid layout",
    icon: {
      class: "fad fa-columns",
    },
  },
  {
    title: "Expand",
    description: "Insert toggle for collapsible data",
    // img: {
    //   src: scrollArrow,
    //   alt: `Insert toggle list`,
    // },
    icon: {
      class: "far fa-chevron-right",
    },
  },

  {
    title: "Table",
    description: "Insert a table",
    img: {
      src: table,
      alt: `Insert table`,
    },
    // icon: {
    //   class: "far fa-table",
    // },
  },
  {
    title: "Quote",
    description: "Insert a quote or citation",
    img: {
      src: quote,
      alt: `Insert a quote`,
    },
  },
  {
    title: "Code Snippet",
    description: "Insert code with syntax highlighting",
    img: {
      src: code,
      alt: `Insert code snippet`,
    },
  },
  {
    title: "Emoji",
    description: "Insert emojis to express ideas and emotions",
    img: {
      src: cool,
      alt: `Insert emoji`,
    },
  },
  {
    title: "Tag/Status",
    description: "Add a custom tag or status",
    img: {
      src: tag,
      alt: `Insert tag`,
    },
  },
  {
    title: "Message Box",
    description: "Insert message box",
    icon: {
      class: "far fa-question-square",
      style: {
        color: "#898989",
      },
    },
  },
  {
    title: "Info Message Box",
    description: "Insert info message box",
    icon: {
      class: "far fa-question-square",
      style: {
        color: "hsl(207, 61%,  53%)",
      },
    },
  },
  {
    title: "Success Message Box",
    description: "Insert success message box",
    icon: {
      class: "far fa-question-square",
      style: {
        color: "hsl(171, 100%, 41%)",
      },
    },
  },
  {
    title: "Warning Message Box",
    description: "Insert warning message box",
    icon: {
      class: "far fa-question-square",
      style: {
        color: "hsl(44,  100%, 77%)",
      },
    },
  },
  {
    title: "Danger Message Box",
    description: "Insert danger message box",
    icon: {
      class: "far fa-question-square",
      style: {
        color: "hsl(348, 86%, 61%)",
      },
    },
  },
  {
    title: "Panel",
    description: "Insert panel to create focus",
    icon: {
      class: "far fa-info-circle",
      style: {
        color: "#898989",
      },
    },
  },
  {
    title: "Info Panel",
    description: "Insert info panel to create focus",
    icon: {
      class: "far fa-info-circle",
      style: {
        color: "hsl(207, 61%,  53%)",
      },
    },
  },
  {
    title: "Info Panel Light",
    description: "Insert light info panel to create focus",
    icon: {
      class: "fal fa-info-circle",
      style: {
        color: "hsl(207, 61%,  53%)",
      },
    },
  },
  {
    title: "Success Panel",
    description: "Insert success panel to create focus",
    icon: {
      class: "far fa-info-circle",
      style: {
        color: "hsl(171, 100%, 41%)",
      },
    },
  },
  {
    title: "Success Panel Light",
    description: "Insert light success panel to create focus",
    icon: {
      class: "fal fa-info-circle",
      style: {
        color: "hsl(171, 100%, 41%)",
      },
    },
  },
  {
    title: "Warning Panel",
    description: "Insert warning panel to create focus",
    icon: {
      class: "far fa-info-circle",
      style: {
        color: "hsl(44,  100%, 77%)",
      },
    },
  },
  {
    title: "Warning Panel Light",
    description: "Insert light warning panel to create focus",
    icon: {
      class: "fal fa-info-circle",
      style: {
        color: "hsl(44,  100%, 77%)",
      },
    },
  },
  {
    title: "Danger Panel",
    description: "Insert danger panel to create focus",
    icon: {
      class: "far fa-info-circle",
      style: {
        color: "hsl(348, 86%, 61%)",
      },
    },
  },
  {
    title: "Danger Panel Light",
    description: "Insert light danger panel to create focus",
    icon: {
      class: "fal fa-info-circle",
      style: {
        color: "hsl(348, 86%, 61%)",
      },
    },
  },
  {
    title: "Divider",
    description: "Insert horizontal rule",
    icon: {
      class: "far fa-horizontal-rule",
      style: {
        padding: "0px",
      },
    },
  },
  {
    title: "Heading 4",
    description: "Use this for deep headings",
    icon: {
      class: "far fa-h4",
    },
  },
  {
    title: "Heading 3",
    description: "Use this for sub sections or group headings",
    icon: {
      class: "far fa-h3",
    },
  },
  {
    title: "Heading 2",
    description: "Use this for key sections",
    icon: {
      class: "far fa-h2",
    },
  },
  {
    title: "Heading 1",
    description: "Insert top level heading",
    icon: {
      class: "far fa-h1",
    },
  },

  // {
  //   title: "Heading 5",
  //   description: "Use this for grouping list items",
  //   icon: {
  //     class: "far fa-h5",
  //   },
  // },
  // {
  //   title: "Heading 6",
  //   description: "Use this for low level heading",
  //   icon: {
  //     class: "far fa-h6",
  //   },
  // },
];

const panelOptions = [
  {
    title: "Default Panel",
    icon: {
      class: "far fa-info-circle",
      style: {
        color: "hsl(0, 0%, 96%)",
      },
    },
  },
  {
    title: "Info Panel",
    icon: {
      class: "far fa-info-circle",
      style: {
        color: "hsl(0, 0%, 96%)",
      },
    },
  },
  {
    title: "Primary Panel",
    icon: {
      class: "far fa-info-circle",
      style: {
        color: "hsl(0, 0%, 96%)",
      },
    },
  },
  {
    title: "Success Panel",
    icon: {
      class: "far fa-info-circle",
      style: {
        color: "hsl(0, 0%, 96%)",
      },
    },
  },
  {
    title: "Warning Panel",
    icon: {
      class: "far fa-info-circle",
      style: {
        color: "hsl(0, 0%, 96%)",
      },
    },
  },
  {
    title: "Danger Panel",
    icon: {
      class: "far fa-info-circle",
      style: {
        color: "hsl(0, 0%, 96%)",
      },
    },
  },
  {
    title: "Default Panel Light",
    icon: {
      class: "far fa-info-circle",
      style: {
        color: "hsl(0, 0%, 96%)",
      },
    },
  },
  {
    title: "Info Panel Light",
    icon: {
      class: "far fa-info-circle",
      style: {
        color: "hsl(0, 0%, 96%)",
      },
    },
  },
  {
    title: "Primary Panel Light",
    icon: {
      class: "far fa-info-circle",
      style: {
        color: "hsl(0, 0%, 96%)",
      },
    },
  },
  {
    title: "Success Panel Light",
    icon: {
      class: "far fa-info-circle",
      style: {
        color: "hsl(0, 0%, 96%)",
      },
    },
  },
  {
    title: "Warning Panel Light",
    icon: {
      class: "far fa-info-circle",
      style: {
        color: "hsl(0, 0%, 96%)",
      },
    },
  },
  {
    title: "Danger Panel Light",
    icon: {
      class: "far fa-info-circle",
      style: {
        color: "hsl(0, 0%, 96%)",
      },
    },
  },
];

export class SlashCommands extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "/",
      blurbykey: false,
    };
  }

  render() {
    let { x, y } = this.props.position;
    const optionDisplay = this.props.slashCommandOptionDisplay;
    const { searchValue } = this.state;
    const positionAttributes = { top: y, left: x };

    const listItem = (item) => (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 5fr",
          gridGap: "0px 6px",
          height: "56px",
        }}
      >
        <div style={{ padding: "5px" }}>
          {item.img ? (
            <img
              src={item.img.src}
              alt={item.img.alt}
              style={{
                border: "1px solid #ededed",
                padding: "2px",
                ...item.img.style,
              }}
            />
          ) : (
            <i
              className={item.icon.class}
              style={{
                border: "1px solid #ededed",
                padding: "2px",
                color: "#5d90c0",
                fontSize: "28px",
                width: "36px",
                textAlign: "center",
                ...item.icon.style,
              }}
            ></i>
          )}
        </div>
        <div style={{ paddingTop: "5px" }}>
          <div style={{ fontWeight: "500" }}>{item.title}</div>
          {item.description && (
            <div
              style={{
                fontSize: "12px",
                lineHeight: "1.2",
                wordWrap: "break-word",
                whiteSpace: "pre-line",
                color: "#787878",
              }}
            >
              {item.description}
            </div>
          )}
        </div>
      </div>
    );

    const slashmenu = () => {
      switch (optionDisplay) {
        case "panelmenu":
          return panelOptions.map((item, index) => (
            <Option key={item.title} value={item.title}>
              {listItem(item, index)}
            </Option>
          ));
        default:
          return menuOptions.map((item, index) => (
            <Option
              {...item.eventHandlers}
              key={item.description}
              value={item.title}
            >
              {listItem(item, index)}
            </Option>
          ));
      }
    };

    return (
      <div
        className="ed-slashcommands-wrapper"
        id="ed-slashcommands-wrapper-id"
        style={
          position
            ? {
                position: "absolute",
                ...positionAttributes,
              }
            : { display: "inline-block" }
        }
      >
        <Select
          // defaultValue={searchValue}
          // id="ed-slashmenu-id"
          showSearch
          // placeholder={`Type '/' for options`}
          defaultOpen={true}
          style={{
            width: 320,
            padding: "0",
            margin: "0",
            fontSize: "18px",
            fontFamily: "Helvetica, Arial, Sans-Serif",
            left: "0",
            height: "20px",
            backgroundColor: "#fff",
            ...this.props.editorStyle,
          }}
          dropdownStyle={{
            boxShadow: "-2px 2px 5px 0px rgba(0,0,0,0.35)",
          }}
          listHeight={400}
          autoFocus={true}
          onSearch={(e) => {
            this.setState({ searchValue: e });
          }}
          onKeyDown={(e) => {
            // console.log(e);
            if (
              e.key === "Escape" ||
              (searchValue.length === 1 && e.key === "Backspace") ||
              e.key === " "
            ) {
              if (searchValue.length === 1 && e.key === "Backspace")
                e.preventDefault();
              e.stopPropagation();
              this.setState({ blurbykey: true }, () =>
                this.props.onBlur(e.key === " " ? searchValue : null)
              );
            }
          }}
          onSelect={(selectedValue, item) => {
            // console.log(item);
            switch (selectedValue) {
              default:
                return this.setState({ blurbykey: true }, () =>
                  this.props.onSelect(selectedValue, searchValue)
                );
            }
          }}
          onBlur={(e, value = this.state.searchValue) => {
            // This is for blurring by outside click
            if (this.state.blurbykey) return;
            this.props.onBlur(value);
          }}
          filterOption={(searchValue, option) =>
            option.value
              .toLowerCase()
              .includes(
                searchValue.substr(1, searchValue.length - 1).toLowerCase()
              ) ||
            option.key
              .toLowerCase()
              .includes(
                searchValue.substr(1, searchValue.length - 1).toLowerCase()
              )
          }
          bordered={false}
          showArrow={false}
          notFoundContent={null}
        >
          {slashmenu()}
        </Select>
      </div>
    );
  }
}

export default SlashCommands;

export const getCaretCoordinates = (useSelection = false, fromStart = true) => {
  // let x, y;
  // const isSupported = typeof window.getSelection !== "undefined";
  // if (isSupported) {
  //   const selection = window.getSelection();
  //   console.log(selection);
  //   if (selection.rangeCount !== 0) {
  //     const range = selection.getRangeAt(0).cloneRange();
  //     range.collapse(fromStart ? true : false);
  //     const rect = range.getClientRects()[0];
  //     if (rect) {
  //       console.log("1");
  //       x = rect.left;
  //       y = rect.top - 5; // -10 is minor adjustmment
  //     } else {
  //       // This is to fix menu popping at the editor base node
  //       if (selection.extentNode.id === "ed-editor-id") {
  //         console.log("2");
  //         x = selection.extentNode.lastChild.getBoundingClientRect().left;
  //         y = selection.extentNode.lastChild.getBoundingClientRect().top;
  //       } else {
  //         console.log("3");
  //         x = selection.extentNode.getBoundingClientRect().left;
  //         y = selection.extentNode.getBoundingClientRect().top + 1;
  //       }
  //     }
  //   }
  // }
  // return { x, y };
  let input = document.querySelector("#ed-editor-id");
  // let input = document.querySelector("#note-editor-cont-id");
  // // console.log(input);
  // // const pos = position(input); // { left: 15, top: 30, height: 20, pos: 15 }
  const off = offset(input); // { left: 15, top: 30, height: 20 }
  // // console.log(off);
  return { x: off.left, y: off.top - 5 };

  // let selection = window.getSelection();
  // console.log(selection);
  // let x = input.getBoundingClientRect().left;
  // let y = input.getBoundingClientRect().top;
  // return { x, y };
};

export const checkNewLine = (useEditor = false) => {
  if (document.getSelection().anchorNode) {
    let node;
    if (useEditor) node = document.getElementById("ed-editor-id");
    else {
      node = document.getSelection().anchorNode;
    }

    var nodeContents = "";
    if (node.innerHTML) {
      nodeContents = node.innerHTML.toLowerCase();
    } else {
      nodeContents = node.innerHTML;
    }
    if (nodeContents !== undefined) {
      // console.log(nodeContents);
      if (
        nodeContents === "" ||
        nodeContents === "<br>" ||
        nodeContents === "<br/>" ||
        nodeContents.substr(nodeContents.length - 5) === "<br/>" ||
        nodeContents.substr(nodeContents.length - 4) === "<br>"
      ) {
        // console.log("you are on a new line :]");
        return true;
      } else {
        // console.log("nope not a new line");
        return false;
      }
    } else {
      // console.log("nice try, but no not a new line");
      return false;
    }
  }
};

export const Placeholder = ({ position, onBlur }) => {
  // console.log(position);
  if (!position) return null;
  return (
    <div
      className="ed-slmplaceholder"
      style={{
        position: "absolute",
        left: position.x,
        top: position.y,
        zIndex: "0",
      }}
    >
      <Input
        bordered={false}
        placeholder={`Type '/' for options`}
        onFocus={() => onBlur()}
      />
    </div>
  );
};
